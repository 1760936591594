export default {
    success: 'The vehicle was successfully exported to Autopropulsion',
    warning: {
        error403: 'Your account does not have access to Autopropulsion',
        error404: 'We could not reach Autopropulsion; please try again later or contact our support team',
        error500: 'There was an error on Autopropulsion\'s side, please contact the support team',
        vehicleAlreadyReceived: 'This vehicle has already been exported to Autopropulsion',
    },
    tooltip: {
        match:
            'The vehicle export to Autopropulsion is includes the make, model, year and odometer. Make sure the values are accurate before exporting',
        niv: 'You must provide a valid VIN in order to have a valid submission through Autopropulsion',
        unsubmitted: 'Export the vehicle to Autopropulsion',
    },
};
