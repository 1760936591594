var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full",class:{ 'flex items-center': _vm.sideBySide }},[(_vm.readOnly)?_c('div',{staticClass:"w-full px-3 label | block",class:_vm.statusClass},[_vm._v(" "+_vm._s(_vm.statusString)+" ")]):(
            !_vm.onlyTime &&
                !_vm.lead.isResultSet() &&
                !_vm.guest &&
                _vm.statusPickerIsEditable &&
                _vm.authUser.hasAccessToEditAccount(_vm.lead.account_id)
        )?_c('el-dropdown',{staticClass:"status-picker | flex",attrs:{"trigger":"click","placement":"bottom-start"},on:{"command":_vm.onResultSelected}},[_c('div',{staticClass:"label | flex-1 inline-flex justify-center items-center",class:_vm.statusClass},[_vm._v(" "+_vm._s(_vm.statusString)+" "),_c('icon',{staticClass:"ml-2",attrs:{"name":"bold/arrow-down-1","scale":"0.55"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.pendingAvailable)?_c('el-dropdown-item',{staticClass:"leading-tight py-2",attrs:{"command":"pending"}},[_c('span',{staticClass:"label label-warning | block"},[_vm._v(_vm._s(_vm.$t('clientCard.pending')))])]):_vm._e(),_c('el-dropdown-item',{staticClass:"leading-tight py-2",attrs:{"command":"attempted"}},[_c('span',{staticClass:"label label-primary | block"},[_vm._v(_vm._s(_vm.$t('clientCard.attempted')))])]),_c('el-dropdown-item',{staticClass:"leading-tight py-2",attrs:{"command":"reached"}},[_c('span',{staticClass:"label label-success | block"},[_vm._v(_vm._s(_vm.$tc('clientCard.reached')))])])],1)],1):(_vm.showStatus)?_c('activix-tooltip',{attrs:{"content":_vm.tooltip('status')}},[_c('div',{staticClass:"label | block",class:_vm.statusClass},[_vm._v(" "+_vm._s(_vm.statusString)+" ")])]):_vm._e(),(_vm.showTime)?_c('div',{staticClass:"flex",class:_vm.sideBySide ? 'ml-1' : 'mt-1'},[_c('activix-tooltip',{attrs:{"content":_vm.tooltip('response_time')}},[_c('span',{staticClass:"label | flex-1",class:{ 'label-default': _vm.responseTimeApplicable || _vm.responseTimeNotApplicableMerged }},[_vm._v(" "+_vm._s(_vm.responseTimeString)+" ")])]),_c('activix-tooltip',{attrs:{"content":_vm.tooltip('first_update_time')}},[_c('span',{staticClass:"label label-default | flex-1 ml-1"},[_vm._v(" "+_vm._s(_vm.firstUpdateTimeString)+" ")])])],1):_vm._e(),_c('add-communication',{attrs:{"opened":_vm.addCommunicationModalOpened,"lead":_vm.lead},on:{"update:opened":function($event){_vm.addCommunicationModalOpened=$event}}}),_vm._t("right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }