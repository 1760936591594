import { initValues } from '@/entities/ModelHelper.ts';
import VehicleDetails from '@/entities/VehicleDetails.ts';
import VehicleCondition from '@/entities/VehicleCondition.ts';
import AdditionalDetail from '@/entities/AdditionalDetail.ts';

export default class DrsTradeInInformation {
    private _vehicle_details: VehicleDetails | null | undefined;

    private _vehicle_condition: VehicleCondition | null | undefined;

    private _additional_details: AdditionalDetail | null | undefined;

    balance_amount: number | undefined = undefined;

    offer_amount: number | undefined = undefined;

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set vehicle_details(vehicleDetails) {
        this._vehicle_details = vehicleDetails ? new VehicleDetails(vehicleDetails) : null;
    }

    set vehicle_condition(vehicleCondition) {
        this._vehicle_condition = vehicleCondition ? new VehicleCondition(vehicleCondition) : null;
    }

    set additional_details(additionalDetails) {
        this._additional_details = additionalDetails ? new AdditionalDetail(additionalDetails) : null;
    }

    get vehicle_details(): VehicleDetails | null | undefined {
        return this._vehicle_details;
    }

    get vehicle_condition(): VehicleCondition | null | undefined {
        return this._vehicle_condition;
    }

    get additional_details(): AdditionalDetail | null | undefined {
        return this._additional_details;
    }

    get conditionQuestionsFormatted(): string {
        const conditionQuestions = this.vehicle_condition?.condition_questions || [];

        return conditionQuestions
            .map((question, index) => {
                let text = `${index + 1}. ${question.question_text} - ${question.answer_text}`;

                if (question.child_question_answers.length > 0) {
                    text += `\n${question.child_question_answers
                        .map((childQuestion) => `     ${childQuestion.question_text}:  ${childQuestion.answer_text}`)
                        .join('\n')}`;
                }

                return text;
            })
            .join('\n\n');
    }

    get isDefined(): boolean {
        return typeof this._vehicle_details !== 'undefined' ||
            typeof this._vehicle_condition !== 'undefined' ||
            typeof this._additional_details !== 'undefined' ||
            typeof this.balance_amount !== 'undefined' ||
            typeof this.offer_amount !== 'undefined';
    }
}
