export enum PreQualificationCreditTier {
    DeepSub = 'Deep Sub',
    SubPrime = 'Sub Prime',
    NearPrime = 'Near Prime',
    Prime = 'Prime',
    SuperPrime = 'Super Prime',
}

export const isPreQualificationCreditTier = (value: any): value is PreQualificationCreditTier => {
    return Object.values(PreQualificationCreditTier).includes(value);
};
