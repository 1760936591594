export default [
    { path: '/search/lead', redirect: { name: 'search', query: { type: 'lead' } } },
    { path: '/search/vehicle', redirect: { name: 'search', query: { type: 'vehicle' } } },
    { path: '/search/user', redirect: { name: 'search', query: { type: 'user' } } },
    {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        meta: {
            accountSelect: {
                disabled: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
