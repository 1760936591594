<template>
    <box-header
        :class="[
            'transition-all duration-300',
            'bg-white -mx-6',
            collapsed ? '!h-12' : '!h-16 lg:!h-18',
        ]"
        :title-class-name="['!text-lg', !collapsed && 'lg:!text-xl']"
    >
        <template #left>
            <box-header-icon
                position="left"
                :class="['!w-14', !collapsed && 'lg:!w-18']"
                icon-name="regular/arrow-left-1"
                :tooltip="backButtonTooltip"
                @click="$emit('back')"
                v-if="!collapsed && backButtonVisible"
            />
        </template>

        <slot />

        <template #right>
            <transition
                enter-active-class="transition duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <box-header-icon
                    :icon-name="$icons.loading"
                    :tooltip="resetTooltip"
                    :class="['!w-14', !collapsed && 'lg:!w-18']"
                    @click="$emit('reset')"
                    v-if="resetVisible && !collapsed"
                />
            </transition>

            <box-header-icon
                :class="['!w-14', !collapsed && 'lg:!w-18']"
                :icon-name="collapsed ? 'regular/arrow-up-1' : 'regular/arrow-down-1'"
                :tooltip="collapsed ? $t('addLead.expand') : $t('addLead.collapse')"
                @click="$emit('collapse')"
            />

            <box-header-icon
                :class="['!w-14', !collapsed && 'lg:!w-18']"
                icon-name="regular/remove"
                :tooltip="$t('addLead.close')"
                @click="$emit('close')"
            />
        </template>
    </box-header>
</template>

<script>
    import BoxHeader from '@/components/presentational/boxes/BoxHeader.vue';
    import BoxHeaderIcon from '@/components/presentational/boxes/BoxHeaderIcon.vue';

    export default {
        components: {
            BoxHeader,
            BoxHeaderIcon,
        },

        props: {
            resetTooltip: {
                type: String,
                default: '',
            },
            resetLoading: {
                type: Boolean,
                default: false,
            },
            resetVisible: {
                type: Boolean,
                default: true,
            },
            collapsed: {
                type: Boolean,
                default: false,
            },
            backButtonVisible: {
                type: Boolean,
                default: false,
            },
            backButtonTooltip: {
                type: String,
                default: '',
            },
        },
    };
</script>
