<template>
    <div>
        <activix-accordion-item
            class="mb-3"
            :item="item"
            :index="index"
            :key="index"
            v-for="(item, index) in items"
        >
            <template slot="header">
                <slot :item="item" name="header" />
            </template>

            <slot :item="item" />
        </activix-accordion-item>
    </div>
</template>

<script>
    export default {
        name: 'ActivixAccordion',

        props: {
            items: {
                type: Array,
                required: true,
            },
        },
    };
</script>
