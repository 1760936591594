<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="space-y-6">
            <div class="bg-white space-y-6 p-6 shadow-lg rounded">
                <div>
                    <div class="flex items-center space-x-2 mb-2">
                        <label class="mb-0">{{ isForwarding ? $t('responseLeadXpress.forwardTo') : $t('responseLeadXpress.to') }}</label>
                        <span class="text-red-500 text-sm" v-text="$t('mailClient.notPreferredEmail')" v-show="!massMailing && preferredEmail && emails && !hasPreferredEmailSelected" />
                        <transition appear name="fade">
                            <email-validation
                                :email="riskyEmail"
                                :show-email="true"
                                v-if="!massMailing && riskyEmail"
                            />
                        </transition>
                    </div>
                    <div class="flex">
                        <activix-input
                            class="w-full truncate"
                            :readonly="true"
                            :value="limitText(massMailingLeadCount)"
                            v-if="massMailing"
                        />
                        <template v-else>
                            <activix-select
                                class="w-full truncate attached-r"
                                :value="response.forwardTo"
                                :no-data-text="$t('mailClient.enterForwardRecipients')"
                                :placeholder="$t('options.defaultFowarding')"
                                :multiple="true"
                                :filterable="true"
                                :allow-create="true"
                                :default-first-option="true"
                                :options="forwardToOptions"
                                @change="updateRecipients('forwardTo', $event)"
                                v-if="isForwarding"
                            >
                                <el-option
                                    :label="item.label"
                                    :value="item.value"
                                    :key="item.value"
                                    v-for="item in forwardToOptions"
                                />
                            </activix-select>
                            <activix-multiselect
                                class="w-full truncate"
                                label="email"
                                :allow-empty="false"
                                :disabled="disallowCommunication"
                                :limit-text="limitText"
                                :multiple="true"
                                :options="emails"
                                :searchable="false"
                                :selected="response.to"
                                :taggable="true"
                                @update="updateSelectedTo"
                                v-else
                            />
                            <activix-button
                                class="attached-l attached-r text-blue-500"
                                :active="ccFieldsVisible.cc"
                                @click="ccFieldsVisible.cc = !ccFieldsVisible.cc"
                            >
                                {{ $t('mailClient.cc') }}
                            </activix-button>
                            <activix-button
                                class="attached-l text-blue-500"
                                :active="ccFieldsVisible.bcc"
                                @click="ccFieldsVisible.bcc = !ccFieldsVisible.bcc"
                            >
                                {{ $t('mailClient.bcc') }}
                            </activix-button>
                        </template>
                    </div>
                </div>

                <div v-if="ccShouldBeVisible && !massMailing">
                    <label>{{ $t('responseLeadXpress.cc') }}</label>
                    <activix-select
                        :value="response.cc"
                        :no-data-text="$t('mailClient.enterCcRecipients')"
                        :placeholder="$t('options.emailCc')"
                        :multiple="true"
                        :filterable="true"
                        :allow-create="true"
                        :default-first-option="true"
                        :options="[]"
                        :force-desktop-layout="true"
                        @change="updateRecipients('cc', $event)"
                    >
                        <el-option
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                            v-for="item in options"
                        />
                    </activix-select>
                </div>

                <div v-if="bccShouldBeVisible && !massMailing">
                    <label>{{ $t('responseLeadXpress.bcc') }}</label>
                    <activix-select
                        :value="response.bcc"
                        :no-data-text="$t('mailClient.enterBccRecipients')"
                        :placeholder="$t('options.emailBcc')"
                        :multiple="true"
                        :filterable="true"
                        :allow-create="true"
                        :default-first-option="true"
                        :options="[]"
                        :force-desktop-layout="true"
                        @change="updateRecipients('bcc', $event)"
                    >
                        <el-option
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                            v-for="item in options"
                        />
                    </activix-select>
                </div>

                <div class="space-y-6 | md:space-y-0 md:flex ">
                    <div class="w-full | md:w-1/4 md:pr-3">
                        <label>{{ $t('responseLeadXpress.category') }}</label>
                        <activix-multiselect
                            class="w-full truncate"
                            label="locale_name"
                            :selected="response.category"
                            :options="orderedCategories"
                            :searchable="false"
                            :allow-empty="!hasTemplate"
                            :clearable="!hasTemplate"
                            :multiple="false"
                            :disabled="disallowCommunication"
                            @update="updateSelectedCategory"
                            @cancel="() => updateSelectedCategory('')"
                        />
                    </div>
                    <div class="w-full | md:flex-1 md:px-3">
                        <label>
                            {{ $t('responseLeadXpress.template') }}
                            <span class="text-red-500" v-if="hasCategory && !hasTemplate">*</span>
                        </label>
                        <div class="flex">
                            <activix-multiselect
                                class="w-full truncate attached-r"
                                label="title"
                                :selected="response.template"
                                :options="filteredTemplates"
                                :searchable="true"
                                :allow-empty="false"
                                :multiple="false"
                                :disabled="disabledTemplateSelection"
                                :max-height="400"
                                @update="updateSelectedTemplate"
                            />
                            <activix-button
                                class="attached-l attached-r text-blue-500"
                                :active="currentLang == 'FR'"
                                @click="$emit('change-lang', 'FR')"
                                v-if="!massMailing"
                            >
                                {{ 'FR' }}
                            </activix-button>
                            <activix-button
                                class="attached-l text-blue-500"
                                :active="currentLang == 'EN'"
                                @click="$emit('change-lang', 'EN')"
                                v-if="!massMailing"
                            >
                                {{ 'EN' }}
                            </activix-button>
                        </div>
                    </div>
                    <div class="w-full | md:w-1/4 md:pl-3">
                        <label>{{ $t('responseLeadXpress.civility') }}</label>
                        <activix-multiselect
                            label="title"
                            class="w-full truncate"
                            :selected="response.civility"
                            :options="civilities"
                            :searchable="false"
                            :allow-empty="false"
                            :multiple="false"
                            :disabled="disabledCivilitySelection"
                            :max-height="800"
                            @update="updateSelectedCivility"
                        />
                    </div>
                </div>

                <div class="space-y-6 | xs:space-y-0 xs:flex xs:justify-between">
                    <div class="w-full | xs:w-2/3 | md:w-1/2">
                        <lead-xpress-from
                            :user="currentUser"
                            :lead="lead"
                            :from.sync="response.from"
                            :from-backup.sync="response.fromBackup"
                            :campaign-settings="campaignSettings"
                            v-if="showFrom"
                        />
                        <div class="flex items-center space-x-6 | xs:h-10" v-else-if="!massMailing">
                            <activix-tooltip :content="$t('responseLeadXpress.originalCommunicationTooltip')" v-if="!includeOriginalCommunicationDisabled">
                                <activix-checkbox :disabled="includeOriginalCommunicationDisabled" v-model="includeOriginalCommunication">
                                    <label class="m-0">{{ $t('responseLeadXpress.originalCommunication') }}</label>
                                </activix-checkbox>
                            </activix-tooltip>
                            <activix-tooltip :content="$t('responseLeadXpress.addLastEmailBodyTooltip')">
                                <activix-checkbox :value="addLastEmailBody" @input="$emit('update:addLastEmailBody', $event)">
                                    <label class="m-0">{{ $t('responseLeadXpress.addLastEmailBody') }}</label>
                                </activix-checkbox>
                            </activix-tooltip>
                        </div>
                    </div>

                    <div class="w-full pl-6 | xs:w-2/3 | md:w-1/2">
                        <div v-if="massMailing">
                            <label>{{ $t('responseLeadXpress.language') }}</label>
                            <activix-multiselect
                                identifier="key"
                                label="label"
                                :allow-empty="false"
                                :multiple="false"
                                :options="localeOptions"
                                :searchable="false"
                                :placeholder="$t('email.lead_locale')"
                                :selected="selectedLocalePreference"
                                @update="updateSelectedLocale"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <activix-tabs
                    :tabs="tabs"
                    text-alignment="left"
                    v-model="activeTab"
                    v-show="massMailing"
                />
                <activix-alert type="info" v-if="signatureWillBeUpdatedOnSend">
                    {{ $t('responseLeadXpress.signatureWillBeUpdatedOnSend') }}
                </activix-alert>

                <template v-if="massMailing || activeTab === 'fr'">
                    <lead-xpress-translate
                        locale="fr"
                        :subject="response.subject.fr"
                        :design="response.design?.fr || response.template?.design?.fr"
                        :body="response.body.fr"
                        :attachments="response.attachments?.fr"
                        :is-advanced="templateAdvanced"
                        :template="response.template"
                        :lead="massMailing ? null : lead"
                        @update-subject="response.subject.fr = $event"
                        @update-body="response.body.fr = $event"
                        @update-design="response.design.fr = $event"
                        @update-attachment="response.attachments.fr = $event"
                        v-show="activeTab == 'fr'"
                    />
                </template>

                <template v-if="massMailing || activeTab === 'en'">
                    <lead-xpress-translate
                        locale="en"
                        :subject="response.subject.en"
                        :design="response.design?.en || response.template?.design?.en"
                        :body="response.body.en"
                        :attachments="response.attachments?.en"
                        :is-advanced="response.template?.is_advanced ?? false"
                        :lead="massMailing ? null : lead"
                        :template="response.template"
                        @update-subject="response.subject.en = $event"
                        @update-body="response.body.en = $event"
                        @update-design="response.design.en = $event"
                        @update-attachment="response.attachments.en = $event"
                        v-show="activeTab == 'en'"
                    />
                </template>
            </div>
        </div>

        <template v-if="displayCommunicationVideo">
            <div class="panel panel-default rounded-t-none border-none shadow-lg shadow-t-0" v-if="!massMailing && leadId">
                <div class="panel-body border-rounded-t-none">
                    <video-communication
                        :ziggeo-id="ziggeoId"
                        :processed="videoProcessed"
                        :user="currentUser"
                        :lead-id="leadId"
                    />
                </div>
            </div>
        </template>

        <div class="panel panel-default | mt-8 shadow-lg shadow-t-0" v-if="lead && connectedCommunicationsCount && !massMailing">
            <div class="panel-heading">
                {{ $t('client.previousEmails') }}
            </div>
            <div class="panel-body">
                <email-accordion
                    :communications="lead.communications"
                    :lead-full-name="lead.fullName"
                />
            </div>
        </div>

        <div class="panel panel-default | mt-8 shadow-lg shadow-t-0" v-if="!massMailing">
            <div class="panel-heading">
                {{ $t('email.originalEmail') }}
            </div>
            <div class="panel-body" v-if="lead">
                <div v-if="lead.chat || lead.scraper_description">
                    <span v-html="lead.chat" v-if="lead.chat" />
                    <span v-else>
                        <span v-html="scraperDescription" v-if="lead.scraper_description" />
                    </span>
                </div>
                <div class="relative" v-else>
                    <span v-html="lead.original_email" v-if="lead.original_email" />
                    <span v-else>
                        {{ $t('email.no_original_email') }}
                    </span>
                </div>
            </div>
        </div>

        <activix-confirm-modal
            type="danger"
            :content="$t('templates.askDeleteTemplate')"
            :opened.sync="modals.askDeleteTemplate.opened"
            @approve="deleteTemplate(modals.askDeleteTemplate.locale)"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types, vue/no-mutating-props, no-unsafe-optional-chaining */
    import { camelCase, get, orderBy } from 'lodash-es';
    import { nl2br } from '@/utils/string.js';
    import LeadEmail from '@/entities/LeadEmail.js';
    import EmailValidationRisk from '@/entities/EmailValidationRisk.js';
    import EmailCompose from '@/mixins/EmailCompose.js';
    import LeadXpressFrom from '@/components/lead_xpress/LeadXpressFrom.vue';
    import EmailAccordion from '@/components/lead/EmailAccordion.vue';
    import EmailValidation from '@/components/lead/EmailValidation.vue';
    import VideoCommunication from '@/components/lead/VideoCommunication.vue';

    // Pinia
    import { mapActions, mapState } from 'pinia';
    import TemplateType from '@/entities/TemplateType.js';
    import { showWarning } from '@/utils/toastr.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import LeadXpressTranslate from './LeadXpressTranslate.vue';

    export default {
        components: {
            LeadXpressTranslate,
            LeadXpressFrom,
            EmailAccordion,
            EmailValidation,
            VideoCommunication,
        },

        mixins: [EmailCompose],

        props: [
            'communicating',
            'communicatingUser',
            'currentLang',
            'draft',
            'isForwarding',
            'lead',
            'response',
            'signature',
            'unsubscribeLink',
            'massMailingLeadCount',
            'addLastEmailBody',
            'responseTemplates',
            'campaignSettings',
        ],

        data() {
            return {
                users: [],
                hasResponseObject: false,
                summernote: {
                    height: null,
                    minHeight: 160,
                    maxHeight: null,
                    focus: true,
                    lang: this.$i18n.locale == 'en' ? 'en-US' : 'fr-FR',
                },
                civility: {
                    id: 'none',
                    title: this.$t('general.none'),
                },
                showCivility: false,
                attachementInternalErrors: [],
                includeOriginalCommunication: false,
                options: [],
                forwardToOptions: [],
                ccFieldsVisible: {
                    cc: false,
                    bcc: false,
                },
                activeTab: '',
                localeAvailable: ['en', 'fr'],
                template: {
                    type: TemplateType.EMAIL,
                    is_advanced: false,
                    title: '',
                    category: null,
                    cc: [],
                    bcc: [],
                    ccAssigned: false,
                    bccAssigned: false,
                    attachments: [],
                    automation_actions: [],
                },
                modals: {
                    askDeleteTemplate: {
                        opened: false,
                        locale: '',
                        subject: {
                            fr: '',
                            en: '',
                        },
                        body: {
                            fr: '',
                            en: '',
                        },
                        design: {
                            fr: '',
                            en: '',
                        },
                        title: '',
                        attachments: '',
                    },
                },
                localePreference: 'leadLocale',
                localeOptions: [
                    {
                        key: 'fr',
                        label: 'FR',
                    },
                    {
                        key: 'en',
                        label: 'EN',
                    },
                    {
                        key: 'leadLocale',
                        label: this.$t('email.lead_locale'),
                    },
                ],
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                massMailing: 'isMassMailing',
                pagination: 'pagination',
            }),
            ...mapState(useGlobalStore, {
                attachmentParameters: 'attachmentParameters',
                configs: 'configs',
                authUser: 'authUser',
                parentAuthUser: 'parentAuthUser',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            ownCommunication() {
                return this.communicatingUser.id === this.parentAuthUser?.id || this.communicatingUser.id === this.authUser.id;
            },

            disallowCommunication() {
                return this.communicating && !this.ownCommunication;
            },

            ziggeoId() {
                return this.draft?.media?.ziggeo_id;
            },

            videoProcessed() {
                return this.draft?.media?.video_processed;
            },

            selectedLocalePreference() {
                return this.localeOptions.find(locale => locale.key === this.localePreference);
            },

            displayCommunicationVideo() {
                const hasVideoData = Object.values(this.response.body).some(value => {
                    if (typeof value === 'string') {
                        return (
                            value.includes('<data value="data-video-communication-thumbnail"') ||
                            value.includes('<thumbnail data-video-communication-thumbnail')
                        );
                    }
                    return false;
                });

                return !this.templateAdvanced || hasVideoData;
            },

            hasCategory() {
                return !!this.response.category?.id;
            },

            hasTemplate() {
                return !!this.response.template?.id;
            },

            templateAdvanced() {
                return (this.response.template?.is_advanced && (!!this.response.template?.design?.en || !!this.response.template?.design?.fr)) || (!!this.response.design?.fr || !!this.response.design?.en);
            },

            categories() {
                return this.customCategories.filter(category => {
                    return this.responseTemplates.some(template => template.response_template_custom_category_id === category.id);
                });
            },

            customCategories() {
                return this.configs.customCategories.map(category => ({
                    id: category.id,
                    locale_name: this.getCategoryLocaleName(category),
                }));
            },

            orderedCategories() {
                return orderBy(this.categories, 'locale_name');
            },

            ccShouldBeVisible() {
                return !empty(this.response.cc) || this.ccFieldsVisible.cc;
            },

            bccShouldBeVisible() {
                return !empty(this.response.bcc) || this.ccFieldsVisible.bcc;
            },

            civilities() {
                return [
                    {
                        id: 'none',
                        title: this.$t('general.none', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'mr',
                        title: this.$t('database.mr', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'ms',
                        title: this.$t('database.ms', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'mrs',
                        title: this.$t('database.mrs', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'miss',
                        title: this.$t('database.miss', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'dr',
                        title: this.$t('database.dr', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'me',
                        title: this.$t('database.me', this.currentLang.toLowerCase()),
                    },
                ].filter((item, index, civilities) => civilities.findIndex(civility => civility.title === item.title) === index);
            },

            defaultTemplate() {
                return this.responseTemplates.find(template => template.is_default);
            },

            filteredTemplates() {
                if (!this.response.category?.id && !this.defaultTemplate) {
                    return [];
                }

                const responseTemplates = this.responseTemplates
                    .filter(responseTemplate => (
                        responseTemplate.type === TemplateType.EMAIL &&
                        responseTemplate.response_template_custom_category_id === this.response.category.id &&
                        (this.massMailing ? true : responseTemplate.translations.some(template => template.locale === this.currentLang.toLowerCase()))
                    )).map(value => {
                        const translationFr = value.translations.find(t => t.locale == 'fr') ?? '';
                        const translationEn = value.translations.find(t => t.locale == 'en') ?? '';

                        const titlePrefix = value.parent ? `[${this.$t('leadXpress.parent')}]` : '';

                        return {
                            id: value.id,
                            main: !value.parent,
                            subject: {
                                fr: translationFr.subject,
                                en: translationEn.subject,
                            },
                            body: {
                                fr: translationFr.body,
                                en: translationEn.body,
                            },
                            design: {
                                fr: translationFr.design,
                                en: translationEn.design,
                            },
                            is_advanced: value.is_advanced,
                            title: `${titlePrefix} ${value.title || translationFr.title || translationEn.title}`.trim(),
                            attachments: {
                                fr: translationFr.attachments,
                                en: translationEn.attachments,
                            },
                            cc: value.cc,
                            bcc: value.bcc,
                            bcc_assigned: value.bcc_assigned,
                            cc_assigned: value.cc_assigned,
                            icon: value.is_favorite ? 'regular/rating-star' : '',
                            is_favorite: value.is_favorite,
                            iconStyle: value.is_favorite ? 'fill-current text-yellow-400' : '',
                            translations: value.translations,
                        };
                    });

                return orderBy(responseTemplates, ['title'], ['asc']).sort((a, b) => b.is_favorite - a.is_favorite);
            },

            emails() {
                if (empty(this.lead)) {
                    return [];
                }

                return this.lead.lead_emails
                    .filter(tmpEmail => {
                        return tmpEmail.valid && !tmpEmail.validated_email?.is_disposable;
                    })
                    .map(tmpEmail => {
                        if (tmpEmail.preferred) {
                            tmpEmail.icon = 'bold/rating-star';
                            tmpEmail.iconStyle = 'text-blue-500';
                        }

                        return tmpEmail;
                    })
                    .sort((a, b) => a.validated_email?.risk_score - b.validated_email?.risk_score);
            },

            riskyEmail() {
                const email = this.response.to
                    .filter(email => email.validated_email?.risk === EmailValidationRisk.MEDIUM)
                    .sort((a, b) => a.validated_email?.risk_score - b.validated_email?.risk_score)
                    .reverse()[0];

                if (!email) {
                    return null;
                }

                return new LeadEmail(email);
            },

            preferredEmail() {
                return this.emails.find(email => email.preferred);
            },

            hasPreferredEmailSelected() {
                return this.response.to.some(email => email.preferred);
            },

            connectedCommunicationsCount() {
                let count = 0;

                if (this.lead.communications.length > 0) {
                    const communications = this.lead.communications.filter(communication => {
                        if (
                            communication.connected &&
                            communication.service_id == 4 &&
                            communication.status != 'interrupted' &&
                            communication.status != 'error' &&
                            communication.status != 'invalid' &&
                            communication.status != 'pending'
                        ) {
                            return true;
                        }

                        return false;
                    });

                    count = communications.length;
                }

                return count;
            },

            scraperDescription() {
                let content = '';

                if (!empty(this.lead.scraper_description)) {
                    content = nl2br(this.lead.scraper_description);

                    const emailRegex = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
                    const phoneRegex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

                    if (this.authUser.hasAdvisorSkills()) {
                        content = content.replace(emailRegex, 'XXXX');
                        content = content.replace(phoneRegex, 'XXXX');
                    }
                }

                return content;
            },

            currentCivility() {
                let tmpCivility = 'ms';

                if (
                    (empty(this.lead.civility) || this.lead.civility == -1) &&
                    (empty(this.lead.sex) || this.lead.civility == -1)
                ) {
                    return {
                        id: 'none',
                        title: this.$t('general.none', this.currentLang.toLowerCase()),
                    };
                }
                if (!empty(this.lead.civility)) {
                    tmpCivility = this.lead.civility;
                } else if (!empty(this.lead.sex) && this.lead.sex == 'M') {
                    tmpCivility = 'mr';
                }

                return {
                    id: tmpCivility,
                    title: this.$t(`database.${tmpCivility}`, this.currentLang.toLowerCase()),
                };
            },

            originalCommunication() {
                let originalCommunication = '';

                if (this.includeOriginalCommunicationDisabled || !this.includeOriginalCommunication) {
                    return originalCommunication;
                }

                originalCommunication = `<hr>${this.$t('email.originalEmail')}:<br><br>${this.lead.comment}<hr>`;

                return nl2br(originalCommunication);
            },

            includeOriginalCommunicationDisabled() {
                return !this.lead.comment || this.massMailing || this.response.template?.is_advanced;
            },

            disabledTemplateSelection() {
                return (
                    !this.response.category?.id ||
                    !this.filteredTemplates.length ||
                    !this.templateAttributes.length ||
                    this.disallowCommunication
                );
            },

            disabledCivilitySelection() {
                return (
                    !this.showCivility ||
                    !this.response.category?.id ||
                    !this.filteredTemplates.length ||
                    !this.templateAttributes.length ||
                    this.disallowCommunication
                );
            },

            signatureWillBeUpdatedOnSend() {
                return this.response.from !== this.currentUser.id;
            },

            showFrom() {
                return this.massMailing;
            },

            currentUser() {
                return this.parentAuthUser.childUserForAccount(this.lead.account_id) || this.authUser;
            },

            leadId() {
                return this.lead?.id;
            },

            tabs() {
                const tabsArray = [
                    {
                        name: 'fr',
                        label: this.$t('templates.locales.fr.name'),
                        subLabel: this.$t('templates.locales.fr.realName'),
                    },
                    {
                        name: 'en',
                        label: this.$t('templates.locales.en.name'),
                        subLabel: this.$t('templates.locales.en.realName'),
                    },
                ];

                if (this.currentLang) {
                    if (this.currentLang === 'FR') {
                        tabsArray.sort((a) => (a.name === 'fr' ? -1 : 1));
                    } else {
                        tabsArray.sort((a) => (a.name === 'en' ? -1 : 1));
                    }
                }

                this.updateActiveTabs(tabsArray[0].name);

                return tabsArray;
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                async handler() {
                    this.users = await this.contextAccount.getUsers();
                },
            },

            response(newValue) {
                if (!empty(newValue.cc)) {
                    this.ccFieldsVisible.cc = true;
                }

                if (!empty(newValue.bcc)) {
                    this.ccFieldsVisible.bcc = true;
                }
            },

            civility(newValue) {
                ['fr', 'en'].forEach(locale => {
                    const html = $('<div />', { html: this.response.body[locale] });

                    html.find('.civility').html(newValue.id != 'none' ? newValue.title : '');

                    this.response.body[locale] = html.html();
                    this.updateResponse();

                    const civilityId = newValue.id != 'none' ? newValue.id : null;
                    this.updateLeadAction(this.lead.id, { civility: civilityId });
                });
            },

            signature(newValue) {
                ['fr', 'en'].forEach(locale => {
                    const html = $('<div />', { html: this.response.body[locale] });

                    html.find('.signature').first().replaceWith(newValue);

                    this.response.body[locale] = html.html();
                    this.updateResponse();
                });
            },

            unsubscribeLink(newValue) {
                ['fr', 'en'].forEach(locale => {
                    const html = $('<div />', { html: this.response.body[locale] });

                    html.find('.unsubscribe-link').first().replaceWith(newValue);

                    this.response.body[locale] = html.html();
                    this.updateResponse();
                });
            },

            'response.to'(newValue) {
                if (!newValue.length && this.emails.length == 1) {
                    this.response.to = this.emails;
                    this.updateResponse();
                }
            },

            'response.from'() {
                this.updateResponse();
            },

            'response.subject'(newValue) {
                if (!newValue.length) {
                    this.updateResponse();
                }
            },

            'response.body'(newValue) {
                if (!newValue.length) {
                    this.updateResponse();
                }
            },

            'response.design'() {
                this.updateResponse();
            },

            'response.template'(newValue) {
                this.showCivility = false;

                if (!empty(newValue)) {
                    this.showCivility = true;

                    this.civility = this.currentCivility;

                    if (newValue.translations) {
                        newValue.translations.forEach(async translation => {
                            const locale = translation.locale;
                            const payload = {
                                sender: this.authUser,
                                lead: this.massMailing ? null : this.lead,
                                locale,
                                prefix: this.currentCivility.id != 'none' ? this.currentCivility.title : '',
                                massMailing: this.massMailing,
                                users: this.users,
                            };
                            const body = await this.replaceAttributesInMessage({
                                ...payload,
                                html: true,
                                message: translation.body,
                            });
                            const subject = await this.replaceAttributesInMessage({
                                ...payload,
                                html: false,
                                message: translation.subject,
                            });

                            if (!this.hasResponseObject) {
                                this.response.subject[locale] = subject;
                            }

                            if (!newValue.is_advanced) {
                                const html = $('<div />', { html: this.response.body[locale] });
                                html.find('.body').first().html(body);

                                this.response.body[locale] = html.html();
                            } else {
                                this.response.body[locale] = body;
                            }

                            this.response.design[locale] = newValue.is_advanced ? newValue.design[locale] : null;

                            if (!this.massMailing && this.currentLang === 'EN') {
                                this.response.subject.fr = this.response.subject.en;
                                this.response.body.fr = this.response.body.en;
                                this.response.design.fr = this.response.design.en;
                                this.response.attachments.fr = this.response.attachments.en;
                            }

                            this.updateResponse();
                        });
                        this.updateResponse();
                    }
                }
            },

            'response.templateAttachments'() {
                this.updateResponse();
            },

            'response.attachments'() {
                this.updateResponse();
            },

            includeOriginalCommunication() {
                const html = $('<div />', { html: this.response.body });

                html.find('.original-communication').first().html(this.originalCommunication);

                this.response.body = html.html();
                this.updateResponse();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            limitText(count) {
                return this.$tc('responseLeadXpress.selectedRecipient', count);
            },

            getCategoryLocaleName(category) {
                if (!category.account_id) {
                    return this.$t(`divisions.${camelCase(category.name)}`);
                }

                if (this.contextAccount.id !== category.account_id) {
                    return `[Parent] ${category.name}`;
                }

                return category.name;
            },

            updateRecipients(field, value) {
                const emails = this.splitEmailList(value);

                if (emails.some(email => !this.emailIsValid(email))) {
                    showWarning(this.$t('client.wrongEmail'));
                }

                const validEmails = this.cleanEmailList(emails);

                this.response[field] = [...new Set(validEmails)];

                this.updateResponse();
            },

            splitEmailList(emails) {
                return emails.map(email => email.toLowerCase().split(/[\s,;]+/)).flat();
            },

            cleanEmailList(emails) {
                return emails.filter(email => this.emailIsValid(email)) || [];
            },

            updateSelectedCategory(value) {
                this.response.category = value;
                this.response.template = null;

                this.updateResponse();
            },

            updateSelectedLocale(value) {
                this.localePreference = get(value, 'key', null);

                if (this.localePreference !== 'leadLocale') {
                    this.activeTab = this.localePreference;
                }

                this.$emit('change-locale', this.localePreference);
            },

            updateActiveTabs(locale) {
                this.activeTab = locale;
            },

            async updateSelectedTemplate(value) {
                this.$emit('set-initial-body');

                this.response.template = value;
                this.response.templateAttachments = value.attachments;

                const emails = await this.lead.getAssignedUserEmails();

                if (value.cc_assigned && emails.length > 0) {
                    const currentCCs = this.response.cc.concat(value.cc || []);
                    const cc = emails
                        .map(email => email.email)
                        .filter(email => {
                            if (value.cc.length < 0) {
                                return false;
                            }

                            return currentCCs.some(templateCc => email == templateCc);
                        });

                    if (cc) {
                        this.response.cc = cc;
                    }
                } else {
                    this.response.cc = this.response.cc.concat(value.cc || []);
                }

                if (value.bcc_assigned && emails.length > 0) {
                    const currentBCCs = this.response.bcc.concat(value.bcc || []);
                    const bcc = emails
                        .map(email => email.email)
                        .filter(email => {
                            if (value.bcc.length < 0) {
                                return false;
                            }

                            return currentBCCs.some(templateBcc => email == templateBcc);
                        });

                    if (bcc) {
                        this.response.bcc = bcc;
                    }
                } else {
                    this.response.bcc = this.response.bcc.concat(value.bcc || []);
                }

                const attachments = {
                    en: [],
                    fr: [],
                };

                value.translations.forEach((translation) => {
                    const locale = translation.locale;
                    const attachmentArray = attachments[locale];

                    translation.attachments.forEach((attachment) => {
                        attachmentArray.push({
                            id: attachment.id,
                            name: attachment.name,
                            size: attachment.size,
                            path: attachment.path,
                        });
                    });
                });

                this.response.attachments = attachments;

                this.updateResponse();
            },

            updateSelectedTo(value) {
                this.response.to = value;
                this.updateResponse();
            },

            updateSelectedCivility(value) {
                this.civility = value;
                this.response.civility = this.civility;
            },

            updateResponse() {
                this.$emit('update:response', this.response);
            },

            async updateDefault() {
                if (this.defaultTemplate) {
                    const category = this.customCategories
                        .find(category => category.id === this.defaultTemplate.response_template_custom_category_id);

                    if (category) {
                        this.updateSelectedCategory(category);
                    }

                    const defaultTemplate = this.filteredTemplates.find(template => template.id === this.defaultTemplate.id);

                    if (defaultTemplate) {
                        this.updateSelectedTemplate(defaultTemplate);
                    }
                }

                if (this.preferredEmail) {
                    this.updateSelectedTo([this.preferredEmail]);
                }
            },

            askDeleteTemplate(locale) {
                this.modals.askDeleteTemplate.locale = locale;
                this.modals.askDeleteTemplate.opened = true;
            },
        },
    };
</script>
