export default {
    call: {
        title: {
            incomingCall: 'Appel entrant',
            webBoost: 'WebBoost',
        },
        description: 'Vous avez un appel de {0}',
    },
    video: {
        clientJoined: {
            title: 'Appel vidéoconférence',
            description: '{0} vous attend dans la salle de vidéoconférence',
        },
        ongoing: {
            title: 'Appel vidéoconférence',
            description: 'En cours avec {0}',
        },
        joinConferenceCall: 'Joindre',
    },
    leadCommunicating: {
        title: 'Communication en cours',
        phone: {
            description: '{userName} est déjà en appel.',
        },
        sms: {
            description: '{userName} compose déjà un SMS.',
        },
        email: {
            description: '{userName} compose déjà un courriel.',
        },
        video: {
            description: '{userName} est déjà en vidéoconférence.',
        },
    },
    goToLead: 'Aller au lead',
    dismiss: 'Fermer',
};
