import { uniqueId } from 'lodash-es';

class AsyncDebounce {
    static debouncedQueues = {};

    static async debounce(queue, func, delay, maxWait = null) {
        if (!queue) {
            queue = uniqueId('asyncDebounce_');
        }

        if (!func) {
            throw new Error('debounce require a function');
        }

        if (typeof AsyncDebounce.debouncedQueues[queue] === 'undefined') {
            AsyncDebounce.debouncedQueues[queue] = {};
        }

        if (typeof AsyncDebounce.debouncedQueues[queue].promise === 'undefined') {
            AsyncDebounce.debouncedQueues[queue].promise = new Promise(resolve => {
                AsyncDebounce.debouncedQueues[queue].resolve = resolve;
            });

            if (maxWait) {
                AsyncDebounce.debouncedQueues[queue].maxTime = Math.floor(Date.now()) + maxWait;
            }
        }

        if (AsyncDebounce.debouncedQueues[queue].maxTime) {
            delay = Math.min(Math.floor(Date.now()) + delay, AsyncDebounce.debouncedQueues[queue].maxTime) - Math.floor(Date.now());
        }

        if (typeof AsyncDebounce.debouncedQueues[queue].timeout !== 'undefined') {
            clearTimeout(AsyncDebounce.debouncedQueues[queue].timeout);
        }

        AsyncDebounce.debouncedQueues[queue].timeout = setTimeout(() => {
            const result = func();
            const resolve = AsyncDebounce.debouncedQueues[queue].resolve;
            delete AsyncDebounce.debouncedQueues[queue];

            if (typeof result === 'object' && typeof result.then === 'function') {
                result.then((result) => {
                    resolve(result);
                });
            } else {
                resolve(result);
            }
        }, delay);

        return AsyncDebounce.debouncedQueues[queue].promise;
    }
}

export default AsyncDebounce;
