import Enum from './Enum.js';

export default class LeadStatus extends Enum {
    static get entries() {
        return {
            duplicate: 'duplicate',
            invalid: 'invalid',
            lost: 'lost',
        };
    }

    static get DUPLICATE() {
        return this.entries.duplicate;
    }

    static get INVALID() {
        return this.entries.invalid;
    }

    static get LOST() {
        return this.entries.lost;
    }

    static get translationGroup() {
        return 'leadStatus';
    }

    static getIcon(value) {
        switch (value) {
            case LeadStatus.DUPLICATE:
                return 'bold/subtract-circle';

            case LeadStatus.INVALID:
                return 'bold/alert-circle';

            case LeadStatus.LOST:
                return 'bold/remove-circle';

            default:
                return 'bold/check-circle-1';
        }
    }
}
