export default {
    add: 'Add',
    clickAnywhereToView: 'Click to view',
    late: 'Late',
    nextStep: 'Next step',
    nothingPlanned: 'No tasks planned',
    noOtherPlannedTask: 'No other planned task',
    scheduled: 'Scheduled',
    view: 'View',
    viewAllActivities: 'View all activities',
};
