import { orderBy } from 'lodash-es';
import axios from 'axios';

const isActive = !!process.env.VUE_APP_STATUS_PAGE_API_URL;

export default {
    install(Vue) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        const statusPage = {
            async fetch() {
                if (!isActive) {
                    return [];
                }

                const incidents = [];
                const [responseMaintenances, responseIncidents] = await Promise.all([
                    axios.get(`${process.env.VUE_APP_STATUS_PAGE_API_URL}/scheduled-maintenances/active.json`),
                    axios.get(`${process.env.VUE_APP_STATUS_PAGE_API_URL}/incidents/unresolved.json`),
                ]);

                if (responseMaintenances.status == 200) {
                    responseMaintenances.data.scheduled_maintenances.forEach(maintenance => {
                        incidents.push({ ...maintenance, type: 'maintenance' });
                    });
                }

                if (responseIncidents.status == 200) {
                    responseIncidents.data.incidents.forEach(maintenance => {
                        incidents.push({ ...maintenance, type: 'incident' });
                    });
                }

                return orderBy(
                    incidents.map(incident => {
                        return {
                            title: incident.name,
                            text: incident.incident_updates[0].body,
                            impact: incident.impact,
                            impactValue: ['none', 'minor', 'maintenance', 'major', 'critical'].indexOf(incident.impact),
                            status: incident.status,
                            components: incident.components.map(component => component.name),
                            type: incident.type,
                        };
                    }),
                    'impactValue',
                    'desc',
                );
            },
        };

        Vue.statusPage = statusPage;
        Vue.prototype.$statusPage = statusPage;
    },
};
