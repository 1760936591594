<template>
    <div class="flex">
        <activix-input
            class="text-grey-700 flex-1 attached-r"
            :placeholder="$t('search.search')"
            :disabled="selectedSearchType == 'recent'"
            ref="mainSearch"
            @keyup.enter="triggerSearch(false)"
            v-model="searchValue"
        >
            <template #icon>
                <icon class="text-grey-700" name="regular/search" />
            </template>
        </activix-input>
        <activix-tooltip
            placement="bottom"
            :content="$t('modal.newTab')"
            v-if="searchValue.length || selectedSearchType == 'recent'"
        >
            <activix-button class="attached-l attached-r" @click="triggerSearch(true)">
                <icon class="text-grey-600" name="regular/expand-6" />
            </activix-button>
        </activix-tooltip>
        <activix-select
            class="w-auto attached-l"
            identifier="value"
            :autosize="true"
            :options="searchTypes"
            v-model="selectedSearchType"
        >
            <el-option
                :label="item.label"
                :value="item.value"
                :key="item.value"
                v-for="item in searchTypes"
            />
        </activix-select>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../store/store.js';

    export default {
        data() {
            return {
                searchValue: '',
                selectedSearchType: 'lead',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            searchTypes() {
                const options = ['lead', 'vehicle'];

                if (this.authUser.isAdmin()) {
                    options.push('user');
                }

                options.push('recent');

                return options.map(option => {
                    return {
                        value: option,
                        label: this.$t(`search.${option}`),
                    };
                });
            },
        },

        watch: {
            '$route.query.value': {
                immediate: true,
                handler(newValue) {
                    if (this.$route.name !== 'search') {
                        return;
                    }

                    this.setValue(newValue);
                },
            },

            '$route.name': {
                immediate: true,
                handler(newValue, oldValue) {
                    if (oldValue == 'search' && newValue != oldValue && this.selectedSearchType == 'recent') {
                        this.selectedSearchType = 'lead';
                    }
                },
            },

            '$route.query.type': {
                immediate: true,
                handler(newValue) {
                    if (this.$route.name !== 'search') {
                        return;
                    }

                    this.setType(newValue);
                },
            },

            selectedSearchType(newValue) {
                if (newValue == 'recent') {
                    this.searchValue = '';
                }

                this.triggerSearch();
            },
        },

        methods: {
            setType(type) {
                const validTypes = ['lead', 'user', 'vehicle', 'recent'];

                if (type == 'recent') {
                    this.searchValue = '';
                }

                this.selectedSearchType = validTypes.includes(type) ? type : 'lead';
            },

            setValue(value) {
                this.searchValue = value || '';
            },

            triggerSearch(inNewTab = false) {
                if (!this.searchValue && this.selectedSearchType != 'recent') {
                    return;
                }

                const searchUrl = {
                    name: 'search',
                    query: {
                        type: this.selectedSearchType,
                        value: this.searchValue,
                    },
                };

                if (inNewTab) {
                    this.$behavior.track('Search', {
                        type: this.selectedSearchType,
                        external: 'yes',
                    });
                    window.open(this.$router.resolve(searchUrl).href, '_blank');
                } else {
                    this.$behavior.track('Search', {
                        type: this.selectedSearchType,
                        external: 'no',
                    });
                    this.$router.push(searchUrl);
                    this.$emit('submit');
                }
            },
        },
    };
</script>
