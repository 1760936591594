<template>
    <div class="alert alert-warning | text-center" v-if="isImapAlertDisplayed">
        {{ $t('responseLeadXpress.imapIsDisabledAlert') }}

        <a class="mr-2" target="_blank" href="https://supportactivix.super.site/personalize-your-profile/connect-your-imap">
            {{ $t('responseLeadXpress.imapIsDisabledDocumentationLink') }}
        </a>

        <activix-button @click="dismissAlert">
            {{ $t('responseLeadXpress.dismiss') }}
        </activix-button>
    </div>
</template>

<script>
    import Account from '@/entities/Account.js';
    import Lead from '@/entities/Lead.js';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    export default {
        components: {},

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            account: {
                type: Account,
                required: true,
            },
        },

        data() {
            return {
                isImapAlertDisplayed: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser', 'authUser']),

            currentUser() {
                const authUser = (this.parentAuthUser.children || []).find(
                    child => child.account_id == this.lead.account_id,
                );

                return authUser || this.authUser;
            },
        },

        watch: {
            'currentUser.id': {
                async handler() {
                    const emailConfig = await this.currentUser.email_config;

                    this.isImapAlertDisplayed = this.account.email_client &&
                        emailConfig &&
                        emailConfig.is_nylas_account_filled &&
                        !emailConfig.active;
                },
                immediate: true,
            },
        },

        methods: {
            dismissAlert() {
                this.isImapAlertDisplayed = false;
            },
        },
    };
</script>
