import Enum from './Enum.js';

export default class CommunicationMethod extends Enum {
    static get entries() {
        return {
            phone: 1,
            email: 2,
            sms: 3,
            messenger: 4,
            video: 5,
        };
    }

    static get PHONE() {
        return this.entries.phone;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get MESSENGER() {
        return this.entries.messenger;
    }

    static get VIDEO() {
        return this.entries.video;
    }

    static get translationGroup() {
        return 'communicationMethods';
    }
}
