import Enum from './Enum.js';

export default class LeadVehicleType extends Enum {
    static get entries() {
        return {
            wanted: 'wanted',
            exchange: 'exchange',
        };
    }

    static get WANTED() {
        return this.entries.wanted;
    }

    static get EXCHANGE() {
        return this.entries.exchange;
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
