export default [
    { path: '/in-turn', name: 'inTurn', redirect: { name: 'inTurn.management' } },
    {
        path: '/in-turn/management',
        name: 'inTurn.management',
        component: () => import(/* webpackChunkName: "in-turn-management" */ '../views/InTurn/Management.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/in-turn/show',
        name: 'inTurn.show',
        component: () => import(/* webpackChunkName: "in-turn-show" */ '../views/InTurn/Show.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
            },
            layout: {
                contentHeader: false,
                nav: false,
            },
            saveInUrl: ['accountId'],
        },
    },
];
