import Enum from './Enum.js';

export default class ProgressState extends Enum {
    static get entries() {
        return {
            approved: 'approved',
            cash: 'cash',
            cash_deal: 'cash_deal',
            endorser: 'endorser',
            proof: 'proof',
            vehicle_selection: 'vehicle_selection',
            refused: 'refused',
            sent: 'sent',
        };
    }

    static get APPROVED() {
        return this.entries.approved;
    }

    static get CASH() {
        return this.entries.cash;
    }

    static get CASH_DEAL() {
        return this.entries.cash_deal;
    }

    static get ENDORSER() {
        return this.entries.endorser;
    }

    static get PROOF() {
        return this.entries.proof;
    }

    static get VEHICLE_SELECTION() {
        return this.entries.vehicle_selection;
    }

    static get REFUSED() {
        return this.entries.refused;
    }

    static get SENT() {
        return this.entries.sent;
    }

    static get translationGroup() {
        return 'progressStates';
    }
}
