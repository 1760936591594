<template>
    <activix-modal
        size="xl"
        :opened="opened"
        :portal="portal"
        :loading="$wait.is('fetching.taskEventLeads')"
        :loading-spinner="false"
        @close="close"
        @closed="onClosed"
        @open="onOpen"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('searchLead.title') }}
            </h4>
        </template>

        <template slot="body">
            <search-lead-table :leads="leads" :lead-loading="selectedLead" @select-lead="onSelectLead" />
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import SearchLeadTable from '../calendar/SearchLeadTable.vue';
    import Lead from '../../entities/Lead.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: { SearchLeadTable },

        props: {
            opened: {
                type: Boolean,
                required: true,
                default: false,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
            selectedAccount: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                leads: Array(10).fill(new Lead()),
                selectedLead: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isLoading() {
                return this.$wait.is('fetching.calendarLead');
            },
        },

        watch: {
            isLoading(newValue, oldValue) {
                if (newValue || !oldValue) {
                    return;
                }

                this.close();
            },
        },

        methods: {
            async fetch() {
                if (!this.contextAccount.id) {
                    return;
                }

                this.$wait.start('fetching.taskEventLeads');

                const response = await this.$axios.get('v1/task-events/get-leads', {
                    params: {
                        accountId: this.selectedAccount || this.contextAccount.id,
                    },
                });

                this.leads = response.data.data.map(lead => new Lead(lead));

                this.$wait.end('fetching.taskEventLeads');
            },

            close() {
                this.$emit('update:opened', false);
            },

            onSelectLead(leadId) {
                this.selectedLead = leadId;
                this.$emit('select-lead', leadId);
            },

            onOpen() {
                this.fetch();
            },

            onClosed() {
                this.selectedLead = null;
            },
        },
    };
</script>
