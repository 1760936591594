export default {
    success: 'Le véhicule a été soumis à Torque avec succès',
    warning: {
        error403: 'Votre compte n\'a pas accès à Torque',
        error404: 'Impossible de joindre Torque, veuillez contacter le support',
        error422: 'Votre adresse courriel n\'a pas été trouvé',
        error500: 'Une erreur du côté de Torque est survenue, veuillez contacter le support',
    },
    tooltip: {
        match:
            "La soumission du véhicule vers Torque est basée sur la marque, le modèle, l'année et l'odomètre. Assurez-vous que les valeurs entrées soient valides avant la soumission",
        niv: "Vous devez saisir un NIV valide afin d'avoir une soumission valide vers Torque",
        unsubmitted: 'Soumettre le véhicule vers Torque',
    },
};
