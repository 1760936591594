<template>
    <div class="rounded p-4" :class="wrapperClassName">
        <div class="flex">
            <div class="flex shrink-0 items-start mt-1">
                <icon :name="iconName" :class="iconClassName" />
            </div>
            <div class="ml-3 flex-1" :class="textClassName">
                <p class="m-0 font-semibold" v-text="title" />
                <p class="m-0">
                    <slot />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActivixAlert',

        props: {
            type: {
                type: String,
                required: true,
                validator: value => ['info', 'error', 'warning'].includes(value),
            },
            title: {
                type: String,
                default: '',
            },
        },

        computed: {
            wrapperClassName() {
                return {
                    info: 'bg-blue-200',
                    error: 'bg-red-200',
                    warning: 'bg-orange-200',
                }[this.type];
            },

            iconName() {
                return {
                    info: 'regular/information-circle',
                    error: 'bold/remove-circle',
                    warning: 'bold/remove-circle',
                }[this.type];
            },

            iconClassName() {
                return {
                    info: 'text-blue-500',
                    error: 'text-red-500',
                    warning: 'text-orange-500',
                }[this.type];
            },

            textClassName() {
                return {
                    info: 'text-blue-800',
                    error: 'text-red-800',
                    warning: 'text-orange-800',
                }[this.type];
            },
        },
    };
</script>
