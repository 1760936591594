export default {
    addModel: 'Create new template',
    advisorCell: "Associate's cell number",
    advisorName: "Associate's name",
    advisorPost: "Associate's extension",
    alerts: {
        index: {
            error: 'Failed fetching the templates',
        },
        store: {
            error: 'Failed creating the template',
            success: 'Template created successfully',
        },
        show: {
            error: 'Failed fetching the template',
        },
        update: {
            error: 'Failed updating the template',
            success: 'Template updated successfully',
        },
        default: {
            error: 'Failed to add your template as default',
            success: 'Template was added as default',
        },
        removeDefault: {
            error: 'Failed to remove your default template',
            success: 'Default template successfully removed',
        },
        destroy: {
            error: 'Failed deleting the template',
            success: 'Template deleted successfully',
        },
        duplicate: {
            error: 'Failed duplicating the template',
            success: 'Template duplicated successfully',
        },
        favorite: {
            error: 'Failed to add your template to your favorite list',
            success: 'Template was added to your favorite list',
        },
        unFavorite: {
            error: 'Failed to remove your template from your favorite list',
            success: 'Template was removed from your favorite list',
        },
        customCategories: {
            index: {
                error: 'Failed fetching the custom categories',
            },
            show: {
                error: 'Failed fetching the custom category',
            },
            store: {
                error: 'Failed creating the custom category',
                success: 'Custom category created successfully',
            },
            update: {
                error: 'Failed updating the custom category',
                success: 'Custom category updated successfully',
            },
            destroy: {
                error: 'Failed deleting the custom category',
                success: 'Custom category deleted successfully',
            },
        },
    },
    askDeleteTemplate: 'Do you want to delete this translation ? This action is irreversible.',
    assignedOnly: 'Assigned only',
    assignedInfoCc: 'Uses default CCs only if the email is from a user assigned to the lead.',
    assignedInfoBcc: 'Uses default BCCs only if the email is from a user assigned to the lead.',
    attachments: 'Attachments',
    attributsList: 'Attributes',
    automationsAttached: 'Automations attached',
    category: 'Category',

    customCategory: {
        defaultGroupLabel: 'Default Categories',
        customGroupLabel: 'Custom Categories',
        name: 'Name',
        add: {
            title: 'Add Custom Category',
            description: 'Create a new custom category.',
            placeholder: 'Service Follow-up',
            buttonLabel: 'Custom Category',
        },
        edit: {
            title: 'Edit Custom Category',
        },
        delete: {
            title: 'Delete Custom Category',
            description: 'Do you wish to <strong>permanently</strong> delete this custom category?',
        },
        error: {
            duplicateFound: 'This custom category already exists',
            emptyName: 'The name cannot be empty',
            activelyUsed: 'This category is currently used in {0} template(s)',
        },
    },

    content: 'Content',
    copyTemplate: 'Copy to {0}',
    create: 'New',
    createdAt: 'Date of creation',
    date: 'Custom date',
    dealerName: "Dealer's name",
    deleted: 'Deleted',
    delete: 'Delete',
    deleteModal: 'Are you sure you want to delete this template?',
    division: 'Division',
    duplicate: 'Duplicate',
    edit: 'Edit',
    editModel: 'Edit template',
    email: 'Email',
    body: {
        email: 'Email body',
        notification: 'Email body',
    },
    subject: {
        email: 'Subject',
        notification: 'Subject',
    },
    preview: {
        email: 'Email preview',
        notification: 'Screen & mobile preview',
    },
    excerpt: 'Screen and Mobile body',
    leadName: 'Name of the lead',
    emptyTemplate: 'Add empty {0}',
    empty: {
        choose: 'Choose your desired editor type.',
        basic: 'Basic Editor',
        basicDescription: 'Quick and typical text editor. Allows basic customization of fonts, some images. <strong class="font-semibold">Preferable for automatic replies and human-to-human conversations</strong>.',
        advanced: 'Advanced Editor',
        advancedDescription: 'Allows a vast amount of customizations, from columns, background colors, responsive views and other specialized tools. <strong class="font-semibold">Preferable for marketing and promo emails</strong>.',
        alert: 'Switching between editor types will clear the content.',
    },
    en: 'English',
    errorUploading: 'An error occured while uploading the image.',
    unFavoriteModal: 'Do you want to remove this template from your favorites list?',
    removeDefaultModal: 'Do you want to remove your default template?',
    changeDefaultModal: 'Do you want to change your default template?',
    fr: 'French',
    fullscreen: 'Fullscreen',
    gender: 'Gender',
    goToBasic: 'Go to basic editor.',
    goToAdvanced: 'Go to advanced editor.',
    interestedBy: 'Looking for',
    leadPhone: 'Phone #',
    locales: {
        en: {
            name: 'English',
            realName: 'English',
        },
        fr: {
            name: 'French',
            realName: 'Français',
        },
    },
    name: 'Name',
    noSelection: 'No selection',
    notification: 'Internal Notification',
    overwriteTemplate: 'Overwrite from {0}',
    phrase: 'Sentence',
    prefix: 'Greeting',
    previewMessage: 'Email Preview',
    price: 'Price',
    result: 'Preview',
    selectAccountWarning: 'You must select an account with the dropdown above.',
    selectTemplate: 'Click on one of the links below to edit the template',
    submit: 'Submit',
    doYouWantToSave: 'Do you want to save your changes before leaving?',
    sureSetMassMailing:
        'Mass mailing only supports the civility and name attributes for quality assurance. Activating this option will clear your email bodies; make sure you copied them somewhere if you do not want to lose your modifications.<br><br>@:general.wishToProceed',
    templateTitle: {
        email: 'Letter template title',
        notification: 'Notification template title',
        tooltip: 'The title must be at least 10 characters',
    },
    templateTitleDuplicate: 'The template title already exists',
    templateDeleteCaution: 'This email template is currently being used in an automation. Deleting it is not possible.',
    templateUpdateCaution: 'This email template is currently associated to an automation. Modifying it could lead to errors. Would you like to continue?',
    title: 'Letter template',
    toggleAdvanced: 'By changing mode, all email content will be completely lost. This action cannot be undone. Are you sure you want to switch mode?',
    type: 'Type',
    unsubscribe: 'Unsubscribe',
    subscribe: 'Confirm your consent',
    options: 'Options',
    textAlign: 'Text Align',
    vehicle: 'Vehicle',
    vehicleLink: 'Vehicle link',
    templateTranslationsMissing: 'Missing body',
};
