<template>
    <div class="relative rounded-full bg-gray-500 flex items-center justify-center w-10 h-10 | lg:w-16 lg:h-16">
        <icon class="text-white text-2xl | lg:text-4xl" :name="typeIcon" />
        <activix-tooltip :content="statusTooltip">
            <div>
                <icon
                    class="absolute bottom-0 right-0 rounded-full bg-white shadow"
                    :class="statusIconColor"
                    :name="statusIcon"
                />
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    // Entities
    import Lead from '../../entities/Lead.js';
    import LeadStatus from '../../entities/LeadStatus.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            typeIcon() {
                return this.getLeadTypeIcon(this.lead);
            },

            statusTooltip() {
                if (this.lead.status) {
                    return this.$t(`result.${this.lead.status}`);
                }

                return this.$t('result.active');
            },

            statusIconColor() {
                switch (this.lead.status) {
                    case LeadStatus.INVALID:
                        return 'text-orange-500';
                    case LeadStatus.DUPLICATE:
                        return 'text-gray-600';
                    case LeadStatus.LOST:
                        return 'text-red-500';
                    default:
                        return 'text-green-500';
                }
            },

            statusIcon() {
                switch (this.lead.status) {
                    case LeadStatus.INVALID:
                        return 'bold/alert-circle';
                    case LeadStatus.DUPLICATE:
                        return 'bold/subtract-circle';
                    case LeadStatus.LOST:
                        return 'bold/remove-circle';
                    default:
                        return 'bold/check-circle-1';
                }
            },
        },
    };
</script>
