<template>
    <span class="inline-flex">
        <component
            :is="tag"
            :disabled="disabled"
            :type="type"
            :to="to"
            :class="[className, colorClassName]"
            v-on="$listeners"
        >
            <slot />
        </component>
    </span>
</template>

<script>
    const colors = {
        grey: 'link-grey',
        lightGrey: 'link-grey-light',
        darkGrey: 'link-grey-dark',
        blue: 'link-blue',
        green: 'link-green',
        orange: 'link-orange',
        red: 'link-red',
    };

    export default {
        name: 'ActivixBaseButton',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'button',
            },
            tag: {
                type: String,
                default: 'button',
            },
            to: {
                type: [String, Object],
                default: null,
            },
            className: {
                type: [String, Array, Object],
                default: '',
            },
            color: {
                type: String,
                default: 'grey',
                validator: value => Object.keys(colors).includes(value) || value === null,
            },
        },

        computed: {
            colorClassName() {
                return colors[this.color];
            },
        },
    };
</script>
