export default [
    {
        path: '/landing/consent',
        name: 'consent.index',
        component: () => import(/* webpackChunkName: "consent" */ '../views/Consent/Consent.vue'),
        meta: {
            landing: true,
        },
    },
];
