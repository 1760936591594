import deepmerge from 'deepmerge';

export const combineInitialValues = (initialValues: any = {}, defaultValues: any = {}): any => {
    initialValues = Object.entries(initialValues || {}).reduce((result: any, [key, value]) => {
        if (key.indexOf('_') === 0) {
            key = key.substr(1);
        }

        result[key] = value;
        return result;
    }, {});

    return deepmerge(defaultValues, initialValues);
};

export const initValues = (object: any, values: any = {}): any => {
    for (const [field, value] of Object.entries(values)) {
        object[field] = value;
    }
};
