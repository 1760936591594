<template>
    <div class="panel panel-default">
        <div
            class="panel-heading | flex items-center justify-between py-2 px-4 cursor-pointer"
            :class="{ 'border-0': !isOpen }"
            @click.capture="isOpen = !isOpen"
        >
            <span class="font-normal">
                <slot name="header"> Item {{ index + 1 }} </slot>
            </span>
            <icon class="text-grey-500 text-sm" name="regular/arrow-up-1" v-if="isOpen" />
            <icon class="text-grey-500 text-sm" name="regular/arrow-down-1" v-else />
        </div>
        <div class="panel-body | py-2 px-4" v-if="isOpen">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActivixAccordionItem',

        props: {
            item: {
                type: [Object, String],
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                isOpen: false,
            };
        },
    };
</script>
