<template>
    <div
        class="flex flex-col items-center w-full h-full"
        :class="{ 'justify-center': isEditable}"
    >
        <div class="flex grid grid-cols-4 gap-6 w-full mb-6" v-if="hasFile">
            <activix-tooltip :content="file.name" :key="file.id" v-for="file in files">
                <div class="flex rounded shadow bg-gray-100">
                    <div class="flex justify-center items-center shrink-0 px-6 py-4 border-r border-gray-300 rounded-l bg-gray-200">
                        <icon :name="getIconName(file)" class="text-3xl text-gray-600" />
                    </div>

                    <div class="flex-1 px-3 py-4 overflow-x-hidden">

                        <p class="m-0 truncate">
                            {{ file.name }}
                        </p>
                        <p class="m-0 p-0 text-sm text-gray-600">
                            {{ getFileSize(file) }} KB
                        </p>
                    </div>

                    <div class="flex justify-center items-center pl-1 py-4 pr-6" v-if="isEditable">
                        <icon class="text-xl text-gray-600 | cursor-pointer transition hover:text-red-700" name="bold/delete-2" @click="removeFile(file)" />
                    </div>
                </div>
            </activix-tooltip>
        </div>

        <div class="p-6 bg-gray-200 w-full h-full" v-if="isEditable">
            <div
                class="relative flex flex-col justify-center items-center border-dashed rounded space-y-3 w-full border-2
                    text-gray-700 border-gray-500
                    hover:text-blue-500 hover:border-blue-500
                    transition duration-300"
                :class="{
                    'text-blue-500 border-blue-500': isHovering,
                    'h-52': isAdvanced,
                    'h-full': !isAdvanced,
                }"
                drag-over="handleDragOver"
                @dragenter="isHovering = true"
                @dragleave="isHovering = false"
            >
                <icon class="text-4xl" name="regular/upload-thick-bottom" />
                <span>{{ $t('activixUi.upload.selectOrDrag') }}</span>

                <input
                    class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    :disabled="isUploading"
                    type="file"
                    :multiple="multiple"
                    :accept="acceptedFileTypes"
                    ref="uploadInput"
                    @change="fileChanged"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { isArray, concat } from 'lodash-es';

    const FILE_TYPES = [
        {
            name: 'XLSX',
            extension: 'xlsx',
            mimeTypes: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            icon: 'regular/office-file-xls',
        },
        {
            name: 'PDF',
            extension: 'pdf',
            mimeTypes: 'application/pdf',
            icon: 'regular/office-file-pdf',
        },
        {
            name: 'JPG',
            extension: 'jpg',
            mimeTypes: 'image/jpeg',
            icon: 'regular/image-file-landscape',
        },
        {
            name: 'JPEG',
            extension: 'jpeg',
            mimeTypes: 'image/jpeg',
            icon: 'regular/image-file-landscape',
        },
        {
            name: 'PNG',
            extension: 'png',
            mimeTypes: 'image/png',
            icon: 'regular/image-file-landscape',
        },
    ];

    export default {
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            accepts: {
                type: [String, Array],
                default: () => ['image/*', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.txt'],
            },
            isUploading: {
                type: Boolean,
                default: false,
            },
            isEditable: {
                type: Boolean,
                default: true,
            },
            multiple: {
                type: Boolean,
                default: true,
            },
            isAdvanced: {
                type: Boolean,
                default: true,
            },
            maximumSize: {
                type: Number,
                default: 0,
            },
            sizeUnit: {
                type: String,
                default: 'MB',
                validator: value => ['B', 'KB', 'MB', 'GB'].includes(value),
            },
        },

        data() {
            return {
                files: this.value,
                isHovering: false,
            };
        },

        computed: {
            acceptedFileTypes() {
                if (this.accepts instanceof Array) {
                    return this.accepts.join(',');
                }

                return this.accepts;
            },

            hasFile() {
                return !!this.files.length;
            },

            sizeMultiplier() {
                // The base is always bytes
                switch (this.sizeUnit) {
                    case 'KB':
                        return 1000;
                    case 'MB':
                        return 1000 * 1000;
                    case 'GB':
                        return 1000 * 1000 * 1000;
                }

                return 1;
            },
        },

        watch: {
            value(newValue) {
                this.files = isArray(newValue) ? newValue : [newValue];
            },

            files(newValue) {
                this.$emit('input', newValue);
            },
        },

        methods: {
            fileChanged(event) {
                this.isHovering = false;

                const files = event.target.files || event.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                this.files = concat(this.value, [...files]);

                const sizeIsValid = this.isSizeValid();

                if (!sizeIsValid) {
                    this.$notify.warning(
                        this.$t('activixUi.upload.fileTooBig', {
                            maximumSize: this.maximumSize + this.sizeUnit,
                        }),
                    );
                }
            },

            removeFile(file) {
                if (this.isUploading) {
                    return;
                }

                this.files = this.files.filter(f => f !== file);
            },

            getFileType(file) {
                return FILE_TYPES.find(fileType => {
                    return (Array.isArray(fileType.mimeTypes)
                        ? fileType.mimeTypes.includes(file.type)
                        : file.type === fileType.mimeTypes) || file.name.split('.').pop() === fileType.extension;
                });
            },

            getIconName(file) {
                return this.getFileType(file)?.icon || 'regular/common-file-text';
            },

            getFileSize(file) {
                return Math.round(file.size / 1024);
            },

            isSizeValid() {
                const totalSize = this.files.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.size;
                }, 0);

                const allowedSize = this.maximumSize * this.sizeMultiplier;
                return !allowedSize || totalSize < allowedSize;
            },
        },
    };
</script>
