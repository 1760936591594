<template>
    <div>
        <ul role="tablist" class="flex justify-between list-none m-0 p-0" :class="toggleClass">
            <li
                class="flex-1 text-center"
                role="presentation"
                :key="i"
                v-for="(tab, i) in tabs"
                v-show="tab.isVisible"
            >
                <a
                    :aria-controls="tab.hash"
                    :aria-selected="tab.active"
                    :href="tab.hash"
                    class="w-full inline-block py-2 px-4 cursor-pointer font-semibold"
                    :class="linkClasses(tab, i)"
                    role="tab"
                    @click="onTabClick(i, $event)"
                >
                    <icon class="text-2xl my-1" :name="tab.icon" v-if="tab.icon" />
                    <template v-else>
                        {{ tab.textHeader }}
                    </template>
                </a>
            </li>
        </ul>
        <div>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActivixTabsMobile',

        props: {
            toggleClass: {
                type: [String, Object, Array],
                default: '',
            },

            inactiveBackground: {
                type: String,
                default: 'bg-white',
            },
        },

        data() {
            return {
                tabs: [],
            };
        },

        methods: {
            linkClasses(tab) {
                return {
                    'bg-blue-400 text-white': tab.active,
                    [this.inactiveBackground]: !tab.active,
                    'text-grey-600 hover:text-grey-800': !tab.active && !tab.disabled,
                    'cursor-not-allowed text-grey-700': tab.disabled,
                };
            },

            findTabByIndex(index) {
                return this.tabs.find(tab => this.tabs.indexOf(tab) === index);
            },

            findTabByName(name) {
                return this.tabs.find(tab => {
                    return tab.name == name;
                });
            },

            onTabClick(index, event) {
                if (event) {
                    event.preventDefault();
                }

                this.selectTab(this.findTabByIndex(index));
            },

            selectTab(tabToSelect) {
                if (!tabToSelect) {
                    return;
                }

                if (tabToSelect.disabled) {
                    return;
                }
                this.tabs.forEach(tab => {
                    tab.active = tab.name === tabToSelect.name;
                });

                this.$emit('changed', { tab: tabToSelect });
            },
        },

        mounted() {
            this.tabs = this.$children.filter(child => {
                return child.constructor.options.name === 'ActivixTabMobile';
            });

            if (this.tabs.length) {
                const initialTab = this.findTabByName(this.$route.query.tab) || this.findTabByIndex(0);

                this.selectTab(initialTab);
            }
        },
    };
</script>
