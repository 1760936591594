export default {
    atv: 'ATV',
    automotive: 'Automotive',
    bike: 'Bike',
    boat: 'Boat',
    caravan: 'Caravan',
    hybride: 'Hybrid',
    mechanical: 'Mec. product',
    motorcycle: 'Motorcycle',
    motorized: 'Motorhome',
    snowmobile: 'Snowmobile',
    suv: 'SUV',
    trailer: 'Trailer',
    truck: 'Truck',
    utility: 'Utility (side by side)',
    van: 'Van',
    watercraft: 'Watercraft',
};
