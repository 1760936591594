class TooltipException extends Error {
    constructor(message, possibleCause, tooltipOptions) {
        super(message);
        this.tooltipOptions = tooltipOptions;
        this.possibleCause = possibleCause;
        this.name = 'TooltipException';

        Error.captureStackTrace(this, TooltipException);
    }
}

export default TooltipException;
