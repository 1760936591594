<template>
    <div class="flex items-center">
        <activix-tooltip :content="validationTooltip">
            <button
                :class="buttonClassName"
                class="inline-flex items-center rounded-full"
                @click.prevent="onClick"
                v-if="isClickable"
            >
                <icon :name="validationStateIcon" />
            </button>
            <icon
                :class="iconClassName"
                :name="validationStateIcon"
                v-else
            />
        </activix-tooltip>
    </div>
</template>

<script>
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import LeadEmail from '@/entities/LeadEmail.js';
    import EmailValidationRisk from '@/entities/EmailValidationRisk.js';
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            email: {
                type: LeadEmail,
                required: true,
            },
            showEmail: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isValidating: false,
            };
        },

        computed: {
            validatedEmail() {
                return this.email.validated_email;
            },

            status() {
                if (this.isValidating) {
                    return 'validating';
                }

                if (this.validatedEmail.isNotValid()) {
                    return 'error';
                }

                return {
                    high: 'error',
                    medium: 'warning',
                    low: 'success',
                }[this.validatedEmail.risk] || 'unknown';
            },

            buttonClassName() {
                return {
                    validating: 'link-grey-light animate-pulse',
                    error: 'link-red',
                    warning: 'link-orange',
                    success: 'link-green',
                    unknown: 'link-grey-light',
                }[this.status];
            },

            iconClassName() {
                return {
                    validating: 'text-grey-500 animate-pulse',
                    error: 'text-red-500',
                    warning: 'text-orange-500',
                    success: 'text-green-500',
                    unknown: 'text-grey-500',
                }[this.status];
            },

            validationStateIcon() {
                return {
                    validating: 'bold/navigation-menu-horizontal-1',
                    error: 'bold/remove-circle',
                    warning: 'bold/alert-circle',
                    success: 'bold/check-circle-1',
                    unknown: 'bold/question-circle',
                }[this.status];
            },

            isClickable() {
                return this.validatedEmail.risk === 'unknown';
            },

            deliverableText() {
                const key = {
                    high: 'emailValidation.deliverable.high',
                    medium: 'emailValidation.deliverable.medium',
                    low: 'emailValidation.deliverable.low',
                    unknown: 'emailValidation.deliverable.unknown',
                }[this.validatedEmail.risk];

                return this.$t(key);
            },

            validationTooltip() {
                let tooltip = '';

                if (this.isValidating) {
                    return this.$t('emailValidation.isValidating');
                }

                if (this.validatedEmail.hasBounced()) {
                    const permanentFailedAt = new ActivixDate(this.validatedEmail.permanent_failed_at);

                    return `
                        <div class="text-left">
                            ${this.$t('emailValidation.bounce')}<br>
                            Date: <span class="font-bold">${permanentFailedAt.toHumanShort(false)}</span>
                        </div>
                    `;
                }

                if (this.validatedEmail.is_disposable) {
                    return `<div class="text-left">${this.$t('emailValidation.disposable')}</div>`;
                }

                if (this.validatedEmail.validation_result === 'unknown') {
                    return `<div class="text-left">${this.$t('emailValidation.reasons.catchAll')}</div>`;
                }

                if (this.showEmail && this.validatedEmail.risk !== EmailValidationRisk.LOW) {
                    tooltip += `<div class="font-semibold">${this.email.email}</div>`;
                }

                if (this.validatedEmail.typo_suggestions.length) {
                    tooltip += `<div>${this.$t('emailValidation.typo', { address: this.validatedEmail.typo_suggestions.join('" "') })}</div>`;
                }

                tooltip += `<div>${this.deliverableText}</div>`;

                let reasonText = '';
                this.validatedEmail.reason.forEach(reason => {
                    reasonText = this.getReasonText(reason);

                    if (reasonText) {
                        tooltip += `<div class="italic">${reasonText}</div>`;
                    }
                });

                return `<div class="text-left space-y-1">${tooltip}</div>`;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadEmail']),

            getReasonText(reason) {
                return {
                    catch_all: this.$t('emailValidation.reasons.catchAll'),
                    high_risk_domain: this.$t('emailValidation.reasons.highRiskDomain'),
                    immature_domain: this.$t('emailValidation.reasons.immatureDomain'),
                    long_term_disposable: this.$t('emailValidation.reasons.longTermDisposable'),
                    mailbox_does_not_exist: this.$t('emailValidation.reasons.mailboxDoesNotExist'),
                    mailbox_is_disposable_address: this.$t('emailValidation.reasons.mailboxIsDisposableAddress'),
                    mailbox_is_role_address: this.$t('emailValidation.reasons.mailboxIsRoleAddress'),
                    no_mx: this.$t('emailValidation.reasons.noMx'),
                    subdomain_mailer: this.$t('emailValidation.reasons.subdomainMailer'),
                    tld_risk: this.$t('emailValidation.reasons.tldRisk'),
                    unknown_provider: this.$t('emailValidation.reasons.unknownProvider'),
                    erroneous_format: this.$t('emailValidation.reasons.erroneousFormat'),
                    max_validation_attempts_exceeded: this.$t('emailValidation.reasons.maxValidationAttemptsExceeded'),
                }[reason] || '';
            },

            async fetchEmailValidation() {
                this.isValidating = true;

                try {
                    const validatedEmail = await this.$api.validatedEmails.validate(this.email.email);

                    this.updateLeadEmail({
                        ...this.email,
                        validated_email: validatedEmail,
                    });

                    this.$emit('update', validatedEmail);
                } catch (error) {
                    this.$notify.error(this.$t('emailValidation.validateError'));
                    throw error;
                } finally {
                    this.isValidating = false;
                }
            },

            onClick() {
                this.fetchEmailValidation();
            },
        },
    };
</script>
