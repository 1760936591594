import { defineStore } from 'pinia';
import initialState from './state.js';
import actions from './actions.js';
import getters from './getters.js';

export const useGlobalStore = defineStore({
    id: 'global',
    state() {
        return initialState;
    },
    getters,
    actions,
});
