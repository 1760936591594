export const NOTIFICATION_WIDTH_TRESHOLD = 325;
export const NOTIFICATION_WIDTH_MAX = 325;
export const NOTIFICATION_WIDTH_ADJUSTMENT = 35;

export const NOTIFICATION_POPOVER_WIDTH_TRESHOLD = 390;
export const NOTIFICATION_POPOVER_WIDTH_MAX = 380;
export const NOTIFICATION_POPOVER_WIDTH_ADJUSTMENT = 10;

export const isXxs = window.matchMedia('(max-width: 575px)').matches;
export const isXs = window.matchMedia('(max-width: 639px)').matches;
export const isSm = window.matchMedia('(max-width: 767px)').matches;
export const isMd = window.matchMedia('(max-width: 1023px)').matches;
export const isLg = window.matchMedia('(max-width: 1279px)').matches;
export const isXl = window.matchMedia('(max-width: 1535px)').matches;
export const is2Xl = window.matchMedia('(min-width: 1536px)').matches;
