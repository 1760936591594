import Vue from 'vue';
import MfaAuth from './mfa-auth.js';

const mfaAuthService = new MfaAuth();

const VueMfaAuth = {
    install(Vue) {
        Vue.mfaAuth = mfaAuthService;
        Vue.prototype.$mfaAuth = mfaAuthService;
    },
};

Vue.use(VueMfaAuth);
