export default {
    alerts: {
        index: {
            error: 'Failed fetching the users',
        },
        store: {
            success: 'User created successfully',
            error: 'Failed creating the user',
        },
        show: {
            error: 'Failed fetching the user',
        },
        update: {
            success: 'User updated successfully',
            error: 'Failed updating the user',
        },
        destroy: {
            success: 'User is being deleted...',
            error: 'Failed deleting the user',
        },
        updateStatus: {
            success: 'Status updated successfully\n',
            error: 'Failed updating your status',
        },
        mfaEnrollment: {
            success: 'MFA enrollment successful',
            error: 'Error occurred during your MFA enrollment. Please try again',
        },
        mfaRemove: {
            success: 'MFA removed successfully',
            error: 'Error disabling MFA. Please try again',
        },
    },
    edit: {
        accessRenewalDirector: 'Access @.capitalize:lexicon.portfolio.singular Director',
        accessRenewalDirectorTooltip: 'View every @:lexicon.portfolio.singular leads, regardless of the assignment',
        account: 'Account',
        accountFrequencyWarning: "Please note that notifications will be lost if they are received outside of the dealer's schedule. You can choose to keep them anytime.",
        accountInformation: 'Account related information',
        accountMustHaveImapEmail: 'The account must have the Imap Access option to activate this option.',
        accountSchedule: "Account's Schedule",
        activateMfaButton: 'Activate multi-factor authentication',
        activeOptionAccount: 'Activate the option in the account',
        activityReport: 'Activity Report',
        addPhoto: 'Click here to add a picture',
        allLead: 'All leads',
        allowAdvancedAutomationAccess: 'Advanced Automation',
        allowAnalyticsAccess: 'Analytics (BI Tool)',
        allowAutomationAccess: 'Automation',
        allowEventCreation: 'Allow events creation',
        allowExportAccess: 'Allow access to the export',
        allowImportAccess: 'Allow access to the import',
        answer: 'Answer',
        answerDescription: 'Answer time',
        answeredLeads: 'Answered leads (by your colleagues)',
        answeredLeadsDesc: 'Receive an alert when a colleague within the same division answers a pending lead',
        anytime: 'Anytime',
        approved: 'Approved',
        approvedAssignedTooltip: 'Receive an alert in real time when a client you are assigned to is approved',
        approvedCredit: 'Credit approved',
        approvedTooltip: 'Receive an alert in real time when a client is approved',
        assignationLeadFollow: 'Assigned communication',
        assignationLeadFollowTooltip: 'Receive an alert for each associate you assign in a client card to facilitate your communications.',
        assignedLeads: 'Assigned Leads/Tasks/Notifications',
        assignedLeadsTooltip: 'Receive a notification when you are assigned a lead, task or appointment',
        assignedLeadUpdated: 'Modification on assigned lead',
        assignedLeadUpdatedDescription: "You will receive this alert when someone modifies a lead you're assigned to",
        assignedLeadVehicleCreated: 'New vehicle for an assigned lead',
        assignedLeadVehicleCreatedTooltip: 'Receive a notification when a vehicle is created for one of your leads.',
        assignedLeadVehicleCreatedManager: 'New vehicle for any lead',
        assignedLeadVehicleCreatedManagerTooltip: 'Receive a notification when a vehicle is created for any lead in your divisions.',
        assignedLeadVehicleUpdated: 'Vehicle modified for an assigned lead',
        assignedLeadVehicleUpdatedTooltip: 'Receive a notification when a vehicle is modified for one of your leads. Only 1 notification will be sent every 15 mins.',
        assignedLeadVehicleUpdatedManager: 'Vehicle modified for any lead',
        assignedLeadVehicleUpdatedManagerTooltip: 'Receive a notification when a vehicle is modified for any lead in your divisions',
        assignedNotice: 'Reply to note',
        assignedNoticeTooltip: 'Receive an alert when someone replies to a note you left in a client card',
        equityAlert: 'Equity',
        hideInUserSelect: 'Hide in "child" account',
        noReassignationNotification: 'No reassignation notification',
        removeMfaButton: 'Remove multi-factor authentication',
        renewalAlert: '@.capitalize:lexicon.portfolio.singular',
        renewalAlertEquity: 'Positive Equity',
        renewalAlertEquityDescription: 'Notification when a vehicle is in positive equity',
        renewalAlertEndLocation: 'End of lease',
        renewalAlertEndLocationDescription: 'Notification when a lease is about to expire',
        renewalAlertEndWarranty: 'End of warranty',
        renewalAlertEndWarrantyDescription: 'Notification when a warranty is about to expire',
        renewalAlertExcessMileage: 'Estimated mileage excess',
        renewalAlertExcessMileageDescription: 'Notification when a customer is estimated to exceed their warranty mileage',
        auth: 'External authentification',
        authorizedDealer: 'Authorized Dealer',
        automaticLeadInfo: 'Automatic lead info',
        automaticLeadInfoTooltip: 'Receive an SMS when you select option 5 while processing a WebBoost request',
        automationNotification: 'Automations Notifications',
        automationNotificationTooltip: '(Automation name) - (Template name) - (Recipient)',
        automationActionNotificationTooltip: "You will be notified if you are a {1} on a lead that follows the criteria of the automation '{0}'",
        automationActionNotificationYouTooltip: "You will be notified for every lead that follows the criteria of the automation '{0}'",
        availableAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to has an availability date added',
        availableDate: 'Veh. here on',
        availableTooltip: 'Receive an alert in real time when a vehicle has an availability date added',
        bdcSuperAccess: 'BDC super access',
        bdcSuperAccessTooltip: 'Gives the bdc agent the same acccess to the lead as a bdc manager',
        before: 'before',
        bellEventOption: 'Task icons "Clients" column',
        bellEventOptionTooltip: 'Show the tasks and events from a lead in the dashboards (icons)',
        businessHours: "Dealer's business hours",
        calendar: 'Calendar',
        calendar_options: 'Calendar',
        campaign: 'Campaign',
        ccCommInbound: 'CC inbound communications',
        ccCommInboundDescription: 'Notifications from inbound SMS and email communications will also be sent to the selected users',
        cellphone: 'Cellphone',
        clientAnswer: 'Answer from client',
        clientAnswerTooltip: 'Receive an alert when a client replies to your emails with the CRM tool response',
        commentFollow: 'Comments communication',
        commentFollowTooltip: 'Receive an alert for each comment that you add in a client card to facilitate your communications.',
        commercialDirector: 'F&I',
        communicationInformation: 'Communication information',
        confirmation: 'Confirmation',
        confirmationApptOption: 'Appointment Confirmation',
        confirmationWhen: 'When will the confirmation message be sent?',
        copy: 'Copy',
        copyModifs: 'Copy modifications',
        confirm: 'Confirm',
        credit: 'Credit',
        crmMax: 'CRM $',
        daily: 'Daily',
        defaultAccount: 'Default account',
        defaultAccountTooltip: 'Select the default account when logging in',
        dateOptions: 'Custom date',
        dateRangeTooltip: 'Configure the time period in months in the dashboard date option',
        declinedAssignationLead: 'Assignment request',
        declinedAssignationLeadTooltip: 'Receive a real time alert when your assignment request to a lead is declined',
        defaultDashboard: 'Dashboard',
        defaultDashboardServiceTooltip: 'Only the service dashboard is available when the service division is the only one selected',
        defaultDashboardTooltip: 'Choose which dashboard you will be redirected to at log in',
        defaultTasksReminder: 'Default tasks reminder',
        defaultNotification: 'My divisions',
        deleteUser: 'Delete the user',
        deletingUser: 'Deletion in progress, can take up to 1 minute',
        delivered: '@.capitalize:lexicon.delivered.singular',
        deliveredAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to is @:lexicon.delivered.singular',
        deliveredTooltip: 'Receive an alert in real time when a vehicle is @:lexicon.delivered.singular',
        deliveryAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to has a @:lexicon.delivery.singular',
        deliveryDate: '@:lexicon.deliveryDate.singular',
        deliveryTooltip: 'Receive an alert in real time when a vehicle has a @:lexicon.delivery.singular date added',
        departmentMoney: 'Dashboard $',
        disableMailClient: 'Disable mail client',
        division: 'Division',
        email: 'Email',
        emailVideo: 'Video email',
        emailReadAlert: 'Email read confirmation',
        emailReadDescription: 'Receive a notification when your email is opened by the recipient',
        emailReadDisabled: 'Feature not compatible with IMAP email client',
        endContractRequired: 'Contract end date required',
        endContractRequiredTooltip: 'Forces the user to enter a contract end date at the time of @:lexicon.delivery.singular',
        event: 'Event',
        eventBadService: 'Bad customer service',
        eventBadServiceDescription: 'Notification sent when a client has indicated to have received bad service',
        eventCanceledAppointment: 'Canceled appointment',
        eventCanceledAppointmentDescription: 'Notification sent when an event appointment is canceled',
        eventConfirmedAppointment: 'Confirmed appointment',
        eventConfirmedAppointmentDescription: 'Notification sent when an event appointment is confirmed',
        eventLeadToReach: 'Client wants to be reached',
        eventLeadToReachDescription: 'Notification sent when a client wants to be reached in the context of an event',
        eventNewAppointment: 'New appointment',
        eventNewAppointmentDescription: 'Notification sent when an event appointment is created',
        eventNewPhoneAppointment: 'New phone appointment',
        eventNewPhoneAppointmentDescription: 'Notification sent when an event phone appointment is created',
        every: 'Every',
        externalNotificationMediumRequired: 'At least one option between email, sms and mobile must be active',
        fAndIState: 'F&I State',
        feedback: 'Trade-in appraisal result',
        feedbackTooltip: 'Receive an alert when receiving an appraisal result',
        firstName: 'First name',
        freezeData: 'Lock $ data',
        freezeFinancialData: 'Lock financial data',
        freezeFinancialDataTooltip: "Gives permission to block a client's financial data",
        frequencySituation: 'Frequency / Situation',
        gas: 'Fueled up',
        gasAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to is fueled up',
        gasTooltip: 'Receive an alert in real time when a vehicle is fueled up',
        gender: 'Gender',
        general: 'General',
        goUser: 'Go to the user',
        group: 'Group',
        hasParentUser: 'This field can only be modified in the parent user profile',
        hideFIProfits: 'Hide F&I profits',
        hideNumber: 'Hide number (xxxx)',
        homepage: 'Homepage',
        ignore: 'Ignore',
        imapEmail: 'IMAP Email',
        incompleteTasksReminder: 'Incomplete tasks reminder',
        inspected: 'Inspected',
        inspectedAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to is inspected',
        inspectedTooltip: 'Receive an alert in real time when a vehicle is inspected',
        inTurn: 'Who\'s Next',
        inTurnEdit: 'Who\'s Next modification',
        inTurnEditTooltip: 'Allow access to make changes to the Who\'s Next module',
        inTurnRemoved: 'User removes himself/herself from Who\'s Next',
        invalidAlert: 'Invalid leads',
        invalidAlertTooltip: 'Receive an alert for each invalid lead.',
        language: 'Language',
        lastName: 'Last name',
        leadDeletion: 'Lead deletion/restoration',
        leadMerging: 'Lead merging',
        leadTransfer: 'Leads transfer',
        leadWaitingInRoom: 'Lead waiting in conference',
        leadWaitingInRoomTooltip: 'Notification when a client is waiting for you in a videoconference room.',
        leadsWithoutDivision: 'Leads without division',
        leadsWithoutPhone: 'Leads without phone',
        leadsWithoutPhoneDescription: 'Notification sent when a WebBoost lead is without a valid phone and Web Lead is not enabled',
        leadXpress: 'Web Lead',
        limitAudioAccess: 'Only access my own audios',
        limitAudioAccessDesc: "This feature limits a users' access to their own audios only.",
        limitCalendarTaskAccess: 'My tasks only',
        limitCalendarTaskAccessTooltip: 'Restricts the user to only see his/her own tasks in the calendar',
        lostAlert: 'Lost leads',
        lostAlertTooltip: 'Receive an alert for each lead lost.',
        loyalty: 'Loyalty',
        newLeadLoyaltyNotif: 'New loyalty lead',
        newLeadLoyaltyNotifTooltip: 'Receive an alert when a new lead of loyalty is received',
        newLeadLoyaltyDuplicateNotif: 'Duplicate loyalty lead',
        newLeadLoyaltyDuplicateNotifTooltip: 'Receive an alert when a duplicate lead of loyalty is received',
        mailClientAlreadyActivated: 'Mail client already activated',
        masterAccountPreferences: 'The preferences of the "parent" users will be duplicated at the creation in child accounts by default. It is possible to modify them individually afterwards.',
        mentions: 'Mentions',
        mentionsTooltip: 'Receive an alert when you are mentioned in a note',
        messenger: 'Facebook Messenger',
        mfaCanOnlyBeUpdatedBySelf: 'Multi-factor authentication can only be modified by the user itself',
        mfaEnforced: 'The dealer enforce multi-factor authentication',
        mfaTitle: 'Multi-factor authentication',
        mfaUnableRetrieveStatus: 'Unable to retrieve enrollment status',
        missedWbTooltip: 'Receive an alert if a WebBoost call is missed',
        push: 'Mobile',
        modulesAndOptions: 'Modules & Options',
        name: 'Name',
        needEquityAlert: 'Equity must be active',
        needRenewal: '@.capitalize:lexicon.portfolio.singular must be activated',
        needRenewalAlert: '@.capitalize:lexicon.portfolio.singular Alert must be activated',
        needRenewalAlertConfig: 'The alert configuration must be completed in the account preference',
        needEvent: 'Event must be activated',
        needInTurn: 'Who\'s Next must be activated',
        needLeadXpress: 'Web Lead must be activated',
        needLeadXpressAndNotWebBoost: 'You must have Web Lead without WebBoost to enable this option',
        needNioText: 'NioText must be activated',
        needService: 'Service must be activated',
        needToLogOnceBeforeActivatingPush: 'You must log in the mobile app once before activating the mobile notifications',
        needVideoConference: 'Videoconference must be activated in the account',
        needWebBoost: 'WebBoost must be activated',
        needWebBoostAndNotLeadXpress: 'You must have WebBoost without Web Lead to enable this option',
        newerThan: 'Newer than X months',
        newLeadAlert: 'New lead alert',
        newLeadNotificationTooltip: 'Because you are in the Web Lead procedure, you must have at least one contact method',
        newLeadDuplicateReceived: 'New lead duplicate received',
        newLeadDuplicateReceivedTooltip: 'Receive an alert when a new lead duplicate is added in Web Lead',
        newLeadReceived: 'New lead received',
        newLeadReceivedTooltip: 'Receive an alert when a new lead is added in Web Lead',
        newNoteOnAssignedLead: 'New note on a lead you are assigned to',
        newNoteOnAssignedLeadTooltip: 'You will receive this alert when a note is left on a lead you are assigned to',
        nextInTurn: 'Next in line',
        nextStep: 'Next step',
        nextStepMandatory: 'Next step mandatory',
        nextStepMandatoryTooltip: 'A task must be created when the "Next step" pop up appears (cannot be dismissed)',
        nextStepTooltip: 'Shows the "Next step" pop up',
        nextToReply: 'Next to reply',
        nioText: 'NioText',
        nioTextIncoming: 'Incoming SMS',
        nioTextIncomingDescription: 'Notification sent when a client responds to you',
        notAnsweredLead: 'Missed WebBoost call',
        notifications: 'Notifications',
        notificationByDivision: 'Divisions',
        notificationsTransfer: 'Notification Transfer of communications',
        notificationsTooltip: 'This type of transfer is always required',
        noUserInTurn: 'No associate available',
        obtainedMeeting: 'Appointment set',
        obtainedMeetingTooltip: 'Receive an alert when an appointment is booked for a client',
        obtainedSale: 'New @:lexicon.sale.singular',
        obtainedSaleAssignedTooltip: 'Receive an alert when a lead you are assigned to is @:lexicon.sold.singular',
        obtainedSaleTooltip: 'Receive an alert when a lead is @:lexicon.sold.singular',
        deposit: 'New deposit',
        depositAssignedTooltip: 'Receive an alert when a deposit is received for a client',
        depositTooltip: 'Receive an alert when a deposit is received',
        olderThan: 'Older than X months',
        optionsMoney: 'Options',
        paperwork: 'Paperwork',
        paperworkAssignedTooltip: 'Receive an alert in real time when a client you are assigned to has the paperwork completed',
        paperworkTooltip: 'Receive an alert in real time when the paperwork is completed',
        password: 'Password',
        personalInformation: 'Personal Information',
        phoneNumber: 'Phone number',
        phoneProviderWarning: 'Deactivating this user will remove his profile from Activix\'s phone system. Would you like to continue?',
        phoneUp: 'Phone-up',
        post: 'Extension',
        preferences: 'Preferences',
        preferred: 'By default',
        preferredTooltip: 'Used to decide which number to replace the user phone attibute in emails',
        preparation: 'Preparation',
        prepared: 'Prepared',
        preparedAssignedTooltip: 'Receive an alert in real time when a vehicle you are assigned to is prepared',
        preparedTooltip: 'Receive an alert in real time when a vehicle is prepared',
        profilePictureTooLarge: 'Your profile picture resolution must be less than 2000px/2000px',
        reassignationLeadDemand: 'Lead assignment',
        reassignationLeadDemandTooltip: 'Receive a real time alert when someone makes a request to be assigned on one of your lead.',
        reenableMailClient: 'Re-enable mail client',
        renewal: '@.capitalize:lexicon.portfolio.singular',
        restrictedNotifications: "Notifications have been changed from SMS to Screen and Mobile at your manager's request. Contact us to reactivate them.<br><br>support@activix.ca or 1 866 430-6767",
        restrictedNotificationsWarning: 'Warning, the notifications have been changed from SMS to Screen and Mobile at the request of management.',
        restrictInterdealerLeads: 'Hide inaccessible inter-dealer leads',
        restrictEmailTemplateEdition: 'Edit email template',
        returnToMarket: 'Return to market',
        returnToMarketTooltip: 'Receive an alert when a lead returns to market',
        ringtone: 'Ringtone',
        ringtoneDescription: 'Ringing time',
        role: 'Role',
        roles: 'Roles',
        saleTable: '@.capitalize:lexicon.sale.plural Board',
        screen: 'Screen',
        selectUser: 'Select user that will receive data',
        selectAll: 'Select All',
        service: 'Service',
        serviceMyClientsAppointments: '"My @:lexicon.sold.singular Clients" service appointments',
        serviceMyClientsAppointmentsDescription: 'Notification sent when a service appointment is booked on one of your @:lexicon.sold.singular leads (as an agent or a BDC)',
        serviceMyClientsVehiclePickUp: '"My Service Clients" vehicle pick up',
        serviceMyClientsVehiclePickUpDescription: 'Notification sent when the pick up date is set on one of your service clients',
        serviceTooltip: 'The service division must be selected in order to activate this option',
        serviceSuperAccess: 'Service super access',
        serviceSuperAccessTooltip: 'Gives the service agent access to all leads',
        serviceVehiclePickUp: 'Vehicle pick up',
        serviceVehiclePickUpDescription: 'Notification sent when the pick up date is set on any service clients',
        showEstimatevalue: 'Vehicle estimate value',
        showEstimatevalueTooltip: 'Show vehicle estimate value ($)',
        showInbox: 'Inbox',
        showInboxTooltip: 'Gives access to the inbox in the menu',
        signature: 'Signature',
        signature_en: 'Signature EN',
        signature_fr: 'Signature FR',
        sms: 'SMS',
        smsCosts: 'SMS costs',
        stateChanged: 'Lead state changed',
        stateChangedTooltip: 'Receive an alert in real time when the state is updated',
        supplier: 'Supplier',
        supplierInformation: 'Suppliers information',
        taskEventUnassigned: 'New Unassigned Tasks/Events',
        taskEventUnassignedToolTip: 'Receive a notification when a new task, an appointment or a @:lexicon.delivery.singular is not assigned.',
        taskDeleted: 'Deleted task/event',
        taskEventTransfer: 'Transfer task and events',
        remindersTransfer: 'Transfer reminders',
        taskEventDatesUpdated: 'Date change on a task/event',
        taskEventStatusUpdated: 'Status change on a task/event',
        team: 'Team',
        teamCalendar: 'Team calendar',
        title: 'Title',
        toDeliverOn: '@.capitalize:lexicon.delivered.infinitive on',
        tradeReport: 'Acquisitions Report',
        tradeReportTooltip: 'Gives access to Acquisitions Report fields ("Value", "Actual value", "Buying", "Notes")',
        transfer: 'Transfer',
        transferModalTitle: 'Transfer user\'s data',
        transferType: 'Select transfer types (Irreversible)',
        unselectAll: 'Unselect all',
        untreatedLead: 'Pending lead alert',
        untreatedLeadDuplicate: 'Pending lead duplicate alert',
        untreatedLeadDuplicateTooltip: 'Receive an alert when a web lead duplicate is pending',
        untreatedLeadTooltip: 'Receive an alert when a web lead is pending',
        updateChildModalTitle: 'Copy modifications to child users in selected accounts',
        userFrequencyWarning: 'Please note that notifications will be lost if they are received outside of your schedule. You can choose to keep them anytime.',
        users: 'Users',
        username: 'Username',
        userSchedule: "User's Schedule",
        vAutoUsernamePlaceholder: 'vAuto username',
        vAutoUsernameTooltip: 'Your vAuto username in order to be tagged as salesperson in vAuto submission',
        video: 'Video',
        videoconference: 'Videoconference',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        warningSmsActivation: 'Please note that SMS notifications incurs costs, be sure to use them only if necessary.<br><br><span class="italic text-base">You can use mobile notifications with the app at no cost.</span>',
        webBoost: 'WebBoost',
        weekly: 'Weekly',
        webOrder: 'Web Order',

        accountInfos: {
            childAccount: '"Children" accounts',
        },
        disabledTooltips: {
            mandatoryOptions: 'Some options are mandatory',
            onlyDirectors: 'Only directors can modify this option',
            optionNotAvailable: 'This option is not available for this account',
            webCrmRequired: 'The CRM Web Lead is required',
            webOrderRequiresIt: 'The Web Order dashboard requires this option',
        },
        options: {
            canCreateChild: '"Child" user creation',
        },
        personalAccessToken: {
            createNewToken: 'Create new token',
            createToken: 'Create token',
            custom: 'Custom...',
            newTokenMessage: "Here is your new personal access token. This is the only time it will be shown so don't lose it! You may now use this token to make API requests.",
            noAccessToken: 'No personal token',
            revoke: 'Revoke',
            revoked: 'Revoked',
            revokeToken: 'Revoke this token?',
            scopes: 'Scopes',
            title: 'Personal Access Tokens',
        },
    },
    global: {
        goToAccount: 'Access the account card',
        impersonate: 'Impersonate',
    },
    list: {
        deleteModal: 'Are you sure you want to delete this user?',
        hasSuspendedUsers: 'Unable to add new users during a suspension',
        hideInactive: 'Hide inactive',
        showInactive: 'Show inactive',
        table: {
            headings: {
                name: 'Name',
                phones: 'Phones',
                email: 'Email',
                role: 'Role',
                divisions: 'Divisions',
                locale: 'Language',
                lastActive: 'Last active',
            },
        },
    },
};
