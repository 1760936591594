<template>
    <div class="notification-wrapper" :class="{ notseen: !notification.isSeen() }" @click.stop="$emit('seen')">
        <div class="mr-1">
            <activix-tooltip :content="notification.tooltip">
                <icon class="w-6 h-6" :class="notification.iconColor" :name="notification.iconName" />
            </activix-tooltip>
        </div>

        <div class="flex-1 px-4 overflow-hidden">
            <div class="subject | font-bold">
                <template v-if="notification.type.indexOf('Sent') !== -1">
                    <router-link
                        :to="{ name: 'calendar' }"
                        v-if="['taskSent', 'eventSent'].includes(notification.type)"
                    >
                        {{ notification.title }}
                        <template v-if="options.advisor">
                            {{ $t('notificationCenter.to') }} : {{ options.advisor }}
                        </template>
                    </router-link>

                    <template v-else>
                        {{ notification.title }}
                        <template v-if="options.advisor">
                            {{ $t('notificationCenter.to') }} : {{ options.advisor }}
                        </template>
                    </template>
                </template>
                <template v-else-if="notification.type == 'automationCompleted'">
                    <router-link :to="{ name: 'automations.index' }">
                        {{ notification.title }}
                    </router-link>
                </template>
                <template v-else-if="options.subject">
                    <router-link
                        :to="notification.leadUrl"
                        @click.native="reloadNotificationLead(options.lead_id)"
                        v-if="options.comm_id && options.lead_id && options.type === NotificationSubType.EMAIL"
                    >
                        {{ notification.title }}
                    </router-link>

                    <template v-else>
                        {{ notification.title }}
                    </template>
                </template>
                <template v-else-if="options.title">
                    <router-link
                        class="link-blue"
                        :to="notification.leadUrl"
                        @click.native="reloadNotificationLead(options.lead_id)"
                    >
                        {{ options.title }}
                    </router-link>
                    <template v-if="startTime">
                        - {{ startTime }}
                    </template>
                </template>
                <template v-else>
                    {{ notification.title }}
                    <template v-if="options.advisor">
                        : {{ options.advisor }}
                    </template>
                </template>
            </div>

            <template v-if="notification.type == 'automationCompleted'">
                <div class="subject">
                    {{ options.title }}
                </div>
                <div class="subject">
                    {{ $tc('automations.table.row.affectedClients', options.affected_count, [options.affected_count]) }}
                </div>
            </template>

            <template v-if="notification.type == 'automationNotification'">
                <activix-tooltip :content="options.bodyNotification">
                    <div class="subject" v-html="options.bodyNotification" />
                </activix-tooltip>
            </template>

            <div class="subject" v-if="notification.leadUrl">
                {{ $t('notificationCenter.lead') }} :
                <router-link
                    class="link-blue"
                    :to="notification.leadUrl"
                    @click.native="reloadNotificationLead(options.lead_id)"
                >
                    {{ notification.leadName }}
                </router-link>
                <span v-if="notification.date">- {{ notification.date }}</span>
            </div>

            <div class="subject" v-if="options.campaign">
                {{ $t('notificationCenter.campaign') }} : {{ options.campaign }}
            </div>

            <div class="subject" v-if="options.dealer_name">
                {{ $t('notificationCenter.dealer') }} : {{ options.dealer_name }}
            </div>

            <div class="subject" v-if="notification.options.byUser">
                {{ $t('notificationCenter.byUser') }} : {{ notification.options.byUser }}
            </div>

            <template
                v-if="
                    ['renewalAlertEquity', 'renewalAlertEndLocation', 'renewalAlertEndWarranty'].includes(
                        notification.type,
                    )
                "
            >
                <div class="subject">
                    <router-link
                        class="link-blue"
                        :to="
                            getViewLink(
                                options.type,
                                options.startDate,
                                options.endDate,
                                notification.type == 'renewalAlertEquity' ? options.notification_date : '',
                            )
                        "
                    >
                        <strong>{{ options.total }}</strong>
                        {{ $tc(`notificationCenter.general.${notification.type}Details`, options.total, options) }}
                    </router-link>
                </div>
            </template>
        </div>

        <div class="flex flex-col text-right text-sm text-grey-600">
            <div>{{ creationDate }}</div>
            <div>{{ creationTime }}</div>
        </div>
    </div>
</template>

<script>
    // Utils
    import { upperFirst } from 'lodash-es';
    // Entities
    import { mapActions, mapState } from 'pinia';
    import Notification from '../entities/Notification.js';
    import NotificationSubType from '../entities/NotificationSubType.js';
    // Value Objects
    import ActivixDate from '../value-objects/ActivixDate.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        props: {
            notification: {
                type: Notification,
                required: true,
            },
        },

        data() {
            return {
                NotificationSubType,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['leads']),

            creationDate() {
                return new ActivixDate(this.notification.created_at).toHumanShort(false);
            },

            creationTime() {
                return new ActivixDate(this.notification.created_at).toHumanTime();
            },

            options() {
                return this.notification.options || {};
            },

            startTime() {
                if (!this.options.start_time) {
                    return '';
                }

                return upperFirst(this.futurMoment(this.options.start_time));
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['reloadLead']),
            getViewLink(notificationType, startDate, endDate, notificationDate) {
                return {
                    name: 'dashboards.renewal',
                    query: {
                        notificationType,
                        startDate,
                        endDate,
                        notificationDate,
                    },
                };
            },

            reloadNotificationLead(leadId) {
                if (this.leads.some(lead => lead.id === leadId)) {
                    this.reloadLead(leadId);
                }
            },
        },
    };
</script>
