import BaseRepository from '@/graphql/repositories/BaseRepository.js';
import GraphCache from '@/graphql/GraphCache.js';

class UserRepository extends BaseRepository {
    static getModelName() {
        return 'User';
    }

    static findUserOrChild(id, specificFieldsToQuery = [], useCache = true, ignoredFields = []) {
        if (!this.isValidId(id)) {
            return null;
        }

        if (!useCache) {
            return this.find(id, specificFieldsToQuery, useCache, ignoredFields);
        }

        let cachedUser = GraphCache.get(this.getKey(id));

        if (cachedUser) {
            return cachedUser;
        }

        cachedUser = Object.values(GraphCache.getAllModelItems(this.getModelName()))
            .find(user => user.parent_user_id == id);

        return cachedUser || this.find(id, specificFieldsToQuery, useCache, ignoredFields);
    }
}

export default UserRepository;
