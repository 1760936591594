import { gql } from 'apollo-boost';
import { forEach, get, lowerFirst, merge, mergeWith, uniqueId } from 'lodash-es';
import { apolloClient } from '@/plugins/vue-apollo.js';
import AsyncDebounce from '@/utils/AsyncDebounce.js';
import GraphDAL from '@/graphql/GraphDAL.js';
import Checksum from '@/plugins/checksum.js';

class GraphQuerier {
    static fetchList = {};

    static debounceTimer = 500;

    static PLURAL_TYPES = {
        User: 'Users',
    };

    static async fetchItemWithFields(modelClass, fields, identifiers) {
        let identifier = GraphQuerier.getCacheKeyForObject({
            __typename: modelClass,
            ...identifiers,
        });

        if (!identifier) {
            throw new Error('Missing identifier to perform the GraphQL query');
        }

        identifier = identifier.replaceAll(':', '___');

        GraphQuerier.fetchList[`${identifier}`] = {
            type: modelClass,
            identifiers,
            changes: [
                ...new Set([
                    ...(get(GraphQuerier.fetchList, `${identifier}.changes`, ['id'])),
                    ...Object.values(fields),
                ]),
            ],
        };

        const response = await AsyncDebounce.debounce('GraphQuerier', GraphQuerier.execute, GraphQuerier.debounceTimer, GraphQuerier.debounceTimer * 2);

        return response[identifier] ?? null;
    }

    static async execute() {
        const fetchList = GraphQuerier.fetchList;
        GraphQuerier.fetchList = {};

        const fetchListGroupedByType = GraphQuerier.groupFetchListByType(fetchList);
        const chunkedFetchList = GraphQuerier.splitFetchListByChunk(fetchListGroupedByType);

        const responses = await Promise.all(chunkedFetchList.map(async fetchListChunk => {
            let queryBody = '';

            const variables = {};

            if (Object.keys(fetchListChunk).length) {
                for await (const fetchId of Object.keys(fetchListChunk)) {
                    const fetch = fetchListChunk[fetchId];
                    queryBody += await GraphQuerier.generateQueryForItem(fetch, variables, fetchId);
                }

                const queryHead = GraphQuerier.generateMainQueryHeader(variables);
                const query = `${queryHead} { ${queryBody} }`;
                const queryResult = await GraphQuerier.queryToGraphQL(query, variables);

                const formattedData = {};
                forEach(queryResult.data, valueSet => {
                    if (valueSet) {
                        if (Array.isArray(valueSet)) {
                            forEach(valueSet, value => {
                                if (value) {
                                    const identifier = GraphQuerier.getCacheKeyForObject(value).replaceAll(':', '___');
                                    formattedData[identifier] = value;
                                }
                            });
                        } else {
                            const identifier = GraphQuerier.getCacheKeyForObject(valueSet).replaceAll(':', '___');
                            formattedData[identifier] = valueSet;
                        }
                    }
                });
                return formattedData;
            }
            return {};
        }));

        let combinedResponses = {};
        forEach(responses, response => {
            combinedResponses = merge(response, combinedResponses);
        });

        return combinedResponses;
    }

    static groupFetchListByType(fetchList) {
        const groupedFetchList = {};

        forEach(fetchList, (fetch, fetchId) => {
            let groupName = `${fetch.type}_${Checksum(fetch.changes)}`;
            if (Object.keys(fetch.identifiers).length > 2) {
                groupName += `_${uniqueId('multi_identifier_')}`;
            }

            if (typeof GraphQuerier.PLURAL_TYPES[fetch.type] === 'undefined') {
                groupName += `_${uniqueId('no_plural_')}`;
            }

            if (!groupedFetchList[groupName]) {
                groupedFetchList[groupName] = {};
            }

            groupedFetchList[groupName][fetchId] = fetch;
        });

        return groupedFetchList;
    }

    static splitFetchListByChunk(fetchList, chunkSize = 10) {
        const chunkedFetchList = [];

        let chunkId = -1;
        let itemId = 0;

        forEach(fetchList, (fetch, fetchId) => {
            itemId++;

            if (itemId % chunkSize === 1) {
                chunkId++;
                chunkedFetchList[chunkId] = {};
            }

            chunkedFetchList[chunkId][fetchId] = fetch;
        });

        return chunkedFetchList;
    }

    static generateMainQueryHeader(variables) {
        let query = 'query';

        if (variables) {
            const variableStr = Object.entries(variables)
                .map(([key, value]) => `$${key}: ${value.type}`)
                .join(', ');

            query += `(${variableStr})`;
        }

        return query;
    }

    static async generateQueryForItem(item, variables, fetchId) {
        let queryHead = '';
        let queryBody = '';

        if (Object.keys(item).length === 1) {
            item = item[Object.keys(item)[0]];
            queryHead = GraphQuerier.generateQueryHeadForItem(item, variables, fetchId);
            queryBody = await GraphQuerier.generateQueryBodyForItem(item);
        } else {
            queryHead = GraphQuerier.generateQueryHeadForItems(item, variables, fetchId);
            queryBody = await GraphQuerier.generateQueryBodyForItems(item);
        }

        return `${queryHead} { ${queryBody} }`;
    }

    static generateQueryHeadForItem(item, variables, fetchId) {
        let queryHead = `${fetchId}: ${lowerFirst(item.type)}`;
        const queryVariables = [];

        forEach(item.identifiers, (identifier, identifierKey) => {
            if (identifierKey.startsWith('_') || !identifier) {
                return;
            }

            const idFetchVar = uniqueId('graphql_var_');
            queryVariables.push(`${identifierKey}: $${idFetchVar}`);

            if (typeof identifier === 'object') {
                variables[idFetchVar] = identifier;
            } else {
                variables[idFetchVar] = {
                    type: 'Int',
                    value: identifier,
                };
            }
        });

        if (queryVariables.length) {
            queryHead += `(${queryVariables.join(',')})`;
        }

        return queryHead;
    }

    static generateQueryHeadForItems(items, variables, fetchId) {
        const firstItem = items[Object.keys(items)[0]];
        const fetchType = firstItem.type;
        let queryHead = `${fetchId}: ${lowerFirst(GraphQuerier.PLURAL_TYPES[fetchType])}`;

        let identifierKey = '';
        forEach(firstItem.identifiers, (identity, identityKey) => {
            if (!identityKey.startsWith('_')) {
                identifierKey = identityKey;
            }
        });

        const variableName = uniqueId('graphql_var_');
        queryHead += `(${identifierKey}_in: $${variableName})`;

        variables[variableName] = {
            type: '[Int]',
            value: [],
        };

        forEach(items, (item) => {
            variables[variableName].value.push(item.identifiers[identifierKey]);
        });

        return queryHead;
    }

    static async generateQueryBodyForItem(item) {
        const fieldsInObjectNotation = GraphQuerier.mapFieldsFromDotToObjectNotation(item.changes);

        return GraphQuerier.objectNotationToQuery(fieldsInObjectNotation, item.type);
    }

    static async generateQueryBodyForItems(items) {
        const firstItem = items[Object.keys(items)[0]];

        return GraphQuerier.generateQueryBodyForItem(firstItem);
    }

    static mapFieldsFromDotToObjectNotation(fields) {
        return fields.reduce((mappedFields, fieldPath) => {
            return mergeWith(mappedFields, GraphQuerier.dotToObjectNotation(fieldPath), (dest, src) => {
                return Array.isArray(dest) ? dest.concat(src) : undefined;
            });
        }, {});
    }

    static dotToObjectNotation(fieldPath) {
        if (!fieldPath.includes('.')) {
            return {
                fields: [fieldPath],
            };
        }

        const fieldParts = fieldPath.split('.');
        const relationKey = fieldParts.shift();
        const field = fieldParts.join('.');

        return {
            relations: {
                [relationKey]: GraphQuerier.dotToObjectNotation(field),
            },
        };
    }

    static async objectNotationToQuery(object, graphQLType = null) {
        const fields = (object.fields || []).join(' ');

        if (!object.relations) {
            return fields;
        }

        const relations = await Promise.all(Object.entries(object.relations).map(async ([relationName, relationData]) => {
            if (graphQLType && !relationData.fields.includes('id')) {
                const introspect = await GraphDAL.getIntrospectedType(graphQLType);
                if (introspect.includes(`${relationName}.id`)) {
                    relationData.fields.push('id');
                }
            }
            return `${relationName} { ${await GraphQuerier.objectNotationToQuery(relationData)} }`;
        }));

        return `${fields} ${relations.join(' ')}`;
    }

    static async queryToGraphQL(query, variables) {
        const graphQlVariables = {};

        forEach(variables, (value, key) => {
            if (Array.isArray(value.value)) {
                graphQlVariables[key] = value.value.map(val => parseInt(val, 10));
            } else {
                graphQlVariables[key] = parseInt(value.value, 10);
            }
        });

        return apolloClient.query({
            query: gql(query),
            variables: graphQlVariables,
        });
    }

    static getCacheKeyForObject(object) {
        switch (object.__typename) {
            case 'AccountProduct':
            case 'AccountSupplier':
                if (!object.id || !object.account_id) {
                    return null;
                }

                return `${object.__typename}:${object.id}_${object.account_id}`;
            case 'FlowUser':
                if (!object.id || !object.flow_id) {
                    return null;
                }

                return `${object.__typename}:${object.id}_${object.flow_id}`;
            default:
                if (!object.id && !object._id) {
                    return null;
                }

                return `${object.__typename}:${object.id || object._id}`; // fall back to `id` and `_id` for all other types
        }
    }
}

export default GraphQuerier;
