export default {
    approved: 'Approuvé',
    cash: 'Dépot',
    cash_deal: 'Cash deal',
    endorser: 'Endosseur',
    proof: 'Preuve',
    vehicle_selection: 'Véhicule à sélectionner',
    refused: 'Refusé',
    sent: 'Envoyé',
};
