import Enum from './Enum.js';

export default class Civility extends Enum {
    static get entries() {
        return {
            mr: 'mr',
            ms: 'ms',
            mrs: 'mrs',
            miss: 'miss',
            dr: 'dr',
            me: 'me',
        };
    }

    static get MR() {
        return this.entries.mr;
    }

    static get MRS() {
        return this.entries.mrs;
    }

    static get MS() {
        return this.entries.ms;
    }

    static get MISS() {
        return this.entries.miss;
    }

    static get DR() {
        return this.entries.dr;
    }

    static get ME() {
        return this.entries.me;
    }

    static get translationGroup() {
        return 'civilities';
    }
}
