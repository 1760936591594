import { initValues } from '@/entities/ModelHelper.ts';

export default class VehicleDetails {
    year: number | null = null;

    make: string = '';

    model: string = '';

    trim: string = '';

    vin: string = '';

    mileage: number = 0;

    color: string = '';

    additional_options: string = '';

    original_owner: boolean = false;

    payment_on_vehicle: string = '';

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }
}
