import Model from './Model.js';
import User from './User.js';

export default class Reminder extends Model {
    static NINE_AM = '9am';

    static NINE_PM = '9pm';

    static get defaults() {
        return {
            sms: false,
            email: false,
            notification: true,
            date: null,
            user_id: null,
            push: false,
        };
    }

    /**
     * Getters
     */

    get user() {
        return this._user;
    }

    /**
     * Setters
     */

    set user(user) {
        this._user = new User(user);
    }

    /**
     * Specifications
     */

    //
}
