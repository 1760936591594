export default {
    conquest: 'Conquête',
    csi: 'CSI',
    endLcap: 'Fin LCAP',
    endLnette: 'Fin LNETTE',
    endWarranty: 'Fin garantie',
    loyalty: 'Loyauté',
    noShow: 'No Show',
    notSold: 'Non @:lexicon.sold.singular',
    other: 'Autre',
    promo: 'Promo',
    reminder: 'Rappel',
    service: 'Service',
};
