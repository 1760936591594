import Vue from 'vue';
import Storage from 'vue-ls';

const options = {
    namespace: '',
    name: 'ls',
    storage: 'local',
};

Vue.use(Storage, options);

const { ls } = Storage.useStorage(options);

export default ls;
