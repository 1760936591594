<template>
    <warning
        :icon="$icons.warning"
        :title="$t('accountNotFound.title', { supplier: $t(`accountNotFound.${supplier}`) })"
        :content="$t('accountNotFound.details', { supplier: $t(`accountNotFound.${supplier}`) })"
        :show-back="false"
        :show-home="true"
    />
</template>

<script>
    import Warning from '../../components/Warning.vue';

    export default {
        components: { Warning },

        computed: {
            supplier() {
                return this.$route.params.supplier;
            },
        },
    };
</script>
