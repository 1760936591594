import Enum from './Enum.js';

export default class Division extends Enum {
    static get entries() {
        return {
            new: 1,
            used: 2,
            service: 3,
            parts: 5,
            general: 6,
            finance: 7,
            revival: 8,
            follow_up: 9,
            bdc: 10,
        };
    }

    static get NEW() {
        return this.entries.new;
    }

    static get USED() {
        return this.entries.used;
    }

    static get SERVICE() {
        return this.entries.service;
    }

    static get PARTS() {
        return this.entries.parts;
    }

    static get GENERAL() {
        return this.entries.general;
    }

    static get FINANCE() {
        return this.entries.finance;
    }

    static get REVIVAL() {
        return this.entries.revival;
    }

    static get FOLLOW_UP() {
        return this.entries.follow_up;
    }

    static get BDC() {
        return this.entries.bdc;
    }

    static get translationGroup() {
        return 'divisions';
    }

    static getByName(searchedName) {
        for (const name of this.keys) {
            if (name == searchedName) {
                return this.entries[name];
            }
        }

        return null;
    }

    static selectOptions(authUser = null, idName = '', labelName = '', sorted = true) {
        const options = [Division.NEW, Division.USED, Division.SERVICE]
            .filter(divisionId => !authUser || authUser.hasDivision(divisionId))
            .map(id => {
                return {
                    [idName || 'id']: id,
                    [labelName || 'label']: this.getTranslation(id),
                };
            });

        if (sorted) {
            options.sort((a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']));
        }

        return options;
    }

    static selectAllOptions(idName = 'id', labelName = 'label', sorted = true) {
        const options = this.values.map(id => ({
            [idName]: id,
            [labelName]: this.getTranslation(id),
        }));

        if (sorted) {
            options.sort((a, b) => a[labelName].localeCompare(b[labelName]));
        }

        return options;
    }
}
