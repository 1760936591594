import AccountNotFound from '../views/Suppliers/AccountNotFound.vue';

export default [
    {
        path: '/:supplier/account-not-found',
        name: 'suppliers.accountNotFound',
        component: AccountNotFound,
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
        },
    },
];
