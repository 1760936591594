import Vue from 'vue';
import { useContextStore } from '@/store/modules/context/store.js';
import axios from '../../../plugins/axios.js';
import { useGlobalStore } from '../../store.js';

export default {
    async validateLeadPhones(leadId) {
        try {
            const response = await axios.post(`v1/leads/${leadId}/validate-phones`);

            return response.data.data.lead_phones.find(phone => phone.validated && phone.mobile);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return null;
            }

            useGlobalStore().appendNewError({
                code: '0006',
                display: true,
                error,
                payload: leadId,
            });
        }

        return null;
    },

    bulkLeadCommunicatingStarted(leads) {
        (leads || []).forEach(lead => this.setCommunicating(lead));
    },

    bulkLeadCommunicatingEnded(leads) {
        (leads || []).forEach(lead => this.removeCommunicating(lead.id));
    },

    async checkCommunicating(leadId) {
        if (!leadId) {
            leadId = useContextStore().leadId;
        }

        if (!leadId) {
            return;
        }

        if (leadId == useContextStore().leadId) {
            this.setPhoneIsEnabled(false);
            this.setEmailIsEnabled(false);
            this.setSmsIsEnabled(false);
        }

        const response = await Vue.api.leads.checkCommunicating(leadId);

        if (response.isCommunicating) {
            this.setCommunicating(response.lead);
        } else {
            this.removeCommunicating(leadId);
        }

        if (leadId == useContextStore().leadId) {
            this.setPhoneIsEnabled(response.phoneIsEnabled);
            this.setEmailIsEnabled(response.emailIsEnabled);
            this.setSmsIsEnabled(response.smsIsEnabled);
        }
    },

    setCommunicating(lead) {
        if (!this.communicatingLeads.some(communicatingLead => communicatingLead.id === lead.id)) {
            this.communicatingLeads.push(lead);
        }
    },

    removeCommunicating(leadId) {
        this.communicatingLeads = this.communicatingLeads.filter(communicatingLead => communicatingLead.id !== leadId);
    },

    setPhoneIsEnabled(value) {
        this.phoneIsEnabled = value;
    },

    setEmailIsEnabled(value) {
        this.emailIsEnabled = value;
    },

    setSmsIsEnabled(value) {
        this.smsIsEnabled = value;
    },
};
