export default {
    alerts: {
        index: {
            error: 'Échec lors de la recherche',
        },
    },
    lead: 'Lead',
    recent: 'Récents',
    results: 'Résultats',
    search: 'Recherche...',
    user: 'Utilisateurs',
    vehicle: 'Véhicules',
};
