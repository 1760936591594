export default {
    dms: 'DMS',
    email: 'Lead Web',
    event: 'Événement',
    loyalty: 'Loyauté',
    phone: 'Téléphone',
    preBooking: 'Pré-booking',
    renewal: '@.capitalize:lexicon.portfolio.singular',
    sms: 'SMS',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webOrder: 'Commande Web',

    withLeadKeyword: {
        dms: 'Lead DMS',
        email: 'Lead Web',
        event: 'Lead Événement',
        loyalty: 'Lead Loyauté',
        phone: 'Lead Phone-up',
        pre_booking: 'Lead Pré-booking',
        renewal: 'Lead @.capitalize:lexicon.portfolio.singular',
        sms: 'Lead SMS',
        walk_in: 'Lead @.capitalize:lexicon.walkIn.singular',
        web_order: 'Lead Commande Web',
    },

    abbreviations: {
        dms: 'DMS',
        email: 'Lead Web',
        event: 'Év.',
        loyalty: 'Loyauté',
        phone: 'Tél.',
        preBooking: 'Pré-b.',
        renewal: '@.capitalize:lexicon.portfolio.abr',
        sms: 'SMS',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        webOrder: 'Commande Web',
    },
};
