<template>
    <splashscreen>
        <h3 class="text-4xl mt-0 mb-3">
            {{ $t('maintenanceMode.title') }}
        </h3>
        <p class="text-lg mt-0 mb-6">
            {{ $t('maintenanceMode.subtitle1') }}<br />
            <i18n path="maintenanceMode.subtitle2" tag="span">
                <a target="_blank" href="http://status.activix.ca">
                    {{ $t('maintenanceMode.statusPage') }}
                </a>
            </i18n>
        </p>
        <activix-button type="primary" :loading="loading" @click="reload">
            {{ $t('maintenanceMode.reload') }}
        </activix-button>
    </splashscreen>
</template>

<script>
    import Splashscreen from '../layouts/Splashscreen.vue';

    export default {
        components: {
            Splashscreen,
        },

        data() {
            return {
                loading: false,
            };
        },

        methods: {
            reload() {
                this.loading = true;
                window.location.reload();
            },
        },

        i18n: {
            messages: {
                en: {
                    maintenanceMode: {
                        reload: 'Reload',
                        subtitle1: 'Sorry, the platform is in maintenance.',
                        subtitle2: 'See our {0} for more details.',
                        statusPage: 'Status page',
                        title: "We'll be right back!",
                    },
                },
                fr: {
                    maintenanceMode: {
                        reload: 'Rafraîchir',
                        subtitle1: 'Désolé, la plateforme est en maintenance.',
                        subtitle2: 'Consultez notre {0} pour plus de détails.',
                        statusPage: "page d'état",
                        title: 'De retour sous peu!',
                    },
                },
            },
        },
    };
</script>
