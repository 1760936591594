/* eslint-disable camelcase */
import { isEmpty } from 'lodash-es';
import { findContextAccount } from '@/pinia/storeHelper.js';
import EmailConfigRepository from '@/graphql/repositories/EmailConfigRepository.js';
import GroupRepository from '@/graphql/repositories/GroupRepository.js';
import PostRepository from '@/graphql/repositories/PostRepository.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import UserPhoneRepository from '@/graphql/repositories/UserPhoneRepository.js';
import i18n from '../plugins/vue-i18n.js';
import Model from './Model.js';
import Account from './Account.js';
import Division from './Division.js';
import Role from './Role.js';
import Session from './Session.js';
import UserPhone from './UserPhone.js';

export default class User extends Model {
    static get defaults() {
        return {
            account: {},
            module_access: {},
            children: [],
            confidentiality_agreement: true,
            custom_permissions: {},
            divisions: [],
            email_config: {},
            groups: [],
            objectives: [],
            phones: [],
            post: null,
            timezone: 'America/Montreal',
            shared_dashboard_views: [],
            shared_calendar_views: [],
        };
    }

    /**
     * Constants
     */
    static get NIOTEXT_USER() {
        return 25558;
    }

    /**
     * Getters
     */

    get account() {
        if (!this.relationLoaded('account') && this.account_id) {
            this._account = findContextAccount(this.account_id) || new Account(this._account);
        }

        return this._account;
    }

    get children() {
        return (async () => {
            if (this._children_cache) {
                return this._children_cache;
            }

            const children = await Promise.all(
                this._children
                    .filter(child => child.id)
                    .map(async child => new User(await UserRepository.find(child.id))),
            );

            this._children_cache = children.sort((a, b) => a.fullName.localeCompare(b.fullName));

            return this._children_cache;
        })();
    }

    get childUserIds() {
        return this._children.map(child => child.id);
    }

    get childAccountIds() {
        return this._children.map(child => child.account_id);
    }

    get childCount() {
        return this._children.length;
    }

    get hasChildren() {
        return this.childCount > 0;
    }

    get groups() {
        return Promise.all(
            this._groups
                .filter(group => group.id)
                .map(group => GroupRepository.find(group.id)),
        );
    }

    get groupsAccountIds() {
        const groupsAccountIds = this._groups.reduce((accountIds, group) => {
            (group.accounts || []).forEach(account => accountIds.add(account.id));

            return accountIds;
        }, new Set());

        return [...groupsAccountIds];
    }

    get hasGroups() {
        return this._groups.length > 0;
    }

    get hasPhones() {
        return this._phones.length > 0;
    }

    get phones() {
        return Promise.all(
            this._phones
                .filter(phone => phone.id)
                .map(async phone => {
                    return new UserPhone(await UserPhoneRepository.find(phone.id));
                }),
        );
    }

    get firstPhone() {
        return (async () => {
            const firstPhoneId = this._phones.find(phone => phone.id)?.id;

            if (!firstPhoneId) {
                return null;
            }

            return new UserPhone(await UserPhoneRepository.find(firstPhoneId));
        })();
    }

    get email_config() {
        return (async () => {
            if (!this._email_config?.id) {
                return {};
            }

            return EmailConfigRepository.find(this._email_config.id);
        })();
    }

    get fullName() {
        return `${this.first_name || ''} ${this.last_name || ''}`.trim();
    }

    get shortName() {
        let name = this.fullName;
        const spacePosition = name.indexOf(' ');

        if (spacePosition !== -1) {
            name = `${name.charAt(0)}. ${name.substring(spacePosition + 1, name.length)}`;
        }

        return name;
    }

    get session() {
        return this._session;
    }

    get post() {
        if (!this.postId) {
            return null;
        }

        return PostRepository.find(this.postId);
    }

    get postId() {
        return this._post?.id;
    }

    get role() {
        const role = Role.getKey(this.role_id);

        if (!role) {
            return '-';
        }

        if (this.sex == 'W') {
            return i18n.t(`database.role_${role}_w`);
        }

        return i18n.t(`database.role_${role}`);
    }

    get divisions() {
        return this._divisions;
    }

    /**
     * Setters
     */

    set account(account) {
        this._account = account;
    }

    set children(children) {
        this.free('children');

        this._children = children;
    }

    set divisions(divisions) {
        this._divisions = divisions.map(division => {
            return division?.id || division;
        });
    }

    set email_config(emailConfig) {
        this._email_config = emailConfig;
    }

    set groups(groups) {
        this._groups = groups;
    }

    set phones(phones) {
        this._phones = phones;
    }

    set user_phones(phones) {
        if (!this._phones.length && phones.length) {
            this._phones = phones;
        }
    }

    set post(post) {
        this._post = post;
    }

    set role(role) {
        if (role?.id) {
            this.role_id = role.id;
        }
    }

    set session(session) {
        this._session = !isEmpty(session) ? new Session(session) : null;
    }

    /**
     * Specifications
     */

    get isSelectable() {
        return this.active && !this.hide_in_user_select;
    }

    hasDivision(divisionId) {
        const divisionIdInt = parseInt(divisionId, 10);

        return !divisionIdInt || this.divisions.includes(divisionIdInt);
    }

    doesNotHaveDivision(divisionId) {
        return !this.hasDivision(divisionId);
    }

    hasDivisionAccess(divisionId) {
        return this.isAdmin() || this.hasDivision(divisionId);
    }

    doesNotHaveDivisionAccess(divisionId) {
        return !this.hasDivisionAccess(divisionId);
    }

    hasCustomPermission(permission) {
        return this.custom_permissions.hasOwnProperty(permission) && this.custom_permissions[permission];
    }

    isProgrammer() {
        return [Role.PROGRAMMER].includes(this.role_id);
    }

    isManager() {
        return [Role.MANAGER].includes(this.role_id);
    }

    isAnalyst() {
        return [Role.ANALYST].includes(this.role_id);
    }

    isDirector() {
        return [Role.DIRECTOR].includes(this.role_id);
    }

    isBdcDirector() {
        return [Role.DIRECTOR_BDC].includes(this.role_id);
    }

    isCommercialDirector() {
        return [Role.COMMERCIAL].includes(this.role_id);
    }

    isBdc() {
        return [Role.AGENT].includes(this.role_id);
    }

    isAdvisor() {
        return [Role.ADVISOR].includes(this.role_id);
    }

    isServiceAdvisor() {
        return [Role.SERVICE_ADVISOR].includes(this.role_id);
    }

    isServiceAgent() {
        return [Role.SERVICE_AGENT].includes(this.role_id);
    }

    isServiceDirector() {
        return [Role.DIRECTOR].includes(this.role_id) && this.hasDivision(Division.SERVICE);
    }

    isService() {
        return this.isServiceAdvisor() || this.isServiceAgent();
    }

    isClient() {
        return this.role_id >= 4 && this.role_id != 6;
    }

    isAdmin() {
        return Role.hasAdminRights(this.role_id);
    }

    hasAdvisorSkills() {
        return Role.hasAdvisorSkills(this.role_id);
    }

    isSuperBdc() {
        return this.isBdc() && this.bdc_super_access;
    }

    isSuperService() {
        return this.isService() && this.service_super_access;
    }

    hasSimpleAccess() {
        return this.isAdvisor() || (this.isService() && !this.isSuperService()) || (this.isBdc() && !this.isSuperBdc());
    }

    hasPhoneUp() {
        return this.account.phone_up && !empty(this.module_access.phone_up) && this.module_access.phone_up;
    }

    hasWebBoost() {
        return this.account.webboost && !empty(this.module_access.web_boost) && this.module_access.web_boost;
    }

    hasWalkIn() {
        return this.account.walk_in && !empty(this.module_access.walk_in) && this.module_access.walk_in;
    }

    hasLeadXpress() {
        return this.account.leadxpress && !empty(this.module_access.leadxpress) && this.module_access.leadxpress;
    }

    hasWebOrder() {
        return this.account.web_order && !empty(this.module_access.web_order) && this.module_access.web_order;
    }

    hasLoyalty() {
        return this.account.loyalty && !empty(this.module_access.loyalty) && this.module_access.loyalty;
    }

    hasCommercial() {
        return this.account.commercial && !empty(this.module_access.commercial) && this.module_access.commercial;
    }

    hasRenewal() {
        return this.account.renewal && !empty(this.module_access.renewal) && this.module_access.renewal;
    }

    hasEvent() {
        return this.account.event && !empty(this.module_access.event) && this.module_access.event;
    }

    hasService() {
        return this.account.service && !empty(this.module_access.service) && this.module_access.service;
    }

    hasSaleTable() {
        return this.account.sale_table && !empty(this.module_access.sale_table) && this.module_access.sale_table;
    }

    hasNioText() {
        return this.has_niotext || this.niotext;
    }

    async childrenHasNioText() {
        return (await this.children).some(child => child.hasNioText());
    }

    async selfOrChildHasNiotext() {
        if (Object.hasOwn(this, 'has_niotext')) {
            return this.has_niotext;
        }

        if (this.hasNioText()) {
            return true;
        }

        return this.childrenHasNioText();
    }

    childUserFromCache(childId) {
        return (this._children_cache || [])
            .find(child => child.id == childId);
    }

    async childUserForAccount(accountId) {
        const childId = this.childIdForAccount(accountId);

        if (!childId) {
            return null;
        }

        const cachedChild = this.childUserFromCache(childId);

        return cachedChild || new User(await UserRepository.find(childId));
    }

    childIdForAccount(accountId) {
        return this._children.find(child => child.account_id == accountId)?.id;
    }

    isParentOf(userId) {
        return this.childUserIds.some(childId => childId == userId);
    }

    isSameUser(userId) {
        if (!userId) {
            return false;
        }

        if ([this.id, this.parent_user_id].includes(userId)) {
            return true;
        }

        return this.isParentOf(userId);
    }

    isFromAccount(accountId) {
        return this.account_id == accountId ||
            this.childAccountIds.some(childAccountId => childAccountId == accountId);
    }

    hasProfilePicture() {
        return this.profile_picture != '/img/nobody.jpg';
    }

    canEditEmailTemplate() {
        return this.isAdmin() || this.isDirector() || this.isBdcDirector() || this.allow_email_template_edition;
    }

    async getEmailConfig(fields = []) {
        if (!this._email_config?.id) {
            return {};
        }

        return EmailConfigRepository.findWithFields(this._email_config.id, fields);
    }

    async getPhones(fields = [], useCache = true) {
        return Promise.all(
            this._phones
                .filter(phone => phone.id)
                .map(async phone => {
                    return new UserPhone(await UserPhoneRepository.findWithFields(phone.id, fields, useCache));
                }),
        );
    }

    static selectOptions(users) {
        return users
            .map(user => {
                return {
                    id: user.id,
                    label: user.fullName,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }
}
