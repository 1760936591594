import Enum from './Enum.js';

export default class NotificationSubType extends Enum {
    static get entries() {
        return {
            appointmentCanceled: 'appointmentCanceled',
            appointmentConfirmed: 'appointmentConfirmed',
            appointmentNoShow: 'appointmentNoShow',
            appointmentStatusUpdated: 'appointmentStatusUpdated',
            email: 'email',
            emailRead: 'emailRead',
            invite: 'invite',
            leadAppt: 'leadAppt',
            leadAssigned: 'leadAssigned',
            leadInvalid: 'leadInvalid',
            leadLost: 'leadLost',
            leadNoDivision: 'leadNoDivision',
            leadNoPhone: 'leadNoPhone',
            leadSale: 'leadSale',
            newComment: 'newComment',
            newCommentAssignedLead: 'newCommentAssignedLead',
            newLead: 'newLead',
            niotextIncoming: 'niotextIncoming',
            noReplyLead: 'noReplyLead',
            phoneAppointmentCanceled: 'phoneAppointmentCanceled',
            phoneAppointmentConfirmed: 'phoneAppointmentConfirmed',
            replyComment: 'replyComment',
            serviceMyClientsAppointments: 'serviceMyClientsAppointments',
            serviceMyClientsVehiclePickUp: 'serviceMyClientsVehiclePickUp',
            serviceVehiclePickUp: 'serviceVehiclePickUp',
            taskAssigned: 'taskAssigned',
            taskDeleted: 'taskDeleted',
            taskDatesUpdated: 'taskDatesUpdated',
            eventDatesUpdated: 'eventDatesUpdated',
            taskStatusUpdated: 'taskStatusUpdated',
            automationCompleted: 'automationCompleted',
            automationNotification: 'automationNotification',
        };
    }

    static get NEW_LEAD() {
        return this.entries.newLead;
    }

    static get LEAD_ASSIGNED() {
        return this.entries.leadAssigned;
    }

    static get TASK_ASSIGNED() {
        return this.entries.taskAssigned;
    }

    static get TASK_DELETED() {
        return this.entries.taskDeleted;
    }

    static get TASK_DATES_UPDATED() {
        return this.entries.taskDatesUpdated;
    }

    static get EVENT_DATES_UPDATED() {
        return this.entries.eventDatesUpdated;
    }

    static get TASK_STATUS_UPDATED() {
        return this.entries.taskStatusUpdated;
    }

    static get APPOINTMENT_STATUS_UPDATED() {
        return this.entries.appointmentStatusUpdated;
    }

    static get LEAD_NO_PHONE() {
        return this.entries.leadNoPhone;
    }

    static get LEAD_NO_DIVISION() {
        return this.entries.leadNoDivision;
    }

    static get LEAD_LOST() {
        return this.entries.leadLost;
    }

    static get NIOTEXT_INCOMING() {
        return this.entries.niotextIncoming;
    }

    static get APPOINTMENT_CONFIRMED() {
        return this.entries.appointmentConfirmed;
    }

    static get APPOINTMENT_CANCELED() {
        return this.entries.appointmentCanceled;
    }

    static get APPOINTMENT_NO_SHOW() {
        return this.entries.appointmentNoShow;
    }

    static get LEAD_INVALID() {
        return this.entries.leadInvalid;
    }

    static get INVITE() {
        return this.entries.invite;
    }

    static get NEW_COMMENT() {
        return this.entries.newComment;
    }

    static get NEW_COMMENT_ASSIGNED_LEAD() {
        return this.entries.newCommentAssignedLead;
    }

    static get SERVICE_MY_CLIENTS_APPOINTMENTS() {
        return this.entries.serviceMyClientsAppointments;
    }

    static get SERVICE_MY_CLIENTS_VEHICLE_PICKUP() {
        return this.entries.serviceMyClientsVehiclePickUp;
    }

    static get SERVICE_VEHICLE_PICKUP() {
        return this.entries.serviceVehiclePickUp;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get NO_REPLY_LEAD() {
        return this.entries.noReplyLead;
    }

    static get REPLY_COMMENT() {
        return this.entries.replyComment;
    }

    static get EMAIL_READ() {
        return this.entries.emailRead;
    }

    static get LEAD_SALE() {
        return this.entries.leadSale;
    }

    static get PHONE_APPOINTMENT_CONFIRMED() {
        return this.entries.phoneAppointmentConfirmed;
    }

    static get PHONE_APPOINTMENT_CANCELED() {
        return this.entries.phoneAppointmentCanceled;
    }

    static get LEAD_APPT() {
        return this.entries.leadAppt;
    }

    static get AUTOMATION_COMPLETED() {
        return this.entries.automationCompleted;
    }

    static get AUTOMATION_NOTIFICATION() {
        return this.entries.automationNotification;
    }
}
