import Enum from './Enum.js';
import icons from '../plugins/svg-icons.js';

export default class NotificationTypeIcon extends Enum {
    static get entries() {
        return {
            renewalAlertEquity: 'regular/flag-plain-3',
            renewalAlertEndLocation: 'regular/flag-plain-3',
            renewalAlertEndWarranty: 'regular/flag-plain-3',
            renewalAlertExcessMileage: 'regular/flag-plain-3',
            appointment: icons.appointment,
            appointmentCanceled: 'regular/calendar',
            appointmentConfirmed: 'regular/calendar',
            appointmentNoShow: 'regular/calendar',
            assignedLeadUpdated: 'regular/flag-plain-3',
            assignedLeadVehicleCreated: 'regular/flag-plain-3',
            assignedLeadVehicleUpdated: 'regular/flag-plain-3',
            automationCompleted: 'regular/design-tool-magic-wand',
            automationNotification: 'regular/video-game-magic-wand',
            appointmentStatusUpdated: 'regular/calendar',
            call: icons.phone,
            communicationBlockedCommunicating: 'regular/flag-plain-3',
            communicationBlockedCantContact: 'regular/flag-plain-3',
            csi: icons.csi,
            delivery: icons.delivery,
            deliveryStatusUpdated: icons.delivery,
            email: 'regular/email-action-unread',
            emailRead: 'regular/email-action-read',
            eventBadService: 'regular/smiley-bad',
            eventCanceledAppointment: 'regular/calendar',
            eventConfirmedAppointment: 'regular/calendar',
            eventDatesUpdated: 'regular/calendar',
            eventLeadToReach: 'regular/flag-plain-3',
            eventNewAppointment: 'regular/calendar',
            eventNewPhoneAppointment: 'regular/flag-plain-3',
            eventSent: 'regular/flag-plain-3',
            feedbackCalculator: 'regular/flag-plain-3',
            invite: 'regular/calendar-3',
            leadAppt: 'regular/calendar',
            leadAssigned: 'regular/flag-plain-3',
            leadAssignedSent: 'regular/flag-plain-3',
            leadsAssigned: 'regular/flag-plain-3',
            assignLeadDemand: 'regular/human-resources-employee-previous',
            declinedLeadDemand: 'regular/human-resources-employee-previous',
            leadInvalid: 'bold/subtract-circle',
            leadLost: 'bold/remove-circle',
            leadNoDivision: 'regular/flag-plain-3',
            leadNoPhone: 'regular/flag-plain-3',
            leadSale: 'regular/real-estate-sign-board-sold',
            newComment: 'regular/messages-bubble-square-typing',
            newCommentAssignedLead: 'regular/messages-bubble-square-typing',
            newCommentSent: 'regular/flag-plain-3',
            newLead: 'regular/earth-1',
            newLeadDuplicate: 'regular/earth-1',
            newLeadLoyalty: 'regular/earth-1',
            newLeadLoyaltyDuplicate: 'regular/earth-1',
            newPhoneAppointment: icons.phoneAppointment,
            niotextIncoming: 'regular/messages-bubble-typing-1',
            noReplyLead: 'regular/earth-1',
            noReplyLeadDuplicate: 'regular/earth-1',
            other: icons.pencilWrite,
            phoneAppointment: icons.appointment,
            phoneAppointmentCanceled: icons.phoneAppointment,
            phoneAppointmentConfirmed: icons.phoneAppointment,
            processUpdated: 'regular/check-2',
            replyComment: 'regular/messages-bubble-square-typing',
            replyCommentSent: 'regular/flag-plain-3',
            serviceMyClientsAppointments: icons.service,
            serviceMyClientsVehiclePickUp: 'regular/login-keys',
            serviceVehiclePickUp: 'regular/login-keys',
            sms: icons.sms,
            taskAssigned: 'regular/calendar',
            taskEventUnassigned: 'regular/calendar',
            taskDatesUpdated: 'regular/check-square',
            taskStatusUpdated: 'regular/check-square',
            taskSent: 'regular/flag-plain-3',
            taskDeleted: 'regular/alert-circle',
            testDrive: icons.testDrive,
            timeLimitToAnswer: 'regular/stopwatch',
            unassignedFromLead: 'regular/alert-circle',
            returnToMarket: 'regular/earth-1',
            virtualAppointment: icons.virtualAppointment,
        };
    }
}
