import { defineStore } from 'pinia';
import actions from './actions.js';
import state from './state.js';

export const useCustomerStore = defineStore({
    id: 'customer',
    state() {
        return state;
    },
    actions,
});
