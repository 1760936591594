import Vue from 'vue';
import BeamerClient from './beamer.js';

const beamer = new BeamerClient();

const VueBeamer = {
    install(Vue) {
        Vue.beamer = beamer;
        Vue.prototype.$beamer = beamer;
    },
};

Vue.use(VueBeamer);
