import Enum from './Enum.js';

export default class CommunicationType extends Enum {
    static get entries() {
        return {
            outgoing: 1,
            incoming: 2,
        };
    }

    static get OUTGOING() {
        return this.entries.outgoing;
    }

    static get INCOMING() {
        return this.entries.incoming;
    }

    static get translationGroup() {
        return 'communicationTypes';
    }
}
