import Enum from './Enum.js';

export default class VideoConferenceStatus extends Enum {
    static get entries() {
        return {
            live: 'live',
            planned: 'planned',
            over: 'over',
        };
    }

    static get LIVE() {
        return this.entries.live;
    }

    static get PLANNED() {
        return this.entries.planned;
    }

    static get OVER() {
        return this.entries.over;
    }
}
