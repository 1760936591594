import Vue, { reactive } from 'vue';
import auth0 from 'auth0-js';

const authClient = new auth0.WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    redirectUri: `${window.location.origin}/auth/mfa-callback`,
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_MFA_AUDIENCE,
    responseType: 'token',
    scope: 'enroll read:authenticators remove:authenticators',
});

class MfaAuth {
    constructor() {
        this.data = reactive({
            accessToken: null,
            action: null,
        });
    }

    enroll() {
        this.generateMfaToken('enroll');
    }

    remove() {
        this.generateMfaToken('remove');
    }

    generateMfaToken(action) {
        const payload = {
            appState: {
                target: `${location.pathname}${location.search}`,
                action,
            },
            connection: process.env.VUE_APP_AUTH0_CONNECTION,
        };

        if (localStorage.getItem('currentLocale')) {
            payload.ui_locales = localStorage.getItem('currentLocale');
        }

        authClient.authorize(payload);
    }

    handleAuthentication() {
        authClient.parseHash((error, authResult) => {
            if (error) {
                Vue.notify.warning(this.$t('users.alerts.mfaEnrollment.error'));

                return;
            }

            this.data.action = authResult.appState.action;
            this.data.accessToken = authResult.accessToken;

            this.redirectToPreviousState(authResult);
        });
    }

    redirectToPreviousState(authResult) {
        const appState = authResult.appState || {};
        const target = appState.target || '/';

        Vue.router.push({ path: target });
    }

    clear() {
        this.data.accessToken = null;
        this.data.action = null;
    }
}

export default MfaAuth;
