export default [
    {
        path: '/customer/:id(\\d+)',
        name: 'customer.update',
        component: () => import(/* webpackChunkName: "customer" */'../views/Customer/Customer.vue'),
        meta: {
            blockAccount: true,
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
        },
    },
];
