import Enum from './Enum.js';

export default class exportType extends Enum {
    static get entries() {
        return {
            xls: 'xls',
            csv: 'csv',
            pdf: 'pdf',
        };
    }

    static get XLS() {
        return this.entries.xls;
    }

    static get CSV() {
        return this.entries.csv;
    }

    static get PDF() {
        return this.entries.pdf;
    }
}
