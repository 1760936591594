<template>
    <!-- TEMP upload component waiting for Activix UI -->
    <div class="relative">
        <transition
            enter-active-class="transition duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="absolute w-full h-full mt-2" style="margin-left: -3.4rem" v-if="show">
                <div class="w-64 bg-white text-gray-600 shadow-lg border border-gray-300 rounded-sm overflow-y-scroll" style="max-height: 11.4rem" v-on-clickaway="close">
                    <div class="flex px-4 py-2.5 | transition duration-200" :key="file.name" v-for="file in files">
                        <div class="flex justify-center items-center rounded-full">
                            <icon :name="getFileType(file) ? getFileType(file).icon : 'regular/common-file-download'" class="w-5 h-5" />
                        </div>

                        <div class="flex-1 flex px-3 py-2 overflow-x-hidden w-56 cursor-default">
                            <p class="m-0 truncate" v-tooltip="generateTooltip(file.name)" v-text="file.name ? file.name : ''" />
                        </div>

                        <button class="flex justify-center items-center p-2 | hover:text-red-700 | transition duration-200" @click.prevent="removeFile(file)" v-if="clearable">
                            <icon :name="$icons.trash" class="w-4 h-4" />
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    const FILE_TYPES = [
        {
            name: 'MP4',
            extension: 'mp4',
            mimeTypes: 'video/mp4',
            icon: 'regular/video-file-play',
        },
        {
            name: 'MP3',
            extension: 'mp3',
            mimeTypes: 'audio/*',
            icon: 'regular/audio-file',
        },
        {
            name: 'MOV',
            extension: 'mov',
            mimeTypes: 'video/quicktime',
            icon: 'regular/video-file-play',
        },
    ];

    export default {
        name: 'ActivixDragUpload',

        props: {
            clearable: {
                type: Boolean,
                default: true,
            },
            files: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                show: false,
            };
        },

        computed: {
            hasFiles() {
                return this.files?.length > 0;
            },

            fileCountText() {
                if (this.lgLayout) {
                    return `(${this.files.length})`;
                }

                return this.$tc('clientCard.attachFilesCount', this.files.length, { count: this.files.length });
            },
        },

        watch: {
            files() {
                if (!this.hasFiles) {
                    this.show = false;
                }
            },
        },

        methods: {
            generateTooltip(tooltip) {
                if (!tooltip) {
                    return '';
                }

                return tooltip.length > 20 ? tooltip : '';
            },

            open() {
                this.show = true;
            },

            close() {
                this.show = false;
            },

            removeFile(file) {
                this.$emit('delete', this.files.indexOf(file));
            },

            getFileType(file) {
                return FILE_TYPES.find(fileType => {
                    return (Array.isArray(fileType.mimeTypes)
                        ? fileType.mimeTypes.includes(file.type)
                        : file.type === fileType.mimeTypes) || file.name.split('.').pop() === fileType.extension;
                });
            },
        },
    };
</script>
