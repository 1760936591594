import Vue from 'vue';
import AuthUser from '@/entities/AuthUser.js';

export default [
    { path: '/templates/list', redirect: { name: 'templates.index' } },
    { path: '/templates/edit/:id', redirect: { name: 'templates.update' } },
    { path: '/templates/create/:type', redirect: { name: 'templates.store' } },
    {
        path: '/templates',
        name: 'templates.index',
        component: () => import(/* webpackChunkName: "templates-list" */ '../views/Templates/List.vue'),
        meta: {
            accountSelect: {
                disabled: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/templates/new/:type',
        name: 'templates.store',
        component: () => {
            return import(/* webpackChunkName: "templates" */ '../views/Templates/Edit.vue');
        },
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId', 'type'],
        },
        beforeEnter: async (to, from, next) => {
            if (!Vue.auth.ready()) {
                await Vue.auth.waitForUser();
            }

            const user = new AuthUser(Vue.auth.user());

            if (!user.canEditEmailTemplate()) {
                next({ name: 'unauthorized' });
            }

            next();
        },
    },
    {
        path: '/templates/:id',
        name: 'templates.update',
        component: () => {
            return import(/* webpackChunkName: "templates" */ '../views/Templates/Edit.vue');
        },
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
];
