<template>
    <activix-helper-notification :title="title" :description="description" ref="notification">
        <template #avatar>
            <div class="h-12 w-12 rounded-full shadow-md bg-grey-500 flex items-center justify-center">
                <icon class="text-white text-2xl" :name="leadIcon" />
            </div>
        </template>
        <template #buttons>
            <activix-button
                class="leading-5"
                type="primary"
                tag="router-link"
                :to="leadLink"
                @click.native="close"
            >
                {{ $t('screenPop.goToLead') }}
            </activix-button>
            <activix-button class="leading-5" @click="close">
                {{ $t('screenPop.dismiss') }}
            </activix-button>
        </template>
    </activix-helper-notification>
</template>

<script>
    import { mapActions } from 'pinia';
    import Lead from '../../entities/Lead.js';
    import LeadPhone from '../../entities/LeadPhone.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        data: () => ({
            type: 'incomingCall',
            opened: false,
            lead: new Lead(),
        }),

        computed: {
            title() {
                if (this.type == 'webBoost') {
                    return this.$t('screenPop.call.title.webBoost');
                }

                return this.$t('screenPop.call.title.incomingCall');
            },

            description() {
                const firstPhone = new LeadPhone(this.lead.lead_phones[0]);

                return this.$t('screenPop.call.description', [
                    this.lead.fullName || firstPhone.toString() || this.$t('general.unknown'),
                ]);
            },

            leadLink() {
                return {
                    name: 'leads.update',
                    params: { id: this.lead.id },
                };
            },

            leadIcon() {
                return this.getLeadTypeIcon(this.lead);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            async open(leadId, type) {
                this.lead = await this.fetchLead(leadId);
                this.type = type;
                this.$refs.notification.open();
            },

            close() {
                this.$refs.notification.close();

                this.$nextTick(() => {
                    this.$reset();
                });
            },
        },

        created() {
            this.$eventBus.$on('screen-pop-open', this.open);
            this.$eventBus.$on('screen-pop-close', this.close);
        },

        beforeDestroy() {
            this.$eventBus.$off('screen-pop-open', this.open);
            this.$eventBus.$off('screen-pop-close', this.close);
        },
    };
</script>
