export default {
    activated: 'Activée',
    active: 'Active',
    applyAndReload: 'Appliquer et rafraîchir',
    autoActivated: 'Activée automatiquement',
    availableSince: 'Disponible depuis le {0}',
    browserNotSupportingVideoPlaying: 'Votre navigateur ne supporte pas la lecture vidéo.',
    deactivateWithoutComment: 'Désactiver sans commentaire',
    deactivated: 'Désactivée',
    giveFeedback: 'Donner votre avis',
    leaveAComment: 'Laisser un commentaire',
    newTag: 'Nouveau',
    noComment: 'Pas de commentaire',
    notAutoActivated: "N'est pas activée automatiquement",
    projectedAdoptionOn: 'Adoption projetée au {0}',
    requestFeedback: 'Avant de désactiver {0}, souhaitez-vous nous faire part de vos commentaires?',
    theF: 'la',
    theM: 'le',
    title: 'Aperçu des fonctionnalités',
    willBe: 'Sera',
};
