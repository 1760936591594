import Vue from 'vue';
import optimizely from '@optimizely/optimizely-sdk';
import { get } from 'lodash-es';
import { contextStore, globalStore } from '@/pinia/storeHelper.js';
import ActivixDate from '@/value-objects/ActivixDate.js';
import Role from '../entities/Role.js';

optimizely.setLogLevel('warn');

Vue.use({
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        let optimizelyInstance;

        if (window.optimizelyDatafile) {
            optimizelyInstance = optimizely.createInstance({
                sdkKey: process.env.VUE_APP_OPTIMIZELY_KEY,
                eventBatchSize: 100,
                eventFlushInterval: 1000,
                datafile: window.optimizelyDatafile,
                datafileOptions: {
                    autoUpdate: process.env.VUE_APP_ENV !== 'local', // Updates every 5 minutes by default.
                    urlTemplate: 'https://features.crm.activix.ca/datafiles/%s.json',
                },
            });
        }

        const feature = {
            isInitialized: false,
            user: null,

            getAuthUser() {
                return Vue.auth.user();
            },

            getOptimizelyUserId(userId) {
                return `user_${userId}`;
            },

            validate(id) {
                return !!id && !!optimizelyInstance;
            },

            isEnabled(feature, defaultValue = true) {
                if (this.hasUrlOverride(feature)) {
                    return this.isForced(feature);
                }

                if (this.featureExist(feature)) {
                    return this.isEnabledForUser(this.getAuthUser(), feature);
                }

                return defaultValue && this.getFeaturesList().length !== 0;
            },

            isEnabledForAccount(accountId, feature) {
                if (!this.validate(accountId)) {
                    return false;
                }

                const optimizelyDecision = optimizelyInstance.createUserContext(`account_${accountId}`, {
                    account_id: accountId,
                    environment: process.env.VUE_APP_ENV,
                }).decide(feature);

                return optimizelyDecision.enabled;
            },

            isEnabledForUser(user, feature) {
                if (!this.validate(user.id)) {
                    return false;
                }

                if (this.isFeaturePreview(feature)) {
                    return this.isFeaturePreviewEnabledForUser(feature);
                }

                const optimizelyDecision = this.getOptimizelyUser().decide(feature);

                return optimizelyDecision.enabled;
            },

            isFeaturePreviewEnabledForUser(feature) {
                const featurePreviewUserData = Vue.auth.featurePreviews().find(featurePreview => featurePreview.key === feature);

                if (featurePreviewUserData?.activated_at) {
                    return true;
                }

                const userOptOutLimitDate = new ActivixDate(featurePreviewUserData?.account_user_opt_out_limit_date);

                return userOptOutLimitDate.isPast();
            },

            isFeaturePreviewEnforcedForUser(feature) {
                const featurePreviewUserData = Vue.auth.featurePreviews().find(featurePreview => featurePreview.key === feature);
                const userOptOutLimitDate = new ActivixDate(featurePreviewUserData?.account_user_opt_out_limit_date);

                if (userOptOutLimitDate.isPast()) {
                    return true;
                }

                if (!featurePreviewUserData?.activated_at) {
                    return false;
                }

                const featurePreviewData = globalStore().featurePreviews.find(featurePreview => featurePreview.key === feature);
                const newDealersEnforcementOn = new ActivixDate(featurePreviewData?.new_dealers_enforced ? featurePreviewData?.new_dealers_auto_activation_on : null);
                const newUsersEnforcementOn = new ActivixDate(featurePreviewData?.new_users_enforced ? featurePreviewData?.new_users_auto_activation_on : null);

                const authUser = this.getAuthUser();
                const userCreationDate = new ActivixDate(authUser.created_at);
                const accountCreationDate = new ActivixDate(authUser.account.created_at);

                return newDealersEnforcementOn.isBefore(accountCreationDate) || newUsersEnforcementOn.isBefore(userCreationDate);
            },

            isFeaturePreview(feature) {
                return globalStore().featurePreviews.some(featurePreview => featurePreview.key === feature);
            },

            hasUrlOverride(feature) {
                return !['production'].includes(process.env.VUE_APP_ENV) && get(Vue.router, `app.$route.query.${feature}`);
            },

            isForced(feature) {
                return get(Vue.router, `app.$route.query.${feature}`) === 'true';
            },

            featureExist(feature) {
                return this.getFeaturesList().some((featureFlag) => {
                    return featureFlag.key === feature;
                });
            },

            getFeaturesList() {
                const authUserId = this.getAuthUser().id;

                if (!this.validate(authUserId)) {
                    return [];
                }

                return get(window, 'optimizelyDatafile.featureFlags', []);
            },

            getEnabled() {
                const authUserId = this.getAuthUser().id;
                if (!this.validate(authUserId)) {
                    return [];
                }

                return optimizelyInstance.getEnabledFeatures(this.getOptimizelyUserId(authUserId));
            },

            getOptimizelyUser() {
                const authUser = this.getAuthUser();
                const userType = Role.hasAdminRights(authUser.role_id) ? 'admin' : 'user';
                const userKey = `${userType}_${authUser.id}`;

                return optimizelyInstance.createUserContext(userKey, {
                    id: authUser.id,
                    account_id: authUser.account_id,
                    role: Role.getKey(authUser.role_id),
                    environment: process.env.VUE_APP_ENV,
                    context_account_id: contextStore().accountId,
                });
            },
        };

        VueInstance.prototype.$feature = feature;
        VueInstance.feature = feature;
    },
});
