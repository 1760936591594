export default {
    alerts: {
        store: {
            error: "Échec de la création de l'horaire",
        },
        update: {
            error: "Échec de la mise à jour de l'horaire",
        },
        destroy: {
            error: "Échec de la suppression de l'horaire",
        },
    },
    availability: {
        offlineForever: 'Hors ligne (indéfini)',
        offlineToday: 'Hors ligne (journée)',
        schedule: 'En ligne',
    },
    visibility: {
        hide: 'Cacher',
        hideOthers: 'Cacher les autres',
        onlyVisibleUsersCanBeDragged: 'Seul les utilisateurs visibles peuvent être ajouté à l\'horaire',
        show: 'Afficher',
        showAll: 'Afficher tous les utilisateurs',
    },
    deleteModal: 'Voulez-vous vraiment supprimer cette horaire pour {user} ?',
    users: 'Utilisateurs',
    calendarTitle: 'Horaire',
    userInactive: 'Utilisateur désactivé',
    userNoAccessDivision: "L'utilisateur n'a pas accès à cette division",
};
