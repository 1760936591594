<template>
    <div>
        <div class="alert | text-center text-white bg-grey-600 border-grey-600" v-if="nextAutomationJob">
            <i18n path="responseLeadXpress.nextAutomationJob" tag="span">
                <a @click="previewModalOpened = true">{{ date }}</a>
            </i18n>
        </div>

        <automation-preview
            :job="nextAutomationJob"
            :opened.sync="previewModalOpened"
            :readonly="true"
        />
    </div>
</template>

<script>
    // Entities
    import AutomationActionType from '../../entities/AutomationActionType.js';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Components
    import AutomationPreview from '../modals/AutomationPreview.vue';

    export default {
        components: {
            AutomationPreview,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                previewModalOpened: false,
            };
        },

        computed: {
            date() {
                if (!this.nextAutomationJob) {
                    return '';
                }

                return new ActivixDate(this.nextAutomationJob.execution_date).toHumanShort();
            },

            nextAutomationJob() {
                return this.lead.automation_jobs
                    .filter(job => job.action.action === AutomationActionType.SEND_EMAIL && !job.execution_started_at && job.active)
                    .sort((a, b) => a.execution_date.localeCompare(b.execution_date))
                    .at(0);
            },
        },
    };
</script>
