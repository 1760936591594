export default {
    bounce: "L'adresse courriel a rebondi et est donc considérée invalide.",
    deliverable: {
        high: 'Cette adresse courriel est invalide et peut nuire à votre réputation.',
        medium: "L'envoi de courriel n'est pas recommandé, cela pourrait nuire à votre réputation.",
        low: "L'adresse courriel est valide.",
        unknown: "Cliquez pour valider l'adresse courriel.",
    },
    disposable: "L'adresse courriel a été identifiée comme jetable et est donc considérée invalide",
    isValidating: 'Validation en cours...',
    reasons: {
        catchAll: "La validité de l'adresse courriel ne peut être déterminée.",
        highRiskDomain: 'Le domaine est considéré comme invalide.',
        immatureDomain: 'Le domaine est nouvellement créé.',
        longTermDisposable: 'La boîte de réception a été identifiée comme étant une adresse courriel jetable.',
        mailboxDoesNotExist: "La boîte de réception n'est pas livrable ou n'existe pas.",
        mailboxIsDisposableAddress: 'La boîte de réception a été identifiée comme étant une adresse courriel jetable.',
        mailboxIsRoleAddress: 'La boîte de réception est une adresse courriel basée sur les rôles (ex. support@..., marketing@...).',
        noMx: "Le domaine du destinataire n'a pas d'hôte MX valide.",
        subdomainMailer: 'Les adresses courriels avec des sous-domaines inconnus sont considérées comme risqué.',
        tldRisk: 'Le domaine a été identifié comme invalide.',
        unknownProvider: 'Le fournisseur est inconnu.',
        erroneousFormat: "L'adresse courriel n'est pas correctement formaté.",
        maxValidationAttemptsExceeded: "Suite à plusieurs essais, l'adresse courriel n'a pas pu être validée",
    },
    sendRisky: 'Vous vous apprêtez à envoyer un courriel à un destinataire ayant un potentiel de risque, cela pourrait nuire à la réputation de votre courriel.',
    typo: 'Une faute de frappe pourrait avoir été détecté. Vouliez-vous dire "{address}" ?',
    validate: 'Valider',
    validateError: 'Impossible de valider cette adresse courriel pour le moment.',
};
