<template>
    <activix-modal
        portal="modal-select-picker"
        :always-on-top="true"
        :closable="true"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <activix-input
                class="text-grey-700 flex-1 attached-r mr-6"
                :placeholder="$t('search.search')"
                v-model="search"
            >
                <template #icon>
                    <icon class="text-grey-700" name="regular/search" />
                </template>
            </activix-input>
        </template>

        <template slot="body">
            <div class="landscape-body | -mt-3 lg:-mt-6">
                <div
                    class="py-2 flex items-center"
                    :class="{
                        'border-b': index < currentOptions.length - 1,
                        'text-grey-500': optionIsDisabled(option),
                    }"
                    :key="option[normalizedIdentifier]"
                    @click="onSelection(option)"
                    v-for="(option, index) in currentOptions"
                >
                    <span class="text-xl mr-2" :class="{ 'w-full font-bold text-center': option.isSeparator }">{{
                        getOptionLabel(option)
                    }}</span>
                    <icon
                        class="text-2xl ml-auto shrink-0"
                        :class="{ 'text-blue-500': optionIsSelected(option) && !optionIsDisabled(option) }"
                        :name="optionIsSelected(option) ? 'bold/check-circle-1' : 'regular/sign-badge-circle'"
                        :value="option[normalizedIdentifier]"
                        v-if="!option['isSeparator']"
                    />
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex flex-1 flex-row">
                <activix-button
                    class="text-lg font-bold uppercase"
                    :class="autoApply ? 'w-full' : 'w-1/2 mr-1'"
                    @click="cancel"
                >
                    {{ $t('general.cancel') }}
                </activix-button>

                <activix-button
                    class="w-1/2 ml-1 text-lg font-bold uppercase"
                    type="primary"
                    :disabled="selected.some(option => !valueIsValid(option))"
                    @click="save"
                    v-if="!autoApply"
                >
                    {{ $t('general.save') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    /* eslint-disable vue/require-prop-types */

    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
                required: true,
            },
            options: Array,
            identifier: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            customLabel: {
                type: Function,
                default(option, label) {
                    if (option) return option[label];
                    return label ? option[label] : option;
                },
            },
            value: {
                required: true,
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            autoApply: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                selected: [],
                search: '',
                canClose: false,
                canceled: false,
                input: null,
            };
        },

        computed: {
            currentOptions() {
                return this.options.filter(option => this.optionIsValid(option));
            },

            normalizedIdentifier() {
                return this.identifier || 'id';
            },

            normalizedLabel() {
                return this.label || 'label';
            },

            normalizedValue() {
                if (this.value instanceof Array) {
                    return this.value;
                }

                return [this.value];
            },
        },

        watch: {
            opened() {
                if (!this.opened) {
                    this.search = '';
                    this.canClose = false;
                }

                this.initSelection();
            },

            selected() {
                if (this.autoApply && this.canClose) {
                    this.save();
                }
            },
        },

        methods: {
            save() {
                if (this.multiple) {
                    if (this.selected.some(value => value === 'all')) {
                        this.input = ['all'];
                    } else {
                        this.input = this.selected;
                    }
                } else {
                    this.input = this.selected.find(option => this.valueIsValid(option));
                }

                this.close();
            },

            cancel() {
                this.canceled = true;
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                if (this.canceled) {
                    this.$emit('cancel');
                }

                if (this.input) {
                    this.$emit('input', this.input);
                }

                this.canceled = false;
                this.input = null;
            },

            optionIsSelected(option) {
                return this.selected.includes(option[this.normalizedIdentifier]);
            },

            optionIsDisabled(option) {
                return (
                    option.disabled ||
                    (this.multiple && !this.allowEmpty && this.optionIsSelected(option) && this.selected.length <= 1)
                );
            },

            optionIsValid(option) {
                return (
                    this.valueIsValid(option[this.normalizedIdentifier], option.isSeparator) &&
                    this.labelSatisfySearch(this.getOptionLabel(option), option.isSeparator)
                );
            },

            valueIsValid(value, isSeparator = false) {
                return value || value === 0 || isSeparator;
            },

            labelSatisfySearch(label, isSeparator) {
                return !this.search || (label.toLowerCase().includes(this.search.toLowerCase()) && !isSeparator);
            },

            onSelection(option) {
                if (this.optionIsDisabled(option) || option.isSeparator) {
                    return;
                }

                const optionValue = option[this.normalizedIdentifier];

                if (optionValue === 'all') {
                    if (this.optionIsSelected(option)) {
                        this.selected.splice(0);
                    } else {
                        this.selected = this.options
                            .filter(option => option[this.normalizedIdentifier] !== 'none')
                            .map(option => option[this.normalizedIdentifier]);
                    }

                    return;
                }

                if (this.optionIsSelected(option)) {
                    this.selected.splice(this.selected.indexOf(optionValue), 1);
                    return;
                }

                if (this.multiple) {
                    this.selected.push(optionValue);
                } else {
                    this.canClose = true;
                    this.selected = [optionValue];
                }
            },

            initSelection() {
                this.selected = this.currentOptions
                    .filter(option => this.normalizedValue.includes(option[this.normalizedIdentifier]))
                    .map(option => option[this.normalizedIdentifier]);
            },

            getOptionLabel(option) {
                if (typeof option === 'object' && option !== null) {
                    return this.customLabel(option, this.normalizedLabel) || option[this.normalizedLabel] || '';
                }

                return option ? this.customLabel(option) : '';
            },
        },
    };
</script>

<style lang="less" scoped>
    .landscape-body {
        max-height: calc(72vh - 121px) !important;
        overflow-y: auto;
    }
</style>
