export default {
    activated: 'Activated',
    active: 'Active',
    applyAndReload: 'Apply and reload',
    autoActivated: 'Auto-activated',
    availableSince: 'Available since {0}',
    browserNotSupportingVideoPlaying: 'Your browser does not support video playing.',
    deactivateWithoutComment: 'Deactivate without comment',
    deactivated: 'Deactivated',
    giveFeedback: 'Give feedback',
    leaveAComment: 'Leave a comment',
    newTag: 'New',
    noComment: 'No comment',
    notAutoActivated: 'Not auto-activated',
    projectedAdoptionOn: 'Projected adoption on {0}',
    requestFeedback: 'Before disabling {0}, would you like to give us your feedback?',
    theF: 'the',
    theM: 'the',
    title: 'Feature Previews',
    willBe: 'Will be',
};
