import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { isEmpty } from 'lodash-es';

const SentryClient = {
    isInitialized: false,

    init() {
        if (this.isInitialized) {
            return;
        }

        Sentry.init({
            Vue,
            logErrors: true,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.VUE_APP_ENV,
            release: `activix-crm-frontend@${process.env.VUE_APP_VERSION}`,
            beforeSend(event) {
                if (!process.env.VUE_APP_SENTRY_DSN) {
                    return null;
                }

                return event;
            },
            ignoreErrors: [
                'Request failed with status code 400',
                'Request failed with status code 401',
                'Request failed with status code 403',
                'Request failed with status code 404',
                'Request failed with status code 422',
                'Request failed with status code 500',
                'Network Error',
                'Request aborted',

                // Element UI
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',

                // Summernote
                "Cannot read property 'pageX' of undefined",

                // Handy Scroll
                "Cannot read property 'scrollLeft' of null",

                // Custom
                'Non-Error promise rejection captured with value: undefined', // Caused by login redirect to Auth0

                // Common errors: https://gist.github.com/impressiver/5092952
                'top.GLOBALS',
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'Can\'t find variable: ZiteReader',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                'fb_xd_fragment',
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                'conduitPage',
                'Script error.',
            ],
            denyUrls: [
                // Common errors: https://gist.github.com/impressiver/5092952
                /graph\.facebook\.com/i,
                /connect\.facebook\.net\/en_US\/all\.js/i,
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                /extensions\//i,
                /^chrome:\/\//i,
                /127\.0\.0\.1:4001\/isrunning/i,
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            ],
        });

        this.isInitialized = true;
    },

    setAuthUser(user) {
        if (isEmpty(user)) {
            Sentry.configureScope(scope => scope.setUser(null));
        } else {
            Sentry.setUser({
                id: user.id,
                email: user.email,
                name: `${user.first_name} ${user.last_name}`,
            });
        }
    },
};

SentryClient.init();

export default SentryClient;
