<template>
    <div class="space-y-6 | xs:flex xs:space-x-6 xs:space-y-0">
        <div class="flex-1">
            <label>{{ $t('responseLeadXpress.sender') }}</label>
            <activix-select
                class="w-full"
                :value="selectedSender"
                identifier="value"
                label="text"
                :options="mobileOptions"
                @input="updateSender"
            >
                <el-option-group v-if="authUserOption.length">
                    <el-option
                        :label="`${user.fullName} (${$t('responseLeadXpress.myself')})`"
                        :value="user.id"
                        :key="user.id"
                    />
                </el-option-group>
                <el-option-group :label="$t('responseLeadXpress.relativeFields')">
                    <el-option
                        :label="relativeOwner.text"
                        :value="relativeOwner.value"
                        :key="index"
                        v-for="(relativeOwner, index) in relativeOptions"
                    />
                </el-option-group>
                <el-option-group :label="$t('responseLeadXpress.specificUser')" v-if="displayUserOptions && activeUserOptions.length">
                    <el-option
                        :label="option.text"
                        :value="option.value"
                        :key="index"
                        v-for="(option, index) in activeUserOptions"
                    />
                </el-option-group>
            </activix-select>
        </div>
        <div class="flex-1">
            <label>{{ $t('responseLeadXpress.senderBackup') }} </label>
            <activix-select
                class="w-full"
                :value="fromBackup"
                :disabled="!displayFromBackup"
                identifier="value"
                label="text"
                :options="mobileBackupOptions"
                @input="$emit('update:fromBackup', $event)"
            >
                <el-option
                    :label="`${user.fullName} (${$t('responseLeadXpress.myself')})`"
                    :value="user.id"
                    :key="user.id"
                    v-if="authUserOption.length"
                />
                <template v-if="displayUserOptions && activeUserOptions.length">
                    <el-option
                        :label="option.text"
                        :value="option.value"
                        :key="option.value"
                        v-for="option in activeUserOptions"
                    />
                </template>
            </activix-select>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    import { concat } from 'lodash-es';

    import User from '@/entities/User.js';

    export default {
        props: {
            from: [String, Number],
            fromBackup: [String, Number],
            user: {
                type: User,
                required: true,
            },
            campaignSettings: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                activeUserOptions: [],
                sendAs: [],
                authUserOption: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            mobileOptions() {
                if (this.displayUserOptions) {
                    return concat(this.authUserOption, this.relativeOptions, this.activeUserOptions);
                }

                return concat(this.authUserOption, this.relativeOptions);
            },

            mobileBackupOptions() {
                return concat(this.authUserOption, this.activeUserOptions);
            },

            relativeOptions() {
                return [
                    {
                        value: 'user_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.user'),
                    },
                    {
                        value: 'bdc_user_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.bdcUser'),
                    },
                    {
                        value: 'commercial_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.commercial'),
                    },
                    {
                        value: 'service_agent_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        value: 'service_advisor_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.serviceAdvisorUser'),
                    },
                ];
            },

            displayFromBackup() {
                return !Number.isInteger(this.from);
            },

            displayUserOptions() {
                return this.user.isAdmin() ||
                    this.user.isDirector() ||
                    this.user.isBdcDirector() ||
                    this.user.isSuperBdc();
            },

            selectedSender() {
                return this.mobileOptions.find(option => option.value === this.from)?.value;
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                async handler() {
                    this.activeUserOptions = (await this.contextAccount.getActiveUsers(['first_name', 'last_name']))
                        .filter(user => {
                            if (user.id == this.user.id) {
                                return false;
                            }

                            if (this.campaignSettings) {
                                return user.campaign;
                            }

                            return true;
                        })
                        .map(user => ({
                            value: user.id,
                            text: user.fullName,
                        }));
                    this.setAuthUserOption();
                },
            },
        },

        methods: {
            async setAuthUserOption() {
                const authUserOptionExists = (await this.contextAccount.getActiveUsers(['first_name', 'last_name']))
                    .find(user => user.id === this.user.id);

                if (!authUserOptionExists || (this.campaignSettings && !this.user.campaign)) {
                    this.authUserOption = [];
                    this.updateSender(null);
                    return;
                }

                this.authUserOption = [
                    {
                        value: this.user.id,
                        text: `${this.user.fullName} (${this.$t('responseLeadXpress.myself')})`,
                    },
                ];
            },

            updateSender(event) {
                this.$emit('update:from', event);
            },
        },
    };
</script>
