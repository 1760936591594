import Enum from './Enum.js';

export default class CommunicationKind extends Enum {
    static get entries() {
        return {
            mass_mailing: 'mass_mailing',
            auto: 'auto',
            planned: 'planned',
        };
    }

    static get MASS_MAILING() {
        return this.entries.mass_mailing;
    }

    static get AUTO() {
        return this.entries.auto;
    }

    static get PLANNED() {
        return this.entries.planned;
    }

    static get translationGroup() {
        return 'communicationKinds';
    }
}
