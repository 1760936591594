import Enum from './Enum.js';

export default class NotificationGroup extends Enum {
    static get entries() {
        return {
            communications: 'communications',
            alerts: 'alerts',
            events: 'events',
        };
    }

    static get COMMUNICATIONS() {
        return this.entries.communications;
    }

    static get ALERTS() {
        return this.entries.alerts;
    }

    static get EVENTS() {
        return this.entries.events;
    }
}
