import Vue from 'vue';
import Auth from './auth.js';

const authService = new Auth();

authService.handshake();

const VueAuth = {
    install(Vue) {
        Vue.auth = authService;
        Vue.prototype.$auth = authService;
    },
};

Vue.use(VueAuth);
