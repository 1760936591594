<template>
    <div class="flex text-2xs items-center | md:text-xs">
        <!-- Name-->
        <activix-tooltip :content="nameIconTooltip">
            <button
                class="rounded-full flex items-center justify-center h-5 w-5 border border-gray-600 | md:h-6 md:w-6"
                :class="nameIconClass"
            >
                <icon name="regular/single-neutral-actions" />
            </button>
        </activix-tooltip>
        <!-- Phone-->
        <activix-tooltip :content="phoneTooltip">
            <button
                class="rounded-full flex items-center justify-center h-5 w-5 border border-gray-600 ml-2 | md:h-6 md:w-6"
                :class="phoneIconClass"
            >
                <icon :name="$icons.phone" />
            </button>
        </activix-tooltip>
        <!-- Email-->
        <activix-tooltip :content="emailTooltip">
            <button
                class="rounded-full flex items-center justify-center h-5 w-5 border border-gray-600 ml-2 | md:h-6 md:w-6"
                :class="emailIconClass"
            >
                <icon :name="$icons.email" />
            </button>
        </activix-tooltip>
    </div>
</template>

<script>
    // Entities
    import Lead from '../../entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
            searchedParameters: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            nameIconTooltip() {
                if (!this.lead.searchScores.name.searched) {
                    return `<em>${this.$t('addLead.noNameSearched')}</em>`;
                }

                if (this.lead.searchScores.name.fullyMatched) {
                    return `<strong>${this.$t('addLead.nameMatch')}</strong>`;
                }

                if (this.lead.searchScores.name.partiallyMatched) {
                    return `<strong>${this.$t('addLead.namePartiallyMatched')}</strong>`;
                }

                return this.$t('addLead.nameNotMatched');
            },

            nameIconClass() {
                if (!this.lead.searchScores.name.searched) {
                    return 'border border-gray-600 text-gray-600';
                }

                if (this.lead.searchScores.name.fullyMatched) {
                    return 'border border-green-600 text-green-600';
                }

                if (this.lead.searchScores.name.partiallyMatched) {
                    return 'border border-yellow-600 text-yellow-600';
                }

                return 'border border-red-600 text-red-600';
            },

            phoneIconClass() {
                if (this.lead.searchScores.phone.matched) {
                    return 'border border-green-600 text-green-600';
                }

                if (!this.lead.searchScores.phone.searched) {
                    return 'border border-gray-600 text-gray-600';
                }

                return 'border border-red-600 text-red-600';
            },

            emailIconClass() {
                if (this.lead.searchScores.email.matched) {
                    return 'border border-green-600 text-green-600';
                }

                if (!this.lead.searchScores.email.searched) {
                    return 'border border-gray-600 text-gray-600';
                }

                return 'border border-red-600 text-red-600';
            },

            phoneTooltip() {
                if (!this.lead.phones.length) {
                    return `<em>${this.$t('addLead.noPhonesInLead')}</em>`;
                }

                const phonesString = this.phonesString;
                let tooltip = '';

                if (!this.lead.searchScores.phone.searched) {
                    tooltip = `<em>${this.$t('addLead.noPhoneSearched')}</em>`;
                } else if (this.lead.searchScores.phone.matched) {
                    tooltip = `<strong>${this.$t('addLead.phoneFound')}</strong>`;
                } else {
                    tooltip = `<strong>${this.$t('addLead.noPhoneFound')}</strong>`;
                }

                return `${tooltip}<br>${phonesString}`;
            },

            phonesString() {
                return (this.lead.lead_phones || [])
                    .map(p => {
                        const leadPhone = this.convertPhoneToTenDigitNumber(p.number, true).toString();
                        const searchedPhone = this.convertPhoneToTenDigitNumber(this.searchedParameters.phone, true);

                        if (searchedPhone == leadPhone) {
                            return `<strong>${leadPhone}</strong>`;
                        }

                        return leadPhone;
                    })
                    .join('<br>');
            },

            emailTooltip() {
                if (!this.lead.emails.length) {
                    return `<em>${this.$t('addLead.noEmailsInLead')}</em>`;
                }

                const emailString = this.emailsString;
                let tooltip = '';

                if (!this.lead.searchScores.email.searched) {
                    tooltip = `<em>${this.$t('addLead.noEmailSearched')}</em>`;
                } else if (this.lead.searchScores.email.matched) {
                    tooltip = `<strong>${this.$t('addLead.emailFound')}</strong>`;
                } else {
                    tooltip = `<strong>${this.$t('addLead.noEmailFound')}</strong>`;
                }

                return `${tooltip}<br>${emailString}`;
            },

            emailsString() {
                return (this.lead.lead_emails || [])
                    .map(e => {
                        const address = e.address.toString();

                        if (this.searchedParameters.email == address) {
                            return `<strong>${address}</strong>`;
                        }

                        return address;
                    })
                    .join('<br>');
            },
        },

        methods: {
            convertPhoneToTenDigitNumber(phone = '', addDashes = false) {
                if (!phone) {
                    return '';
                }

                // Remove extention
                if (phone.indexOf('x') > 1) {
                    phone = phone.substring(0, phone.indexOf('x') - 1);
                }

                // Remove any non digit character
                phone = phone.replace(/\D/g, '');

                // Remove leading '1' in saved string (automatically added by component)
                while (phone.charAt(0) == 1 || phone.charAt(0) == '+') {
                    phone = phone.slice(1);
                }

                if (addDashes && phone.length == 10) {
                    phone = `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
                }

                return phone;
            },
        },
    };
</script>
