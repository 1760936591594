export default [
    {
        path: '/trash',
        name: 'leads.trash',
        component: () => import(/* webpackChunkName: "trash" */ '../views/LeadTrash.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
