export default [
    { path: '/lead/:id', redirect: { name: 'leads.update' } },
    { path: '/leads/new', redirect: { name: 'dashboards.allLead' } },
    {
        path: '/leads/:id(\\d+)',
        name: 'leads.update',
        component: () => import(/* webpackChunkName: "lead" */ '../views/Leads/VersionSelector.vue'),
        meta: {
            blockAccount: true,
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
        },
    },
];
