<template>
    <activix-modal :closable="false" :opened="opened" @close="close">
        <template slot="body">
            <div class="form-group">
                <div class="panel panel-default">
                    <div class="panel-body h-screen-50 | overflow-auto" ref="">
                        <template v-if="$i18n.locale == 'en'">
                            <privacy-policy-en />
                            <hr class="my-8" />
                            <terms-of-use-en />
                        </template>
                        <template v-else>
                            <privacy-policy-fr />
                            <hr class="my-8" />
                            <terms-of-use-fr />
                        </template>
                    </div>
                </div>
            </div>
        </template>

        <template slot="footer">
            <div>
                <activix-checkbox size="lg" v-model="confirmation">
                    <span slot="before">{{ $t('confidentialityAgreement.termsAndConditionsConfirmation') }}</span>
                </activix-checkbox>
            </div>

            <activix-button
                class="mt-3"
                type="primary"
                :disabled="!confirmation"
                @click="save"
            >
                {{ $t('confidentialityAgreement.iAgree') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>

    // Components
    import { mapActions, mapState } from 'pinia';
    import PrivacyPolicyEn from '../confidentiality/PrivacyPolicyEn.vue';
    import PrivacyPolicyFr from '../confidentiality/PrivacyPolicyFr.vue';
    import TermsOfUseEn from '../confidentiality/TermsOfUseEn.vue';
    import TermsOfUseFr from '../confidentiality/TermsOfUseFr.vue';

    import { showWarning } from '../../utils/toastr.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            PrivacyPolicyEn,
            PrivacyPolicyFr,
            TermsOfUseEn,
            TermsOfUseFr,
        },
        data() {
            return {
                confirmation: false,
                scrolledToBottom: false,
                opened: false,
            };
        },
        computed: {
            ...mapState(useGlobalStore, ['authUser']),
        },

        watch: {
            authUser() {
                this.opened = !this.authUser.confidentiality_agreement;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateParentAuthUser']),

            async save() {
                try {
                    await this.$api.users.update(this.authUser.id, {
                        confidentiality_agreement: true,
                    });

                    this.updateParentAuthUser({ confidentiality_agreement: true });
                } catch (error) {
                    showWarning();
                }

                this.close();
            },

            close() {
                this.opened = false;
            },
        },

        mounted() {
            this.opened = !this.authUser.confidentiality_agreement;
        },
    };
</script>
