<template>
    <div
        class="el-select grow"
        :class="[selectSize ? 'el-select--' + selectSize : '']"
        :style="autosize ? { width: resizeWidth + 'px' } : {}"
        @click.stop="toggleMenu"
        v-clickoutside="handleClose"
    >
        <div
            class="el-select__tags"
            :style="{ 'max-width': inputWidth - 32 + 'px' }"
            ref="tags"
            v-if="taggable"
        >
            <transition-group @after-leave="resetInputHeight">
                <el-tag
                    :closable="!selectDisabled && !isDisabled(item)"
                    :size="collapseTagSize"
                    :hit="item.hitState"
                    type="info"
                    disable-transitions
                    :key="getValueKey(item)"
                    @close="deleteTag($event, item)"
                    v-for="item in selectedVisible"
                >
                    <span class="el-select__tags-text">{{ item.currentLabel }}</span>
                </el-tag>
                <el-tag
                    :closable="false"
                    :size="collapseTagSize"
                    :hit="false"
                    type="info"
                    disable-transitions
                    :key="selectedVisible.length + 1"
                    @close="() => {}"
                    v-if="visibleLimitExceeded"
                >
                    <span class="el-select__tags-text">+{{ visibleLimitExcess }}</span>
                </el-tag>
            </transition-group>

            <input
                type="text"
                class="el-select__input"
                :class="[selectSize ? `is-${selectSize}` : '']"
                :disabled="selectDisabled"
                :autocomplete="autoComplete"
                :debounce="remote ? 300 : 0"
                :style="{ width: inputLength + 'px', 'max-width': inputWidth - 42 + 'px' }"
                ref="input"
                @focus="handleFocus"
                @blur="softFocus = false"
                @click.stop
                @keyup="managePlaceholder"
                @keydown.exact="resetInputState"
                @keydown.down.prevent="navigateOptions('next')"
                @keydown.up.prevent="navigateOptions('prev')"
                @keydown.enter.prevent="selectOption"
                @keydown.esc.stop.prevent="visible = false"
                @keydown.delete="deletePrevTag"
                @compositionstart="handleComposition"
                @compositionupdate="handleComposition"
                @compositionend="handleComposition"
                @input="e => handleQueryChange(e.target.value)"
                v-model="query"
                v-if="filterable"
            />
        </div>
        <el-input
            :id="id"
            type="text"
            :placeholder="currentPlaceholder"
            :name="name"
            :autocomplete="autoComplete"
            :size="selectSize"
            :disabled="selectDisabled"
            :readonly="readonly"
            :validate-event="false"
            :class="{ 'is-focus': visible }"
            ref="reference"
            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.native="debouncedOnInputChange"
            @keydown.native.down.stop.prevent="navigateOptions('next')"
            @keydown.native.up.stop.prevent="navigateOptions('prev')"
            @keydown.native.enter.prevent="selectOption"
            @keydown.native.esc.stop.prevent="visible = false"
            @keydown.native.tab="visible = false"
            @paste.native="debouncedOnInputChange"
            @mouseenter.native="inputHovering = true"
            @mouseleave.native="inputHovering = false"
            v-model="selectedLabel"
        >
            <template slot="prefix" v-if="$slots.prefix">
                <slot name="prefix" />
            </template>
            <i
                :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"
                slot="suffix"
                @click="handleIconClick"
            />
        </el-input>
        <transition name="el-zoom-in-top" @before-enter="handleMenuEnter" @after-leave="doDestroy">
            <el-select-menu :append-to-body="popperAppendToBody" ref="popper" v-show="visible && emptyText !== false">
                <el-scrollbar
                    tag="ul"
                    wrap-class="el-select-dropdown__wrap"
                    view-class="el-select-dropdown__list"
                    :class="{ 'is-empty': !allowCreate && query && filteredOptionsCount === 0 }"
                    ref="scrollbar"
                    v-show="options.length > 0 && !loading"
                >
                    <el-option :value="query" created v-if="showNewOption" />
                    <slot />
                </el-scrollbar>
                <p
                    class="el-select-dropdown__empty"
                    v-if="emptyText && (!allowCreate || loading || (allowCreate && options.length === 0))"
                >
                    {{ emptyText }}
                </p>
            </el-select-menu>
        </transition>
    </div>
</template>

<script>
    /* eslint-disable no-nested-ternary, vue/require-prop-types, no-unused-expressions, default-param-last */
    import Emitter from 'element-ui/src/mixins/emitter.js';
    import Focus from 'element-ui/src/mixins/focus.js';
    import Locale from 'element-ui/src/mixins/locale.js';
    import ElInput from 'element-ui/packages/input/index.js';
    import ElSelectMenu from 'element-ui/packages/select/src/select-dropdown.vue';
    import ElTag from 'element-ui/packages/tag/index.js';
    import debounce from 'throttle-debounce/debounce.js';
    import Clickoutside from 'element-ui/src/utils/clickoutside.js';
    import { addClass, removeClass, hasClass } from 'element-ui/src/utils/dom.js';
    import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event.js';
    import { t } from 'element-ui/src/locale/index.js';
    import scrollIntoView from 'element-ui/src/utils/scroll-into-view.js';
    import { getValueByPath, valueEquals } from 'element-ui/src/utils/util.js';
    import NavigationMixin from 'element-ui/packages/select/src/navigation-mixin.js';
    import { isKorean } from 'element-ui/src/utils/shared.js';

    const sizeMap = {
        medium: 36,
        small: 32,
        mini: 28,
    };

    export default {
        name: 'ElSelectCustom',

        directives: { Clickoutside },

        components: {
            ElInput,
            ElSelectMenu,
            ElTag,
        },

        mixins: [Emitter, Locale, Focus('reference'), NavigationMixin],

        props: {
            name: String,
            id: String,
            value: {
                required: true,
            },
            autoComplete: {
                type: String,
                default: 'off',
            },
            automaticDropdown: Boolean,
            size: String,
            disabled: Boolean,
            clearable: Boolean,
            filterable: Boolean,
            allowCreate: Boolean,
            loading: Boolean,
            popperClass: String,
            remote: Boolean,
            loadingText: String,
            noMatchText: String,
            noDataText: String,
            remoteMethod: Function,
            filterMethod: Function,
            multiple: Boolean,
            multipleLimit: {
                type: Number,
                default: 0,
            },
            limitSelectedVisible: {
                type: Number,
                required: false,
            },
            placeholder: {
                type: String,
                default() {
                    return t('el.select.placeholder');
                },
            },
            defaultFirstOption: Boolean,
            reserveKeyword: Boolean,
            valueKey: {
                type: String,
                default: 'value',
            },
            taggable: {
                type: Boolean,
                default: true,
            },
            collapseTags: {
                type: Boolean,
                default: false,
            },
            popperAppendToBody: {
                type: Boolean,
                default: true,
            },
            displayLimitText: {
                type: Function,
                default: count => `${count} selected`,
            },
            autosize: {
                type: Boolean,
                default: false,
            },
        },

        componentName: 'ElSelect',

        inject: {
            elForm: {
                default: '',
            },

            elFormItem: {
                default: '',
            },
        },

        provide() {
            return {
                select: this,
            };
        },

        data() {
            return {
                options: [],
                cachedOptions: [],
                createdLabel: null,
                createdSelected: false,
                selected: this.multiple ? [] : {},
                inputLength: 20,
                inputWidth: 0,
                resizeWidth: 0,
                cachedPlaceHolder: '',
                optionsCount: 0,
                filteredOptionsCount: 0,
                visible: false,
                softFocus: false,
                selectedLabel: '',
                hoverIndex: -1,
                query: '',
                previousQuery: null,
                inputHovering: false,
                currentPlaceholder: '',
                menuVisibleOnFocus: false,
                isOnComposition: false,
                isSilentBlur: false,
            };
        },

        computed: {
            _elFormItemSize() {
                return (this.elFormItem || {}).elFormItemSize;
            },

            readonly() {
                // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
                const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
                return !this.filterable || this.multiple || (!isIE && !this.visible);
            },

            iconClass() {
                const criteria = this.clearable && !this.selectDisabled && !this.isValueEmpty;
                return criteria ? 'circle-close is-show-close' : this.remote && this.filterable ? '' : 'arrow-up';
            },

            debounce() {
                return this.remote ? 300 : 0;
            },

            emptyText() {
                if (this.loading) {
                    return this.loadingText || this.t('el.select.loading');
                }
                if (this.remote && this.query === '' && this.options.length === 0) return false;
                if (this.filterable && this.query && this.options.length > 0 && this.filteredOptionsCount === 0) {
                    return this.noMatchText || this.t('el.select.noMatch');
                }
                if (this.options.length === 0) {
                    return this.noDataText || this.t('el.select.noData');
                }

                return null;
            },

            showNewOption() {
                const hasExistingOption = this.options
                    .filter(option => !option.created)
                    .some(option => option.currentLabel === this.query);
                return this.filterable && this.allowCreate && this.query !== '' && !hasExistingOption;
            },

            selectSize() {
                return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
            },

            selectDisabled() {
                return this.disabled || (this.elForm || {}).disabled;
            },

            collapseTagSize() {
                return ['small', 'mini'].indexOf(this.selectSize) > -1 ? 'mini' : 'small';
            },

            isValueEmpty() {
                let isEmpty = this.value === undefined || this.value === null;

                if (this.multiple) {
                    isEmpty = isEmpty || !this.value.length;
                } else {
                    isEmpty = isEmpty || this.value === '';
                }

                return isEmpty;
            },

            selectedVisible() {
                return this.selected.filter((value, index) => {
                    if (this.limitSelectedVisible) {
                        return index < this.limitSelectedVisible && value;
                    }

                    return true;
                });
            },

            visibleLimitExceeded() {
                return this.limitSelectedVisible && this.limitSelectedVisible < this.selected.length;
            },

            visibleLimitExcess() {
                return this.limitSelectedVisible ? this.selected.length - this.limitSelectedVisible : '';
            },
        },

        watch: {
            selectDisabled() {
                this.$nextTick(() => {
                    this.resetInputHeight();
                });
            },

            placeholder(val) {
                this.cachedPlaceHolder = val;
                this.currentPlaceholder = val;

                if (this.multiple && this.value.length > 0) {
                    this.currentPlaceholder = '';
                }
            },

            value(val) {
                if (this.multiple) {
                    this.resetInputHeight();
                    if (val.length > 0 || (this.$refs.input && this.query !== '')) {
                        this.currentPlaceholder = '';
                    } else {
                        this.currentPlaceholder = this.cachedPlaceHolder;
                    }
                    if (this.filterable && !this.reserveKeyword) {
                        this.query = '';
                        this.handleQueryChange(this.query);
                    }
                }
                this.setSelected();
                if (this.filterable && !this.multiple) {
                    this.inputLength = 20;
                }
            },

            visible(val) {
                if (!val) {
                    this.handleIconHide();
                    this.broadcast('ElSelectDropdown', 'destroyPopper');
                    if (this.$refs.input) {
                        this.$refs.input.blur();
                    }
                    this.query = '';
                    this.previousQuery = null;
                    this.selectedLabel = this.multiple && !this.taggable ? this.selectedLabel : '';
                    this.inputLength = 20;
                    this.resetHoverIndex();
                    this.$nextTick(() => {
                        if (this.$refs.input && this.$refs.input.value === '' && this.selected.length === 0) {
                            this.currentPlaceholder = this.cachedPlaceHolder;
                        }
                    });
                    if (!this.multiple) {
                        if (this.selected) {
                            if (this.filterable && this.allowCreate && this.createdSelected && this.createdLabel) {
                                this.selectedLabel = this.createdLabel;
                            } else {
                                this.selectedLabel = this.selected.currentLabel;
                            }
                            if (this.filterable) this.query = this.selectedLabel;
                        }
                    }
                } else {
                    this.handleIconShow();
                    this.broadcast('ElSelectDropdown', 'updatePopper');
                    if (this.filterable) {
                        this.query = this.remote ? '' : this.selectedLabel;
                        this.handleQueryChange(this.query);
                        if (this.multiple) {
                            this.$refs.input.focus();
                        } else {
                            if (!this.remote) {
                                this.broadcast('ElOption', 'queryChange', '');
                                this.broadcast('ElOptionGroup', 'queryChange');
                            }
                            this.broadcast('ElInput', 'inputSelect');
                        }
                    }
                }
                this.$emit('visible-change', val);
            },

            options() {
                if (this.$isServer) return;
                this.$nextTick(() => {
                    this.broadcast('ElSelectDropdown', 'updatePopper');
                });
                if (this.multiple) {
                    this.resetInputHeight();
                }
                const inputs = this.$el.querySelectorAll('input');
                if ([].indexOf.call(inputs, document.activeElement) === -1) {
                    this.setSelected();
                }
                if (this.defaultFirstOption && (this.filterable || this.remote) && this.filteredOptionsCount) {
                    this.checkDefaultFirstOption();
                }
            },
        },

        methods: {
            handleComposition(event) {
                const text = event.target.value;
                if (event.type === 'compositionend') {
                    this.isOnComposition = false;
                    this.handleQueryChange(text);
                } else {
                    const lastCharacter = text[text.length - 1] || '';
                    this.isOnComposition = !isKorean(lastCharacter);
                }
            },
            handleQueryChange(val) {
                if (this.previousQuery === val || this.isOnComposition) return;
                if (
                    this.previousQuery === null &&
                    (typeof this.filterMethod === 'function' || typeof this.remoteMethod === 'function')
                ) {
                    this.previousQuery = val;
                    return;
                }
                this.previousQuery = val;
                this.$nextTick(() => {
                    if (this.visible) this.broadcast('ElSelectDropdown', 'updatePopper');
                });
                this.hoverIndex = -1;
                if (this.multiple && this.filterable) {
                    const length = this.$refs.input.value.length * 15 + 20;
                    this.inputLength = this.collapseTags ? Math.min(50, length) : length;
                    this.managePlaceholder();
                    this.resetInputHeight();
                }
                if (this.remote && typeof this.remoteMethod === 'function') {
                    this.hoverIndex = -1;
                    this.remoteMethod(val);
                } else if (typeof this.filterMethod === 'function') {
                    this.filterMethod(val);
                    this.broadcast('ElOptionGroup', 'queryChange');
                } else {
                    this.filteredOptionsCount = this.optionsCount;
                    this.broadcast('ElOption', 'queryChange', val);
                    this.broadcast('ElOptionGroup', 'queryChange');
                }
                if (this.defaultFirstOption && (this.filterable || this.remote) && this.filteredOptionsCount) {
                    this.checkDefaultFirstOption();
                }
            },

            handleIconHide() {
                const icon = this.$el.querySelector('.el-input__icon');
                if (icon) {
                    removeClass(icon, 'is-reverse');
                }
            },

            handleIconShow() {
                const icon = this.$el.querySelector('.el-input__icon');
                if (icon && !hasClass(icon, 'el-icon-circle-close')) {
                    addClass(icon, 'is-reverse');
                }
            },

            scrollToOption(option) {
                const target = Array.isArray(option) && option[0] ? option[0].$el : option.$el;
                if (this.$refs.popper && target) {
                    const menu = this.$refs.popper.$el.querySelector('.el-select-dropdown__wrap');
                    scrollIntoView(menu, target);
                }
                this.$refs.scrollbar && this.$refs.scrollbar.handleScroll();
            },

            handleMenuEnter() {
                this.$nextTick(() => this.scrollToOption(this.selected));
            },

            emitChange(val) {
                if (!valueEquals(this.value, val)) {
                    this.$emit('change', val);
                    this.dispatch('ElFormItem', 'el.form.change', val);
                }
            },

            getOption(value) {
                let option;
                const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]';
                for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
                    const cachedOption = this.cachedOptions[i];
                    const isEqual = isObject
                        ? getValueByPath(cachedOption.value, this.valueKey) === getValueByPath(value, this.valueKey)
                        : cachedOption.value === value;
                    if (isEqual) {
                        option = cachedOption;
                        break;
                    }
                }
                if (option) return option;
                const label = !isObject ? value : '';
                const newOption = {
                    value,
                    currentLabel: label,
                };
                if (this.multiple) {
                    newOption.hitState = false;
                }
                return newOption;
            },

            setSelected() {
                if (!this.multiple) {
                    const option = this.getOption(this.value);
                    if (option.created) {
                        this.createdLabel = option.currentLabel;
                        this.createdSelected = true;
                    } else {
                        this.createdSelected = false;
                    }
                    this.selectedLabel = option.currentLabel;
                    this.selected = option;

                    if (this.filterable) this.query = this.selectedLabel;

                    if (this.autosize) {
                        this.resizeWidth = this.calculateTextWidth(this.selectedLabel);
                    }
                    return;
                }

                const result = [];

                if (Array.isArray(this.value)) {
                    this.value.forEach(value => {
                        result.push(this.getOption(value));
                    });
                }

                this.selected = result;

                if (this.multiple && !this.taggable) {
                    if (this.selected.length == 0) {
                        this.selectedLabel = '';
                    } else if (this.selected.length == 1) {
                        this.selectedLabel = this.selected.shift().currentLabel;
                    } else {
                        this.selectedLabel = this.displayLimitText(this.value.filter(v => v !== 'all').length);
                    }
                }

                if (this.autosize) {
                    this.resizeWidth = this.calculateTextWidth(this.selectedLabel);
                }

                this.$nextTick(() => {
                    this.resetInputHeight();
                });
            },

            calculateTextWidth(label) {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                context.font = '14px "Source Sans Pro", sans-serif';
                const metrics = context.measureText(label);

                return parseInt(metrics.width + 45, 10);
            },

            handleFocus(event) {
                if (!this.softFocus) {
                    if (this.automaticDropdown || this.filterable) {
                        this.visible = true;
                        this.menuVisibleOnFocus = true;
                    }
                    this.$emit('focus', event);
                } else {
                    this.softFocus = false;
                }
            },

            blur() {
                this.visible = false;
                this.$refs.reference.blur();
            },

            handleBlur(event) {
                setTimeout(() => {
                    if (this.isSilentBlur) {
                        this.isSilentBlur = false;
                    } else {
                        this.$emit('blur', event);
                    }
                }, 50);
                this.softFocus = false;
            },

            handleIconClick(event) {
                if (this.iconClass.indexOf('circle-close') > -1) {
                    this.deleteSelected(event);
                }
            },

            doDestroy() {
                this.$refs.popper && this.$refs.popper.doDestroy();
            },

            handleClose() {
                this.visible = false;
            },

            toggleLastOptionHitState(hit) {
                if (!Array.isArray(this.selected)) return false;
                const option = this.selected[this.selected.length - 1];
                if (!option) return false;

                if (hit === true || hit === false) {
                    option.hitState = hit;
                    return hit;
                }

                option.hitState = !option.hitState;
                return option.hitState;
            },

            deletePrevTag(e) {
                if (e.target.value.length <= 0 && !this.toggleLastOptionHitState()) {
                    const value = this.value.slice();
                    value.pop();
                    this.$emit('input', value);
                    this.emitChange(value);
                }
            },

            managePlaceholder() {
                if (this.currentPlaceholder !== '') {
                    this.currentPlaceholder = this.$refs.input.value ? '' : this.cachedPlaceHolder;
                }
            },

            resetInputState(e) {
                if (e.keyCode !== 8) this.toggleLastOptionHitState(false);
                this.inputLength = this.$refs.input.value.length * 15 + 20;
                this.resetInputHeight();
            },

            resetInputHeight() {
                if (!this.taggable && !this.filterable) return;
                this.$nextTick(() => {
                    if (!this.$refs.reference) return;
                    const inputChildNodes = this.$refs.reference.$el.childNodes;
                    const input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0];
                    const tags = this.$refs.tags;
                    const sizeInMap = sizeMap[this.selectSize] || sizeMap.medium;
                    input.style.height =
                        this.selected.length === 0
                            ? `${sizeInMap}px`
                            : `${Math.max(
                                tags ? tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0) : 0,
                                sizeInMap,
                            )}px`;
                    if (this.visible && this.emptyText !== false) {
                        this.broadcast('ElSelectDropdown', 'updatePopper');
                    }
                });
            },

            resetHoverIndex() {
                setTimeout(() => {
                    if (!this.multiple) {
                        this.hoverIndex = this.options.indexOf(this.selected);
                    } else if (this.selected.length > 0) {
                        this.hoverIndex = Math.min.apply(
                            null,
                            this.selected.map(item => this.options.indexOf(item)),
                        );
                    } else {
                        this.hoverIndex = -1;
                    }
                }, 300);
            },

            handleOptionSelect(option, byClick) {
                if (this.multiple) {
                    const value = this.value.slice();
                    const optionIndex = this.getValueIndex(value, option.value);
                    if (optionIndex > -1) {
                        value.splice(optionIndex, 1);
                    } else if (this.multipleLimit <= 0 || value.length < this.multipleLimit) {
                        value.push(option.value);
                    }
                    this.$emit('input', value);
                    this.emitChange(value);
                    if (option.created) {
                        this.query = '';
                        this.handleQueryChange('');
                        this.inputLength = 20;
                    }
                    if (this.filterable) this.$refs.input.focus();
                } else {
                    this.$emit('input', option.value);
                    this.emitChange(option.value);
                    this.visible = false;
                }
                this.isSilentBlur = byClick;
                this.setSoftFocus();
                if (this.visible) return;
                this.$nextTick(() => {
                    this.scrollToOption(option);
                });
            },

            setSoftFocus() {
                this.softFocus = true;
                const input = this.$refs.input || this.$refs.reference;
                if (input) {
                    input.focus();
                }
            },

            getValueIndex(arr = [], value) {
                const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]';
                if (!isObject) {
                    return arr.indexOf(value);
                }
                const valueKey = this.valueKey;
                let index = -1;
                arr.some((item, i) => {
                    if (getValueByPath(item, valueKey) === getValueByPath(value, valueKey)) {
                        index = i;
                        return true;
                    }
                    return false;
                });
                return index;
            },

            toggleMenu() {
                if (!this.selectDisabled) {
                    if (this.menuVisibleOnFocus) {
                        this.menuVisibleOnFocus = false;
                    } else {
                        this.visible = !this.visible;
                    }
                    if (this.visible) {
                        (this.$refs.input || this.$refs.reference).focus();
                    }
                }
            },

            selectOption() {
                if (!this.visible) {
                    this.toggleMenu();
                } else if (this.options[this.hoverIndex]) {
                    this.handleOptionSelect(this.options[this.hoverIndex]);
                }
            },

            deleteSelected(event) {
                event.stopPropagation();
                this.$emit('input', '');
                this.emitChange('');
                this.visible = false;
                this.$emit('clear');
            },

            deleteTag(event, tag) {
                const index = this.selected.indexOf(tag);
                if (index > -1 && !this.selectDisabled) {
                    const value = this.value.slice();
                    value.splice(index, 1);
                    this.$emit('input', value);
                    this.emitChange(value);
                    this.$emit('remove-tag', tag.value);
                }
                event.stopPropagation();
            },

            onInputChange() {
                if (this.filterable && this.query !== this.selectedLabel) {
                    this.query = this.selectedLabel;
                    this.handleQueryChange(this.query);
                }
            },

            onOptionDestroy(index) {
                if (index > -1) {
                    this.optionsCount--;
                    this.filteredOptionsCount--;
                    this.options.splice(index, 1);
                }
            },

            resetInputWidth() {
                this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
            },

            handleResize() {
                this.resetInputWidth();
                if (this.multiple) this.resetInputHeight();
            },

            checkDefaultFirstOption() {
                this.hoverIndex = -1;

                let hasCreated = false;
                for (let i = this.options.length - 1; i >= 0; i--) {
                    if (this.options[i].created) {
                        hasCreated = true;
                        this.hoverIndex = i;
                        break;
                    }
                }
                if (hasCreated) return;
                for (let i = 0; i !== this.options.length; ++i) {
                    const option = this.options[i];
                    if (this.query) {
                        // highlight first options that passes the filter
                        if (!option.disabled && !option.groupDisabled && option.visible) {
                            this.hoverIndex = i;
                            break;
                        }
                    } else if (option.itemSelected) {
                        // highlight currently selected option
                        this.hoverIndex = i;
                        break;
                    }
                }
            },

            getValueKey(item) {
                if (Object.prototype.toString.call(item.value).toLowerCase() !== '[object object]') {
                    return item.value;
                }

                return getValueByPath(item.value, this.valueKey);
            },

            isDisabled() {
                return !this.clearable && this.selected.length < 1;
            },

            handleFieldReset() {
                this.dispatch('ElFormItem', 'el.form.change');
            },
        },

        created() {
            this.cachedPlaceHolder = this.placeholder;
            this.currentPlaceholder = this.placeholder;

            if (this.multiple && !Array.isArray(this.value)) {
                this.$emit('input', []);
            }
            if (!this.multiple && Array.isArray(this.value)) {
                this.$emit('input', '');
            }

            this.debouncedOnInputChange = debounce(this.debounce, () => {
                this.onInputChange();
            });

            this.$on('handleOptionClick', this.handleOptionSelect);
            this.$on('setSelected', this.setSelected);
            this.$on('fieldReset', this.handleFieldReset);
        },

        mounted() {
            if (this.multiple && Array.isArray(this.value) && this.value.length > 0) {
                this.currentPlaceholder = '';
            }
            addResizeListener(this.$el, this.handleResize);
            if (this.remote && this.multiple) {
                this.resetInputHeight();
            }
            this.$nextTick(() => {
                if (this.$refs.reference && this.$refs.reference.$el) {
                    this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
                }
            });
            this.setSelected();
        },

        beforeDestroy() {
            if (this.$el && this.handleResize) removeResizeListener(this.$el, this.handleResize);

            this.$off('handleOptionClick', this.handleOptionSelect);
            this.$off('setSelected', this.setSelected);
            this.$off('fieldReset', this.handleFieldReset);
        },
    };
</script>

<style lang="less">
    .el-input.el-input--prefix {
        & > input {
            @apply pl-11 !important;
        }

        & > .el-input__prefix {
            margin-left: 0.65rem !important;
        }
    }
</style>
