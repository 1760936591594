<template>
    <div class="absolute flex items-center justify-center h-screen w-screen" v-if="showScreen">
        <div class="w-full max-w-sm m-6">
            <div class="mb-6 flex justify-center">
                <router-link to="/">
                    <img class="h-10" src="../assets/img/logo_activix_crm_gray.png" alt="Activix CRM" />
                </router-link>
            </div>
            <div class="box | mb-4">
                <router-view class="box-body | p-6" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            showLoading: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            showScreen() {
                return this.$route.name === 'auth.newPassword';
            },
        },

        watch: {
            showScreen: {
                immediate: true,
                handler(showScreen) {
                    if (showScreen) {
                        this.$emit('update:showLoading', false);
                    }
                },
            },
        },
    };
</script>
