import Enum from './Enum.js';

export default class SupplierType extends Enum {
    static get entries() {
        return {
            sale: 'sale',
            service: 'service',
            api: 'api',
            inventory: 'inventory',
            phone: 'phone',
        };
    }

    static get SALE() {
        return this.entries.sale;
    }

    static get SERVICE() {
        return this.entries.service;
    }

    static get API() {
        return this.entries.api;
    }

    static get INVENTORY() {
        return this.entries.inventory;
    }

    static get PHONE() {
        return this.entries.phone;
    }
}
