export default {
    salesActivity: {
        title: 'Sales Activity',
        newLead: 'New Lead',
        newLeadTooltip: 'The total number of new leads received. \n' +
            '\n' +
            'The count is based on the lead creation date falling within the selected date range.',
        pendingLead: 'Pending Lead',
        pendingLeadTooltip: 'The total number of leads with \'Pending\' as a result. \n' +
            '\n' +
            'The count is based on the lead creation date falling within the selected date range.',
        awaitingReplyFromDealer: 'Awaiting Reply From Dealer',
        awaitingReplyFromDealerTooltip: 'The total of active leads whose most recent communication was inbound. \n' +
            '\n' +
            'The count is based on the last communication date falling within the selected date range.',
        awaitingReplyFromCustomer: 'Awaiting Reply From Customer',
        awaitingReplyFromCustomerTooltip: 'The total of active leads whose most recent communication is outbound. \n' +
            '\n' +
            'The count is based on the last communication date falling within the selected date range.',
        appointment: 'Appointment',
        appointmentTooltip: 'The total number of leads that have an appointment or phone appointment set. \n' +
            '\n' +
            'The count is based on the date of the most recent appointment within the selected range.',
        testDrive: 'Test Drive',
        testDriveTooltip: 'The total number of leads that have had test drives. \n' +
            '\n' +
            'The count is based on the date of the test drives.',
        storeVisits: 'Visits',
        storeVisitsTooltip: 'The total number of leads that have had visits. \n' +
            '\n' +
            'The count is based on the date of the visits.',
        sold: 'Sold',
        soldTooltip: 'Total number of leads marked as sold. \n' +
            '\n' +
            'The count is based on the date when each lead was set to sold.',
        delivered: 'Delivered',
        deliveredTooltip: 'Total number of leads marked as delivered. \n' +
            '\n' +
            'The count is based on the date when each lead was delivered.',
        lost: 'Lost',
        lostTooltip: 'Total number of leads set to lost status.\n' +
            '\n' +
            'The count is based on the of the date when each lead was marked as lost.',
    },
    newLeadByType: {
        title: 'New Lead by Type',
    },
    responseTime: {
        title: 'Response Time',
        webTitle: 'Web',
        phoneUpTitle: 'Phone-up',
        lastDaysText: 'Versus previous {0}',
        noChangeText: 'No change versus previous {0}',
        webPerformanceTooltip: 'Represents the average time taken before an initial communication is made on a web lead, excluding any manual or automated communications.',
        phoneUpTooltip: 'Represents the average duration required to transfer calls from the receptionist to the advisor.',
        period: {
            '7days': '7 days',
            '30days': '30 days',
            '90days': '90 days',
        },
        filters: {
            period: 'Time Period',
        },
    },
    tasks: {
        title: 'Tasks',
        addTask: 'Add a task',
        noTasks: 'There are no tasks that match your filters.',
    },
    appointments: {
        title: 'Appointments',
        addAppointment: 'Add an appointment',
        noAppointments: 'There are no appointments that match your filters.',
    },
    filters: {
        divisions: 'Divisions',
        users: 'All Users',
        noDivision: 'No division',
        period: 'Time Period',
        appointmentType: 'Appointment Type',
        taskType: 'Task Type',
    },
};
