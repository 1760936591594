export default [
    {
        path: '/groups',
        name: 'groups.index',
        component: () => import(/* webpackChunkName: "groups" */ '../views/Groups/List.vue'),
        meta: {
            header: {
                search: true,
                settings: true,
            },
        },
    },
];
