import { initValues } from '@/entities/ModelHelper.ts';

export default class ConditionQuestion {
    question_id: string = '';

    question_text: string = '';

    answer_text: string = '';

    private _child_question_answers: ConditionQuestion[] = [];

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set child_question_answers(childQuestionAnswers) {
        this._child_question_answers = childQuestionAnswers.map((question: any) => new ConditionQuestion(question));
    }

    get child_question_answers(): ConditionQuestion[] {
        return this._child_question_answers;
    }
}
