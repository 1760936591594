export default {
    call: 'Appel',
    email: 'Courriel',
    appointment: 'Rendez-vous',
    delivery: '@.capitalize:lexicon.delivery.singular',
    other: 'Autre',
    csi: 'CSI',
    sms: 'SMS',
    phone_appointment: 'Rendez-vous téléphonique',
    virtual_appointment: 'Rendez-vous virtuel',
    test_drive: 'Essai routier',
};
