// Utils
import { escapeRegExp, get } from 'lodash-es';
import { mapState } from 'pinia';
import { useGlobalStore } from '@/store/store.js';
import ClientCardSection from '@/entities/ClientCardSection.js';
import { parseCustomField } from '@/value-objects/CustomField.js';
import { useContextStore } from '@/store/modules/context/store.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import UserPhone from '@/entities/UserPhone.js';
import i18n from '../plugins/vue-i18n.js';
import { formatPhone } from '../utils/index.js';

// Entities
import CommunicationType from '../entities/CommunicationType.js';
import LeadVehicle from '../entities/LeadVehicle.js';
import Service from '../entities/Service.js';

const getLeadValue = ({ attribute, html, lead, locale, prefix }) => {
    switch (attribute.tag) {
        case 'prefix':
            return getPrefix({ prefix, lead, html });

        case 'gender':
            return locale.toLowerCase() == 'fr' && lead.sex == 'W' ? 'e' : '';

        case 'response-subject':
            return getResponseSubject(lead);

        case 'wanted-vehicle-link':
            return getWantedVehicleLink({ lead, html, locale });

        case 'wanted-vehicle-drs-link-dealer':
            return getWantedVehicleDrsLinkDealer({ lead, html, locale });

        case 'wanted-vehicle-drs-link-client':
            return getWantedVehicleDrsLinkClient({ lead, html, locale });

        case 'wanted-stock-state':
            return getStockState({ lead, locale });

        case 'exchange-equity':
            if (lead.exchange_vehicles.length) {
                return lead.exchange_vehicles[0].calculated_equity;
            }

            break;

        case 'exchange-month-remaining':
            if (lead.exchange_vehicles.length) {
                return LeadVehicle.calculateMonthsRemaining(lead.exchange_vehicles[0].end_contract_date, true);
            }

            break;

        case 'commercial-institution':
            return getInstitution(lead);

        case 'subscribe':
            return getSubscribe(locale);

        case 'lead-division':
            return getDivisionName({ lead, locale });

        case 'lead-form':
            return getFormName({ lead, locale });

        case 'lead-approved':
            return formatBoolean(lead.approved, locale);
    }

    return '';
};

const getAdvisorValue = async ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        // @TODO Rename "firstName" to "first-name"
        case 'assigned-advisor-firstName':
            return (await lead.getUser(['first_name'])).first_name;

        case 'assigned-advisor-lastName':
            return (await lead.getUser(['last_name'])).last_name;

        case 'assigned-advisor-name':
            return (await lead.getUser(['first_name', 'last_name'])).fullName;

        case 'assigned-advisor-post': {
            const user = await UserRepository.find(lead.userId, ['post.name']);

            return getUserPost({
                post: user?.post,
                locale,
            });
        }

        case 'assigned-advisor-phone': {
            const user = await UserRepository.find(lead.userId, ['phones.number', 'phones.extension', 'phones.is_preferred']);
            const phones = (user?.phones || []).map(phone => new UserPhone(phone));

            return getUserPhone(phones);
        }

        case 'assigned-advisor-email':
            return (await lead.getUser(['email'])).email || '';
    }

    return '';
};

const getBDCValue = async ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        // @TODO Rename "firstName" to "first-name"
        case 'assigned-bdc-first-name':
            return (await lead.getBdcUser(['first_name'])).first_name;

        case 'assigned-bdc-name':
            return (await lead.getBdcUser(['first_name', 'last_name'])).fullName;

        case 'assigned-bdc-post': {
            const user = await UserRepository.find(lead.bdcUserId, ['post.name']);

            return getUserPost({
                post: user?.post,
                locale,
            });
        }

        case 'assigned-bdc-phone': {
            const user = await UserRepository.find(lead.bdcUserId, ['phones.number', 'phones.extension', 'phones.is_preferred']);
            const phones = (user?.phones || []).map(phone => new UserPhone(phone));

            return getUserPhone(phones);
        }

        case 'assigned-bdc-email':
            return (await lead.getBdcUser(['email'])).email || '';
    }

    return '';
};

const getCommercialValue = async ({ attribute, lead }) => {
    switch (attribute.tag) {
        case 'commercial-assigned-director-email':
            return (await lead.getCommercialUser(['email'])).email || '';

        default:
            return (await lead.getCommercialUser(['first_name', 'last_name'])).fullName;
    }
};

const getDeliveryValue = async ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        case 'assigned-delivery-user-first-name':
            return (await lead.getDeliveredByUser(['first_name'])).first_name;

        case 'assigned-delivery-user-name':
            return (await lead.getDeliveredByUser(['first_name', 'last_name'])).fullName;

        case 'assigned-delivery-user-post': {
            const user = await UserRepository.find(lead.deliveredById, ['post.name']);

            return getUserPost({
                post: user?.post,
                locale,
            });
        }

        case 'assigned-delivery-user-phone': {
            const user = await UserRepository.find(lead.deliveredById, ['phones.number', 'phones.extension', 'phones.is_preferred']);
            const phones = (user?.phones || []).map(phone => new UserPhone(phone));

            return getUserPhone(phones);
        }

        case 'assigned-delivery-user-email':
            return (await lead.getDeliveredByUser(['email'])).email || '';
    }

    return '';
};

const getCustomableValue = ({ attribute, lead }) => {
    let customField = lead.account.getCustomFieldByField(attribute.tag);

    if (!customField) {
        customField = useContextStore().account.getCustomFieldByField(attribute.tag);
    }

    if (!customField) {
        return '';
    }

    let customable;

    if (ClientCardSection.isVehicleField(customField.section)) {
        customable = lead.getCustomableVehicleById(customField.id, customField.section);
    } else {
        customable = lead.getCustomableById(customField.id);
    }

    if (!customable) {
        if (customField.type === 'boolean') {
            return i18n.t('general.no');
        }

        return '';
    }

    return parseCustomField.toRead(customable.pivot.value, customField.type);
};

const getMassMailingValue = ({ attribute, groupName }) => {
    return normalizeTagText(`{${groupName} - ${attribute.text}}`);
};

const getVideoCommunicationThumbnail = ({ locale }) => {
    return `
        <thumbnail data-video-communication-thumbnail class='communication-video-placeholder-wrapper' style="background: rgba(0, 0, 0, .35) url('/img/cars.jpeg')">
            <div class="communication-video-placeholder" style="backdrop-filter: blur(3px);">{ ${i18n.t('communications.video.thumbnailPlaceholder', locale)} }</div>
        </thumbnail>
    `;
};

const getModelValue = payload => {
    const { attribute, locale } = payload;
    let value = '';

    attribute.model.forEach(model => {
        value += !value ? '' : ' ';

        const dynamicVariable = get(payload, model);

        if (!dynamicVariable) {
            return;
        }

        if (attribute.formatJs) {
            value += locale_dt(dynamicVariable).humanString(attribute.formatJs == 'date', locale);
            return;
        }

        value += dynamicVariable;
    });

    return value;
};

const getPrefix = ({ prefix, lead, html }) => {
    const civility = prefix || (lead.civility ? i18n.t(`civilities.${lead.civility}`) : '');

    if (html) {
        return `<span class="civility">${civility}</span>`;
    }

    return civility;
};

const getResponseSubject = lead => {
    const communication = lead.communications.find(communication => {
        return (
            communication.communication_type_id == CommunicationType.INCOMING &&
            communication.service_id == Service.LEAD_XPRESS &&
            !!communication.email_subject
        );
    });

    if (!communication) {
        return '';
    }

    return `Re: ${communication.email_subject}`;
};

const getSenderValue = async ({ attribute, locale, sender }) => {
    switch (attribute.tag) {
        case 'sender-advisor-first-name':
            return sender.first_name;

        case 'sender-advisor-last-name':
            return sender.last_name;

        case 'sender-advisor-full-name':
            return sender.fullName;

        case 'sender-advisor-phone':
            return getUserPhone(await sender.phones);

        case 'sender-advisor-post':
            return getUserPost({
                post: await sender.post,
                locale,
            });
    }

    return '';
};

const getUserPhone = phones => {
    const preferredPhone = phones.find(p => p.preferred) || phones[0];

    if (!preferredPhone) {
        return '';
    }

    const number = formatPhone(preferredPhone.phone);
    const extension = preferredPhone.extension ? `#${preferredPhone.extension}` : '';

    return `${number} ${extension}`.trim();
};

const getUserPost = ({ post, locale }) => {
    if (!post) {
        return '';
    }

    return i18n.t(`database.post_${post.name}`, locale);
};

const getDivisionName = ({ lead, locale }) => {
    if (!lead || !lead.division) {
        return '';
    }

    return i18n.t(`divisions.${lead.division.name}`, locale);
};

const getFormName = ({ lead, locale }) => {
    if (!lead) {
        return '';
    }

    if (lead.lead_form) {
        return lead.lead_form[`display_name_${locale}`] || lead.lead_form.name;
    }

    return lead.form || '';
};

const getStockState = ({ lead, locale }) => {
    if (!lead || !lead.getSoldOrFirstWantedVehicle() || !lead.getSoldOrFirstWantedVehicle().stock_state) {
        return '';
    }

    return i18n.t(`leadVehicleStockStates.${lead.getSoldOrFirstWantedVehicle().stock_state}`, locale);
};

const formatBoolean = (value, locale) => {
    return value ? i18n.t('general.yes', locale) : i18n.t('general.no', locale);
};

const getWantedVehicleLink = ({ lead, html, locale }) => {
    const vehicle = lead.getSoldOrFirstWantedVehicle();

    if (!vehicle || !vehicle.url) {
        return '';
    }

    if (html) {
        return `
            <a href="${vehicle.url}">
                ${i18n.t('responseLeadXpress.wantedVehicleLink', locale)}
            </a>
        `;
    }

    return vehicle.url;
};

const getWantedVehicleDrsLinkDealer = ({ lead, html, locale }) => {
    const vehicle = lead.getSoldOrFirstWantedVehicle();

    if (!vehicle || !vehicle.drs_link_dealer) {
        return '';
    }

    if (html) {
        return `
            <a href="${vehicle.drs_link_dealer}">
                ${i18n.t('responseLeadXpress.wantedVehicleDrsLinkDealer', locale)}
            </a>
        `;
    }

    return vehicle.drs_link_dealer;
};

const getWantedVehicleDrsLinkClient = ({ lead, html, locale }) => {
    const vehicle = lead.getSoldOrFirstWantedVehicle();

    if (!vehicle || !vehicle.drs_link_client) {
        return '';
    }

    if (html) {
        return `
            <a href="${vehicle.drs_link_client}">
                ${i18n.t('responseLeadXpress.wantedVehicleDrsLinkClient', locale)}
            </a>
        `;
    }

    return vehicle.drs_link_client;
};

const getInstitution = lead => {
    if (lead.lead_bank) {
        return lead.lead_bank.name;
    }

    if (lead.imported_bank) {
        return lead.imported_bank;
    }

    return '';
};

const getSubscribe = locale => {
    return `
            <a rel="noopener" href="%tag_subscribe_url%" target="_blank">
                ${i18n.t('templates.subscribe', locale)}
            </a>
        `;
};

const normalizeTagText = (content) => {
    if (content.includes('&amp;')) {
        return content.replace(/&amp;/g, '&');
    }

    return content;
};

export default {
    computed: {
        ...mapState(useGlobalStore, ['templateAttributes']),
    },

    methods: {
        normalizeTagText(content) {
            return normalizeTagText(content);
        },

        async replaceAttributesInMessage(payload) {
            let { message, html } = payload;

            const attributesToReplace = this.templateAttributes.reduce((attributes, group) => {
                const groupAttributes = group.tags.reduce((groupAttributes, attribute) => {
                    const htmlTag = `<span class="${attribute.tag}"></span>`;
                    let attributeTagFr = `{${group.name_fr} - ${attribute.text_fr}}`;
                    let attributeTagEn = `{${group.name_en} - ${attribute.text_en}}`;

                    attributeTagFr = html ? attributeTagFr : this.normalizeTagText(attributeTagFr);
                    attributeTagEn = html ? attributeTagEn : this.normalizeTagText(attributeTagEn);

                    const messageIncludesAttribute = [htmlTag, attributeTagFr, attributeTagEn].some(tag => message.includes(tag));

                    if (messageIncludesAttribute) {
                        groupAttributes[attribute.tag] = {
                            attribute,
                            groupName: group.name,
                            tags: [htmlTag, escapeRegExp(attributeTagFr), escapeRegExp(attributeTagEn)],
                        };
                    }

                    return groupAttributes;
                }, {});

                return {
                    ...attributes,
                    ...groupAttributes,
                };
            }, {});

            const values = await Promise.all(Object.values(attributesToReplace).map(async ({ attribute, groupName, tags }) => {
                const value = await this.getAttributeValue({ groupName, attribute, ...payload });

                return { value, tags };
            }));

            values.forEach(({ value, tags }) => {
                for (const tag of tags) {
                    message = message.replace(new RegExp(tag, 'g'), value);
                }
            });

            return message;
        },

        async getAttributeValue(payload) {
            const { attribute, sender, massMailing, notification } = payload;

            if (attribute.tag.startsWith('sender-')) {
                if (!sender) {
                    return getMassMailingValue(payload);
                }

                return getSenderValue(payload);
            }

            if (massMailing || notification) {
                return getMassMailingValue(payload);
            }

            if (attribute.tag === 'dealer-phone') {
                return formatPhone(get(payload, 'lead.account.phone'));
            }

            if (attribute.tag === 'lead-phone') {
                return formatPhone(get(payload, 'lead.lead_phones[0].phone'));
            }

            if (attribute.tag === 'lead-cell-phone') {
                return formatPhone(payload.lead?.cellPhone?.phone);
            }

            if (attribute.tag === 'lead-home-phone') {
                return formatPhone(payload.lead?.homePhone?.phone);
            }

            if (attribute.tag === 'lead-work-phone') {
                return formatPhone(payload.lead?.workPhone?.phone);
            }

            if (attribute.tag === 'lead-home-email') {
                return payload.lead?.homeEmail?.email;
            }

            if (attribute.tag === 'lead-work-email') {
                return payload.lead?.workEmail?.email;
            }

            if (attribute.tag.startsWith('assigned-advisor-')) {
                return getAdvisorValue(payload);
            }

            if (attribute.tag.startsWith('assigned-bdc-')) {
                return getBDCValue(payload);
            }

            if (attribute.tag.startsWith('commercial-assigned-')) {
                return getCommercialValue(payload);
            }

            if (attribute.tag.startsWith('assigned-delivery-user-')) {
                return getDeliveryValue(payload);
            }

            if (attribute.tag === 'video-communication-thumbnail') {
                return getVideoCommunicationThumbnail(payload);
            }

            if (attribute.tag.startsWith('custom_')) {
                return getCustomableValue(payload);
            }

            if (attribute.model.length) {
                return getModelValue(payload);
            }

            return getLeadValue(payload);
        },
    },
};
