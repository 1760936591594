import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        if (!payload.filter.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/users', { params: payload });

        return response.data.data;
    },

    async store(payload = {}) {
        const response = await axios.post('v1/users', payload);

        return response.data.data;
    },

    async show(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/users/${id}`, { params: payload });

        return response.data.data;
    },

    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.put(`v1/users/${id}`, payload);

        return response.data.data;
    },

    async destroy(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.delete(`v1/users/${id}`);

        return response.data.data;
    },
};
