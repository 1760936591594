/* eslint-disable  global-require */
import SvgIcon from 'vue-svgicon';

export function importIcons() {
    if (SvgIcon.icons.length) {
        return;
    }

    require('../icons.js');
}

export function getIconMarkup(iconPath, options = {}) {
    importIcons();
    const icon = SvgIcon.icons[iconPath];

    const classes = ['svg-icon'];
    let width = '1em';
    let height = '1em';

    if (iconPath.includes('bold/')) {
        classes.push('svg-fill');
    }

    if (options.class) {
        classes.push(options.class);
    }

    if (options.width) {
        width = options.width;
    }

    if (options.height) {
        height = options.height;
    }

    return `
        <svg
            version="1.1"
            class="${classes.join(' ')}"
            viewBox="${icon.viewBox}"
            style="width: ${width}; height: ${height};"
        >
            ${icon.data}
        </svg>
    `;
}
