/* eslint-disable camelcase */
import { isEmpty } from 'lodash-es';
import Model from './Model.js';

export default class CustomerVehicleLead extends Model {
    static get defaults() {
        return {};
    }

    static isConsideredEmpty(vehicle, ignoreFields = []) {
        ignoreFields = [
            ...ignoreFields,
            'id',
            'lead_id',
            'type',
            'created_at',
            'created_by',
            'updated_at',
            'updated_by',
            'deleted_at',
            'deleted_by',
        ];

        return !Object.entries(vehicle)
            .some(([key, value]) => !ignoreFields.includes(key) && !isEmpty(value) && value);
    }
}
