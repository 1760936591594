/* eslint-disable camelcase */
import { get, isArray, uniqWith } from 'lodash-es';
import i18n from '@/plugins/vue-i18n.js';

import { findContextAccount } from '@/pinia/storeHelper.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import CreatedMethods from '@/entities/CreatedMethod.js';
import Supplier from '@/entities/Supplier.js';
import Account from './Account.js';
import CommunicationMethod from './CommunicationMethod.js';
import CommunicationStatus from './CommunicationStatus.js';
import CommunicationType from './CommunicationType.js';
import Customer from './Customer.js';
import Dashboard from './Dashboard.js';
import Division from './Division.js';
import LeadEmail from './LeadEmail.js';
import LeadEmailType from './LeadEmailType.js';
import LeadPhone from './LeadPhone.js';
import LeadPhoneType from './LeadPhoneType.js';
import LeadResult from './LeadResult.js';
import LeadStatus from './LeadStatus.js';
import LeadType from './LeadType.js';
import LeadVehicle from './LeadVehicle.js';
import LeadVehicleType from './LeadVehicleType.js';
import Model from './Model.js';
import ProgressState from './ProgressState.js';
import SaleState from './SaleState.js';
import TaskEventType from './TaskEventType.js';
import User from './User.js';

import ActivixDate from '../value-objects/ActivixDate.js';
import ClientCardSection from './ClientCardSection.js';

export default class Lead extends Model {
    static get defaults() {
        return {
            // IDs
            bdc_user_id: null,
            commercial_id: null,
            division_id: null,
            lead_type_id: null,
            second_bdc_user_id: null,
            second_user_id: null,
            secondary_user_id: null,
            service_advisor_id: null,
            service_agent_id: null,
            user_id: null,
            created_by: null,
            updated_by: null,

            // Fields
            gas: false,
            unsubscribe_all_date: null,

            // Relationships (BelongsTo)
            account: {},
            customer: {},
            lead_type: {},
            creator: {},
            updater: {},

            // Relationships (HasMany)
            automation_jobs: [],
            renewal_alert_notifications: [],
            custom_fields: [],
            communications: [],
            emails: [],
            lead_events: [],
            phones: [],
            products: [],
            task_events: [],
            vehicles: [],
            campaigns: [],
        };
    }

    init(values) {
        delete values.sold_or_first_wanted_vehicle;

        super.init(values);
    }

    /**
     * Getters
     */
    get account() {
        if (this.loaded('account')) {
            return this.load('account');
        }

        if (!this.relationLoaded('account') && this.account_id) {
            this._account = findContextAccount(this.account_id) || new Account(this._account);
        }

        this.store('account', this._account);
        return this._account;
    }

    get customer() {
        return this._customer;
    }

    get lead_phones() {
        return this.phones;
    }

    get lead_emails() {
        return this.emails;
    }

    get fullName() {
        if (this.business && this.business_name) {
            return this.business_name.trim();
        }

        return [this.first_name, this.last_name].join(' ').trim();
    }

    get shortName() {
        if (this.business && this.business_name) {
            return this.business_name.trim();
        }

        return [this.first_name.charAt(0), this.last_name].join('. ').trim();
    }

    get exchange_vehicles() {
        return this.vehicles.filter(vehicle => vehicle?.type == LeadVehicleType.EXCHANGE);
    }

    get wanted_vehicles() {
        return this.vehicles.filter(vehicle => vehicle?.type == LeadVehicleType.WANTED);
    }

    get sold_or_first_wanted_vehicle() {
        return this.getSoldOrFirstWantedVehicle();
    }

    get renewalVehicle() {
        return this.exchange_vehicles[0];
    }

    get serviceVehicle() {
        return this.exchange_vehicles.find(vehicle => vehicle.service) || this.exchange_vehicles[0];
    }

    get financialDataFreezed() {
        return this.freeze_financial_data && this.account.commercial;
    }

    get cellPhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.CELL;
        });
    }

    get homeEmail() {
        return this.emails.find(email => {
            return email.type === LeadEmailType.HOME;
        });
    }

    get homePhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.HOME;
        });
    }

    get workEmail() {
        return this.emails.find(email => {
            return email.type === LeadEmailType.WORK;
        });
    }

    get workPhone() {
        return this.phones.find(phone => {
            return phone.type === LeadPhoneType.WORK;
        });
    }

    get hasValidEmail() {
        return this.lead_emails.some(email => email.valid);
    }

    get isDuplicate() {
        return this.status === LeadStatus.DUPLICATE;
    }

    get isInvalid() {
        return this.status === LeadStatus.INVALID;
    }

    get isLost() {
        return this.status === LeadStatus.LOST;
    }

    get statusText() {
        const translationKey = {
            [LeadStatus.INVALID]: 'leadStatus.invalid',
            [LeadStatus.DUPLICATE]: 'leadStatus.duplicate',
            [LeadStatus.LOST]: 'leadStatus.lost',
        }[this.status] || 'leadStatus.active';

        return i18n.t(translationKey);
    }

    get statusIcon() {
        return LeadStatus.getIcon(this.status);
    }

    get statusIconColor() {
        return {
            [LeadStatus.INVALID]: 'link-orange',
            [LeadStatus.DUPLICATE]: 'link-grey',
            [LeadStatus.LOST]: 'link-red',
        }[this.status] || 'link-green';
    }

    get typeIcon() {
        return LeadType.getIcon(this.lead_type_id);
    }

    get contextDate() {
        let date = null;

        if (this.isService()) {
            date = this.appointment_date;
        } else {
            switch (this.lead_type_id) {
                case LeadType.PHONE:
                    date = this.call_date;
                    break;
                case LeadType.WALK_IN:
                    date = this.presented_date;
                    break;
                case LeadType.RENEWAL:
                    date = this.end_contract_date;
                    break;
            }
        }

        return new ActivixDate(date || this.created_at);
    }

    /**
     * Setters
     */

    set account(account) {
        this._account = account;
    }

    set customer(customer) {
        if (this.relationLoaded('customer') && empty(customer)) {
            return;
        }

        this._customer = new Customer(customer);
    }

    set client(client) {
        if (this.relationLoaded('customer')) {
            return;
        }

        this._customer = new Customer(client);
    }

    set lead_phones(newPhones) {
        this.phones = newPhones;
    }

    set lead_emails(newEmails) {
        this.emails = newEmails;
    }

    set user(user) {
        this._user = user;
    }

    set creator(user) {
        this._creator = user;
    }

    set updater(user) {
        this._updater = user;
    }

    set bdc_user(user) {
        this._bdc_user = user;
    }

    set commercial(user) {
        this._commercial = user;
    }

    set delivered_by(user) {
        this._delivered_by = user;
    }

    set take_over_director(user) {
        this._take_over_director = user;
    }

    set status_updated_by(user) {
        this._status_updated_by = user;
    }

    set secondary_user(user) {
        this._secondary_user = user;
    }

    set second_user(user) {
        this._second_user = user;
    }

    set second_bdc_user(user) {
        this._second_bdc_user = user;
    }

    set service_advisor_user(user) {
        if (!this._service_advisor) {
            this._service_advisor = user;
        }
    }

    set service_agent_user(user) {
        if (!this._service_agent) {
            this._service_agent = user;
        }
    }

    set service_advisor(user) {
        this._service_advisor = user;
    }

    set service_agent(user) {
        this._service_agent = user;
    }

    set wanted_vehicles(vehicles) {
        this.vehicles = this.vehicles
            .filter(vehicle => {
                return vehicle.type != LeadVehicleType.WANTED;
            })
            .concat(vehicles);
    }

    set exchange_vehicles(vehicles) {
        this.vehicles = this.vehicles
            .filter(vehicle => {
                return vehicle.type != LeadVehicleType.EXCHANGE;
            })
            .concat(vehicles);
    }

    /**
     * Specifications
     */

    isAssigned() {
        return !!this.userId || !!this.secondaryUserId || !!this.bdcUserId;
    }

    isNotAssigned() {
        return !this.isAssigned();
    }

    isAssignedTo(userIds, extended = false) {
        if (!isArray(userIds)) {
            userIds = [userIds];
        }

        if (!extended) {
            return userIds.includes(this.userId) ||
                userIds.includes(this.secondaryUserId) ||
                userIds.includes(this.bdcUserId);
        }

        return userIds.includes(this.userId) ||
            userIds.includes(this.secondaryUserId) ||
            userIds.includes(this.bdcUserId) ||
            userIds.includes(this.commercialId) ||
            userIds.includes(this.serviceAdvisorId) ||
            userIds.includes(this.serviceAgentId);
    }

    isNotAssignedTo(userIds, extended = false) {
        return !this.isAssignedTo(userIds, extended);
    }

    isOfType(types) {
        if (!isArray(types)) {
            types = [types];
        }

        return types.includes(this.lead_type_id);
    }

    isNotOfType(types) {
        return !this.isOfType(types);
    }

    get isTRS() {
        return this.created_method === CreatedMethods.AUTOSYNC;
    }

    get latestTrsTradeInVehicleId() {
        return this.exchange_vehicles
            .map(v => ({
                ...v,
                trsSupplier: v.suppliers?.find(suppliable => [Supplier.AUTOSYNC].includes(suppliable.id)),
            }))
            .filter(v => v.trsSupplier)
            .sort((a, b) => b.trsSupplier.state.imported_on.localeCompare(a.trsSupplier.state.imported_on))
            .at(0)?.id;
    }

    isNew() {
        return this.division_id === Division.NEW;
    }

    isUsed() {
        return this.division_id === Division.USED;
    }

    isService() {
        return this.division_id === Division.SERVICE;
    }

    isFinance() {
        return this.division_id === Division.FINANCE;
    }

    isSold() {
        return !!this.sale_date;
    }

    isDelivered() {
        return !!this.delivered_date;
    }

    wasPresentedBetween(startDate, endDate) {
        return new ActivixDate(this.presented_date).isBetween(startDate, endDate);
    }

    wasBeBackBetween(startDate, endDate) {
        return new ActivixDate(this.be_back_date).isBetween(startDate, endDate);
    }

    wasSoldBetween(startDate, endDate) {
        return new ActivixDate(this.sale_date, 'date').isBetween(startDate, endDate);
    }

    wasBoughtBack() {
        return this.isOfType(LeadType.RENEWAL) && !!this.refinanced_date;
    }

    getAccountName() {
        return (get(this.account, 'name') || '-').trim();
    }

    getMobilePhones() {
        return (this.phones || []).filter(p => p.mobile && p.validated);
    }

    getUniquePhones() {
        return uniqWith(this.phones || [], (a, b) => {
            return !a.valid || a.type === b.type && a.phone === b.phone;
        });
    }

    async getAssignedUserEmails() {
        const [...assignedUsers] = await Promise.all([
            this.getUser(['email']),
            this.getBdcUser(['email']),
            this.getCommercialUser(['email']),
        ]);

        return assignedUsers
            .map(user => user?.email)
            .filter(email => email);
    }

    getEmailsString() {
        return (this.emails || [])
            .map(e => {
                return new LeadEmail(e).toString();
            })
            .join('<br>');
    }

    getPhonesString() {
        return (this.phones || [])
            .map(p => {
                return new LeadPhone(p).toString();
            })
            .join('<br>');
    }

    getVehiclesString(type = null) {
        const vehicleRelation = LeadVehicle.validType(type) ? type : 'lead';

        return (this[`${vehicleRelation}_vehicles`] || [])
            .map(v => {
                return new LeadVehicle(v).toString();
            })
            .join('<br>');
    }

    getVehiclesField(field, type = null) {
        const vehicleRelation = LeadVehicle.validType(type) ? type : 'lead';

        return (this[`${vehicleRelation}_vehicles`] || []).map(v => v[field]).join('<br>');
    }

    getSoldVehicle() {
        return this.wanted_vehicles.find(v => v.sold);
    }

    getPrimaryWantedVehicle() {
        return this.wanted_vehicles.find(v => v.primary_vehicle);
    }

    getSoldOrFirstWantedVehicle() {
        return this.getSoldVehicle() || this.getPrimaryWantedVehicle() || this.wanted_vehicles[0];
    }

    hasVerifiedSoldVehicle() {
        return this.wanted_vehicles.some(v => v.sold && v.verified_by_id);
    }

    isSoldOrRefinanced() {
        return !!(this.sale_date || this.sale_by_phone || this.refinanced_date);
    }

    isRefinanced() {
        return this.isOfType(LeadType.RENEWAL) && !!this.refinanced_date;
    }

    isRenewed() {
        return this.isOfType(LeadType.RENEWAL) && !!this.sale_date;
    }

    isValidForAutoRenew() {
        const soldVehicle = this.getSoldVehicle();

        if (!soldVehicle) {
            return false;
        }

        return !!soldVehicle.end_contract_date || soldVehicle.modality == 'cash';
    }

    canAutoRenew() {
        return (
            this.account.auto_renewal &&
            (![1, 2].includes(this.division_id) ||
                (this.isNew() && this.account.auto_renewal_new) ||
                (this.isUsed() && this.account.auto_renewal_used))
        );
    }

    getSaleState(start, end, carryOverOption) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        const isDiscounted = this.account.client_card_fields.process.discounted && this.discounted_date;

        if (this.isForcedWaitingSale()) {
            return SaleState.WAITING_SALE;
        }

        if (this.status == 'lost') {
            return SaleState.LOST;
        }

        if (this.progress_state == ProgressState.REFUSED) {
            return SaleState.REFUSED;
        }

        const deliveryDate = new ActivixDate(this.delivery_date);
        const deliveredDate = new ActivixDate(this.delivered_date);

        if (carryOverOption && this.isCarryOver(start, end)) {
            if (this.delivered_date) {
                if (isDiscounted) {
                    return SaleState.DISCOUNTED;
                }

                return SaleState.CARRY_OVER;
            }

            if (this.delivery_date || this.deliverable_date) {
                return SaleState.DELIVERY_CARRY_OVER;
            }

            return SaleState.CARRY_OVER_NOT_DELIVERED;
        }

        if (this.isWaitingSale(start, end)) {
            return SaleState.WAITING_SALE;
        }

        if ((!this.delivered_date && deliveryDate.isAfter(end)) || deliveredDate.isAfter(end)) {
            return SaleState.POSTPONED;
        }

        if (!this.delivered_date && deliveryDate.isBetween(start, end)) {
            return SaleState.PLANNED;
        }

        if (!this.delivery_date) {
            if (this.approved) {
                return SaleState.APPROVED;
            }

            return SaleState.SOLD;
        }

        if (this.delivered_date) {
            if (isDiscounted) {
                return SaleState.DISCOUNTED;
            }

            return SaleState.DELIVERED;
        }

        return SaleState.NONE;
    }

    isCarryOver(start, end) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        if (!this.sale_date || this.status == 'lost' || this.progress_state == ProgressState.REFUSED) {
            return false;
        }

        const carryOverRange = Dashboard.getCarryOverRange(start, end);
        const saleDate = new ActivixDate(this.sale_date, 'date');
        const deliveryDate = new ActivixDate(this.delivery_date);
        const deliveredDate = new ActivixDate(this.delivered_date);
        const deliverableDate = new ActivixDate(this.deliverable_date);

        return (
            saleDate.isBefore(start) &&
            (
                deliveryDate.isBetween(start, end) ||
                deliveredDate.isBetween(start, end) ||
                deliverableDate.isBetween(start, end) ||
                !this.delivery_date && !this.deliverable_date && saleDate.isBetween(carryOverRange.start, carryOverRange.end)

            )
        );
    }

    isUnsubscribedToAll() {
        return !!this.unsubscribe_all_date && new ActivixDate(this.unsubscribe_all_date).isFuture();
    }

    isUnsubscribedToEmail() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_email_date;
    }

    isUnsubscribedToCall() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_call_date;
    }

    isUnsubscribedToSms() {
        return this.isUnsubscribedToAll() || !!this.unsubscribe_sms_date;
    }

    isWaitingSale(start, end) {
        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        if (this.isForcedWaitingSale()) {
            return true;
        }

        const deliveryDate = new ActivixDate(this.delivery_date, 'date');
        const deliverableDate = new ActivixDate(this.deliverable_date, 'date');

        if (
            !this.account.enabledWaitingSale() ||
            this.status == 'lost' ||
            this.progress_state == ProgressState.REFUSED ||
            !this.sale_date ||
            this.delivered_date ||
            deliveryDate.isBetween(start, end) ||
            deliverableDate.isBetween(start, end)
        ) {
            return false;
        }

        const waitingSaleDate = new ActivixDate(this.account.waiting_sale_date, 'date');
        const saleDate = new ActivixDate(this.sale_date, 'date');

        return saleDate.isBefore(start) && saleDate.isSameOrAfter(waitingSaleDate);
    }

    isForcedWaitingSale() {
        return this.account.enabledWaitingSale() && this.waiting_sale && !this.delivered_date;
    }

    isCreatedBeforeServiceReachedRelease() {
        const serviceReleaseDate = new ActivixDate('2021-08-17 00:00');
        const leadCreationDate = new ActivixDate(this.created_at);

        return leadCreationDate.isBefore(serviceReleaseDate);
    }

    getResult() {
        if (
            this.isCreatedBeforeServiceReachedRelease() && this.division_id == Division.SERVICE && (this.appointment_date || this.appt_call_date) ||
            this.presented_date ||
            this.presented_other_date ||
            (this.sale_date && this.account.mark_sold_lead_as_reached) ||
            this.delivered_date
        ) {
            return LeadResult.REACHED;
        }

        if (this.communications.length) {
            let reached = false;
            let attempted = false;

            for (const communication of this.communications) {
                // If comunication more than 60 seconds
                if (
                    !!communication.url &&
                    communication.duration_call >= 60 &&
                    (!this.result || this.result == LeadResult.REACHED)
                ) {
                    return LeadResult.REACHED;
                }

                // If incoming communication
                if (
                    communication.communication_type_id == CommunicationType.INCOMING &&
                    (communication.communication_method_id == CommunicationMethod.EMAIL ||
                        communication.created_by_user)
                ) {
                    return LeadResult.REACHED;
                }

                // If created or updated by user and set to answered
                if (
                    communication.status == CommunicationStatus.ANSWERED &&
                    (communication.created_by_user || communication.updated_by_user)
                ) {
                    return LeadResult.REACHED;
                }

                // If SMS communication
                if (
                    communication.communication_method_id == CommunicationMethod.SMS &&
                    communication.status == CommunicationStatus.ANSWERED
                ) {
                    return LeadResult.REACHED;
                }

                // If Messenger communication
                if (
                    communication.communication_method_id == CommunicationMethod.MESSENGER &&
                    (communication.exchange_counter > 0 || communication.outbound_counter > 0) &&
                    communication.status != CommunicationStatus.ATTEMPTED
                ) {
                    return LeadResult.REACHED;
                }

                if (
                    communication.communication_method_id == CommunicationMethod.VIDEO &&
                    [CommunicationStatus.CALLING, CommunicationStatus.ANSWERED].includes(communication.status)
                ) {
                    return LeadResult.REACHED;
                }

                // If answered communication or lead is reached or communication has appointment (deprecated)
                if (
                    communication.status == CommunicationStatus.ANSWERED ||
                    this.result == LeadResult.REACHED ||
                    (this.communications[0] && this.communications[0].appointment)
                ) {
                    reached = true;
                }

                if (
                    this.result == LeadResult.ATTEMPTED ||
                    (communication.affect_lead_status && communication.status == CommunicationStatus.ATTEMPTED)
                ) {
                    attempted = true;
                }
            }

            if (this.isOfType(LeadType.WALK_IN) && this.created_by_user) {
                return LeadResult.REACHED;
            }

            if (reached && (this.result != LeadResult.ATTEMPTED || this.isResultSet())) {
                return LeadResult.REACHED;
            }

            if (attempted) {
                return LeadResult.ATTEMPTED;
            }

            return LeadResult.PENDING;
        }

        if (this.result == LeadResult.REACHED) {
            return LeadResult.REACHED;
        }

        if (this.result == LeadResult.ATTEMPTED) {
            return LeadResult.ATTEMPTED;
        }

        return LeadResult.PENDING;
    }

    isResultSet() {
        if (this.id == 0) {
            return false;
        }

        if (
            this.isCreatedBeforeServiceReachedRelease() && this.division_id == Division.SERVICE && (this.appointment_date || this.appt_call_date) ||
            this.presented_date ||
            this.presented_other_date ||
            this.sale_date ||
            this.delivered_date ||
            this.road_test_date ||
            this.home_road_test_date ||
            this.be_back_date ||
            this.take_over_date
        ) {
            return true;
        }

        for (const communication of this.communications || []) {
            if (communication.communication_type_id == CommunicationType.INCOMING) {
                return true;
            }

            if (
                communication.communication_type_id == CommunicationType.OUTGOING &&
                communication.communication_method_id == CommunicationMethod.PHONE &&
                communication.status == CommunicationStatus.ANSWERED &&
                !communication.created_by_user
            ) {
                return true;
            }

            if (
                communication.communication_method_id == CommunicationMethod.SMS &&
                communication.status == CommunicationStatus.ANSWERED
            ) {
                return true;
            }
        }

        return false;
    }

    hasDeliveryMan(userId) {
        if (!userId) {
            return false;
        }

        return this.task_events.some(task => {
            return task.task_event_type_id == TaskEventType.DELIVERY && this.deliveredById == userId;
        });
    }

    hasInvalidStatus() {
        return [LeadStatus.INVALID, LeadStatus.LOST, LeadStatus.DUPLICATE].includes(this.status);
    }

    fill(payload) {
        if (!payload) {
            return;
        }

        Object.keys(payload).forEach(key => {
            if (typeof this[key] !== 'undefined') {
                this[key] = payload[key];
            }
        });
    }

    fillCustomable(id, payload) {
        if (!payload) {
            return;
        }

        let leadVehicle = null;
        const value = typeof payload.value == 'string' ? [payload.value] : payload.value;
        let customField = this.custom_fields.find(customField => customField.id == id);

        if (payload.vehicleId) {
            leadVehicle = this.vehicles.find(leadVehicle => leadVehicle.id == payload.vehicleId);
            customField = leadVehicle?.custom_fields?.find(customField => customField.id == id);
        }

        if (customField) {
            customField.pivot.value = value;
        } else {
            const newCustomField = {
                id,
                name: payload.label,
                field: payload.field,
                pivot: {
                    value,
                },
            };

            if (leadVehicle) {
                leadVehicle.custom_fields.push(newCustomField);
            } else {
                this.custom_fields.push(newCustomField);
            }
        }
    }

    fillVehicle(id, payload) {
        const vehicle = this.vehicles.find(v => v.id == id);

        Object.keys(payload).forEach(key => {
            if (typeof vehicle[key] !== 'undefined') {
                vehicle[key] = payload[key];
            }
        });
    }

    getVehicleCustomFieldsById(customFieldId, clientCardSection = '') {
        let vehicleType = '';
        let vehicles = this.vehicles;

        if (ClientCardSection.isVehicleExchangeField(clientCardSection)) {
            vehicleType = LeadVehicleType.EXCHANGE;
        } else if (ClientCardSection.isVehicleWantedField(clientCardSection)) {
            vehicleType = LeadVehicleType.WANTED;
        }

        if (vehicleType) {
            vehicles = vehicles.filter(v => v.type === vehicleType);
        }

        if (vehicles.some(v => v.sold)) {
            vehicles = vehicles.filter(v => v.sold);
        }

        vehicles = vehicles.map(vehicle => {
            return { custom_fields: vehicle.custom_fields, vehicle };
        })
            .flat();

        return vehicles.filter(obj => obj?.custom_fields?.filter(customField => customField.id === customFieldId));
    }

    getCustomableById(customFieldId) {
        return this.custom_fields.find(customField => customField.id === customFieldId);
    }

    getCustomableVehicleById(customFieldId, clientCardSection) {
        const vehicles = this.getVehicleCustomFieldsById(customFieldId, clientCardSection);

        if (!vehicles.length) {
            return null;
        }

        return vehicles[0].custom_fields.find(customField => customField.id == customFieldId);
    }

    disabledBySaleVerified(authUser) {
        return authUser.id &&
            this.account.verified_sale &&
            this.hasVerifiedSoldVehicle();
    }

    allowCommunicationsWhenVerified(authUser) {
        return authUser.id &&
            this.account.verified_sale &&
            this.account.allow_communications_when_verified_sale;
    }

    get userId() {
        return this.user_id || this._user?.id || null;
    }

    set userId(userId) {
        this.user_id = userId;
    }

    get secondaryUserId() {
        return this.secondary_user_id || this._secondary_user?.id || null;
    }

    set secondaryUserId(userId) {
        this.secondary_user_id = userId;
    }

    get secondUserId() {
        return this.second_user_id || this._second_user?.id || null;
    }

    set secondUserId(userId) {
        this.second_user_id = userId;
    }

    get bdcUserId() {
        return this.bdc_user_id || this._bdc_user?.id || null;
    }

    set bdcUserId(userId) {
        this.bdc_user_id = userId;
    }

    get secondBdcUserId() {
        return this.second_bdc_user_id || this._second_bdc_user?.id || null;
    }

    set secondBdcUserId(userId) {
        this.second_bdc_user_id = userId;
    }

    get commercialId() {
        return this.commercial_id || this._commercial?.id || null;
    }

    set commercialId(userId) {
        this.commercial_id = userId;
    }

    get deliveredById() {
        return this.delivered_by_id || this._delivered_by?.id || null;
    }

    set deliveredById(userId) {
        this.delivered_by_id = userId;
    }

    get serviceAdvisorId() {
        return this.service_advisor_id || this._service_advisor?.id || null;
    }

    set serviceAdvisorId(userId) {
        this.service_advisor_id = userId;
    }

    get serviceAgentId() {
        return this.service_agent_id || this._service_agent?.id || null;
    }

    set serviceAgentId(userId) {
        this.service_agent_id = userId;
    }

    get statusUpdatedById() {
        return this.status_updated_by_id || this._status_updated_by?.id || null;
    }

    set statusUpdatedById(userId) {
        this.status_updated_by_id = userId;
    }

    get takeOverDirectorId() {
        return this.take_over_director_id || this._take_over_director?.id || null;
    }

    set takeOverDirectorId(userId) {
        this.take_over_director_id = userId;
    }

    get createdById() {
        return this.created_by || this._creator?.id || null;
    }

    set createdById(userId) {
        this.created_by = userId;
    }

    get updatedById() {
        return this.updated_by || this._updater?.id || null;
    }

    set updatedById(userId) {
        this.updated_by = userId;
    }

    async getUser(fields = []) {
        if (!this.userId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.userId, fields));
    }

    async getSecondaryUser(fields = []) {
        if (!this.secondaryUserId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.secondaryUserId, fields));
    }

    async getSecondUser(fields = []) {
        if (!this.secondUserId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.secondUserId, fields));
    }

    async getBdcUser(fields = []) {
        if (!this.bdcUserId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.bdcUserId, fields));
    }

    async getSecondBdcUser(fields = []) {
        if (!this.secondBdcUserId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.secondBdcUserId, fields));
    }

    async getCommercialUser(fields = []) {
        if (!this.commercialId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.commercialId, fields));
    }

    async getDeliveredByUser(fields = []) {
        if (!this.deliveredById) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.deliveredById, fields));
    }

    async getServiceAdvisorUser(fields = []) {
        if (!this.serviceAdvisorId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.serviceAdvisorId, fields));
    }

    async getServiceAgentUser(fields = []) {
        if (!this.serviceAgentId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.serviceAgentId, fields));
    }

    async getStatusUpdatedByUser(fields = []) {
        if (!this.statusUpdatedById) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.statusUpdatedById, fields));
    }

    async getTakeOverDirectorUser(fields = []) {
        if (!this.takeOverDirectorId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.takeOverDirectorId, fields));
    }

    async getCreatorUser(fields = []) {
        let userData = {
            first_name: 'Activix',
            last_name: 'CRM',
        };

        if (this.createdById) {
            userData = await UserRepository.findWithFields(this.createdById, fields);
        }

        return new User(userData);
    }

    async getUpdaterUser(fields = []) {
        let userData = {
            first_name: 'Activix',
            last_name: 'CRM',
        };

        if (this.updatedById) {
            userData = await UserRepository.findWithFields(this.updatedById, fields);
        }

        return new User(userData);
    }
}
