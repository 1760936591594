<template>
    <div class="sa">
        <component :is="icon" />
    </div>
</template>

<script>
    import ErrorIcon from './ErrorIcon.vue';
    import DangerIcon from './DangerIcon.vue';
    import SuccessIcon from './SuccessIcon.vue';
    import WarningIcon from './WarningIcon.vue';
    import InfoIcon from './InfoIcon.vue';

    export default {
        components: {
            ErrorIcon,
            DangerIcon,
            SuccessIcon,
            WarningIcon,
            InfoIcon,
        },

        props: {
            type: {
                type: String,
                required: true,
                validator: value => ['error', 'danger', 'warning', 'success', 'info'].includes(value),
            },
        },

        computed: {
            icon() {
                return `${this.type}-icon`;
            },
        },
    };
</script>

<style lang="less" scoped>
    .sa {
        width: 120px;
        height: 120px;
        padding: 16px;
        background-color: #fff;
    }
</style>
