export default [
    {
        path: '/automations',
        name: 'automations.index',
        component: () => import(/* webpackChunkName: "automation-list" */ '../views/Automations/List.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/automations/new',
        name: 'automations.store',
        component: () => import(/* webpackChunkName: "automation" */ '../views/Automations/Edit.vue'),
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
    {
        path: '/automations/:id',
        name: 'automations.update',
        component: () => import(/* webpackChunkName: "automation" */ '../views/Automations/Edit.vue'),
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
];
