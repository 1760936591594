export default [
    {
        path: '/analytics',
        name: 'analytics',
        component: () => import('../views/Analytics.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showGroups: true,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                team: true,
                settings: true,
            },
            layout: {
                contentHeader: false,
            },
            fixedContent: true,
            saveInUrl: ['accountId'],
        },
    },
];
