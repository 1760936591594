import Vue from 'vue';
import { capitalize } from 'lodash-es';
import { formatPhone } from '../utils/index.js';

/**
 * Capitalize a string usgin lodash/capitalize.
 * Example: hello POTATO => Hello potato
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('capitalize', value => {
    return !value ? '' : capitalize(value);
});

/**
 * Uppercase a string.
 * Example: hello POTATO => HELLO POTATO
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('uppercase', value => {
    return !value ? '' : value.toUpperCase();
});

/**
 * Lowercase a string.
 * Example: hello POTATO => hello potato
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('lowercase', value => {
    return !value ? '' : value.toLowerCase();
});

/**
 * Formats a phone number using https://github.com/catamphetamine/libphonenumber-js.
 * Example: +12345678901 => 234 567-8901
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('phone', value => {
    return !value ? '' : formatPhone(value);
});

/**
 * Formats a phone number using https://github.com/catamphetamine/libphonenumber-js.
 * Example: +12345678901 => 234 567-8901
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('phone', value => {
    return !value ? '' : formatPhone(value);
});

/**
 * Formats a phone number using https://github.com/catamphetamine/libphonenumber-js.
 * Example: +12345678901 => +1 234 567-8901
 *
 * @param {String} value
 * @return {String}
 */
Vue.filter('phoneWithCountry', value => {
    return !value ? '' : formatPhone(value, true);
});
