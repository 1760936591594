<template>
    <el-popover
        popper-class="max-w-full el-popper-notification-center"
        placement="bottom"
        trigger="click"
        :width="popoverWidth"
        v-model="showPopover"
    >
        <button
            class="btn-nav | md:mr-2"
            :class="{ 'px-3': mdLayout }"
            type="button"
            slot="reference"
            @mouseenter="fetchAllNotifications"
        >
            <template v-if="communications.notSeenCount">
                <icon :class="mdLayout ? 'text-2xl' : 'text-lg'" name="regular/wifi-signal-2" />
                <div class="badge | bg-blue-500">
                    {{ communications.notSeenCount }}
                </div>
            </template>
            <template v-else>
                <icon :class="mdLayout ? 'text-2xl' : 'text-lg'" name="regular/alarm-bell" />
                <div class="badge badge-empty | bg-blue-500" v-if="hasNotSeenNotifications" />
            </template>
        </button>

        <div class="flex border-b border-grey-200">
            <button
                type="button"
                class="flex items-center justify-center flex-1 p-3 text-grey-600 font-sm"
                :class="{ 'bg-grey-200 text-grey-700': activeTab == NotificationGroup.COMMUNICATIONS }"
                @click="activeTab = NotificationGroup.COMMUNICATIONS"
            >
                {{ $t('notificationCenter.communicationTitle') }}
                <div class="badge | bg-blue-500 ml-2" v-if="communications.notSeenCount">
                    {{ communications.notSeenCount }}
                </div>
            </button>
            <button
                type="button"
                class="flex items-center justify-center flex-1 p-3 text-grey-600 font-sm"
                :class="{ 'bg-grey-200 text-grey-700': activeTab == NotificationGroup.EVENTS }"
                @click="activeTab = NotificationGroup.EVENTS"
            >
                {{ $t('notificationCenter.eventTitle') }}
                <div class="badge | bg-blue-500 ml-2" v-if="events.notSeenCount">
                    {{ events.notSeenCount }}
                </div>
            </button>

            <button
                type="button"
                class="flex items-center justify-center flex-1 p-3 text-grey-600 font-sm"
                :class="{ 'bg-grey-200 text-grey-700': activeTab == NotificationGroup.ALERTS }"
                @click="activeTab = NotificationGroup.ALERTS"
            >
                {{ $t('notificationCenter.alertTitle') }}
                <div class="badge | bg-blue-500 ml-2" v-if="alerts.notSeenCount">
                    {{ alerts.notSeenCount }}
                </div>
            </button>
        </div>

        <!-- Communications -->
        <div class="notification-list" v-if="activeTab == NotificationGroup.COMMUNICATIONS">
            <div class="menu">
                <div
                    class="h-full flex items-center justify-center border-t border-grey-200"
                    v-if="!communicationNotifications.length"
                >
                    <div class="flex flex-col items-center">
                        <template v-if="$wait.is(`fetching.notifications.${NotificationGroup.COMMUNICATIONS}`)">
                            <activix-spinner :size="40" />
                        </template>
                        <template v-else>
                            <icon class="text-grey-600 text-4xl" name="regular/wifi-signal-2" />
                            <span class="mt-1" v-text="$t('notificationCenter.noCommunications')" />
                        </template>
                    </div>
                </div>
                <notification-item
                    :notification="notification"
                    :key="notification.id"
                    @seen="markAsSeen(notification)"
                    v-for="notification in communicationNotifications"
                />
            </div>
            <div
                class="text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200 cursor-pointer | hover:text-grey-800"
                @click.prevent="loadMoreCommunications"
                v-if="communications.nextPageUrl"
            >
                {{ $t('notificationCenter.viewMore') }}
            </div>
            <div
                :class="[
                    'text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200',
                    {
                        'cursor-not-allowed opacity-50': !showCommunicationAllAsReadButton,
                        'cursor-pointer hover:text-grey-800': showCommunicationAllAsReadButton,
                    },
                ]"
                :disabled="!showCommunicationAllAsReadButton"
                @click="markAllAsSeen(NotificationGroup.COMMUNICATIONS)"
            >
                {{ $t('notificationCenter.markAllAsRead') }}
            </div>
        </div>

        <!-- Events -->
        <div class="notification-list" v-if="activeTab == NotificationGroup.EVENTS">
            <div class="menu">
                <div
                    class="h-full flex items-center justify-center border-t border-grey-200"
                    v-if="!eventNotifications.length"
                >
                    <div class="flex flex-col items-center">
                        <template v-if="$wait.is(`fetching.notifications.${NotificationGroup.EVENTS}`)">
                            <activix-spinner :size="40" />
                        </template>
                        <template v-else>
                            <icon class="text-grey-600 text-4xl" name="regular/alarm-bell" />
                            <span class="mt-1" v-text="$t('notificationCenter.noEvents')" />
                        </template>
                    </div>
                </div>
                <notification-item
                    :notification="notification"
                    :key="notification.id"
                    @seen="markAsSeen(notification)"
                    v-for="notification in eventNotifications"
                />
            </div>
            <div
                class="text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200 cursor-pointer | hover:text-grey-800"
                @click.stop="loadMoreEvents"
                v-if="events.nextPageUrl"
            >
                {{ $t('notificationCenter.viewMore') }}
            </div>
            <div
                :class="[
                    'text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200',
                    {
                        'cursor-not-allowed opacity-50': !showEventsAllAsReadButton,
                        'cursor-pointer hover:text-grey-800': showEventsAllAsReadButton,
                    },
                ]"
                :disabled="!showEventsAllAsReadButton"
                @click="markAllAsSeen(NotificationGroup.EVENTS)"
            >
                {{ $t('notificationCenter.markAllAsRead') }}
            </div>
        </div>

        <!-- Alerts -->
        <div class="notification-list" v-if="activeTab == NotificationGroup.ALERTS">
            <div class="menu">
                <div
                    class="h-full flex items-center justify-center border-t border-grey-200"
                    v-if="!alertNotifications.length"
                >
                    <div class="flex flex-col items-center">
                        <template v-if="$wait.is(`fetching.notifications.${NotificationGroup.ALERTS}`)">
                            <activix-spinner :size="40" />
                        </template>
                        <template v-else>
                            <icon class="text-grey-600 text-4xl" name="regular/flag-plain-3" />
                            <span class="mt-1" v-text="$t('notificationCenter.noAlerts')" />
                        </template>
                    </div>
                </div>
                <notification-item
                    :notification="notification"
                    :key="notification.id"
                    @seen="markAsSeen(notification)"
                    v-for="notification in alertNotifications"
                />
            </div>
            <div
                class="text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200 cursor-pointer | hover:text-grey-800"
                @click.stop="loadMoreAlerts"
                v-if="alerts.nextPageUrl"
            >
                {{ $t('notificationCenter.viewMore') }}
            </div>
            <div
                :class="[
                    'text-grey-600 text-center py-2 px-4 text-sm border-t border-grey-200',
                    {
                        'cursor-not-allowed opacity-50': !showAlertsAllAsReadButton,
                        'cursor-pointer hover:text-grey-800': showAlertsAllAsReadButton,
                    },
                ]"
                :disabled="!showAlertsAllAsReadButton"
                @click="markAllAsSeen(NotificationGroup.ALERTS)"
            >
                {{ $t('notificationCenter.markAllAsRead') }}
            </div>
        </div>
    </el-popover>
</template>

<script>
    import { mapState } from 'pinia';
    import {
        NOTIFICATION_POPOVER_WIDTH_MAX,
        NOTIFICATION_POPOVER_WIDTH_TRESHOLD,
        NOTIFICATION_POPOVER_WIDTH_ADJUSTMENT,
    } from '../utils/layout.js';

    // Entities
    import NotificationType from '../entities/NotificationType.js';
    import NotificationSubType from '../entities/NotificationSubType.js';
    import Notification from '../entities/Notification.js';
    import NotificationGroup from '../entities/NotificationGroup.js';

    // Components
    import NotificationItem from './NotificationItem.vue';
    import { useGlobalStore } from '../store/store.js';
    import { useContextStore } from '../store/modules/context/store.js';

    export default {
        components: {
            NotificationItem,
        },

        data() {
            return {
                alreadyFetched: false,
                activeTab: 'communications',
                perPage: 50,
                communications: {
                    data: [],
                    nextPageUrl: null,
                    notSeenCount: 0,
                },
                events: {
                    data: [],
                    nextPageUrl: null,
                    notSeenCount: 0,
                },
                alerts: {
                    data: [],
                    nextPageUrl: null,
                    notSeenCount: 0,
                },
                showPopover: false,
                NotificationGroup,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'lead',
            }),
            ...mapState(useGlobalStore, ['authUser', 'configs']),

            communicationNotifications() {
                return this.communications.data
                    .map(notification => {
                        return new Notification(notification);
                    })
                    .sort((a, b) => b.id - a.id);
            },

            eventNotifications() {
                return this.events.data
                    .map(notification => {
                        return new Notification(notification);
                    })
                    .sort((a, b) => b.id - a.id);
            },

            alertNotifications() {
                return this.alerts.data
                    .map(notification => {
                        return new Notification(notification);
                    })
                    .sort((a, b) => b.id - a.id);
            },

            hasNotSeenNotifications() {
                return !!this.communications.notSeenCount || !!this.events.notSeenCount || !!this.alerts.notSeenCount;
            },

            showEventsAllAsReadButton() {
                return !!this.events.notSeenCount;
            },

            showCommunicationAllAsReadButton() {
                return !!this.communications.notSeenCount;
            },

            showAlertsAllAsReadButton() {
                return !!this.alerts.notSeenCount;
            },

            popoverWidth() {
                return window.innerWidth < NOTIFICATION_POPOVER_WIDTH_TRESHOLD
                    ? window.innerWidth - NOTIFICATION_POPOVER_WIDTH_ADJUSTMENT
                    : NOTIFICATION_POPOVER_WIDTH_MAX;
            },
        },

        watch: {
            'authUser.id': {
                immediate: true,
                handler() {
                    this.alreadyFetched = false;
                    this.fetchAllNotSeenCounts();
                },
            },

            '$route.path': {
                immediate: true,
                handler() {
                    if (this.showPopover && this.mdLayout) {
                        this.showPopover = false;
                    }
                },
            },
        },

        methods: {
            async fetchAllNotifications() {
                if (this.alreadyFetched) {
                    return;
                }

                this.alreadyFetched = true;

                await Promise.all([
                    this.fetchNotifications(NotificationGroup.ALERTS),
                    this.fetchNotifications(NotificationGroup.COMMUNICATIONS),
                    this.fetchNotifications(NotificationGroup.EVENTS),
                ]);
            },

            async fetchAllNotSeenCounts() {
                await Promise.all([
                    this.fetchNotSeenCount(NotificationGroup.ALERTS),
                    this.fetchNotSeenCount(NotificationGroup.COMMUNICATIONS),
                    this.fetchNotSeenCount(NotificationGroup.EVENTS),
                ]);
            },

            async fetchNotifications(group) {
                this.$wait.start(`fetching.notifications.${group}`);

                const notifications = await this.$api.notifications.index(group, { limit: this.perPage });

                this[group].nextPageUrl = notifications.links.next;
                this[group].data = notifications.data;

                this.$wait.end(`fetching.notifications.${group}`);
            },

            async fetchNotSeenCount(group) {
                const notSeenCount = await this.$api.notifications.notSeenCount(group);

                this[group].notSeenCount = notSeenCount;
            },

            async loadMoreCommunications() {
                const { data } = await this.$axios.get(this.communications.nextPageUrl);

                this.communications.data.push(...data.data.data);
                this.communications.nextPageUrl = data.data.links.next;
            },

            async loadMoreEvents() {
                const { data } = await this.$axios.get(this.events.nextPageUrl);

                this.events.data.push(...data.data.data);
                this.events.nextPageUrl = data.data.links.next;
            },

            async loadMoreAlerts() {
                const { data } = await this.$axios.get(this.alerts.nextPageUrl);

                this.alerts.data.push(...data.data.data);
                this.alerts.nextPageUrl = data.data.links.next;
            },

            markAsSeen(notification) {
                if (notification.seen_at) {
                    return;
                }

                const rawNotification = this[notification.group].data.find(rawNotification => {
                    return rawNotification.id == notification.id;
                });

                if (!rawNotification) {
                    return;
                }

                this[notification.group].notSeenCount--;
                this.markEmailCommunicationAsRead(rawNotification);
                rawNotification.seen_at = now().toString();

                this.$api.notifications.markAsSeen(notification.id);
            },

            markAllAsSeen(group) {
                if (!this[group].notSeenCount) {
                    return;
                }

                this[group].data.forEach(notification => {
                    this.markEmailCommunicationAsRead(notification);
                    notification.seen_at = now().toString();
                });

                this[group].notSeenCount = 0;

                this.$api.notifications.markAllAsSeen(group);
            },

            markEmailCommunicationAsRead(notification) {
                if (notification.options.communicationId && notification.user_id == this.authUser.id) {
                    useGlobalStore().userSeenEmailRead(notification.options.communicationId);
                }
            },

            onNotificationInTurn(data) {
                this.$notify.warning({
                    duration: -1,
                    title: this.$t('notificationCenter.inTurn'),
                    text: data.content,
                    data: {
                        icon: this.$icons.inTurn,
                    },
                });
            },

            onExportLeadError(data) {
                this.$notify.warning({
                    duration: -1,
                    title: this.$t('toastr.warning'),
                    text: this.$t(data.message, [data.supplierName, data.leadName]),
                });

                if (this.contextLead.id == data.leadId) {
                    let exportedSuppliers = this.contextLead.exported_suppliers;
                    exportedSuppliers = delete exportedSuppliers[data.supplierAccountId];

                    this.contextLead.exported_suppliers = exportedSuppliers;
                }
            },

            onExportLeadFromIntegrationsResult(data) {
                if (data.notificationType === 'export_success') {
                    const title = this.$t('notificationCenter.general.exportSuccessTitle', [data.supplierName]);
                    const text = this.$t('notificationCenter.general.exportSuccessText', { fullName: data.fullName });

                    this.$notify.success({
                        duration: -1,
                        title,
                        text: data.message ? data.message : text,
                    });
                } else if (data.notificationType === 'export_failure') {
                    const title = this.$t('notificationCenter.general.exportFailureTitle', [data.supplierName]);
                    const text = this.$t('notificationCenter.general.exportFailureText', { fullName: data.fullName });

                    this.$notify.warning({
                        duration: -1,
                        title,
                        text: data.message ? data.message : text,
                    });

                    this.$eventBus.$emit('export-lead-from-integrations-reset', data.supplierId);
                }
            },

            onNewNotification(notification) {
                if (!notification.screen) {
                    return;
                }

                notification = new Notification(notification);

                if (!NotificationGroup.exists(notification.group)) {
                    return;
                }

                let title = notification.title;
                let text = '';

                this[notification.group].data.unshift(notification);
                this[notification.group].notSeenCount++;

                if (notification.type.indexOf('Sent') !== -1) {
                    if (notification.options.advisor) {
                        title += ` ${this.$t('notificationCenter.to')} : ${notification.options.advisor}`;
                    }
                } else if (notification.type == 'automationCompleted') {
                    text = `
                        ${notification.options.title}<br>
                        ${this.$tc('automations.table.row.affectedClients', notification.options.affected_count, [
                            notification.options.affected_count,
                    ])}
                    `;
                } else if (notification.options.title) {
                    title = notification.options.title;
                } else if (notification.options.advisor) {
                    title += ` : ${notification.options.advisor}`;
                }

                if (notification.options.campaign) {
                    text += `${this.$t('notificationCenter.campaign')} : ${notification.options.campaign}`;
                }

                if (notification.options.dealer_name) {
                    text += `${this.$t('notificationCenter.dealer')} : ${notification.options.dealer_name}`;
                }

                const options = {
                    title,
                    text,
                    data: {
                        icon: notification.iconName,
                        notification,
                    },
                };

                if ([NotificationSubType.EMAIL, NotificationSubType.AUTOMATION_COMPLETED].includes(notification.type)) {
                    this.$notify.success(options);
                } else if (
                    [NotificationType.EVENT, NotificationType.TASK, NotificationType.OTHER].includes(
                        notification.category,
                    )
                ) {
                    this.$notify.warning({ ...options, duration: -1 });
                } else {
                    this.$notify.info(options);
                }
            },

            onNotificationSeen(notification) {
                const notificationObject = new Notification(notification);
                const existingNotification = this[notificationObject.group].data.find(
                    groupNotif => groupNotif.id == notificationObject.id,
                );

                if (!existingNotification || existingNotification.seen_at) {
                    return;
                }

                existingNotification.seen_at = now().toString();
                this[notificationObject.group].notSeenCount--;
            },

            onNotificationGroupSeen(group) {
                this[group].notSeenCount = 0;

                this[group].data.forEach(notification => {
                    notification.seen_at = now().toString();
                });
            },
        },

        created() {
            this.$eventBus.$on('notification-in-turn', this.onNotificationInTurn);
            this.$eventBus.$on('export-lead-error', this.onExportLeadError);
            this.$eventBus.$on('new-notification', this.onNewNotification);
            this.$eventBus.$on('notification-seen', this.onNotificationSeen);
            this.$eventBus.$on('notification-group-seen', this.onNotificationGroupSeen);
            this.$eventBus.$on('export-lead-from-integrations-result', this.onExportLeadFromIntegrationsResult);
        },

        beforeDestroy() {
            this.$eventBus.$off('notification-in-turn', this.onNotificationInTurn);
            this.$eventBus.$off('export-lead-error', this.onExportLeadError);
            this.$eventBus.$off('new-notification', this.onNewNotification);
            this.$eventBus.$off('notification-seen', this.onNotificationSeen);
            this.$eventBus.$off('notification-group-seen', this.onNotificationGroupSeen);
            this.$eventBus.$off('export-lead-from-integrations-result', this.onExportLeadFromIntegrationsResult);
        },
    };
</script>

<style lang="less">
    @import '../assets/less/variables.less';

    .notification-list {
        .menu {
            @apply overflow-x-hidden -mt-px;

            max-height: 120px;
            height: 120px !important;

            @media (min-height: 568px) {
                max-height: 400px;
                height: 400px !important;
            }

            .notification-wrapper {
                @apply relative whitespace-nowrap border-b border-l-3 border-grey-200 p-4 flex items-center;

                border-left-color: theme('colors.transparent');

                .subject {
                    @apply truncate text-sm text-grey-600;
                }

                .from {
                    @apply text-grey-800;
                    margin: 0 0 0 30px;
                    padding: 0;
                    font-size: 15px;
                    font-weight: bold;
                    position: relative;
                }

                &.notseen {
                    @apply bg-grey-200 border-grey-300;

                    border-left-color: theme('colors.blue.500');
                }

                &:first-child {
                    @apply border-t;
                }

                &:last-child {
                    @apply border-b-0;
                }
            }
        }
    }
</style>
