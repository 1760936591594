export default {
    in_stock: 'In stock',
    available: 'Available',
    unavailable: 'Unavailable',
    order: 'Ordered',
    reserved: 'Reserved',
    locate: 'Locate',
    invoice: 'Invoiced',
    production: 'In production',
    transit: 'In transit',
    hold: 'On hold',
    sold: 'Sold',
    tbd: 'Stock TBD',
};
