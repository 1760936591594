import Vue from 'vue';
import { stringify } from 'qs';
import Axios from 'axios';
import AxiosRetry from 'axios-retry';
import { showNetworkDisconnected } from '../utils/toastr.js';

const networkError = {
    attemps: 0,
    shown: false,
};

const cancelManagers = {};

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        common: {
            Accept: 'application/json, text/plain, */*',
        },
    },
    paramsSerializer: {
        serialize(params) {
            return stringify(params, {
                arrayFormat: 'brackets',
                encodeValuesOnly: true,
            });
        },
    },
});

axios.interceptors.request.use(
    async config => {
        if (config.once) {
            const cancelKey = typeof config.once === 'string' ? config.once : config.url;

            if (cancelManagers[cancelKey]) {
                cancelManagers[cancelKey].cancel('Only one request allowed at a time.');
            }

            cancelManagers[cancelKey] = Axios.CancelToken.source();

            config.cancelToken = cancelManagers[cancelKey].token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    response => {
        networkError.attemps = 0;
        networkError.shown = false;

        return Promise.resolve(response);
    },
    async error => {
        if (isNetworkDisconnectedError(error)) {
            networkError.attemps++;

            if (networkError.attemps > 3 && !networkError.shown) {
                networkError.attemps = 0;
                networkError.shown = true;
                appendDisconnectedNetworkError({ error, display: true });
            }
        }

        if (error.response) {
            networkError.shown = false;

            // Not authorized
            if (error.response.status === 401) {
                try {
                    await Vue.auth.handleRefreshToken();

                    error.config.headers.Authorization = `Bearer ${await Vue.auth.token()}`;

                    return axios.request(error.config);
                } catch (error) {
                    return Promise.reject(error);
                }
            }

            if (error.response.status === 409) {
                Vue.router.push({ name: 'auth.error' });
            }

            if (isForbiddenAccessError(error)) {
                Vue.router.push({ name: 'forbidden' });
            }

            // Maintenance mode
            if (error.response.status === 503) {
                Vue.eventBus.$emit('set-maintenance-mode', true);
            }
        }

        return Promise.reject(error);
    },
);

const isNetworkDisconnectedError = error => {
    return !error.response && error.message.includes('Network Error') && AxiosRetry.isRetryableError(error);
};

const isForbiddenAccessError = error => {
    return error.response.status === 403 && error.response?.data?.message?.includes('Access Denied');
};

const appendDisconnectedNetworkError = options => {
    const defaults = {
        code: '999',
        display: false,
        error: null,
        online: navigator && navigator.onLine,
    };

    const { display } = { ...defaults, ...options };

    if (display) {
        showNetworkDisconnected();
    }
};

AxiosRetry(axios, {
    retries: 3,
    retryDelay: retryCount => {
        return 2 ** retryCount * 400;
    },
    retryCondition: error => {
        if (error.response?.status === 503) {
            return false;
        }

        return AxiosRetry.isNetworkOrIdempotentRequestError(error) || isNetworkDisconnectedError(error);
    },
});

export { isNetworkDisconnectedError, isForbiddenAccessError };

export const getOnce = async (url, payload) => {
    try {
        const response = await axios.get(url, {
            once: true,
            params: payload,
        });

        return response.data;
    } catch (error) {
        if (Axios.isCancel(error)) {
            return null;
        }

        throw error;
    }
};

export const postOnce = async (url, payload) => {
    try {
        Object.keys(payload).forEach((key) => {
            if (Array.isArray(payload[key]) && payload[key].length === 0) {
                payload[key] = undefined;
            }
        });
        const response = await axios.post(url, payload, {
            once: true,
        });

        return response.data;
    } catch (error) {
        if (Axios.isCancel(error)) {
            return null;
        }

        throw error;
    }
};

export default axios;
