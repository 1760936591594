<template>
    <svg
        width="3440"
        height="497"
        viewBox="0 0 3440 497"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="h-full"
    >
        <path d="M296 44C282 7 259 0 230 0C201 0 179 7 166 44C157.347 68.6266 102.179 218.845 0.496088 494.654C0.305044 495.172 0.57025 495.747 1.08844 495.938C1.1992 495.979 1.31631 496 1.43435 496H81.4888C87.7987 496 93.4339 492.051 95.5878 486.12L229.081 118.531C229.269 118.012 229.843 117.744 230.362 117.932C230.636 118.032 230.852 118.245 230.956 118.517L311.638 331.046C311.834 331.562 312.411 331.822 312.927 331.626C312.934 331.623 312.94 331.621 312.947 331.618L364.564 310.79C365.076 310.583 365.659 310.831 365.866 311.343C366.014 311.712 365.931 312.133 365.653 312.417L322.301 356.672C321.504 357.485 321.237 358.68 321.611 359.756L365.497 485.928C367.594 491.958 373.279 496 379.664 496H458.575C459.127 496 459.575 495.552 459.575 495C459.575 494.884 459.555 494.769 459.516 494.66C359.823 218.847 305.318 68.6268 296 44Z" fill="white" />
        <path d="M336.778 323.171L162.053 495.16C161.66 495.547 161.027 495.542 160.639 495.149C160.597 495.106 160.559 495.06 160.526 495.01L100.329 406.671C100.018 406.214 100.136 405.592 100.593 405.281C100.663 405.233 100.74 405.194 100.82 405.165L335.742 321.516C336.262 321.331 336.834 321.603 337.019 322.123C337.15 322.489 337.056 322.898 336.778 323.171Z" fill="white" />
        <transition
            enter-active-class="transition duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-300"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <g v-if="!iconOnly">
                <path d="M621 180C573 180 525 208 525 300C525 392 573 420 621 420C657.806 420 693.317 410.928 727.531 392.785C728.019 392.526 728.625 392.712 728.883 393.2C728.96 393.344 729 393.505 729 393.668V472C694.6 488.8 655 496.8 617 496C559 496 509 468 485 436C461 404 441 344.4 441 300C441 246 457 192 485 160C513 128 561.8 104 617 104C657.4 104 691.4 112.8 729 128C729 128 729 154.111 729 206.332C729 206.884 728.552 207.332 728 207.332C727.837 207.332 727.676 207.292 727.531 207.215C693.317 189.072 657.806 180 621 180Z" fill="white" />
                <path d="M889 376C889 407.6 905.2 420 932 420C950.073 420 965.959 417.5 979.657 412.501C980.176 412.311 980.75 412.579 980.939 413.097C980.979 413.207 981 413.323 981 413.44C981 451.92 981 475.44 981 484C957 496 926 496 917 496C830.2 496 801 454.8 801 368V208H761V128H801V59C801 50.7157 807.716 44 816 44H889V128H966C974.284 128 981 134.716 981 143V208H889V376Z" fill="white" />
                <path d="M1086 88H1013V15C1013 6.71573 1019.72 0 1028 0H1101V73C1101 81.2843 1094.28 88 1086 88ZM1086 496H1013V143C1013 134.716 1019.72 128 1028 128H1101V481C1101 489.284 1094.28 496 1086 496Z" fill="white" />
                <path d="M1214.4 128H1134.41C1133.86 128 1133.41 128.448 1133.41 129C1133.41 129.113 1133.43 129.225 1133.47 129.332L1249 458C1258.2 482.4 1282.6 496 1309.8 496C1325.4 495.6 1338.2 489.2 1349 478L1228.54 137.991C1226.42 132.002 1220.75 128 1214.4 128Z" fill="white" />
                <path d="M1483.61 128H1411.87C1405.38 128 1399.63 132.166 1397.61 138.328L1323.59 364.145C1322.56 367.293 1322.6 370.694 1323.7 373.817L1357 468C1366.98 466.669 1409.5 353.775 1484.56 129.317C1484.73 128.793 1484.45 128.227 1483.93 128.052C1483.83 128.017 1483.72 128 1483.61 128Z" fill="white" />
                <path d="M1590 88H1517V15C1517 6.71573 1523.72 0 1532 0H1605V73C1605 81.2843 1598.28 88 1590 88ZM1590 496H1517V143C1517 134.716 1523.72 128 1532 128H1605V481C1605 489.284 1598.28 496 1590 496Z" fill="white" />
                <path d="M1638.91 128H1729.05C1734.02 128 1738.67 130.463 1741.46 134.577L1812.17 238.781C1812.48 239.238 1813.1 239.357 1813.56 239.047C1813.67 238.976 1813.76 238.885 1813.83 238.781L1884.54 134.577C1887.33 130.463 1891.98 128 1896.95 128H1987.09C1987.64 128 1988.09 128.448 1988.09 129C1988.09 129.204 1988.02 129.403 1987.91 129.571L1866.96 303.434C1863.38 308.583 1863.38 315.417 1866.96 320.566L1987.91 494.429C1988.22 494.882 1988.11 495.506 1987.66 495.821C1987.49 495.937 1987.29 496 1987.09 496H1896.95C1891.98 496 1887.33 493.537 1884.54 489.423L1813.83 385.219C1813.52 384.762 1812.9 384.643 1812.44 384.953C1812.33 385.024 1812.24 385.115 1812.17 385.219L1741.46 489.423C1738.67 493.537 1734.02 496 1729.05 496H1638.91C1638.36 496 1637.91 495.552 1637.91 495C1637.91 494.796 1637.98 494.597 1638.09 494.429L1759.04 320.566C1762.62 315.417 1762.62 308.583 1759.04 303.434L1638.09 129.571C1637.78 129.118 1637.89 128.494 1638.34 128.179C1638.51 128.062 1638.71 128 1638.91 128Z" fill="white" />
                <path d="M2892.18 496.002H2791.21C2785.99 496.002 2781.14 493.283 2778.41 488.825L2665.2 303.596H2605.6L2605.97 480.971C2605.99 489.255 2599.28 495.985 2591 496.002C2590.99 496.002 2590.98 496.002 2590.97 496.002H2514.4L2514 93.2197C2514.4 42.1228 2556.4 0.606467 2607.6 1.00566H2669.2C2710 2.20325 2747.2 0.207272 2785.2 18.5702C2834 42.1228 2858 97.2117 2858 149.905C2857.2 199.805 2839.6 247.309 2795.6 275.253C2785.6 281.64 2774.4 286.829 2763.2 290.023L2893.02 494.466C2893.32 494.932 2893.18 495.55 2892.72 495.846C2892.56 495.948 2892.37 496.002 2892.18 496.002ZM2605.6 222.559H2676C2719.6 222.559 2764 205.793 2764 153.498C2764 99.2077 2736 81.6431 2671.6 81.6431H2626.4C2614.4 82.4415 2605.2 92.0222 2605.6 103.998V222.559Z" fill="white" />
                <path d="M2477.98 25.6692V111.891C2477.98 112.443 2477.53 112.891 2476.98 112.891C2476.84 112.891 2476.7 112.862 2476.57 112.806C2436.14 95.0136 2394.52 85.7843 2351.72 85.118C2286.76 84.107 2209.07 130.412 2209.27 247.894C2209.47 365.376 2290 411.277 2353.33 411.48C2395.09 411.613 2436.84 402.518 2478.6 384.194C2479.1 383.972 2479.69 384.202 2479.92 384.708C2479.97 384.834 2480 384.971 2480 385.109V470.524C2436.7 486.296 2399.87 495.193 2353.74 496.002C2293.44 496.002 2235.17 479.421 2191.87 436.958C2138.45 386.406 2117 317.656 2117 245.67C2117 185.413 2136.42 121.515 2178.92 72.9856C2223.43 22.8383 2283.73 1 2351.72 1C2398.25 1.40441 2435.48 10.3015 2477.98 25.6692Z" fill="white" />
                <path d="M3071.12 51.261L3173.75 361.306C3173.92 361.831 3174.49 362.115 3175.01 361.942C3175.32 361.841 3175.55 361.601 3175.65 361.296L3274.6 51.261C3284.5 19.9644 3306.79 3.08076 3340.22 1.84536C3350.95 1.84536 3362.09 4.31615 3372 8.84592L3322.89 166.564L3225.69 485.376C3223.77 491.689 3217.94 496.002 3211.34 496.002H3140.73C3134.32 496.002 3128.61 491.918 3126.54 485.842L3021.62 178.062C3021.44 177.539 3020.87 177.26 3020.35 177.438C3019.96 177.57 3019.69 177.924 3019.67 178.332L3003.75 481.788C3003.33 489.756 2996.75 496.002 2988.77 496.002H2919.05C2918.5 496.002 2918.05 495.554 2918.05 495.002C2918.05 494.985 2918.05 494.968 2918.05 494.951L2940.29 64.0267C2940.7 29.8476 2967.94 2.25716 3001.78 1.02177C3034.8 0.198176 3061.22 22.847 3071.12 51.261ZM3382.89 12.9759C3402.61 22.8587 3415.76 42.6245 3417.81 68.9789L3439.95 494.95C3439.97 495.502 3439.55 495.972 3439 496.001L3438.95 496.002H3362.34C3354.3 496.002 3347.7 489.673 3347.35 481.646L3334.01 171.275C3334 171.161 3334.02 171.046 3334.05 170.937L3382.89 12.9759Z" fill="white" />
            </g>
        </transition>
    </svg>
</template>

<script>
    export default {
        props: {
            iconOnly: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
