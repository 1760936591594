import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/inventory-units', {
            params: payload,
        });

        return response.data;
    },

    async getCards(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/inventory-units/cards', {
            params: payload,
        });

        return response.data;
    },

    async getFilters(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/inventory-units/filters', {
            params: payload,
        });

        return response.data;
    },

    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.put(`v1/inventory-units/${id}`, payload);

        return response.data;
    },
};
