<template>
    <div class="relative flex items-center" data-v-step="announcement">
        <icon
            class="absolute right-0 top-0 z-10"
            :class="[currentStatusColor, { 'availability-status': !mdLayout }]"
            name="bold/sign-badge-circle"
            scale="0.5"
        />

        <el-dropdown
            class="overflow-hidden"
            trigger="click"
            placement="bottom-start"
            @command="onDropdownCommand"
        >
            <activix-tooltip placement="bottom" :content="userTooltip">
                <div class="flex items-center group">
                    <activix-avatar
                        class="rounded-full shadow-inner text-gray-650"
                        :class="mdLayout ? 'text-xl' : 'text-2xl'"
                        :size="mdLayout ? 28 : 35"
                        :username="authUser.fullName"
                        :src="authUser.hasProfilePicture() ? authUser.profile_picture : ''"
                    />
                    <mq-layout mq="lg+">
                        <icon class="text-grey-600 ml-3 text-xs | group-hover:text-grey-700" :name="$icons.expand" />
                    </mq-layout>
                </div>
            </activix-tooltip>
            <el-dropdown-menu slot="dropdown">
                <router-link :to="profileUrl" v-if="!mdLayout">
                    <el-dropdown-item>
                        <div class="flex items-center">
                            <icon class="mr-2 text-grey-500" name="regular/single-neutral-actions" />
                            {{ $t('navs.userDropdown.myProfile') }}
                        </div>
                    </el-dropdown-item>
                </router-link>
                <router-link :to="accountUrl" v-if="!mdLayout && authUser.hasAccessTo('accounts.update')">
                    <el-dropdown-item>
                        <div class="flex items-center">
                            <icon class="mr-2 text-grey-500" name="regular/shop" />
                            {{ $t('navs.userDropdown.myAccount') }}
                        </div>
                    </el-dropdown-item>
                </router-link>

                <router-link :to="{ name: 'groups.index' }" v-if="!mdLayout && authUser.hasAccessTo('groups.index')">
                    <el-dropdown-item divided>
                        <div class="flex items-center">
                            <icon class="mr-2 text-grey-500" name="regular/color-palette" />
                            {{ $t('navs.userDropdown.groups') }}
                        </div>
                    </el-dropdown-item>
                </router-link>
                <router-link
                    :to="{ name: 'accounts.index' }"
                    v-if="!mdLayout && authUser.hasAccessTo('accounts.index')"
                >
                    <el-dropdown-item>
                        <div class="flex items-center">
                            <icon class="mr-2 text-grey-500" name="regular/shop" />
                            {{ $t('navs.userDropdown.accounts') }}
                        </div>
                    </el-dropdown-item>
                </router-link>
                <router-link :to="{ name: 'users.index' }" v-if="!mdLayout && authUser.hasAccessTo('users.index')">
                    <el-dropdown-item
                        :divided="!authUser.hasAccessTo('groups.index') && !!authUser.hasAccessTo('accounts.index')"
                    >
                        <div class="flex items-center">
                            <icon class="mr-2 text-grey-500" name="regular/multiple-neutral-2" />
                            {{ $t('navs.userDropdown.users') }}
                        </div>
                    </el-dropdown-item>
                </router-link>

                <el-dropdown-item command="online" :divided="!mdLayout">
                    <div class="flex items-center">
                        <icon class="mr-2 text-green-500" name="bold/sign-badge-circle" scale="0.6" />
                        {{ $t('schedule.availability.schedule') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="offlineToday">
                    <div class="flex items-center">
                        <icon class="mr-2 text-orange-500" name="bold/sign-badge-circle" scale="0.6" />
                        {{ $t('schedule.availability.offlineToday') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="offlineForever">
                    <div class="flex items-center">
                        <icon class="mr-2 text-red-500" name="bold/sign-badge-circle" scale="0.6" />
                        {{ $t('schedule.availability.offlineForever') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="openActivixExp" divided>
                    <div class="flex items-center group">
                        <icon class="mr-2 text-grey-500" name="regular/book-open-2" />
                        {{ $t('navs.userDropdown.training') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="openFreshdesk">
                    <div class="flex items-center group">
                        <icon class="mr-2 text-grey-500" name="regular/question-circle" />
                        {{ $t('navs.userDropdown.help') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="toggleLocale">
                    <div class="flex items-center group">
                        <icon class="mr-2 text-grey-500" name="regular/earth-1" />
                        {{ $i18n.locale == 'en' ? 'Français' : 'English' }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="openFeedbackModal">
                    <div class="flex items-center">
                        <icon class="mr-2 text-sm" name="regular/messages-bubble-square-information" />
                        {{ $t('navs.userDropdown.suggestions') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="openChangeLog">
                    <div class="flex items-center">
                        <icon class="mr-2 text-sm" name="regular/megaphone-1" />
                        {{ $t('navs.userDropdown.changelog') }}
                    </div>
                </el-dropdown-item>
                <el-dropdown-item command="logout" divided>
                    <div class="flex items-center group">
                        <icon class="mr-2 text-grey-500" name="regular/logout-1" />
                        {{ $t('navs.userDropdown.disconnect') }}
                    </div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <activix-confirm-modal
            type="info"
            :title="$t('navs.userDropdown.turnOffNotifications')"
            :content="$t('navs.userDropdown.turnOffNotificationsDetails')"
            :opened.sync="doNotDisturbModalOpened"
            @approve="activateDoNotDisturb()"
            @deny="deactivateDoNotDisturb()"
        />

    </div>
</template>

<script>
    import { camelCase } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import FreshdeskUrl from '../../mixins/FreshdeskUrl.js';
    import ActivixExpSSO from '../../mixins/ActivixExpSSO.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        mixins: [FreshdeskUrl, ActivixExpSSO],

        data() {
            return {
                doNotDisturbModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            userTooltip() {
                return `
                    <div class="font-semibold">${this.authUser.fullName}</div>
                    ${this.authUser.role}
                `;
            },

            profileUrl() {
                return {
                    name: 'users.update',
                    params: { id: this.authUser.id },
                };
            },

            accountUrl() {
                return {
                    name: 'accounts.update',
                    params: { id: this.authUser.account_id },
                };
            },

            currentStatusColor() {
                switch (this.currentStatus) {
                    case 'offlineToday':
                        return 'text-orange-500';
                    case 'offlineForever':
                        return 'text-red-500';
                    default:
                        return 'text-green-500';
                }
            },

            currentStatus() {
                return camelCase(this.authUser.availability_status);
            },

            showReturnToOldVersion() {
                return process.env.VUE_APP_ENV !== 'production';
            },

            changeLogUrl() {
                if (this.$i18n.locale == 'en') {
                    return process.env.VUE_APP_CHANGELOG_EN;
                }

                return process.env.VUE_APP_CHANGELOG_FR;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateParentAuthUser']),

            logout() {
                this.$eventBus.$emit('logout');
            },

            online() {
                this.changeStatus('schedule');
            },

            offlineForever() {
                this.changeStatus('offline_forever');
                this.doNotDisturbModalOpened = true;
            },

            offlineToday() {
                this.changeStatus('offline_today');
                this.doNotDisturbModalOpened = true;
            },

            toggleLocale() {
                const newLocale = this.$i18n.locale == 'en' ? 'fr' : 'en';

                this.$eventBus.$emit('set-locale', newLocale);
            },

            openFeedbackModal() {
                this.$eventBus.$emit('open-feedback-modal');
            },

            activateDoNotDisturb() {
                this.updateParentAuthUser({ do_not_disturb: true }, true);
                this.$api.users.update(this.authUser.id, {
                    do_not_disturb: true,
                });
            },

            deactivateDoNotDisturb() {
                if (this.authUser.do_not_disturb) {
                    this.updateParentAuthUser({ do_not_disturb: false }, true);
                    this.$api.users.update(this.authUser.id, {
                        do_not_disturb: false,
                    });
                    this.$notify.info(this.$t('navs.userDropdown.turningOnNotifications'));
                }
            },

            async changeStatus(status) {
                const oldStatus = this.authUser.availability_status;

                this.updateParentAuthUser({ availability_status: status }, true);
                if (status === 'schedule') {
                    this.deactivateDoNotDisturb();
                }
                try {
                    await this.$api.users.update(this.authUser.id, {
                        availability_status: status,
                    });
                } catch (error) {
                    this.updateParentAuthUser({ availability_status: oldStatus }, true);

                    this.$notify.error(this.$t('users.alerts.updateStatus.error'));

                    throw error;
                }
            },

            openChangeLog() {
                this.$beamer.show();
            },

            onDropdownCommand(command) {
                if (command) {
                    this[command]();
                }
            },
        },
    };
</script>

<style lang="less">
    .availability-status {
        margin-right: 21px;
    }
</style>
