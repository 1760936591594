import Enum from './Enum.js';

export default class EmailValidationResult extends Enum {
    static get entries() {
        return {
            deliverable: 'deliverable',
            undeliverable: 'undeliverable',
            catchAll: 'catch_all',
            unknown: 'unknown',
            permanentFail: 'permanent_fail',
        };
    }

    static get DELIVERABLE() {
        return this.entries.deliverable;
    }

    static get UNDELIVERABLE() {
        return this.entries.undeliverable;
    }

    static get CATCH_ALL() {
        return this.entries.catchAll;
    }

    static get UNKNOWN() {
        return this.entries.unknown;
    }

    static get PERMANENT_FAIL() {
        return this.entries.permanentFail;
    }
}
