<template>
    <activix-helper-notification :title="title" :description="description" ref="notification">
        <template #avatar>
            <div class="h-12 w-12 rounded-full shadow-md bg-grey-500 flex items-center justify-center">
                <icon class="text-white text-2xl" :name="$icons.camera" />
            </div>
        </template>
        <template #buttons>
            <activix-button
                tag="a"
                target="_blank"
                :href="roomUrl"
                class="leading-5"
                type="primary"
                @click="onJoinClick"
            >
                {{ $t('screenPop.video.joinConferenceCall') }}
            </activix-button>
            <activix-button
                class="leading-5"
                tag="router-link"
                :to="leadLink"
                @click="onShowLeadClick"
            >
                {{ $t('screenPop.goToLead') }}
            </activix-button>
        </template>
    </activix-helper-notification>
</template>

<script>
    import Lead from '@/entities/Lead.js';
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    const CLIENT_JOINED = 'client_joined';
    const ONGOING = 'ongoing';

    export default {
        data: () => ({
            lead: new Lead(),
            roomUrl: '',
            roomSid: '',
            type: '',
        }),

        computed: {
            title() {
                if (!this.type) {
                    return '';
                }

                return {
                    [ONGOING]: this.$t('screenPop.video.ongoing.title'),
                    [CLIENT_JOINED]: this.$t('screenPop.video.clientJoined.title'),
                }[this.type];
            },

            description() {
                if (!this.type) {
                    return '';
                }

                const leadName = this.lead.fullName || this.$t('general.unknown');

                return {
                    [ONGOING]: this.$t('screenPop.video.ongoing.description', [leadName]),
                    [CLIENT_JOINED]: this.$t('screenPop.video.clientJoined.description', [leadName]),
                }[this.type];
            },

            leadLink() {
                return {
                    name: 'leads.update',
                    params: { id: this.lead.id },
                };
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            async open({ leadId, roomUrl, roomSid, type }) {
                if (this.roomSid === roomSid && this.type === ONGOING) {
                    return;
                }

                this.lead = await this.fetchLead(leadId);
                this.roomUrl = roomUrl;
                this.roomSid = roomSid;
                this.type = type;
                this.$refs.notification.open();

                this.$behavior.track('VideoConference', { action: 'show', location: 'screenpop' });
            },

            onJoinClick() {
                this.$behavior.track('VideoConference', { action: 'join', location: 'screenpop' });
            },

            onShowLeadClick() {
                this.$behavior.track('VideoConference', { action: 'show-lead', location: 'screenpop' });
            },

            close() {
                this.$refs.notification.close();

                this.$nextTick(() => {
                    this.$reset();
                });
            },
        },

        created() {
            this.$eventBus.$on('screen-pop-video-conference-open', this.open);
            this.$eventBus.$on('screen-pop-video-conference-close', this.close);
        },

        beforeDestroy() {
            this.$eventBus.$off('screen-pop-video-conference-open', this.open);
            this.$eventBus.$off('screen-pop-video-conference-close', this.close);
        },
    };
</script>
