import Vue from 'vue';
import { get, orderBy } from 'lodash-es';
import i18n from '../plugins/vue-i18n.js';
import moment from '../plugins/moment.js';

import ActivixDate from '../value-objects/ActivixDate.js';
import Account from '../entities/Account.js';
import LeadType from '../entities/LeadType.js';
import CreatedMethod from '../entities/CreatedMethod.js';
import CommunicationMethod from '../entities/CommunicationMethod.js';
import CommunicationType from '../entities/CommunicationType.js';
import { useContextStore } from './modules/context/store.js';

export default {
    timezones() {
        const timezones = this.configs.timezones.map(timezone => {
            const offset = moment.tz(timezone).format('Z');

            return {
                value: timezone,
                text: `(GMT${offset}) ${timezone}`,
            };
        });

        return orderBy(timezones, 'text');
    },

    groupDisplay() {
        return !empty(useContextStore().group.id) || get(useContextStore().account, 'account_manager', false);
    },

    authUser() {
        const childUser = this.parentAuthUser.childUserForAccount(useContextStore().account.id);

        return childUser || this.parentAuthUser;
    },

    childAccount() {
        const lead = useContextStore().contextLead;
        let account = useContextStore().account;

        if (lead.account_id && useContextStore().account.id && lead.account_id != useContextStore().account.id) {
            account = useContextStore().account.children.find(c => c.id == lead.account_id) || useContextStore().account;
        }

        if (account.id) {
            return new Account(account);
        }

        return new Account(this.authUser.account);
    },

    sources() {
        const sources = [
            {
                id: null,
                name: i18n.t('general.none'),
            },
        ];

        if (empty(useContextStore().account.sources)) {
            return sources;
        }

        for (const source of useContextStore().account.sources) {
            source.name = source.name.trim();
            sources.push(source);
        }

        if (!empty(sources)) {
            // Sort
            sources.sort((a, b) => a.name.localeCompare(b.name));
        }

        return sources;
    },

    sourcesWithProvider() {
        const sources = [
            {
                id: null,
                name: i18n.t('general.none'),
            },
        ];

        if (empty(useContextStore().account.sourcesWithProvider)) {
            return sources;
        }

        for (const source of useContextStore().account.sourcesWithProvider) {
            source.name = source.name.trim();
            sources.push(source);
        }

        if (!empty(sources)) {
            // Sort
            sources.sort((a, b) => a.name.localeCompare(b.name));
        }

        return sources;
    },

    guest() {
        const user = this.authUser;
        const lead = useContextStore().contextLead;

        if (typeof Vue.router === 'undefined' || empty(Vue.router.app) || Vue.router.app.$route.name != 'leads.update') {
            return false;
        }

        if (user.isAdmin()) {
            return false;
        }

        if (!user.hasAccessToEditAccount(lead.account_id)) {
            return true;
        }

        const releaseDate = new ActivixDate('2021-05-01 00:00');
        const leadCreationDate = new ActivixDate(lead.created_at);

        if (lead.lead_type_id === LeadType.EVENT && leadCreationDate.isAfter(releaseDate)) {
            const nonEventLeads = lead.customer.leads.filter(customerLead => {
                return customerLead.id === lead.event_related_id;
            });

            if (nonEventLeads.length === 0 || !lead.event_related_id) {
                return true;
            }
        }

        if (lead.isAssignedTo(user.id)) {
            return false;
        }

        if (
            !user.isDirector() &&
            lead.lead_type_id == LeadType.EMAIL &&
            lead.created_method != CreatedMethod.MANUAL &&
            lead.account.leadxpress &&
            lead.leadxpress_flow &&
            lead.account.leadxpress_option.priority &&
            !userHasAccessToLeadXpressFlow(lead, user.id)
        ) {
            return true;
        }

        return false;
    },
};

function userHasAccessToLeadXpressFlow(lead, userId) {
    const contextAccount = useContextStore().account;
    const defaultFlowDivision = contextAccount.leadxpress_option?.division || null;
    const validFlow = contextAccount.flows.find(flow => {
        return (
            !flow.round_robin &&
            (
                (lead.division_id && flow.division_id == lead.division_id) ||
                (!lead.division_id && flow.division_id == defaultFlowDivision) ||
                (!lead.division_id && !flow.division_id && flow.lead_form_id && lead.lead_form_id && flow.lead_form_id == lead.lead_form_id)
            ) &&
            flow.communication_method_id == CommunicationMethod.EMAIL &&
            flow.communication_type_id == CommunicationType.INCOMING
        );
    });

    if (!validFlow) {
        return true;
    }

    const userIsInFlow = validFlow.activeUsers.some(flowUser => {
        return userId == flowUser.id && flowUser.flow_order;
    });

    const userIsInFutureProcedure = validFlow.activeUsers.some(flowUser => {
        return userId == flowUser.id && Math.floor(flowUser.flow_order) > lead.leadxpress_flow;
    });

    if (!userIsInFlow) {
        return false;
    }

    if (fallbackEnoughTimeElapsed(lead)) {
        return true;
    }

    if (userIsInFutureProcedure) {
        return false;
    }

    return true;
}

function fallbackEnoughTimeElapsed(lead) {
    const minutesSinceLeadCreation = new ActivixDate('now').diffInMinutes(new ActivixDate(lead.created_at));
    return minutesSinceLeadCreation >= lead.account.leadxpress_option.priority * 6;
}
