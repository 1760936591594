import accountGuestGroup from './accountGuestGroup.js';
import accounts from './accounts.js';
import activixExp from './activixExp.js';
import authUser from './authUser.js';
import automationJobs from './automationJobs.js';
import automations from './automations.js';
import calendarViews from './calendarViews.js';
import campaign from './campaign.js';
import comments from './comments.js';
import communications from './communications.js';
import customers from './customers.js';
import dashboard from './dashboard.js';
import eti from './eti.ts';
import flows from './flows.js';
import freshdesk from './freshdesk.js';
import groups from './groups.js';
import homepage from './homepage.js';
import inventoryUnits from './inventoryUnits.js';
import leadTrash from './leadTrash.js';
import leadVehicles from './leadVehicles.js';
import leads from './leads.js';
import niotextTemplates from './niotextTemplates.js';
import notifications from './notifications.js';
import preQualification from './preQualification.ts';
import objectives from './objectives.js';
import stock from './stock.js';
import taskEvents from './taskEvents.js';
import users from './users.js';
import validatedEmails from './validatedEmails.js';

export default {
    accountGuestGroup,
    accounts,
    activixExp,
    authUser,
    automationJobs,
    automations,
    calendarViews,
    campaign,
    comments,
    communications,
    customers,
    dashboard,
    eti,
    flows,
    freshdesk,
    groups,
    homepage,
    inventoryUnits,
    leadTrash,
    leadVehicles,
    leads,
    niotextTemplates,
    notifications,
    objectives,
    preQualification,
    stock,
    taskEvents,
    users,
    validatedEmails,
};
