import { postOnce } from '../plugins/axios.js';

export default {
    async getLeads(payload) {
        return postOnce('/dashboard/leads', payload);
    },

    async getCards(payload) {
        return postOnce('/dashboard/cards', payload);
    },

    async getStats(payload) {
        return postOnce('/dashboard/stats-table', payload);
    },

    async getGridReport(payload) {
        return postOnce('/dashboard/sale-table/grid-report', payload);
    },

    async getSaleReport(payload) {
        return postOnce('/dashboard/sale-table/sale-report', payload);
    },

    async getPastSales(payload) {
        return postOnce('/dashboard/sale-table/past-sales', payload);
    },

    async getProfitAndAverageReport(payload) {
        return postOnce('/dashboard/sale-table/profit-and-average-report', payload);
    },

    async getTradeReport(payload) {
        return postOnce('/dashboard/trade-report', payload);
    },

    async getActivityReport(payload) {
        return postOnce('/dashboard/activity-report', payload);
    },

    async getGraphs(payload) {
        return postOnce('/dashboard/graphs', payload);
    },
};
