/* eslint-disable prefer-const, prefer-destructuring, default-case */
import Moment from './value-objects/Moment.js';
import { empty } from './utils/index.js';

// DATES
self.now = () => {
    return Moment.now();
};

self.now_date = () => {
    return Moment.now('date');
};

self.yesterday = () => {
    return Moment.yesterday();
};

self.yesterday_date = () => {
    return Moment.yesterday('date');
};

self.tomorrow = () => {
    return Moment.tomorrow();
};

self.tomorrow_date = () => {
    return Moment.tomorrow('date');
};

self.timestamp = () => {
    return Moment.now().timestamp();
};

self.as_locale = (date, key, format = '', timezone = '', defaultNow = false) => {
    return Moment.asLocale(date, key, { format, timezone, defaultNow });
};

self.locale_dt = (date, format = '', timezone = '', defaultNow = false) => {
    return Moment.init(date, 'dateTime', { format, timezone, defaultNow });
};

self.locale_date = (date, format = '', timezone = '', defaultNow = false) => {
    return Moment.init(date, 'date', { format, timezone, defaultNow });
};

self.locale_time = (time, timezone) => {
    return Moment.init(time, 'time', { timezone });
};

self.empty = empty;

/**
 *  Datetime picker defaults
 */
// English
self.datetimeLangEn = {
    format: 'YYYY/MM/DD',
    applyLabel: 'Submit',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
    customRangeLabel: 'Custom',
    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    firstDay: 0,
};

self.datetimeRangeEn = {
    Today: [now(), now()],
    Yesterday: [yesterday(), yesterday()],
    'Last 7 days': [now().subDays(6), now()],
    'This month': [now().startOfMonth(), now().endOfMonth()],
    'Last month': [
        now()
            .subMonths(1)
            .startOfMonth(),
        now()
            .subMonths(1)
            .endOfMonth(),
    ],
    'Last 30 days': [now().subDays(29), now()],
};

// DATE PICKER
self.datetimeLangFr = {
    format: 'YYYY/MM/DD',
    applyLabel: 'Enregistrer',
    cancelLabel: 'Annuler',
    fromLabel: 'De',
    toLabel: 'À',
    customRangeLabel: 'Personnalisé',
    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
    firstDay: 0,
};

self.datetimeRangeFr = {
    "Aujourd'hui": [now(), now()],
    Hier: [yesterday(), yesterday()],
    '7 derniers jours': [now().subDays(6), now()],
    'Ce mois-ci': [now().startOfMonth(), now().endOfMonth()],
    'Mois derniers': [
        now()
            .subMonths(1)
            .startOfMonth(),
        now()
            .subMonths(1)
            .endOfMonth(),
    ],
    '30 derniers jours': [now().subDays(29), now()],
};
