<template>
    <el-dropdown
        class=""
        trigger="click"
        data-intercom-target="attachments"
        placement="bottom-end"
        @command="onDropdownCommand"
    >
        <activix-tooltip :content="$t('mailClient.attachments')">
            <activix-button
                type="white"
                class="p-2"
            >
                <icon
                    name="regular/attachment"
                    class="text-md"
                />
                <icon
                    name="regular/arrow-down-1"
                    class="text-xs ml-2"
                />
            </activix-button>
        </activix-tooltip>

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                :command="attachment"
                :key="attachment.id"
                v-for="attachment in attachments"
            >
                <div class="flex items-center">
                    <icon class="mr-2 text-grey-500" :name="getAttachmentIcon(attachment)" />
                    <span class="max-w-64 truncate text-gray-700">{{ attachment.name }}</span>
                    <span class="ml-2 text-gray-500">({{ attachmentSize(attachment) }} MB)</span>
                </div>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { round } from '@/utils/numbers.js';

    export default {
        props: {
            attachments: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            attachmentSize(attachment) {
                return round(attachment.size / 1000000, 2);
            },

            onDropdownCommand(attachment) {
                window.open(attachment.url || attachment.path, '_blank');
            },

            getAttachmentIcon(attachment) {
                if (!empty(attachment) && !empty(attachment.contentType)) {
                    if (attachment.contentType.indexOf('video/') !== -1) {
                        return 'regular/video-file-play';
                    }
                    if (attachment.contentType.indexOf('image/') !== -1) {
                        return 'regular/image-file-camera';
                    }
                    if (attachment.contentType.indexOf('audio/') !== -1) {
                        return 'regular/audio-file';
                    }
                    if (attachment.contentType.indexOf('text/') !== -1) {
                        return 'regular/common-file-text';
                    }
                    if (attachment.contentType.indexOf('excel') !== -1) {
                        return 'regular/office-file-xls';
                    }
                    if (
                        attachment.contentType.indexOf('msword') !== -1 ||
                        attachment.contentType.indexOf('ms-word') !== -1
                    ) {
                        return 'regular/office-file-doc';
                    }
                    if (attachment.contentType.indexOf('powerpoint') !== -1) {
                        return 'regular/office-file-pdf';
                    }
                    if (attachment.contentType.indexOf('pdf') !== -1) {
                        return 'regular/office-file-pdf';
                    }
                }

                return 'regular/common-file-empty';
            },
        },
    };
</script>
