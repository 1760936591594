export default {
    alerts: {
        index: {
            error: 'Échec de la récupération des objectifs',
        },
        store: {
            success: 'Objectif créé avec succès',
            error: "Échec de la création de l'objectif",
        },
        show: {
            error: "Échec de la récupération de l'objectif",
        },
        update: {
            success: 'Objectif mis à jour avec succès',
            error: "Échec de la mise à jour de l'objectif",
        },
        destroy: {
            success: 'Objectif supprimé avec succès',
            error: "Échec de la suppression de l'objectif",
        },
    },
    noObjectives: 'Aucun objectifs.',
};
