<template>
    <svg-icon
        :name="name"
        :fill="isFilled"
        :scale="scale"
        v-on="$listeners"
    />
</template>

<script>
    import SvgIcon from 'vue-svgicon';

    export default {
        name: 'Icon',

        components: {
            SvgIcon,
        },

        props: {
            height: {
                type: String,
                default: '1em',
            },
            name: {
                type: String,
                required: true,
            },
            scale: {
                type: String,
                default: undefined,
            },
            width: {
                type: String,
                default: '1em',
            },
            fill: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            isFilled() {
                return this.fill || this.name.includes('bold/');
            },
        },
    };
</script>
