import Enum from './Enum.js';

export default class CaslConsentType extends Enum {
    static get entries() {
        return {
            double_opt_in: 'double_opt_in',
            manual: 'manual',
        };
    }

    static get DOUBLE_OPT_IN() {
        return this.entries.double_opt_in;
    }

    static get MANUAL() {
        return this.entries.manual;
    }
}
