<template>
    <div>
        <activix-button
            tag="a"
            class="shadow w-full"
            target="_blank"
            :href="attachment.url || attachment.path"
        >
            <div class="w-full flex items-center">
                <div class="mr-3">
                    <icon class="text-3xl" :name="icon" />
                </div>
                <div class="text-left overflow-hidden">
                    <div class="truncate" v-text="attachment.name" />
                    <div class="text-sm text-grey-600">
                        {{ sizeInMb }} MB
                    </div>
                </div>
            </div>
        </activix-button>
    </div>
</template>

<script>
    import { round } from '@/utils/numbers.js';

    export default {
        props: {
            attachment: {
                type: Object,
                require: true,
            },
        },

        computed: {
            icon() {
                if (!this.attachment.contentType) {
                    return 'regular/common-file-empty';
                }

                if (this.attachment.contentType.includes('video/')) {
                    return 'regular/video-file-play';
                }

                if (this.attachment.contentType.includes('image/')) {
                    return 'regular/image-file-camera';
                }

                if (this.attachment.contentType.includes('audio/')) {
                    return 'regular/audio-file';
                }

                if (this.attachment.contentType.includes('text/')) {
                    return 'regular/common-file-text';
                }

                if (this.attachment.contentType.includes('excel')) {
                    return 'regular/office-file-xls';
                }

                if (this.attachment.contentType.includes('msword') ||
                    this.attachment.contentType.includes('ms-word')
                ) {
                    return 'regular/office-file-doc';
                }

                if (this.attachment.contentType.includes('powerpoint')) {
                    return 'regular/office-file-pdf';
                }

                if (this.attachment.contentType.includes('pdf')) {
                    return 'regular/office-file-pdf';
                }

                return 'regular/common-file-empty';
            },

            sizeInMb() {
                return round(this.attachment.size / 1000000, 2);
            },
        },
    };
</script>
