import { initValues } from '@/entities/ModelHelper.ts';
import ConditionQuestionAdjustment from '@/entities/ConditionQuestionAdjustment.ts';
import ValueImpactOption from '@/entities/ValueImpactOption.ts';

export default class ValueAdjustment {
    mileage_adjustment: number = 0;

    postal_code_adjustment: number = 0;

    private _condition_questions_adjustments: ConditionQuestionAdjustment[] = [];

    private _value_impact_options: ValueImpactOption[] = [];

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set condition_questions_adjustments(conditionQuestionsAdjustments) {
        this._condition_questions_adjustments = conditionQuestionsAdjustments.map((question: any) => new ConditionQuestionAdjustment(question));
    }

    set value_impact_options(valueImpactOptions) {
        this._value_impact_options = valueImpactOptions.map((option: any) => new ValueImpactOption(option));
    }

    get condition_questions_adjustments(): ConditionQuestionAdjustment[] {
        return this._condition_questions_adjustments;
    }

    get value_impact_options(): ValueImpactOption[] {
        return this._value_impact_options;
    }
}
