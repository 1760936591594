export enum PreQualificationCategory {
    Poor = 'Poor',
    Fair = 'Fair',
    Good = 'Good',
    VeryGood = 'Very Good',
    Excellent = 'Excellent',
}
export const isPreQualificationCategory = (value: any): value is PreQualificationCategory => {
    return Object.values(PreQualificationCategory).includes(value);
};
