import Vue, { reactive } from 'vue';

import i18n from './vue-i18n.js';

class BeamerClient {
    constructor() {
        this.data = reactive({
            hasNotifications: true,
            isInitialized: false,
            user: null,
        });
    }

    init(user) {
        if (!Beamer) {
            return;
        }
        this.data.user = user;
        this.configure();

        Beamer.init();

        this.data.isInitialized = true;
    }

    configure() {
        const updateHasNotification = count => {
            Vue.nextTick(() => {
                this.data.hasNotifications = !!(count || Beamer.notificationNumber);
            });
        };

        Beamer.update({
            selector: '.changelog-selector',
            display: 'right',
            language: i18n.locale.toUpperCase(),
            callback: updateHasNotification,
            onopen: updateHasNotification,
            user_id: this.data.user?.id || '',
            filter: this.data.user?.account?.type || '',
        });
    }

    changeLocale(locale) {
        if (!this.data.isInitialized) {
            return;
        }

        Beamer.update({ language: locale });
    }

    show() {
        Beamer.show();
    }

    get hasNotifications() {
        return this.data.hasNotifications;
    }
}

export default BeamerClient;
