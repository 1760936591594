<template>
    <div class="sa-info">
        <div class="sa-info-dot" />
        <div class="sa-info-body" />
    </div>
</template>

<script>
    export default {};
</script>

<style lang="less" scoped>
    .sa-info {
        @apply border-blue-400;
        border-radius: 50%;
        border-width: 4px;
        box-sizing: content-box;
        height: 80px;
        padding: 0;
        position: relative;
        background-color: #fff;
        width: 80px;
        animation: scaleWarning 0.75s infinite alternate;

        &:after,
        &:before {
            background: #fff;
            content: '';
            border-radius: 50%;
            height: 100%;
            position: absolute;
            width: 100%;
        }

        &:before {
            display: inline-block;
            opacity: 0;
            left: 0;
            animation: pulseWarning 2s linear infinite;
        }

        &:after {
            display: block;
            z-index: 1;
        }

        &-body {
            @apply bg-blue-400;
            bottom: 18px;
            border-radius: 2px;
            height: 28px;
            left: 50%;
            margin-left: -2px;
            position: absolute;
            width: 5px;
            z-index: 2;
            animation: pulseWarningIns 0.75s infinite alternate;
        }

        &-dot {
            @apply bg-blue-400;
            top: 18px;
            border-radius: 50%;
            height: 7px;
            left: 50%;
            margin-left: -3px;
            position: absolute;
            width: 7px;
            z-index: 2;
            animation: pulseWarningIns 0.75s infinite alternate;
        }
    }

    @keyframes scaleWarning {
        0% {
            transform: scale(1);
        }
        30% {
            transform: scale(1.02);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes pulseWarning {
        0% {
            background-color: #fff;
            transform: scale(1);
            opacity: 0.5;
        }
        30% {
            background-color: #fff;
            transform: scale(1);
            opacity: 0.5;
        }
        100% {
            @apply bg-blue-400;
            transform: scale(2);
            opacity: 0;
        }
    }

    @keyframes pulseWarningIns {
        0% {
            @apply bg-blue-300;
        }
        100% {
            @apply bg-blue-400;
        }
    }
</style>
