import { defineStore } from 'pinia';
import Vue from 'vue';

export const useLayoutStore = defineStore({
    id: 'layout',
    state: () => ({
        activeSidebar: 'addLead',
        bodyMarginTop: 0,
        bodyPaddingTop: 0,
        headerHeight: 0,
        navExpanded: false,
        navLocked: Vue.ls.get('navLocked', false),
    }),
    getters: {
        bodySpacingTop: state => state.bodyMarginTop + state.bodyPaddingTop,
    },
    actions: {
        setActiveSidebar(value) {
            this.activeSidebar = value;
        },

        setBodyMarginTop(value) {
            this.bodyMarginTop = parseInt(value || 0, 10);
        },

        setBodyPaddingTop(value) {
            this.bodyPaddingTop = parseInt(value || 0, 10);
        },

        setHeaderHeight(value) {
            this.headerHeight = parseInt(value || 0, 10);
        },

        setNavExpanded(value) {
            this.navExpanded = value;
        },

        setNavLocked(value) {
            this.navLocked = value;

            Vue.ls.set('navLocked', value);
        },
    },
});
