var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-class":"translate-y-full","enter-to-class":"translate-y-0","leave-class":"translate-y-0","leave-to-class":"translate-y-full"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showing),expression:"showing"}],class:[
            _vm.collapsed ? 'h-12' : 'h-main-view',
            'max-w-[calc(100vw-1.5rem)] w-[25rem] lg:w-[30rem]',
            'bg-gray-100 rounded-lg shadow-origin-xl',
            'pointer-events-auto overflow-hidden',
            'transition-all duration-300',
        ]},[(_vm.type === 'addLead')?_c('add-lead',{attrs:{"visible":!_vm.collapsed,"collapsed":_vm.collapsed},on:{"update:collapsed":function($event){_vm.collapsed=$event},"close":_vm.hide}}):_vm._e(),(_vm.type === 'interAccountLeads' && _vm.showing)?_c('inter-account-leads',{attrs:{"collapsed":_vm.collapsed},on:{"update:collapsed":function($event){_vm.collapsed=$event},"close":_vm.hide}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }