<template>
    <div class="bg-white rounded-lg shadow-md overflow-hidden">
        <div class="flex items-center justify-center px-4 py-3 gap-2 bg-gray-100 rounded-t-lg">
            <span class="font-semibold uppercase font-sm text-gray-550">
                {{ $t('addLead.results') }}
            </span>
            <activix-tooltip :content="$t('addLead.interDealerLeadsTooltip')">
                <icon class="text-sm text-grey-500" name="regular/information-circle" />
            </activix-tooltip>
        </div>

        <inter-account-leads-result-item
            class="h-30"
            :lead="lead"
            :key="lead.id"
            @click="$emit('navigate-to-lead', lead.id)"
            v-for="lead in leads"
        />

        <div
            class="flex items-center justify-center border-t border-gray-300 text-center italic p-6"
            v-if="!leads.length && !loading"
        >
            {{ $t('addLead.noResults') }}
        </div>

        <!-- Skeleton loading -->
        <template v-if="loading">
            <div
                class="flex items-center h-30 border-t border-gray-200 | animate-pulse"
                :key="key"
                v-for="key in 3"
            >
                <div class="flex items-center w-full pb-0.5">
                    <div class="pl-4 py-4 | md:py-6">
                        <div class="relative rounded-full bg-gray-300 flex items-center justify-center w-10 h-10 | lg:w-16 lg:h-16" />
                    </div>
                    <div class="ml-4 justify-between w-full">
                        <div class="rounded-md mb-4 h-4 w-3/5 bg-gray-200" />
                        <div class="rounded-md mb-2 h-4 w-1/2 bg-gray-200" />
                        <div class="rounded-md h-4 w-1/4 bg-gray-200" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import InterAccountLeadsResultItem from '@/components/inter_account_leads/InterAccountLeadsResultItem.vue';

    export default {
        components: {
            InterAccountLeadsResultItem,
        },

        props: {
            leads: {
                type: Array,
                default: () => [],
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
