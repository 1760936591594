<template>
    <div>
        <div
            class="flex items-center cursor-pointer py-4"
            @click="toggleActive"
        >
            <div class="mr-4">
                <activix-tooltip :content="statusTooltip">
                    <icon class="w-10 h-10" :class="iconColor" :name="emailIcon" />
                </activix-tooltip>
            </div>
            <div class="flex-1 flex flex-col overflow-hidden mr-2">
                <div class="truncate flex items-center">
                    <span class="text-lg text-grey-700 font-semibold" v-text="senderName" />
                    <span class="ml-1" v-if="!isOutgoing && communication.client_email">
                        &lt;{{ communication.client_email }}&gt;
                    </span>
                    <div v-if="communicationHasCcOrBcc(communication.email_cc)">
                        <activix-tooltip :content="getBccOrCcEmails(communication.email_cc)">
                            <span class="label label-default | flex items-center text-xs bg-gray-300 text-gray-600 ml-2">
                                {{ $t('mailClient.cc') }}
                            </span>
                        </activix-tooltip>
                    </div>
                    <div v-if="communicationHasCcOrBcc(communication.email_bcc)">
                        <activix-tooltip :content="getBccOrCcEmails(communication.email_bcc)">
                            <span class="label label-default | flex items-center text-xs bg-gray-300 text-gray-600 ml-2">
                                {{ $t('mailClient.bcc') }}
                            </span>
                        </activix-tooltip>
                    </div>
                    <div v-if="communication.automation_action?.automation_id">
                        <router-link
                            target="_blank"
                            :to="{ name: 'automations.update', params: { id: communication.automation_action?.automation_id } }"
                            v-if="parentAuthUser.hasAccessTo('automations.index')"
                        >
                            <activix-button class="ml-2" size="small">
                                <icon name="regular/video-game-magic-wand" />
                            </activix-button>
                        </router-link>
                    </div>
                </div>
                <div class="flex items-center overflow-hidden">
                    <icon
                        name="regular/attachment"
                        class="text-sm text-grey-600 mr-1"
                        v-if="!empty(communication.attachments)"
                    />
                    <div class="truncate" v-text="emailSubject" />
                </div>
            </div>
            <div class="text-sm text-right text-grey-600">
                <div v-text="date" />
                <div v-text="time" />
            </div>
        </div>
        <div class="pb-6" :class="{'collapse': !active}">
            <activix-spinner :size="40" v-if="isLoading" />
            <div class="note-editable | pt-2 pl-14 overflow-x-auto" v-else>
                <activix-alert
                    type="error"
                    class="mb-4"
                    :title="$t('client.emailNotDelivered')"
                    v-if="hasBounced"
                >
                    {{ bouncedReason }}
                </activix-alert>

                <div class="flex justify-end" v-if="!empty(communication.attachments)">
                    <activix-email-attachments-button :attachments="communication.attachments" />
                </div>

                <shadow-root>
                    <div v-html="communication.email_body" />
                </shadow-root>

                <div class="mt-6">
                    <div class="flex flex-wrap -m-2">
                        <email-accordion-item-attachment
                            class="p-2 w-full md:w-1/2 lg:w-1/4"
                            :attachment="attachment"
                            :key="attachment.id"
                            v-for="attachment in communication.attachments"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import CommunicationKind from '@/entities/CommunicationKind.js';
    import CommunicationType from '@/entities/CommunicationType.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import EmailAccordionItemAttachment from '@/components/lead/EmailAccordionItemAttachment.vue';
    import ActivixEmailAttachmentsButton from '@/components/buttons/ActivixEmailAttachmentsButton.vue';
    import { useGlobalStore } from '@/store/store.js';

    export default {
        components: { EmailAccordionItemAttachment, ActivixEmailAttachmentsButton },
        props: {
            communication: {
                type: Object,
                required: true,
            },
            leadFullName: {
                type: String,
                required: true,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                active: false,
            };
        },

        computed: {
            ...mapState(useClientCardStore, ['currentCommunicationId']),
            ...mapState(useGlobalStore, { parentAuthUser: 'parentAuthUser' }),

            isCurrentCommunication() {
                return this.currentCommunicationId === this.communication.id;
            },

            emailSubject() {
                return this.communication.email_subject || `(${this.$t('mailClient.noSubject')})`;
            },

            date() {
                if (this.communication.original_created_at) {
                    return new ActivixDate(this.communication.original_created_at).toHumanShort(false);
                }

                return new ActivixDate(this.communication.created_at).toHumanShort(false);
            },

            time() {
                if (this.communication.original_created_at) {
                    return new ActivixDate(this.communication.original_created_at).toHumanTime();
                }

                return new ActivixDate(this.communication.created_at).toHumanTime();
            },

            isOutgoing() {
                return this.communication.communication_type_id == CommunicationType.OUTGOING;
            },

            hasBounced() {
                return this.communication.bounced_at;
            },

            emailIcon() {
                return this.communication.media?.ziggeo_id ? this.$icons.emailVideo : this.$icons.email;
            },

            statusTooltip() {
                let tooltip = '';

                if (this.hasBounced) {
                    return this.$t('client.emailNotDelivered');
                }

                if (this.communication.status == 'delivery_failed') {
                    return this.$t('leadXpress.deliveryFailed');
                }

                if (this.communication.kind == CommunicationKind.AUTO) {
                    return this.$t('clientCard.automatedEmail');
                }

                if (this.isOutgoing) {
                    if (this.communication.media?.ziggeo_id) {
                        tooltip = this.$t('client.outgoing_email_video');
                    } else {
                        tooltip = this.$t('client.outgoing_email');
                    }
                } else if (!this.isOutgoing) {
                    if (this.communication.media?.ziggeo_id) {
                        tooltip = this.$t('client.incoming_email_video');
                    } else {
                        tooltip = this.$t('client.incoming_email');
                    }
                }

                if (this.communication.kind == CommunicationKind.MASS_MAILING) {
                    tooltip += ` - ${this.$t('options.massMailing')}`;
                }

                return tooltip;
            },

            iconColor() {
                if (this.communication.status == 'delivery_failed' || this.hasBounced) {
                    return 'text-red-500';
                }

                if (this.communication.kind == 'auto') {
                    return 'text-grey-500';
                }

                if (this.isOutgoing) {
                    return 'text-orange-500';
                }

                return 'text-green-500';
            },

            senderName() {
                return this.isOutgoing
                    ? this.getFullName(this.communication.user).trim()
                    : this.leadFullName;
            },

            bouncedReason() {
                if (!this.communication.bounced_reason) {
                    return '';
                }
                const reason = this.communication.bounced_reason.toLowerCase();

                if (reason.includes('the email account that you tried to reach does not exist') ||
                    reason.includes('no such person at this address') ||
                    reason.includes('not listed in Domino Directory') ||
                    reason.includes('bad destination email address ') ||
                    reason.includes('domain is not hosted here') ||
                    reason.includes('recipient does not exit') ||
                    reason.includes('undeliverable address') ||
                    reason.includes('user does not exist') ||
                    reason.includes('invalid recipient') ||
                    reason.includes('no such user here') ||
                    reason.includes('unknown alias') ||
                    reason.includes('user unknown') ||
                    reason.includes('unknown user') ||
                    reason.includes('not found') ||
                    reason.includes('no mx for')
                ) {
                    return this.$t('communications.email.bouncedReasons.emailAccountDoesntExist');
                }

                if (reason.includes('not delivering to previously bounced address')) {
                    return this.$t('communications.email.bouncedReasons.previouslyBouncedEmail');
                }

                if (reason.includes('marked your messages as spam') ||
                    reason.includes('listed as abusive') ||
                    reason.includes('phrase Filter') ||
                    reason.includes('blacklisted') ||
                    reason.includes('spam')
                ) {
                    return this.$t('communications.email.bouncedReasons.userMarkedYourMessagesAsSpam');
                }

                if (reason.includes("nous sommes désolés de vous informer que votre message n'a pu être livré") ||
                    reason.includes("i'm sorry to have to inform you that your message could not be delivered") ||
                    reason.includes('message delivery failed') ||
                    reason.includes('possible mail loop ') ||
                    reason.includes('server disconnected') ||
                    reason.includes('hop count exceeded') ||
                    reason.includes('connection failed') ||
                    reason.includes('address rejected') ||
                    reason.includes('message expired') ||
                    reason.includes('access denied') ||
                    reason.includes('rejected') ||
                    reason.includes('too old')
                ) {
                    return this.$t('communications.email.bouncedReasons.emailNotDelivered');
                }

                if (reason.includes('no mailbox here by that name') ||
                    reason.includes('this mailbox is disabled') ||
                    reason.includes('missing or bad mailbox') ||
                    reason.includes('mailbox not available') ||
                    reason.includes('not a valid mailbox') ||
                    reason.includes('mailbox unavailable') ||
                    reason.includes('mailbox not found') ||
                    reason.includes('invalid mailbox')
                ) {
                    return this.$t('communications.email.bouncedReasons.mailboxDisabledOrInvalid');
                }

                if (reason.includes('account blocked from receiving mail') ||
                    reason.includes('blocked')
                ) {
                    return this.$t('communications.email.bouncedReasons.blockedAddress');
                }

                if (reason.includes('invalid characters')) {
                    return this.$t('communications.email.bouncedReasons.invalidCharacter');
                }

                if (reason.includes('message size is over limit') ||
                    reason.includes('message file is too big')
                ) {
                    return this.$t('communications.email.bouncedReasons.tooBig');
                }

                if (reason.includes('mailbox full') ||
                    reason.includes('over quota') ||
                    reason.includes('overquota')
                ) {
                    return this.$t('communications.email.bouncedReasons.overQuota');
                }

                if (reason.includes('policy') ||
                    reason.includes('dmarc')
                ) {
                    return this.$t('communications.email.bouncedReasons.policyReason');
                }

                if (reason.includes('requires authentication') ||
                    reason.includes('not authenticated') ||
                    reason.includes('unable to relay') ||
                    reason.includes('not authorized') ||
                    reason.includes('access denied') ||
                    reason.includes('not allowed')
                ) {
                    return this.$t('communications.email.bouncedReasons.notAuthorized');
                }

                if (reason.includes('need rcpt command')) {
                    return this.$t('communications.email.bouncedReasons.requireEmailCheck');
                }

                return this.communication.bounced_reason;
            },
        },

        watch: {
            isCurrentCommunication: {
                handler(newValue) {
                    if (!newValue) {
                        return;
                    }

                    this.toggleActive();

                    this.$nextTick(() => {
                        this.$el.scrollIntoView({ behavior: 'smooth' });
                    });
                },
                immediate: true,
            },
        },

        methods: {
            communicationHasCcOrBcc(emails) {
                return emails && emails.length;
            },

            getBccOrCcEmails(emails) {
                return emails.join('<br/>');
            },

            toggleActive() {
                this.$emit('load');

                this.active = !this.active;
            },
        },
    };
</script>
