<template>
    <component
        :is="as"
        class="flex items-center w-full px-6 py-4 relative | transition"
        :class="{
            'text-gray-300 hover:text-white': moduleActive && !isActive,
            'text-gray-550 hover:text-gray-450': !moduleActive && !isActive,
            'text-blue-400 font-bold': isActive,
        }"
        :to="to"
        v-tooltip="{ content: navExpanded ? '' : title, placement: 'right' }"
    >
        <span class="absolute left-0 inset-y-0 flex items-center" v-if="isActive">
            <span class="w-1.5 h-10 rounded-r bg-blue-500" />
        </span>

        <span class="inline-flex shrink-0 items-center justify-center ml-1.5">
            <icon :class="iconClass" :name="icon" />
        </span>

        <transition
            enter-active-class="transition-opacity duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-300"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
            @before-enter="showBadge = false"
            @after-enter="showBadge = true"
            @before-leave="showBadge = false"
            @after-leave="showBadge = true"
        >
            <span
                class="leading-5 whitespace-nowrap ml-3 pr-1.5"
                v-text="title"
                v-if="navExpanded"
            />
        </transition>

        <transition
            enter-active-class="transition-opacity duration-300"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-300"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="badge badge-danger | h-4 min-w-4 text-xs ml-1.5" v-tooltip="badgeTooltip" v-if="badge && showBadge">
                {{ badge > 99 ? '99+' : badge }}
            </div>
        </transition>
    </component>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';

    import AppLink from '@/components/presentational/AppLink.vue';

    export default {
        components: {
            AppLink,
        },

        props: {
            badge: {
                default: 0,
                type: Number,
            },
            badgeTooltip: {
                default: '',
                type: String,
            },
            flipIcon: {
                default: false,
                type: Boolean,
            },
            forceActive: {
                default: false,
                type: Boolean,
            },
            icon: {
                required: true,
                type: String,
            },
            iconClass: {
                default: 'w-5 h-5',
                type: String,
            },
            moduleActive: {
                default: true,
                type: Boolean,
            },
            showActive: {
                type: Boolean,
                default: true,
            },
            title: {
                required: true,
                type: String,
            },
            to: {
                type: null,
                default: null,
            },
            as: {
                type: String,
                default: 'app-link',
            },
        },

        data: () => ({
            showBadge: true,
        }),

        computed: {
            ...mapState(useLayoutStore, ['navExpanded']),

            isActive() {
                if (!this.showActive) {
                    return false;
                }

                return this.$route.name === this.to?.name || this.forceActive;
            },
        },
    };
</script>
