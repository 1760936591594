import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/campaign', {
            params: payload,
        });

        return response.data;
    },

    async store(query = {}, payload = {}) {
        if (!query.id && !query.ids && !query.search) {
            throw new InvalidPayload('Query not provided.');
        }

        if (!(payload instanceof FormData)) {
            throw new InvalidPayload('Payload must be an instance of "FormData".');
        }

        if (query.id) {
            payload.append('leadIds[]', query.id);
        }

        if (query.ids) {
            query.ids.forEach(id => {
                payload.append('leadIds[]', id);
            });
        }

        payload.append('search', JSON.stringify(query.search));

        const response = await axios.post('v1/campaign', payload);

        return response.data;
    },

    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const campaign = {
            active: payload.active,
        };

        const response = await axios.put(`v1/campaign/${id}`, campaign);

        return response.data;
    },

    async destroy(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.delete(`v1/campaign/${id}`);

        return response.data;
    },
};
