import axios from '../plugins/axios.js';

export default {
    async getUrl(payload = {}) {
        const response = await axios.get('v2/freshdesk/url', {
            params: payload,
        });

        return response.data;
    },
};
