import Vue from 'vue';
import Tracker from '@openreplay/tracker';
import trackerGraphQL from '@openreplay/tracker-graphql';
import trackerAssist from '@openreplay/tracker-assist';
import trackerVuex from '@openreplay/tracker-vuex';
import * as Sentry from '@sentry/vue';
import { useContextStore } from '@/store/modules/context/store.js';
import axios from './axios.js';

class OpenReplay {
    setup() {
        const projectKey = process.env.VUE_APP_OPENREPLAY_PROJECTKEY;

        if (this.tracker) {
            return;
        }

        if (!projectKey) {
            this.tracker = null;
            return;
        }

        this.tracker = new Tracker({
            projectKey,
            __DISABLE_SECURE_MODE: process.env.VUE_APP_ENV === 'local',
            captureIFrames: false,
            onStart: ({ sessionToken }) => {
                Sentry.setTag('openReplaySessionToken', sessionToken);
                Sentry.setTag('openReplaySessionURL', this.tracker.getSessionURL());
                axios.defaults.headers.common['X-OpenReplay-SessionToken'] = sessionToken;
            },
            network: {
                capturePayload: true,
            },
        });

        this.tracker.use(trackerAssist());
        this.recordGraphQL = this.tracker.use(trackerGraphQL());
    }

    start() {
        this.setup();

        if (!this.tracker) {
            return;
        }

        const piniaPlugin = this.tracker.use(trackerVuex());
        const contextStoreWrapper = piniaPlugin('contextStore');
        contextStoreWrapper(useContextStore());

        this.tracker.start();
    }

    stop() {
        if (!this.tracker) {
            return;
        }

        this.tracker.stop();
    }

    setUserID(userId) {
        if (!this.tracker) {
            return;
        }

        this.tracker.setUserID(userId);
    }
}

const serviceOpenReplay = new OpenReplay();

const VueOpenReplay = {
    install(Vue) {
        Vue.openReplay = serviceOpenReplay;
        Vue.prototype.$openReplay = serviceOpenReplay;
    },
};

Vue.use(VueOpenReplay);
