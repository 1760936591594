import Vue from 'vue';
import { gql } from 'apollo-boost';
import { apolloClient } from '@/plugins/vue-apollo.js';
import { merge } from '@/utils/index.js';
import axios from '@/plugins/axios.js';

export default {
    addTaskEvent(taskEvent) {
        if (!this.leadIds.includes(taskEvent.lead_id)) {
            return;
        }

        const taskAlreadyExists = this.taskEvents.some(activity => activity.id === taskEvent.id);

        if (taskAlreadyExists) {
            return;
        }

        this.taskEvents.push(taskEvent);
    },

    deleteTaskEvent(deletedTaskEvent) {
        if (!this.leadIds.includes(deletedTaskEvent.lead_id)) {
            return;
        }

        const taskIndex = this.taskEvents.findIndex(taskEvent => taskEvent.id === deletedTaskEvent.id);

        if (taskIndex === -1) {
            return;
        }

        this.taskEvents.splice(taskIndex, 1);
    },

    async fetchTaskEvents() {
        const taskEvents = await Vue.api.taskEvents.index(this.leadIds);

        this.setTaskEvents(taskEvents);
    },

    setTaskEvents(taskEvents) {
        this.taskEvents = taskEvents.filter(taskEvent => this.leadIds.includes(taskEvent.lead_id));
    },

    updateTaskEvent(updatedTaskEvent) {
        if (!this.leadIds.includes(updatedTaskEvent.lead_id)) {
            return;
        }

        const taskEvent = this.taskEvents.find(taskEvent => taskEvent.id === updatedTaskEvent.id);

        if (!taskEvent) {
            return;
        }

        merge(taskEvent, updatedTaskEvent);
    },

    addCommunication(addedCommunication) {
        if (!this.leadIds.includes(addedCommunication.lead_id)) {
            return;
        }

        const communicationAlreadyExists = this.communications.some(communication => communication.id === addedCommunication.id);

        if (communicationAlreadyExists) {
            return;
        }

        this.communications.push(addedCommunication);
    },

    deleteCommunication(deletedCommunication) {
        if (!this.leadIds.includes(deletedCommunication.lead_id)) {
            return;
        }

        const communicationIndex = this.communications.findIndex(communication => communication.id === deletedCommunication.id);

        if (communicationIndex === -1) {
            return;
        }

        this.communications.splice(communicationIndex, 1);
    },

    async fetchCommunications() {
        const communications = await Vue.api.communications.index(this.leadIds, 'communications_customer');

        this.setCommunications(communications);
    },

    setCommunications(communications) {
        this.communications = communications.filter(communication => this.leadIds.includes(communication.lead_id));
    },

    updateCommunication(updatedCommunication) {
        if (!this.leadIds.includes(updatedCommunication.lead_id)) {
            return;
        }

        const communication = this.communications.find(communication => communication.id === updatedCommunication.id);

        if (!communication) {
            return;
        }

        merge(communication, updatedCommunication);
    },

    async fetchCommunicationAutomationJobs() {
        const response = await axios.get('v1/automation-jobs/communications', {
            params: {
                leadIds: this.leadIds,
            },
        });

        this.setCommunicationAutomationJobs(response.data.data);
    },

    setCommunicationAutomationJobs(communicationAutomationJobs) {
        this.communicationAutomationJobs = communicationAutomationJobs.filter(automationJob => this.leadIds.includes(automationJob.automatable_id));
    },

    updateCommunicationAutomationJob(updatedAutomationJob) {
        if (!this.leadIds.includes(updatedAutomationJob.lead_id)) {
            return;
        }

        const communicationAutomationJob = this.communicationAutomationJobs.find(communicationAutomationJob => communicationAutomationJob.id === updatedAutomationJob.id);

        if (!communicationAutomationJob) {
            return;
        }

        merge(communicationAutomationJob, updatedAutomationJob);
    },

    async fetchLeadIds(customerId) {
        const leadIdsQuery = apolloClient.query({
            query: gql`query leads_customer($customerId: Int!) {
                leads(customer_id: $customerId) {
                    id
                }
            }`,
            variables: {
                customerId,
            },
        });

        const leadIds = (await leadIdsQuery).data.leads.map(lead => lead.id);

        this.setLeadIds(leadIds);
    },

    setLeadIds(leadIds) {
        this.leadIds = leadIds;
    },
};
