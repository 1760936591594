export default {
    call: 'Call',
    email: 'Email',
    appointment: 'Appointment',
    delivery: '@.capitalize:lexicon.delivery.singular',
    other: 'Other',
    csi: 'CSI',
    sms: 'SMS',
    phone_appointment: 'Phone appointment',
    virtual_appointment: 'Virtual appointment',
    test_drive: 'Test drive',
};
