<template>
    <div class="bg-white rounded-lg shadow-md">
        <div class="text-center px-4 py-3 font-semibold uppercase font-sm text-gray-550 bg-gray-100 rounded-t-lg">
            <div
                class="flex items-center justify-center cursor-pointer"
                @click="$emit('back-to-search-results')"
                v-if="state.associateToResultItemId"
            >
                {{ $t('addLead.backToSearchResults') }}
                <icon class="link-blue ml-2 text-xl" name="regular/navigation-left-circle-1" />
            </div>
            <template v-else>
                {{ $t('addLead.results') }}
            </template>
        </div>
        <div
            class="flex items-center justify-center border-t border-gray-300 text-center italic"
            v-if="state.searching || state.noResults"
        >
            <activix-spinner class="p-4 h-18" :size="40" v-if="state.searching" />
            <div class="p-6" v-else>
                {{ $t('addLead.noResults') }}
            </div>
        </div>
        <template v-else>
            <add-lead-result-item
                :account="account"
                :lead="lead"
                :new-lead="newLead"
                :searched-parameters="searchedParameters"
                :state="state"
                :item-selected-for-viewing="state.selectedForViewingId == lead.id"
                :inventory-unit="inventoryUnit"
                :key="lead.id"
                @add-to-sales-journey="$emit('add-to-sales-journey', lead)"
                @navigate-to-lead="$emit('navigate-to-lead', lead.id)"
                @select-lead-for-completion="$emit('select-lead-for-completion', lead, $event)"
                @select-lead-for-association="$emit('select-lead-for-association', lead)"
                v-for="lead in formattedLeads"
            />
        </template>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import AddLead from '@/mixins/AddLead.js';
    import AddLeadResultItem from '@/components/add_lead/AddLeadResultItem.vue';

    import Account from '@/entities/Account.js';

    export default {
        components: {
            AddLeadResultItem,
        },

        mixins: [AddLead],

        props: {
            leads: {
                type: Array,
                default: () => [],
            },
            searchedParameters: {
                type: Object,
                default: () => {},
            },
            state: {
                type: Object,
                default: () => {},
            },
            newLead: {
                type: Object,
                default: () => {},
            },
            account: {
                type: Account,
                required: true,
            },
            inventoryUnit: {
                type: Object,
                required: false,
            },
        },

        data() {
            return {
                forceLoading: false,
                bulkSelectLeads: [],
                maxResults: 25,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            formattedLeads() {
                return this.leads
                    .filter(
                        lead => !this.state.associateToResultItemId || lead.id == this.state.associateToResultItemId,
                    )
                    .map(lead => {
                        lead.searchScores = this.formatLeadSearchScore(lead);

                        return lead;
                    })
                    .sort((a, b) => {
                        if (b.status && !a.status) {
                            return -1;
                        }

                        if (!b.status && a.status) {
                            return 1;
                        }

                        if (b.searchScores.matchScore == a.searchScores.matchScore) {
                            if (as_locale(a.created_at).isAfter(as_locale(b.created_at))) {
                                return -1;
                            }

                            return 1;
                        }

                        return b.searchScores.matchScore - a.searchScores.matchScore;
                    })
                    .slice(0, this.maxResults);
            },
        },

        methods: {
            formatLeadSearchScore(lead) {
                const name = this.getNameScore(lead);
                const phone = this.getPhoneScore(lead);
                const email = this.getEmailScore(lead);
                const matchScore = this.getMatchScore(name, phone, email);

                return {
                    name,
                    phone,
                    email,
                    matchScore,
                };
            },

            getNameScore(lead) {
                const newLeadName = this.newLead.name.toLowerCase().trim();
                const leadFullName = `${lead.first_name.toLowerCase()} ${lead.last_name.toLowerCase()}`.trim();
                const fullNameMatch = newLeadName === leadFullName ? this.searchScoreValues.fullName : 0;
                const nameSearched = !empty(this.searchedParameters.name);
                const partialNameMatch =
                    !fullNameMatch && !empty(newLeadName) && leadFullName.indexOf(newLeadName) !== -1
                        ? this.searchScoreValues.partialName
                        : 0;

                return {
                    searched: nameSearched,
                    fullyMatched: fullNameMatch,
                    partiallyMatched: partialNameMatch,
                };
            },

            getPhoneScore(lead) {
                const phoneSearched = !!this.searchedParameters.phone;
                const phoneMatched = lead.phones
                    .map(phone => {
                        return this.formatPhoneNumber(phone.number);
                    })
                    .includes(this.newLead.phone)
                    ? this.searchScoreValues.phone
                    : 0;

                return {
                    searched: phoneSearched,
                    matched: phoneMatched,
                };
            },

            getEmailScore(lead) {
                const emailSearched = !!this.searchedParameters.email;
                const emailMatched = lead.emails
                    .map(email => {
                        return email.address;
                    })
                    .includes(this.newLead.email)
                    ? this.searchScoreValues.email
                    : 0;

                return {
                    searched: emailSearched,
                    matched: emailMatched,
                };
            },

            getMatchScore(name, phone, email) {
                return name.fullyMatched + name.partiallyMatched + phone.matched + email.matched;
            },
        },
    };
</script>
