import Enum from './Enum.js';
import icons from '../plugins/svg-icons.js';

export default class LeadType extends Enum {
    static get entries() {
        return {
            email: 1,
            phone: 2,
            walk_in: 3,
            loyalty: 4,
            renewal: 5,
            sms: 6,
            event: 7,
            pre_booking: 8,
            dms: 9,
            web_order: 10,
        };
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get PHONE() {
        return this.entries.phone;
    }

    static get WALK_IN() {
        return this.entries.walk_in;
    }

    static get LOYALTY() {
        return this.entries.loyalty;
    }

    static get RENEWAL() {
        return this.entries.renewal;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get EVENT() {
        return this.entries.event;
    }

    static get PRE_BOOKING() {
        return this.entries.pre_booking;
    }

    static get DMS() {
        return this.entries.dms;
    }

    static get WEB_ORDER() {
        return this.entries.web_order;
    }

    static get translationGroup() {
        return 'leadTypes';
    }

    static getIcon(value) {
        switch (value) {
            case LeadType.EMAIL:
                return icons.leadXpress;

            case LeadType.PHONE:
                return icons.phone;

            case LeadType.SMS:
                return icons.sms;

            case LeadType.WALK_IN:
                return icons.visit;

            case LeadType.LOYALTY:
                return icons.loyalty;

            case LeadType.RENEWAL:
                return icons.renewal;

            case LeadType.EVENT:
                return icons.event;

            case LeadType.DMS:
            case LeadType.PRE_BOOKING:
                return icons.service;

            case LeadType.WEB_ORDER:
                return icons.webOrder;

            default:
                return icons.leadXpress;
        }
    }

    static selectOptions(account, authUser, valueUsed = [], idName = '', labelName = '', sorted = true) {
        const options = Object.entries(this.entries)
            .map(([key, value]) => {
                const invalid = LeadType.isOptionInvalid(value, key, account, authUser, valueUsed);

                return {
                    [idName || 'id']: value,
                    [labelName || 'label']: this.getTranslation(value),
                    invalid,
                };
            });

        if (sorted) {
            options.sort((a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']));
        }

        return options;
    }

    static isOptionInvalid(value, key, account, authUser, valueUsed = []) {
        let invalid = false;

        switch (value) {
            case this.EVENT:
            case this.LOYALTY:
            case this.WEB_ORDER:
            case this.RENEWAL:
            case this.WALK_IN:
                if (!account[key]) {
                    invalid = (!authUser || !authUser.isAdmin()) && !valueUsed.includes(value);
                }
                break;
            case this.EMAIL:
                if (!account.leadxpress) {
                    invalid = (!authUser || !authUser.isAdmin()) && !valueUsed.includes(value);
                }
                break;
            case this.PHONE:
                if (!account.phone_up) {
                    invalid = (!authUser || !authUser.isAdmin()) && !valueUsed.includes(value);
                }
                break;
            case this.SMS:
                if (!account.niotext) {
                    invalid = (!authUser || !authUser.isAdmin()) && !valueUsed.includes(value);
                }
                break;
        }

        return invalid;
    }
}
