import Enum from './Enum.js';

export default class CaslConsentState extends Enum {
    static get entries() {
        return {
            confirmed: 'confirmed',
            temporary: 'temporary',
            expired: 'expired',
        };
    }

    static get CONFIRMED() {
        return this.entries.confirmed;
    }

    static get TEMPORARY() {
        return this.entries.temporary;
    }

    static get EXPIRED() {
        return this.entries.expired;
    }

    static get translationGroup() {
        return 'consent.states';
    }
}
