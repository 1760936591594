import Vue from 'vue';
import { debounce } from 'lodash-es';
import { mapState } from 'pinia';
import { merge } from '../utils/index.js';

// Entities
import DashboardType from '../entities/DashboardType.js';

// Workers
import filterStatsWorker from '../workers/filter-stats/index.js';
import { useDashboardStore } from '../store/modules/dashboard/store.js';
import { useContextStore } from '../store/modules/context/store.js';
import { useGlobalStore } from '../store/store.js';

const filteredLeads = {};
const userLeads = {};
const loaded = {};

DashboardType.values.forEach(dashboardType => {
    filteredLeads[dashboardType] = [];
    userLeads[dashboardType] = [];
    loaded[dashboardType] = {
        advancedStats: false,
        cardStats: false,
        userStats: false,
    };
});

const stats = new Vue({
    data() {
        return {
            leads: {
                activityReport: [],
            },
            fetched: {
                activityReport: false,
            },
            filteredLeads,
            loaded,
            userLeads,
            advancedStats: [],
            latestFetch: {
                accountId: null,
                groupId: null,
            },
        };
    },

    computed: {
        ...mapState(useGlobalStore, ['authUser', 'configs']),
        ...mapState(useDashboardStore, {
            dashboardType: 'dashboardType',
            options: store => store.configs.options,
        }),

        context() {
            return useContextStore().$state;
        },
    },

    methods: {
        async filterLeads(data) {
            data.configs = this.configs;
            data.context = this.context;
            data.leads = this.leads.activityReport;

            const workerPayload = await filterStatsWorker.send(data);

            this.filteredLeads[workerPayload.dashboardType] = Object.freeze(workerPayload.leads);

            this.$emit('filter-worker', workerPayload);
        },

        clear(dashboard, emptyStats = false) {
            this.leads.activityReport = [];
            this.fetched.activityReport = false;

            if (!emptyStats) {
                return;
            }

            this.userLeads.activityReport = [];
            this.advancedStats = [];
        },

        clearAll() {
            DashboardType.values.forEach(dashboardType => {
                this.clear(dashboardType, true);
            });
        },

        leadExists(leadId) {
            return Object.keys(this.leads).some(key => {
                return this.leads[key].some(lead => lead.id == leadId);
            });
        },

        emitStatsUpdated(leadsKey) {
            if (leadsKey == 'activityReport') {
                this.$eventBus.$emit('stats-updated');
            }
        },

        updateLead(leadId, payload) {
            Object.keys(this.leads).forEach(key => {
                const leads = this.leads[key].filter(l => l.id == leadId);

                leads.forEach(lead => {
                    merge(lead, payload);
                });

                if (leads.length) {
                    this.emitStatsUpdated(key);
                }
            });
        },

        deleteLeads(leadIds) {
            Object.keys(this.leads).forEach(key => {
                const leads = this.leads[key].filter(l => !leadIds.includes(l.id));

                if (leads.length != this.leads[key]) {
                    this.leads[key] = Object.freeze(leads);
                    this.emitStatsUpdated(key);
                }
            });
        },

        addSubEntity(entityKey, entity) {
            Object.keys(this.leads).forEach(key => {
                this.leads[key]
                    .filter(l => l.id == entity.lead_id)
                    .forEach(lead => {
                        lead[entityKey] = lead[entityKey] || [];
                        const entityExists = lead[entityKey].some(p => p.id == entity.id);

                        if (!entityExists) {
                            lead[entityKey].push(entity);
                            this.emitStatsUpdated(key);
                        }
                    });
            });
        },

        updateSubEntity(entityKey, entity) {
            Object.keys(this.leads).forEach(key => {
                this.leads[key]
                    .filter(l => l.id == entity.lead_id)
                    .forEach(lead => {
                        const existingEntity = (lead[entityKey] || []).find(p => p.id == entity.id);

                        if (existingEntity) {
                            merge(existingEntity, entity);
                            this.emitStatsUpdated(key);
                        }
                    });
            });
        },

        deleteSubEntity(entityKey, entity) {
            Object.keys(this.leads).forEach(key => {
                this.leads[key]
                    .filter(l => l.id == entity.lead_id)
                    .forEach(lead => {
                        const existingEntityIndex = (lead[entityKey] || []).findIndex(p => p.id == entity.id);

                        if (existingEntityIndex !== -1) {
                            lead[entityKey].splice(existingEntityIndex, 1);
                            this.emitStatsUpdated(key);
                        }
                    });
            });
        },
    },

    created() {
        this.emitStatsUpdated = debounce(this.emitStatsUpdated, 50);
    },
});

const Stats = {
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.stats = stats;
        VueInstance.prototype.$stats = stats;
    },
};

Vue.use(Stats);
