export default [
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '../views/Calendar/Calendar.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: true,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
