import Enum from './Enum.js';
import icons from '../plugins/svg-icons.js';

export default class TaskEventType extends Enum {
    static get entries() {
        return {
            call: 1,
            email: 2,
            sms: 7,
            phone_appointment: 8,
            appointment: 3,
            delivery: 4,
            other: 5,
            csi: 6,
            virtual_appointment: 9,
            test_drive: 10,
        };
    }

    static get CALL() {
        return this.entries.call;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get APPOINTMENT() {
        return this.entries.appointment;
    }

    static get DELIVERY() {
        return this.entries.delivery;
    }

    static get OTHER() {
        return this.entries.other;
    }

    static get CSI() {
        return this.entries.csi;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get PHONE_APPOINTMENT() {
        return this.entries.phone_appointment;
    }

    static get VIRTUAL_APPOINTMENT() {
        return this.entries.virtual_appointment;
    }

    static get TEST_DRIVE() {
        return this.entries.test_drive;
    }

    static getIconName(value) {
        switch (value) {
            case TaskEventType.CALL:
                return icons.phone;

            case TaskEventType.EMAIL:
                return icons.email;

            case TaskEventType.APPOINTMENT:
            case TaskEventType.PHONE_APPOINTMENT:
                return icons.appointment;

            case TaskEventType.DELIVERY:
                return icons.delivery;

            case TaskEventType.CSI:
                return icons.csi;

            case TaskEventType.SMS:
                return icons.sms;

            case TaskEventType.VIRTUAL_APPOINTMENT:
                return icons.camera;

            case TaskEventType.OTHER:
                return icons.pencilWrite;

            case TaskEventType.TEST_DRIVE:
                return icons.testDrive;

            default:
                return icons.appointment;
        }
    }

    static getTaskTypes() {
        return [
            this.CALL,
            this.EMAIL,
            this.SMS,
            this.CSI,
        ];
    }

    static getEventTypes(withOther = false) {
        const eventTypes = [
            this.APPOINTMENT,
            this.PHONE_APPOINTMENT,
            this.VIRTUAL_APPOINTMENT,
            this.DELIVERY,
            this.TEST_DRIVE,
        ];

        if (withOther) {
            eventTypes.push(this.OTHER);
        }

        return eventTypes;
    }

    static get translationGroup() {
        return 'taskEventTypes';
    }
}
