<template>
    <div>
        <activix-select
            class="nav-select"
            :autosize="true"
            :options="[]"
            :placeholder="$t('multiselect.selectOption')"
            v-model="selectedDivisionId"
        >
            <el-option
                :label="division.label"
                :value="division.value"
                :key="division.value"
                v-for="division in orderedDivisions"
            />
        </activix-select>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { sort } from '../utils/index.js';
    import Division from '../entities/Division.js';

    // Pinia
    import { useFlowStore } from '../store/modules/flow/store.js';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        data() {
            return {
                selectedDivisionId: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextDivision: 'contextDivision',
            }),
            ...mapState(useFlowStore, {
                flowService: 'service',
            }),

            showNew() {
                return this.$route.meta.header.division.includes(Division.NEW);
            },

            showUsed() {
                return this.$route.meta.header.division.includes(Division.USED);
            },

            showCredit() {
                return this.contextAccount.credit && this.$route.meta.header.division.includes(Division.FINANCE);
            },

            showService() {
                return this.contextAccount.service || this.authUser.hasDivisionAccess(Division.SERVICE);
            },

            orderedDivisions() {
                const divisions = [];

                if (this.showNew) {
                    divisions.push(Division.NEW);
                }

                if (this.showUsed) {
                    divisions.push(Division.USED);
                }

                if (this.showCredit) {
                    divisions.push(Division.FINANCE);
                }

                if (this.showService) {
                    divisions.push(Division.SERVICE);
                }

                return sort(
                    divisions.map(divisionId => {
                        return {
                            value: divisionId,
                            label: Division.getTranslation(divisionId),
                        };
                    }),
                    'label',
                );
            },
        },

        watch: {
            'contextDivision.id': {
                immediate: true,
                handler(divisionId) {
                    this.selectedDivisionId = divisionId;
                },
            },

            selectedDivisionId(divisionId) {
                this.setContextDivisionId(divisionId);
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextDivisionId']),
        },
    };
</script>
