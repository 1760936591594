import Vue from 'vue';
import i18n from '../plugins/vue-i18n.js';

export function showError(message = '', code = '') {
    message = message ? i18n.t(`toastr.${message}`) : i18n.t('toastr.errorMessage');

    if (code) {
        message += ` <span>(${code})</span>`;
    }

    message += `
        <button class="btn" onclick="window.location.reload()">
            ${i18n.t('toastr.refresh')}
        </button>
    `;

    Vue.notify.error({
        duration: -1,
        title: i18n.t('toastr.error'),
        text: message,
    });
}

export function showWarning(message = '', duration = 3000) {
    message = message || i18n.t('toastr.errorMessage');

    Vue.notify.warning({
        duration,
        title: i18n.t('toastr.warning'),
        text: message,
    });
}

export function showUnauthenticated() {
    const routeRequiresAuth = Vue.router.currentRoute.meta.auth;

    // Only show message if not on login.
    if (typeof routeRequiresAuth !== 'undefined' && !routeRequiresAuth) {
        return;
    }

    Vue.notify.warning({
        duration: -1,
        title: i18n.t('toastr.sessionExpired'),
        ignoreDuplicates: true,
        text: `
            <button class="btn" onClick="window.location.reload()">
                ${i18n.t('toastr.refresh')}
            </button>
        `,
    });
}

export function showLeadNotFound() {
    Vue.notify.warning(i18n.t('toastr.leadNotFound'));
}

export function showLeadDeleted(deletedBy) {
    Vue.notify.warning(`${i18n.t('toastr.leadDeleted')} ${deletedBy}`);
}

export function showConnectionTimeout() {
    Vue.notify.warning({
        duration: -1,
        title: i18n.t('toastr.connectionTimeout'),
        text: `
            <button class="btn" onclick="window.location.reload()">
                ${i18n.t('toastr.refresh')}
            </button>
        `,
    });
}

export function showNetworkDisconnected() {
    Vue.notify.warning({
        duration: -1,
        title: i18n.t('toastr.networkDisconnected'),
        text: `
            <button class="btn" onclick="window.location.reload()">
                ${i18n.t('toastr.refresh')}
            </button>
        `,
    });
}
