<template>
    <warning
        icon="regular/lock-1"
        :title="$t('unauthorized.title')"
        :content="$t('unauthorized.details')"
        :show-back="true"
        :show-home="true"
    />
</template>

<script>
    import Warning from '../components/Warning.vue';

    export default {
        components: { Warning },
    };
</script>
