export default {
    call: {
        title: {
            incomingCall: 'Incoming call',
            webBoost: 'WebBoost',
        },
        description: 'You have an incoming call from {0}',
    },
    video: {
        clientJoined: {
            title: 'Videoconference call',
            description: '{0} is waiting for you in the videoconference room',
        },
        ongoing: {
            title: 'Videoconference call',
            description: 'Ongoing call with {0}',
        },
        joinConferenceCall: 'Join',
    },
    leadCommunicating: {
        title: 'Communication in progress',
        phone: {
            description: '{userName} is already on a phone call.',
        },
        sms: {
            description: '{userName} is already composing an SMS.',
        },
        email: {
            description: '{userName} is already composing an email.',
        },
        video: {
            description: '{userName} is already in videoconference.',
        },
    },
    goToLead: 'Go to lead',
    dismiss: 'Dismiss',
};
