export default {
    atv: 'VTT',
    automotive: 'Automobile',
    bike: 'Vélo',
    boat: 'Bateau',
    caravan: 'Roulotte',
    hybride: 'Hybride',
    mechanical: 'Produit méc.',
    motorcycle: 'Motocyclette',
    motorized: 'Motorisé',
    snowmobile: 'Motoneige',
    suv: 'VUS',
    trailer: 'Remorque',
    truck: 'Camion',
    utility: 'Utilitaire (side by side)',
    van: 'Camionette',
    watercraft: 'Motomarine',
};
