export default {
    success: 'The vehicle was submitted to Torque successfully',
    warning: {
        error403: 'Your account does not have access to Torque',
        error404: 'We could not reach Torque, please try again later or contact our support team',
        error422: 'We couldn\'t find the user email adresse',
        error500: 'There was an error on Torque\'s side, please contact the support team',
    },
    tooltip: {
        match:
            'The submission of the vehicle through Torque is based on the make, model, year and odometer. Make sure entered values are valid before submitting',
        niv: 'You must provide a valid VIN in order to have a valid submission through Torque',
        unsubmitted: 'Submit the vehicle information through Torque',
    },
};
