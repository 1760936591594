import Enum from './Enum.js';

export default class LeadVehicleFilter extends Enum {
    static get entries() {
        return {
            all: 'all',
            order: 'order',
            test_drive: 'test_drive',
            test_drive_ayl: 'test_drive_ayl',
            test_drive_tdm: 'test_drive_tdm',
        };
    }

    static get ALL() {
        return this.entries.all;
    }

    static get ORDER() {
        return this.entries.order;
    }

    static get TEST_DRIVE() {
        return this.entries.test_drive;
    }

    static get TEST_DRIVE_AYL() {
        return this.entries.test_drive_ayl;
    }

    static get TEST_DRIVE_TDM() {
        return this.entries.test_drive_tdm;
    }
}
