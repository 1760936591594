export default {
    communicationAutomationJobs: [],
    communications: [],
    currentCommunication: {},
    currentCommunicationId: null,
    replyingEmail: false,
    taskEvents: [],
    vehicles: [],
    vehicleMakes: [],
    vehicleModels: {},
    vehicleTrims: {},
    vehicleMakeWarranties: {},
    preQualification: {},
};
