import Enum from './Enum.js';

export default class LeadVehicleModality extends Enum {
    static get entries() {
        return {
            cash: 'cash',
            financing: 'financing',
            leasing: 'leasing',
        };
    }

    static get CASH() {
        return this.entries.cash;
    }

    static get FINANCING() {
        return this.entries.financing;
    }

    static get LEASING() {
        return this.entries.leasing;
    }

    static get translationGroup() {
        return 'leadVehicleModalities';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
