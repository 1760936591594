<template>
    <activix-confirm-modal
        type="info"
        portal="modal-3"
        :approve-text="$t('general.ok')"
        :show-deny="false"
        :opened="show"
        :disabled="!updateAutomatedSiblings"
        :title="automatedEventTooltip"
        :approve-callback="save"
        @close="close"
    >
        <div slot="content">
            <el-radio-group class="flex flex-col justify-center mt-4" v-model="updateAutomatedSiblings">
                <el-radio label="none" class="ml-0">
                    {{ $t('modal.modifyAutomatedEventCurrent') }}
                </el-radio>
                <el-radio label="future" class="ml-0">
                    {{ $t('modal.modifyAutomatedEventFuture') }}
                </el-radio>
                <el-radio label="all" class="ml-0">
                    {{ $t('modal.modifyAutomatedEventAll') }}
                </el-radio>
            </el-radio-group>
        </div>
    </activix-confirm-modal>
</template>

<script>
    import { clone } from 'lodash-es';

    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            payload: {
                type: Object,
                default: null,
            },
            id: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                updateAutomatedSiblings: 'none',
            };
        },

        computed: {
            automatedEventTooltip() {
                if (this.payload.delete) {
                    return this.$t('modal.deleteAutomatedEvent');
                }
                return this.$t('modal.modifyAutomatedEvent');
            },
        },

        watch: {
            show(newValue) {
                if (!newValue) {
                    this.updateAutomatedSiblings = 'none';
                }
            },
        },

        methods: {
            close() {
                this.$emit('update:show', false);
            },

            save() {
                const payload = clone(this.payload);
                payload.updateAutomatedSiblings = this.updateAutomatedSiblings;
                this.$emit('save', payload);
                this.close();
            },
        },
    };
</script>
