import { initValues } from '@/entities/ModelHelper.ts';

export default class DrsQuoteInformation {
    private _rate: number | null | undefined;

    private _term: number | null | undefined;

    private _payment: number | null | undefined;

    private _modality: string | null | undefined;

    private _frequency: string | null | undefined;

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set rate(rate) {
        this._rate = rate ? Number(rate) : null;
    }

    set term(term) {
        this._term = term ? Number(term) : null;
    }

    set payment(payment) {
        this._payment = payment ? Number(payment) : null;
    }

    set modality(modality) {
        this._modality = modality;
    }

    set frequency(frequency) {
        this._frequency = frequency;
    }

    set payment_frequency(frequency: string | null | undefined) {
        this._frequency = frequency;
    }

    get rate(): number | null {
        return this._rate || null;
    }

    get term(): number | null {
        return this._term || null;
    }

    get payment(): number | null {
        return this._payment || null;
    }

    get modality(): string | null {
        return this._modality || null;
    }

    get frequency(): string | null {
        return this._frequency || null;
    }

    get isDefined(): boolean {
        return typeof this._rate !== 'undefined' ||
            typeof this._term !== 'undefined' ||
            typeof this._payment !== 'undefined' ||
            typeof this._modality !== 'undefined' ||
            typeof this._frequency !== 'undefined';
    }
}
