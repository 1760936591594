export function maskEmail(email) {
    if (!email) return email;

    const tmpEmail = email.split(', ');

    for (let i = 0; i < tmpEmail.length; i++) {
        tmpEmail[i] = `XXXX${tmpEmail[i].substring(4)}`;
    }

    return tmpEmail.join(', ');
}

export function maskPhone(number) {
    if (!number) return '';

    return `${number.slice(0, -4)}XXXX`;
}
