import Vue from 'vue';

const can = permission => {
    const abilities = Vue.auth.abilities();
    return !!abilities.includes(permission);
};

Vue.use({
    install(VueInstance) {
        VueInstance.prototype.$can = can;
    },
});

export default can;
