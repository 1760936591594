<template>
    <el-upload
        :class="{ 'flex flex-col': !multiple }"
        :accept="acceptedFileTypes"
        :action="action"
        :before-upload="onBeforeUpload"
        :file-list="files"
        :http-request="onFileChosen"
        :multiple="multiple"
        :on-remove="onRemove"
        :on-change="onChange"
        :show-file-list="multiple"
        :disabled="disabled"
        ref="uploader"
    >
        <slot>
            <template v-if="multiple">
                <activix-button :disabled="disabled" size="small" type="primary">
                    {{ $t('activixUi.upload.selectFile') }}
                </activix-button>
            </template>

            <template v-else>
                <div class="flex">
                    <input
                        class="form-control | rounded-r-none"
                        :class="{ 'cursor-pointer': !disabled }"
                        disabled
                        :placeholder="$t('activixUi.upload.selectFile')"
                        ref="fileInput"
                    />
                    <activix-button
                        class="rounded-l-none"
                        size="small"
                        type="primary"
                        :disabled="disabled"
                    >
                        <icon name="regular/folder-empty" class="text-grey-200 mr-2" />
                        {{ $t('activixUi.upload.browse') }}
                    </activix-button>
                </div>
            </template>
        </slot>
    </el-upload>
</template>

<script>
    import { isArray } from 'lodash-es';

    export default {
        name: 'ActivixUpload',

        props: {
            accepts: {
                type: [String, Array],
                default: () => ['image/*', '.pdf', '.xls', '.xlsx', '.doc', '.docx', '.txt'],
            },
            action: {
                type: String,
                default: '',
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [Array, File],
                default: () => [],
            },
            maximumSize: {
                type: Number,
                default: 0,
            },
            sizeUnit: {
                type: String,
                default: 'MB',
                validator: value => ['B', 'KB', 'MB', 'GB'].includes(value),
            },
            showFileList: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                files: [],
                totalSize: 0,
            };
        },

        computed: {
            acceptedFileTypes() {
                if (this.accepts instanceof Array) {
                    return this.accepts.join(',');
                }

                return this.accepts;
            },

            sizeMultiplier() {
                // The base is always bytes
                switch (this.sizeUnit) {
                    case 'KB':
                        return 1000;
                    case 'MB':
                        return 1000 * 1000;
                    case 'GB':
                        return 1000 * 1000 * 1000;
                }

                return 1;
            },
        },

        watch: {
            value(newValue) {
                this.files = isArray(newValue) ? newValue : [newValue];
            },

            files: {
                immediate: true,
                handler(newFiles) {
                    if (!this.multiple && !newFiles.length) {
                        return;
                    }

                    if (!this.multiple && this.$refs.fileInput) {
                        this.$refs.fileInput.value = newFiles[0].name;
                    }

                    this.$emit('input', this.multiple ? newFiles : newFiles[0]);
                },
            },
        },

        methods: {
            async onFileChosen() {
                //
            },

            onRemove(file, fileList) {
                this.setFiles(fileList);
            },

            onChange(file, fileList) {
                this.setFiles(fileList);
            },

            onBeforeUpload(file) {
                const totalSize = file.size + this.totalSize;
                const allowedSize = this.maximumSize * this.sizeMultiplier;
                const sizeIsValid = !allowedSize || totalSize < allowedSize;

                if (!sizeIsValid) {
                    this.$notify.warning(
                        this.$t('activixUi.upload.fileTooBig', {
                            maximumSize: this.maximumSize + this.sizeUnit,
                        }),
                    );
                }

                this.totalSize = this.files.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.size;
                }, 0);

                return sizeIsValid;
            },

            setFiles(fileList) {
                this.files = fileList.map(file => {
                    return file.raw || file;
                });
            },

            clear() {
                this.$refs.uploader.clearFiles();

                if (!this.multiple && this.$refs.fileInput) {
                    this.$refs.fileInput.value = '';
                }
            },
        },
    };
</script>
