export default {
    noSubscription: 'Aucun abonnement',
    now: 'Aucune récurrence',
    daily: 'Quotidienne',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuelle',
    yearly: 'Annuelle',
    adverb: {
        daily: 'quotidiennement',
        weekly: 'hebdomadairement',
        monthly: 'mensuellement',
        yearly: 'annuellement',
    },
};
