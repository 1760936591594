<template>
    <div class="flex flex-1 items-center justify-center">
        <div class="text-center">
            <icon class="text-5xl" :name="icon" />
            <h3 class="text-4xl mt-4 mb-0">
                {{ title }}
            </h3>
            <p class="mt-2 mb-0 max-w-sm">
                {{ content }}
            </p>
            <slot name="actions">
                <activix-button
                    class="mt-6 mx-1"
                    type="primary"
                    size="small"
                    @click="$router.back()"
                    v-if="showBack"
                >
                    {{ $t('unauthorized.goBack') }}
                </activix-button>
                <activix-button
                    class="mt-6 mx-1"
                    size="small"
                    @click="$router.push('/')"
                    v-if="showHome"
                >
                    {{ $t('unauthorized.goHome') }}
                </activix-button>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: 'regular/alert-triangle',
            },
            title: {
                type: String,
                default: '',
                require: true,
            },
            content: {
                type: String,
                default: '',
                require: true,
            },
            showBack: {
                type: Boolean,
                default: false,
            },
            showHome: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
