export default [
    { path: '/users/create', redirect: { name: 'users.store' } },
    { path: '/users/edit/:id', redirect: { name: 'users.update' } },
    {
        path: '/users',
        name: 'users.index',
        component: () => import(/* webpackChunkName: "users-list" */ '../views/Users/List.vue'),
        meta: {
            accountSelect: {
                disabled: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/users/new',
        name: 'users.store',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users/Edit.vue'),
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/users/:id',
        name: 'users.update',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users/Edit.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showActiveAccounts: false,
                showInactiveAccounts: false,
                showParentAccounts: true,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
];
