import { initValues } from '@/entities/ModelHelper.ts';

export default class ConditionQuestionAdjustment {
    question_id: string = '';

    adjustment: number = 0;

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }
}
