import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        if (!payload.account_id) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/inventory', { params: payload });

        return response.data.data;
    },
};
