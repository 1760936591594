import { Observable } from 'apollo-boost';

export default promise => new Observable((subscriber) => {
    promise.then(
        (value) => {
            if (subscriber.closed) return;
            subscriber.next(value);
            subscriber.complete();
        },
        err => subscriber.error(err),
    );
});
