<template>
    <div class="relative">
        <masked-input
            class="form-control"
            :class="classes"
            :disabled="disabled"
            :guide="guide"
            :mask="mask"
            :placeholder="placeholder"
            :type="type"
            :value="currentValue"
            ref="input"
            v-on="inputListeners"
        />
        <div
            class="absolute h-full flex items-center justify-center left-0 top-0 w-12 pointer-events-none"
            v-if="$slots.icon"
        >
            <slot name="icon" />
        </div>
    </div>
</template>

<script>
    import MaskedInput from 'vue-text-mask';
    import emailMask from 'text-mask-addons/dist/emailMask.js';
    import createNumberMask from 'text-mask-addons/dist/createNumberMask.js';
    import { masks } from '../../utils/index.js';

    export default {
        name: 'ActivixMaskedInput',

        components: {
            MaskedInput,
        },

        props: {
            inputClass: {
                type: [String, Array],
                default: '',
            },
            type: {
                type: String,
                default: 'text',
                validator: value => ['text', 'tel', 'url', 'password', 'search', 'number', 'email'].includes(value),
            },
            placeholder: {
                type: String,
                default: '',
            },
            value: {
                type: [String, Number],
                default: '',
            },
            maskType: {
                type: String,
                required: true,
                validator: value => [
                    'phone',
                    'phoneWithCountry',
                    'phoneExtension',
                    'postalCode',
                    'email',
                    'number',
                    'money',
                    'percent',
                    'kilometers',
                    'custom',
                    'text',
                ].includes(value),
            },
            customMask: {
                type: [Array, Function, Boolean, Object],
                default: () => null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            integerLimit: {
                type: Number,
                default: 10,
            },
            allowLeadingZeroes: {
                type: Boolean,
                default: false,
            },
            allowZeroValue: {
                type: Boolean,
                default: false,
            },
            minimumValue: {
                type: Number,
                default: null,
            },
            white: {
                type: Boolean,
                default: false,
            },
            country: {
                type: String,
                default: 'CA',
            },
        },

        data() {
            return {
                currentValue: '',
            };
        },

        computed: {
            classes() {
                const inputClass = Array.isArray(this.inputClass) ? this.inputClass : [this.inputClass];

                return inputClass.concat([
                    {
                        'border-red-500': this.hasError,
                        'pl-12': !!this.$slots.icon,
                        'input-white': this.white,
                    },
                ]);
            },

            mask() {
                switch (this.maskType) {
                    case 'phone':
                        return masks.phone;

                    case 'phoneWithCountry':
                        return masks.phoneWithCountry;

                    case 'phoneExtension':
                        return masks.phoneExtension;

                    case 'postalCode':
                        return masks.postalCode[this.country];

                    case 'email':
                        return emailMask;

                    case 'number':
                        return createNumberMask({
                            ...this.maskOptions,
                            allowDecimal: false,
                            prefix: '',
                        });

                    case 'money':
                        return createNumberMask({
                            ...this.maskOptions,
                            prefix: this.maskOptions.moneyPrefix,
                            suffix: this.maskOptions.moneySuffix,
                            requireDecimal: false,
                            allowDecimal: true,
                            allowNegative: true,
                        });

                    case 'percent':
                        return createNumberMask({
                            ...this.maskOptions,
                            prefix: '',
                            suffix: ' %',
                            requireDecimal: true,
                        });

                    case 'kilometers':
                    case 'meters':
                        return createNumberMask({
                            ...this.maskOptions,
                            prefix: '',
                            requireDecimal: false,
                        });

                    case 'text':
                        return masks.text;

                    default:
                        return this.customMask;
                }
            },

            guide() {
                switch (this.maskType) {
                    case 'email':
                        return true;
                    default:
                        return false;
                }
            },

            maskOptions() {
                const defaults = {
                    integerLimit: this.integerLimit,
                    allowLeadingZeroes: this.allowLeadingZeroes || this.allowZeroValue,
                };

                if (this.$i18n.locale == 'fr') {
                    return {
                        ...defaults,
                        moneyPrefix: '',
                        moneySuffix: ' $',
                        includeThousandsSeparator: false,
                    };
                }

                return {
                    ...defaults,
                    moneyPrefix: '$',
                    moneySuffix: '',
                    includeThousandsSeparator: false,
                };
            },

            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        input: this.onInput,
                    },
                };
            },
        },

        watch: {
            value() {
                this.updateValue();
            },
        },

        methods: {
            updateValue() {
                let value = this.value;

                if (!value && (isNaN(parseInt(value, 10)) || !this.allowZeroValue)) {
                    value = '';
                }

                this.currentValue = value.toString();

                this.$nextTick(() => {
                    this.$refs.input.textMaskInputElement.update(value.toString());
                });
            },

            focus() {
                this.$nextTick(() => {
                    $(this.$refs.input.$el).focus();
                    $(this.$refs.input.$el).select();
                });
            },

            getRawValue(value) {
                if (['money'].includes(this.maskType)) {
                    value = parseFloat(value.replace(/[, $]/g, ''));

                    if (Number.isNaN(value)) {
                        value = null;
                    }
                }

                if (['number'].includes(this.maskType)) {
                    value = parseInt(value.replace(/[, $]/g, ''), 10);

                    if (isNaN(value)) {
                        value = null;
                    }
                }

                if (['phone', 'phoneWithCountry'].includes(this.maskType)) {
                    value = value.replace(/[^\d+]/g, '');
                }

                return value;
            },

            onInput(value) {
                const rawValue = this.getRawValue(value);

                if (this.value == rawValue) {
                    return;
                }

                this.$emit('input', rawValue);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.updateValue();
            });
        },
    };
</script>
