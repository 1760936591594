import axios from '@/plugins/axios.js';
import Lead from '../../../entities/Lead.js';

export default {
    addInterAccountLeads(leads) {
        leads.forEach(lead => {
            if (!this.leads.some(l => l.id === lead.id)) {
                this.leads.push(new Lead(lead));
            }
        });
    },
    async fetchInterAccountLeads({ lead_id: leadId, clear = true, ...payload }) {
        if (!leadId) {
            throw new Error('"lead_id" is required.');
        }

        this.loading = true;
        this.leadId = leadId;

        if (clear) {
            this.leads = [];
        }

        const response = await axios.get(`/leads/${leadId}/inter-account-leads`, {
            params: {
                page: payload.page || 1,
                per_page: payload.per_page || 25,
                limit: payload.page * payload.per_page || 25,
                filters: {
                    division_ids: payload.division_ids || [],
                    account_ids: payload.account_ids || [],
                },
            },
        });
        this.addInterAccountLeads(response.data.data.leads);
        this.siblingAccounts = response.data.data.siblingAccounts;
        this.loading = false;

        return response.data.data;
    },

    setInterAccountLeadsOpened(opened) {
        this.opened = opened;
    },
};
