import Enum from './Enum.js';

export default class CaslConsentStatus extends Enum {
    static get entries() {
        return {
            aboutToExpire: 'aboutToExpire',
            active: 'active',
            expired: 'expired',
            waitingForDoubleOptIn: 'waitingForDoubleOptIn',
        };
    }

    static get ACTIVE() {
        return this.entries.active;
    }

    static get ABOUT_TO_EXPIRE() {
        return this.entries.aboutToExpire;
    }

    static get EXPIRED() {
        return this.entries.expired;
    }

    static get WAITING_FOR_DOUBLE_OPT_IN() {
        return this.entries.waitingForDoubleOptIn;
    }

    static get translationGroup() {
        return 'consent.statuses';
    }
}
