export default {
    error: 'Authentication error',
    forbidden: 'Access denied',
    errors: {
        unknown: 'An unknown error has occurred. Please try again.',
        userNotActive: "This user's access has been disabled.",
        userRestricted: "This user's access is restricted.",
    },
    newPassword: {
        alerts: {
            success: 'Your password has been changed successfully.',
        },
        changePassword: 'Change password',
        errors: {
            passwordDoNotMatch: 'The password and the password confirmation do not match.',
            unknownError: 'An unknown error occured.',
            invalidToken: 'The reset password token is expired.',
        },
        instructions: "You're almost done!<br>Please enter a new password (at least 8 characters).",
    },
    password: 'Password',
    passwordConfirmation: 'Retype password',
    retry: 'Retry',
};
