/* eslint-disable camelcase */
import { snakeCase } from 'lodash-es';
import can from '@/plugins/can.js';
import Account from './Account.js';
import Division from './Division.js';
import Role from './Role.js';
import User from './User.js';

export default class AuthUser extends User {
    get children() {
        return this._children;
    }

    get childAccounts() {
        return this.children.map(child => child.account);
    }

    set children(children) {
        this._children = children.map(child => new AuthUser(child));
    }

    hasChildUserForAccount(accountId) {
        return this.children.some(child => child.account_id == accountId);
    }

    childUserForAccount(accountId) {
        return this.children.find(child => child.account_id == accountId);
    }

    selfOrChildUserForAccount(accountId) {
        return [
            ...this.children,
            this,
        ].find(child => child.account_id == accountId);
    }

    childrenHasNioText() {
        return this.children.some(child => child.hasNioText());
    }

    selfOrChildHasNiotext() {
        if (Object.hasOwn(this, 'has_niotext')) {
            return this.has_niotext;
        }

        if (this.hasNioText()) {
            return true;
        }

        return this.childrenHasNioText();
    }

    selfOrChildForAccountHasNioText(accountId) {
        return this.hasNioText() || this.childUserForAccount(accountId)?.hasNioText();
    }

    canImpersonate() {
        if (this.isAdmin()) {
            return true;
        }

        if (!can('users.impersonate')) {
            return false;
        }

        return this.isBdcDirector() || this.isDirector();
    }

    canImpersonateUser(user = null) {
        const userAccountId = user.account_id || user.account?.id;

        if (!user.id) {
            return false;
        }

        if (!user.active) {
            return false;
        }

        if (this.isAdmin() && userAccountId != this.account_id) {
            return true;
        }

        if (!can('users.impersonate')) {
            return false;
        }

        // if the logged in user is not a parent, than it cannot impersonnate other parent users
        if (!this.hasChildren) {
            return !user.parent_user_id;
        }

        // If the logged in user is the same as the user to impersonate, return false
        if (this.id == user.id) {
            return false;
        }

        // If the logged in user has a parent and is the same as the user to impersonate, return false
        if (user.parent_user_id == this.id) {
            return false;
        }

        // if the user has access to the account of the user to impersonate and is a director, return true
        if (user && this.hasAccessToSeeAccount(user.parent?.account_id ?? userAccountId)) {
            return this.isBdcDirector() || this.isDirector();
        }

        return false;
    }

    isAuthorizedToActionOnLead(lead) {
        if (
            !this.account.assigned_lead &&
            ((!lead.userId && this.isAdvisor()) || (!lead.bdcUserId && this.isBdc() && !this.bdc_super_access))
        ) {
            return false;
        }

        return true;
    }

    hasAccessToExport() {
        return (this.isAdmin() || this.allow_export_access) && can('export.generate_report:create');
    }

    hasAccessToEquity() {
        return this.isAdmin() && can('accounts.renewal_alert_options:update');
    }

    hasAccessToService() {
        return this.hasAccessTo('dashboards.service') || this.hasDivisionAccess(Division.SERVICE);
    }

    hasUserAccess(userId) {
        if (this.isAdmin() || this.id == userId) {
            return true;
        }

        return this.isParentOf(userId);
    }

    hasAccessToSeeAccount(accountId) {
        if (!this.id || !accountId) {
            return false;
        }

        if (accountId == Account.ACTIVIX_RECRUTEMENT && !this.sensitive_access && this.account_id != accountId) {
            return false;
        }

        if (this.isAdmin()) {
            return true;
        }

        if (this.isFromAccount(accountId)) {
            return true;
        }

        if (this.isDirector()) {
            return this.groupsAccountIds.some(groupAccountId => groupAccountId == accountId);
        }

        return false;
    }

    hasAccessToEditAccount(accountId) {
        if (!this.id || !accountId) {
            return false;
        }

        if (this.isAdmin()) {
            return true;
        }

        return this.isFromAccount(accountId);
    }

    hasAccessToInventorySupplier() {
        return this.account.hasActiveInventorySupplier();
    }

    hasAccessTo(page = '', accountId = null) {
        return this.checkAccessTo(page, accountId) || this.children.some(child => child.checkAccessTo(page));
    }

    checkAccessTo(page, accountId = null) {
        const dashboardSlug = snakeCase(page.replace('dashboards.', ''));

        // If user only has access to service division, hide all dashboards but service or events
        if (this.divisions.length === 1 && this.hasDivision(Division.SERVICE) && page !== 'dashboards.leadXpress') {
            if (page.startsWith('dashboards.')) {
                return (
                    ['dashboards.service', 'dashboards.event'].includes(page) &&
                    this.account[dashboardSlug] &&
                    this.module_access[dashboardSlug]
                );
            }

            if (page === 'inTurn') {
                return false;
            }
        }

        if (accountId && !this.hasAccessToSeeAccount(accountId)) {
            return false;
        }

        // General for all users' role
        switch (page) {
            case 'nioText':
                return this.hasNioText() && this.account.hasNioText();

            case 'dashboards.allLead':
            case 'customer.update':
            case 'leads.update':
            case 'users.update':
            case 'schedule':
            case 'search':
            case 'suppliers.accountNotFound':
                return true;
        }

        if (this.isAdmin()) {
            return true;
        }

        switch (page) {
            case 'duplicates':
                return this.account.duplicates;

            case 'imports.index':
            case 'imports.match':
                return this.allow_import_access;

            case 'automations.index':
            case 'automations.store':
            case 'automations.update':
                return this.account.automation && this.automation_access;

            case 'accounts.update':
                return this.isDirector();

            case 'flow.webBoost':
                return this.hasWebBoost() && (this.isDirector() || this.isBdcDirector());

            case 'flow.phoneUp':
                return this.hasPhoneUp() && !this.account.haveCustomPhoneProvider() && (this.isDirector() || this.isBdcDirector());

            case 'flow.leadXpress':
                return this.hasLeadXpress() && (this.isDirector() || this.isBdcDirector());

            case 'flow':
            case 'users.index':
            case 'users.store':
                return this.isDirector() || this.isBdcDirector();

            case 'dashboards.commercial':
            case 'dashboards.event':
            case 'dashboards.loyalty':
            case 'dashboards.renewal':
            case 'dashboards.service':
                return this.account[dashboardSlug] && this.module_access[dashboardSlug];
            case 'dashboards.inventory':
                return this.hasAccessToInventorySupplier();
            case 'dashboards.leadXpress':
            case 'dashboards.phoneUp':
            case 'dashboards.saleTable':
            case 'dashboards.walkIn':
            case 'dashboards.webBoost':
            case 'dashboards.webOrder':
                return (
                    (this.account[dashboardSlug] || this.account[dashboardSlug.replace('_', '')]) &&
                    (this.module_access[dashboardSlug] || this.module_access[dashboardSlug.replace('_', '')])
                );

            case 'objectives.saleTable':
                return this.isDirector() && this.module_access.sale_table && this.account.sale_table;

            case 'objectives.activityReport':
                return (
                    (this.isDirector() || this.isBdcDirector()) &&
                    this.module_access.activity_report &&
                    this.account.activity_report
                );

            case 'dashboards.activityReport':
                return this.module_access.activity_report && this.account.activity_report;

            case 'inTurn.show':
            case 'inTurn.management':
                return this.account.in_turn || this.in_turn_edit;

            case 'templates.index':
            case 'templates.store':
            case 'templates.update':
            case 'inventory':
            case 'calendar':
                return true;
            case 'leads.trash':
                return Role.hasDirectorRights(this.role_id) && this.custom_permissions.delete_lead;
            case 'analytics':
                return !!this.has_analytics_access;
            case 'campaign':
                return this.account.campaign && this.campaign;
            case 'homepage':
                return this.account.homepage;
        }

        return false;
    }
}
