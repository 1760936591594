import Vue from 'vue';
import ZiggeoClient from './ziggeo.js';

const ziggeo = new ZiggeoClient();

const VueZiggeo = {
    install(Vue) {
        Vue.ziggeo = ziggeo;
        Vue.prototype.$ziggeo = ziggeo;
    },
};

Vue.use(VueZiggeo);
