<template>
    <div>
        <activix-banner
            color="orange-400"
            :dismissable="false"
            v-if="(impersonating && !$wait.is('auth.impersonating')) || $wait.is('auth.unimpersonating')"
        >
            <template v-if="$wait.is('auth.unimpersonating')">
                <span class="font-semibold truncate">
                    {{ $t('banner.impersonation.endingSession') }}
                    <div class="relative | inline-flex">
                        <icon class="ml-1 text-sm spin-inverse" :name="$icons.loading" />
                    </div>
                </span>
            </template>
            <template v-else>
                <span class="font-semibold truncate">
                    {{ $t(
                        'banner.impersonation.title',
                        [`${impersonatedUser.fullName}`]
                    ) }}
                </span>

                <activix-tooltip :content="$t('banner.impersonation.tooltip', [`${impersonatedUser.fullName}`])">
                    <icon class="text-sm ml-1 shrink-0" name="regular/information-circle" />
                </activix-tooltip>

                <div class="ml-5 flex items-center group absolute right-4">
                    <a class="md:ml-2 text-white font-bold" @click="$eventBus.$emit('logout')">
                        <icon class="mr-1 " name="regular/logout-1" />
                        {{ $t('banner.impersonation.exit') }}
                    </a>
                </div>
            </template>
        </activix-banner>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import User from '@/entities/User.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        computed: {
            User() {
                return User;
            },
            ...mapState(useGlobalStore, ['authUser']),
            impersonatedUser() {
                return new User(this.$auth.data.profile);
            },
            impersonating() {
                return this.$auth.impersonating();
            },
        },
    };
</script>
