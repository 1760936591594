export default {
    alerts: {
        index: {
            error: 'Failed fetching the objectives',
        },
        store: {
            success: 'Objective created successfully',
            error: 'Failed creating the objective',
        },
        show: {
            error: 'Failed fetching the objective',
        },
        update: {
            success: 'Objective updated successfully',
            error: 'Failed updating the objective',
        },
        destroy: {
            success: 'Objective deleted successfully',
            error: 'Failed deleting the objective',
        },
    },
    noObjectives: 'No objectives.',
};
