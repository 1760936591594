import { initValues } from '@/entities/ModelHelper.ts';
import ValueAdjustment from '@/entities/ValueAdjustment.ts';

export default class AdditionalDetail {
    eti_value: number = 0;

    eti_range_low: number = 0;

    eti_range_high: number = 0;

    creation_date: string = '';

    eti_id: string = '';

    language: string = '';

    private _value_adjustment: ValueAdjustment = new ValueAdjustment();

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set value_adjustment(valueAdjustment) {
        this._value_adjustment = new ValueAdjustment(valueAdjustment);
    }

    get value_adjustment(): ValueAdjustment {
        return this._value_adjustment;
    }
}
