/* eslint-disable camelcase */
import { isEmpty } from 'lodash-es';
import CustomerVehicleLead from '@/entities/CustomerVehicleLead.js';
import Model from './Model.js';

export default class CustomerVehicle extends Model {
    static get defaults() {
        return {
            customer_vehicle_lead: [],
        };
    }

    get customer_vehicle_lead() {
        return this._customer_vehicle_lead;
    }

    set customer_vehicle_lead(customerVehicleLead) {
        this._customer_vehicle_lead = customerVehicleLead.map(v => new CustomerVehicleLead(v));
    }

    static isConsideredEmpty(vehicle, ignoreFields = []) {
        ignoreFields = [
            ...ignoreFields,
            'id',
            'customer_id',
            'type',
            'created_at',
            'created_by',
            'updated_at',
            'updated_by',
            'deleted_at',
            'deleted_by',
        ];

        return CustomerVehicleLead.isConsideredEmpty(vehicle?.customer_vehicle_lead) &&
            !Object.entries(vehicle)
                .some(([key, value]) => !ignoreFields.includes(key) && !isEmpty(value) && value);
    }
}
