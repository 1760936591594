<template>
    <div class="relative">
        <input
            class="form-control"
            :class="classes"
            :disabled="disabled"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            :readonly="readonly"
            :maxlength="maxlength"
            ref="input"
            v-on="inputListeners"
        />
        <div
            class="absolute h-full flex items-center justify-center left-0 top-0 w-12 pointer-events-none"
            v-if="$slots.icon"
        >
            <slot name="icon" />
        </div>
        <div class="absolute h-full flex items-center justify-center right-0 top-0 w-12" v-if="error">
            <activix-tooltip :content="error">
                <icon class="text-xl text-red-500" name="regular/alert-circle" />
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActivixInput',

        props: {
            inputClass: {
                type: [String, Array],
                default: '',
            },
            type: {
                type: String,
                default: 'text',
            },
            value: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            maxlength: {
                type: Number,
                default: 255,
            },
            error: {
                type: String,
                default: '',
            },
            hasError: {
                type: Boolean,
                default: false,
            },
            white: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            classes() {
                const inputClass = Array.isArray(this.inputClass) ? this.inputClass : [this.inputClass];

                return inputClass.concat([
                    {
                        'border-red-400': this.hasError || !!this.error,
                        'pl-12': !!this.$slots.icon,
                        'input-white': this.white,
                    },
                ]);
            },

            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        input: event => this.$emit('input', event.target.value),
                    },
                };
            },
        },

        methods: {
            focus() {
                this.$refs.input.focus();
            },
        },
    };
</script>
