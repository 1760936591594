import Enum from './Enum.js';

export default class TemplateType extends Enum {
    static get entries() {
        return {
            email: 'email',
            notification: 'notification',
        };
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get NOTIFICATION() {
        return this.entries.notification;
    }

    static get translationGroup() {
        return 'templates';
    }
}
