<template>
    <vue-notifications :width="notificationWidth" :duration="6000">
        <template #body="{ item: { type, title, text, data }, close }">
            <div :class="type">
                <div class="flex items-center flex-1 p-6 overflow-hidden">
                    <div class="mr-6" v-if="data.icon">
                        <icon class="w-7 h-7" :class="data.iconClass" :name="data.icon" />
                    </div>
                    <div class="flex-1 overflow-hidden">
                        <template v-if="data.notification">
                            <div class="font-bold truncate" v-if="title">
                                <router-link
                                    :to="{ name: 'calendar' }"
                                    v-if="['taskSent', 'eventSent'].includes(data.notification.type)"
                                >
                                    {{ title }}
                                </router-link>
                                <template v-else>
                                    {{ title }}
                                </template>
                            </div>
                            <div v-html="text" v-if="text" />
                            <div class="truncate" v-if="data.notification.leadUrl">
                                {{ $t('notificationCenter.lead') }} :
                                <router-link :to="data.notification.leadUrl">
                                    {{ data.notification.leadName }}
                                </router-link>
                                <span v-if="data.notification.date">- {{ data.notification.date }}</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="font-bold truncate" v-if="title">
                                {{ title }}
                            </div>
                            <div v-html="text" v-if="text" />
                        </template>
                    </div>
                </div>
                <button
                    type="button"
                    class="close | absolute w-6 h-6 mr-2 mt-2 right-0 top-0 leading-none text-2xl"
                    @click="close"
                >
                    &times;
                </button>
            </div>
        </template>
    </vue-notifications>
</template>

<script>
    import {
        NOTIFICATION_WIDTH_MAX,
        NOTIFICATION_WIDTH_TRESHOLD,
        NOTIFICATION_WIDTH_ADJUSTMENT,
    } from '../utils/layout.js';

    export default {
        computed: {
            notificationWidth() {
                return window.innerWidth < NOTIFICATION_WIDTH_TRESHOLD
                    ? window.innerWidth - NOTIFICATION_WIDTH_ADJUSTMENT
                    : NOTIFICATION_WIDTH_MAX;
            },
        },
    };
</script>
