export default {
    additionalFields: 'Additional fields',
    addToSalesJourney: 'Add to the sales journey',
    associate: 'Associate',
    associateLeadNotActiveModalText: 'The lead you selected is {0}<br>Are you sure you want to continue?',
    associateToSelectedLead: 'Associate to selected lead',
    backToSearchResults: 'Back to search results',
    businessNameLength: 'The business name must be at least 3 letters',
    businessNameRequired: 'The business name is required',
    clientNameLength: 'The client name must be at least 3 letters',
    clientNameRequired: 'The client name is required',
    close: 'Close',
    collapse: 'Collapse',
    completableFieldsTooltip: 'The following information will be added to the selected lead.',
    complete: 'Complete',
    confirmLeadAssociation: 'Do you really want to create an associated lead?',
    confirmLeadCreation: 'Do you really want to create a new lead?',
    confirmLeadCreationOverAddingVisit: 'Do you really want to create a lead instead of adding a visit to an existing lead (suggested procedure)?',
    confirmLeadCreationOverSelectingExisting: 'Do you really want to create a new lead instead of selecting an existing one?',
    confirmLeadEdit: 'Do you want to edit this lead directly?',
    continue: 'Continue',
    copyClientsInformations: 'Copy client\'s information',
    createAssociatedLead: 'Create associated lead',
    createNewLead: 'Create new lead',
    createdAt: 'Created on',
    disableManualDuplicate: 'You cannot create a lead when a lead with the same email address already exists.',
    divisionRequired: 'The division is required',
    emailFound: 'Email found',
    emailOrPhoneRequired: 'An email address or a phone number is required',
    emailRequired: 'The email address is required',
    error: 'An error has occured. Please try again.',
    errorDuringAddEventToSalesJourney: 'An error has occured while appending an event to the sales journey. Please try again.',
    errorDuringAssociation: 'An error has occured during the lead association. Please try again.',
    errorDuringSearch: 'An error has occured during the search. Please try again.',
    expand: 'Expand',
    fieldDisabled: 'This field cannot be modified while creating an associated lead',
    interDealerLeads: 'Inter-Dealer Leads',
    interDealerLeadsTooltip: 'Only related leads from other dealers in your group are visible.',
    leadAssociation: 'Lead association',
    leadCreation: 'Lead Creation',
    leadTypeRequried: 'The lead type is required',
    mustSelectAccount: 'An account must be selected',
    nameMatch: 'Exact name found',
    nameNotMatched: 'Name does not match search',
    namePartiallyMatched: 'Partial name match',
    nextStep: 'Next step',
    noAdvisor: 'No associate',
    noEmailFound: 'No email match',
    noEmailSearched: 'No email searched',
    noEmailsInLead: 'This lead does not have any emails',
    noInterDealerLeads: 'No inter-dealer lead',
    noMoreResults: 'No other results.',
    noNameSearched: 'No name searched',
    noPhoneFound: 'No phone number match',
    noPhoneSearched: 'No phone number searched',
    noPhonesInLead: 'This lead does not have phone numbers',
    noResults: 'No results',
    noVehicle: 'No vehicle',
    originRequired: 'The lead source is required',
    originalEmail: 'Original Email',
    phoneFound: 'Phone number found',
    phoneRequired: 'The phone number is required',
    resetSearch: 'Clear',
    resetSearchDisabled: 'The search fields cannot be cleared while adding an associated lead',
    results: 'Results',
    returnInitialLead: 'Back to initial lead ({lead})',
    select: 'Select',
    selectLeadForAssociation: 'Select this lead for association',
    selectLeadNotActiveModalText: 'The lead you selected is {0}<br>Please select an active lead.',
    showMore: 'Show more',
    validCallDateRequired: 'A valid call date is required',
    validEndContractDateRequired: 'A valid end contract date is required',
    validPresentedDateRequired: 'A valid presented date is required',
    vehicle: 'Vehicle',
};
