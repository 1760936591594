import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(payload = {}) {
        const response = await axios.get('v1/comments', { params: payload });

        return response.data.data;
    },

    async store(payload = {}) {
        const formData = new FormData();

        const comment = {
            parent_id: payload.comment.parent_id,
            alert_users: payload.comment.alert_users,
            alert: payload.comment.alert,
            alert_sms: payload.comment.alert_sms,
            alert_email: payload.comment.alert_email,
            content: payload.comment.content,
            file: payload.comment.file,
            private: payload.comment.private,
            lead_id: payload.comment.lead_id,
            user_id: payload.comment.user_id,
            parent_full_name: payload.comment.parent_full_name,
            request_user_id: payload.comment.request_user_id,
        };

        if (payload.file) {
            formData.append('file', payload.file);
        }

        formData.append('data', JSON.stringify(comment));

        const response = await axios.post('v1/comments', formData);

        return response.data.comment;
    },

    async storeAudioNote(payload = {}) {
        const response = await axios.post('v1/audio-note', payload);

        return response.data.audioNote;
    },

    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const comment = {
            id,
            content: payload.content,
            file_url: payload.file_url,
            private: payload.private,
            alert_users: payload.alert_users,
        };

        const response = await axios.put(`v1/comments/${id}`, comment);

        return response.data;
    },

    async destroy(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.delete(`v1/comments/${id}`);

        return response.data.data;
    },
};
