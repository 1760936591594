export default [
    { path: '/accounts/create', redirect: { name: 'accounts.store' } },
    { path: '/accounts/edit/:id', redirect: { name: 'accounts.update' } },
    {
        path: '/accounts',
        name: 'accounts.index',
        component: () => import(/* webpackChunkName: "account-list" */ '../views/Accounts/List.vue'),
        meta: {
            header: {
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
    {
        path: '/accounts/new',
        name: 'accounts.store',
        component: () => import(/* webpackChunkName: "account" */ '../views/Accounts/Edit.vue'),
        meta: {
            header: {
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
    {
        path: '/accounts/:id',
        name: 'accounts.update',
        component: () => import(/* webpackChunkName: "account" */ '../views/Accounts/Edit.vue'),
        meta: {
            accountSelect: {
                disabled: false,
                showActiveAccounts: false,
                showInactiveAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
];
