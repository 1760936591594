import Enum from './Enum.js';

export default class AccountType extends Enum {
    static get entries() {
        return {
            dealer: 'dealer',
            custom: 'custom',
            polestar: 'polestar',
        };
    }

    static get DEALER() {
        return this.entries.dealer;
    }

    static get CUSTOM() {
        return this.entries.custom;
    }

    static get POLESTAR() {
        return this.entries.polestar;
    }
}
