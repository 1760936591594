export default {
    error: "Erreur d'authentification",
    forbidden: 'Accès refusé',
    errors: {
        unknown: "Une erreur inconnue s'est produite. Veuillez réessayer.",
        userNotActive: 'Les accès de cet utilisateur ont été désactivés',
        userRestricted: "L'accès de cet utilisateur est restreint.",
    },
    newPassword: {
        alerts: {
            success: 'Le mot de passe a été changé avec succès.',
        },
        changePassword: 'Changer le mot de passe',
        errors: {
            passwordDoNotMatch: 'Le mot de passe ne concordent pas.',
            unknownError: 'Une erreur inconnue est survenue.',
            invalidToken: 'La modification de mot de passe à expiré',
        },
        instructions: 'Vous avez presque terminé!<br>Veuillez entrer un nouveau mot de passe (minimum 8 caractères).',
    },
    password: 'Mot de passe',
    passwordConfirmation: 'Ressaisissez le mot de passe',
    retry: 'Réessayer',
};
