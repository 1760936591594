import { formatNumber, parseNumber, getCountryCallingCode } from 'libphonenumber-js';
import { cloneDeep, isArray, isObject, isEmpty, isEqual } from 'lodash-es';
import baseEmpty from 'locutus/php/var/empty.js';
import i18n from '../plugins/vue-i18n.js';
import Moment from '../value-objects/Moment.js';

export const masks = {
    phone: [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    phoneWithCountry: ['+', '1', ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    phoneExtension: [/[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/, /[\dw]/],
    postalCode: {
        CA: [/[A-Z]|[a-z]/, /\d/, /[A-Z]|[a-z]/, ' ', /\d/, /[A-Z]|[a-z]/, /\d/],
        US: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
    },
    text: [/.*/],
};

export function merge(to, from) {
    Object.keys(to).forEach(key => {
        if (!from.hasOwnProperty(key) && key.startsWith('_')) {
            key = key.slice(1);
        }

        if (!from.hasOwnProperty(key)) {
            return;
        }

        if (to[key] instanceof Moment && from[key] instanceof Moment) {
            to[key] = from[key].clone();
        } else if (isArray(to[key]) && !isArray(from[key])) {
            to[key] = [from[key]];
        } else if (isArray(to[key]) && isArray(from[key])) {
            to[key] = cloneDeep(from[key]);
        } else if (isObject(to[key]) && isObject(from[key])) {
            merge(to[key], from[key]);
        } else {
            to[key] = from[key];
        }
    });
}

// Sort array by key. Return a new instance.
export function sort(array, key, asc = true) {
    const sortedArray = array.concat().sort((a, b) => {
        let valueA = a;
        let valueB = b;

        if (key) {
            valueA = a[key];
            valueB = b[key];
        }

        return valueA.toString().localeCompare(valueB.toString());
    });

    return asc ? sortedArray : sortedArray.reverse();
}

// Sort object by key. Return a new instance.
export function orderByKey(object) {
    const newObject = {};

    Object.keys(object)
        .sort()
        .forEach(key => {
            newObject[key] = object[key];
        });

    return newObject;
}

export function formatPhone(value, withCountryCode = false) {
    if (!value) {
        return '';
    }

    const country = 'CA';
    const parsedNumber = parseNumber(value, country);
    const countryCode = getCountryCallingCode(country);

    if (isEmpty(parsedNumber)) {
        return '';
    }

    const number = formatNumber(parsedNumber, 'National').replace(/[()]/g, '');

    return withCountryCode ? `+${countryCode} ${number}` : number;
}

export function randomString(length = 5) {
    return Math.random().toString(36).substr(2, length);
}

export function empty(value) {
    return value instanceof Moment ? !value.isValid() : baseEmpty(value);
}

export function equal(a, b) {
    if (a instanceof Moment) {
        return a.isSame(b);
    }

    if (b instanceof Moment) {
        return b.isSame(a);
    }

    return isEqual(a, b);
}

/**
 * Compare arrays to see if they are the same
 *
 * @param  Array a First array
 * @param  Array b Second array
 * @return Boolean
 */
export function arraysEqual(a, b) {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length != b.length) {
        return false;
    }
    if (a.length == 0) {
        return true;
    }

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function toMoney(decimal, fixed, locale, hidden) {
    let x = '';

    if (typeof fixed === 'undefined') {
        fixed = 0;
    }

    if (typeof locale === 'undefined') {
        locale = i18n.locale;
    }

    if (typeof hidden === 'undefined') {
        hidden = false;
    }

    if (!Number.isNaN(parseFloat(decimal))) {
        if (locale == 'fr') {
            x = `${parseFloat(decimal)
                .toFixed(fixed)
                .replace(/./g, (c, i, a) => {
                    return i && c !== ',' && (a.length - i) % 3 === 0 ? ` ${c}` : c;
                })
                .replace(' .', '.')} $`;
        } else {
            x = `$${parseFloat(decimal)
                .toFixed(fixed)
                .replace(/./g, (c, i, a) => {
                    return i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c;
                })
                .replace(' .', '.')}`;
        }

        return !hidden ? x : x.replace(/[0-9]/g, 'X');
    }

    return !hidden ? x : 'XX.XX';
}

export function toNumber(number, fixed, locale) {
    if (typeof fixed === 'undefined') {
        fixed = 0;
    }

    if (typeof locale === 'undefined') {
        locale = i18n.locale;
    }

    if (Number.isNaN(parseFloat(number))) {
        return number;
    }

    const parseNumber = parseFloat(number).toFixed(fixed);

    if (locale == 'fr') {
        return parseNumber
            .replace(/./g, (c, i, a) => {
                return i && c !== ',' && (a.length - i) % 3 === 0 ? ` ${c}` : c;
            })
            .replace(' .', '.');
    }

    return parseNumber
        .replace(/./g, (c, i, a) => {
            return i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c;
        })
        .replace(' .', '.');
}

export function toUrl(value, secure = true) {
    if (!value) {
        return '';
    }

    if (value.startsWith('http')) {
        return value;
    }

    if (secure) {
        return 'https://'.concat(value);
    }

    return 'http://'.concat(value);
}

export function getFullName(object, unknown = true) {
    if (isEmpty(object)) {
        return '';
    }

    let value = '';

    if (object.first_name) {
        value += `${object.first_name} `;
    }

    if (object.last_name && object.last_name.toLowerCase().trim() !== 'api') {
        value += object.last_name;
    }

    if (object.name) {
        value += object.name;
    }

    if (object.business && object.business_name) {
        value = object.business_name;
    }

    value = value.trim();

    return value || (unknown ? i18n.t('general.unknown') : '');
}

export function deskitSsoUrl(leadId) {
    const clientId = process.env.VUE_APP_AUTH0_DESKIT_CLIENT_ID;
    const connection = process.env.VUE_APP_AUTH0_CONNECTION;

    const url = `https://${process.env.VUE_APP_AUTH0_DOMAIN}/samlp/${clientId}?connection=${connection}`;
    const relayState = {
        fallbackUrl: `${window.location.protocol}//${window.location.host}/deskit/account-not-found`,
        type: '',
        id: null,
    };

    if (leadId) {
        relayState.type = 'deal';
        relayState.id = leadId;
    }

    return `${url}&RelayState=${JSON.stringify(relayState)}`;
}
