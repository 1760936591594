import Enum from './Enum.js';

export default class DashboardType extends Enum {
    static get entries() {
        return {
            activityReport: 'activityReport',
            allLead: 'allLead',
            commercial: 'commercial',
            event: 'event',
            leadXpress: 'leadXpress',
            loyalty: 'loyalty',
            phoneUp: 'phoneUp',
            renewal: 'renewal',
            saleTable: 'saleTable',
            service: 'service',
            walkIn: 'walkIn',
            webBoost: 'webBoost',
            webOrder: 'webOrder',
        };
    }

    static get ALL() {
        return this.entries.allLead;
    }

    static get LOYALTY() {
        return this.entries.loyalty;
    }

    static get RENEWAL() {
        return this.entries.renewal;
    }

    static get COMMERCIAL() {
        return this.entries.commercial;
    }

    static get SALE() {
        return this.entries.saleTable;
    }

    static get EVENT() {
        return this.entries.event;
    }

    static get SERVICE() {
        return this.entries.service;
    }

    static get ACTIVITY() {
        return this.entries.activityReport;
    }

    static get WEB_ORDER() {
        return this.entries.webOrder;
    }

    static get LEAD_XPRESS() {
        return this.entries.leadXpress;
    }

    static get WEB_BOOST() {
        return this.entries.webBoost;
    }

    static get PHONE_UP() {
        return this.entries.phoneUp;
    }

    static get WALK_IN() {
        return this.entries.walkIn;
    }
}
