import Vue from 'vue';
import axios from './axios.js';

const VueAxios = {
    install(VueInstance, axios) {
        VueInstance.axios = axios;
        VueInstance.prototype.$axios = axios;
    },
};

Vue.use(VueAxios, axios);
