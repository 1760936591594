import axios from '@/plugins/axios.js';
import { merge } from '@/utils/index.js';
import { useContextStore } from '@/store/modules/context/store.js';
import InvalidPayload from '../../../exceptions/InvalidPayload.js';
import Lead from '../../../entities/Lead.js';

export default {
    setCustomerId(customerId) {
        const contextLeadId = useContextStore().leadId;
        const contextLeadAlreadyExists = contextLeadId && this.associatedLeads.some(lead => lead.id === contextLeadId);

        if (this.customerId === customerId && (!contextLeadId || contextLeadAlreadyExists)) {
            return;
        }

        this.customerId = customerId;

        this.fetchAssociatedLeads();
    },

    async fetchAssociatedLeads() {
        if (!this.customerId) {
            throw new InvalidPayload('ID not provided.');
        }

        this.associatedLeads = [];

        try {
            const response = await axios.get(`v1/customers/${this.customerId}/associatedLeads`);

            this.associatedLeads = response.data.data
                .map(lead => new Lead(lead))
                .sort((b, a) => {
                    return a.contextDate.diffInMilliseconds(b.contextDate);
                });
        } catch (error) {
            // Glitch with the associated lead creation flow which is executed in 2 steps
        }
    },

    removeLead(leadId) {
        const leadIdIndex = this.associatedLeads.findIndex(lead => lead.id === leadId);

        if (leadIdIndex === -1) {
            return;
        }

        this.associatedLeads.splice(leadIdIndex, 1);
    },

    updateLead(updatedLead) {
        const associatedLead = this.associatedLeads.find(lead => lead.id === updatedLead.id);

        if (!associatedLead) {
            return;
        }

        merge(associatedLead, updatedLead);
    },

    updateLeadVehicle(updatedLeadVehicle) {
        if (!updatedLeadVehicle.id || !updatedLeadVehicle.lead_id) {
            return;
        }

        const associatedLead = this.associatedLeads.find(lead => lead.id === updatedLeadVehicle.lead_id);

        if (!associatedLead) {
            return;
        }

        const associatedLeadVehicle = associatedLead.vehicles.find(vehicle => vehicle.id === updatedLeadVehicle.id);

        if (!associatedLeadVehicle) {
            return;
        }

        merge(associatedLeadVehicle, updatedLeadVehicle);
    },
};
