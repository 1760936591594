import Enum from './Enum.js';
import icons from '../plugins/svg-icons.js';
import { getIconMarkup } from '../utils/icon.js';

export default class TaskEventTypeIcon extends Enum {
    static get entries() {
        return {
            call: getIconMarkup(icons.phone, { class: 'event-icon' }),
            email: getIconMarkup(icons.email, { class: 'event-icon' }),
            appointment: getIconMarkup(icons.appointment, { class: 'event-icon' }),
            phone_appointment: getIconMarkup(icons.appointment, { class: 'event-icon' }),
            delivery: getIconMarkup(icons.delivery, { class: 'event-icon' }),
            other: getIconMarkup(icons.pencilWrite, { class: 'event-icon' }),
            csi: getIconMarkup(icons.csi, { class: 'event-icon' }),
            sms: getIconMarkup(icons.sms, { class: 'event-icon' }),
            virtual_appointment: getIconMarkup(icons.camera, { class: 'event-icon' }),
            test_drive: getIconMarkup(icons.testDrive, { class: 'event-icon' }),
        };
    }

    static get CALL() {
        return this.entries.call;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get APPOINTMENT() {
        return this.entries.appointment;
    }

    static get PHONE_APPOINTMENT() {
        return this.entries.phone_appointment;
    }

    static get DELIVERY() {
        return this.entries.delivery;
    }

    static get OTHER() {
        return this.entries.other;
    }

    static get CSI() {
        return this.entries.csi;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get VIRTUAL_APPOINTMENT() {
        return this.entries.virtual_appointment;
    }

    static get TEST_DRIVE() {
        return this.entries.test_drive;
    }
}
