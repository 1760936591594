const gray = {
    50: '#FBFBFB',
    100: '#F9F9F9',
    150: '#F5F5F5',
    200: '#F2F2F2',
    250: '#EDEDED',
    300: '#E9E9E9',
    350: '#DFDFDF',
    400: '#D5D5D5',
    450: '#c2c2c2',
    500: '#B0B0B0',
    550: '#9A9A9A',
    600: '#838383',
    650: '#6E6E6E',
    700: '#595959',
    750: '#4D4D4D',
    800: '#3B3B3B',
    900: '#232323',
};

module.exports = {
    inherit: 'inherit',
    current: 'currentColor',
    transparent: 'transparent',
    black: '#000000',
    white: '#FFFFFF',

    // Custom Colors
    gray,
    grey: gray, // TODO Delete in favor of "gray"
    blue: {
        100: '#EBF8FF',
        200: '#C1E1F4',
        300: '#91C5E3',
        400: '#67ACD5',
        500: '#3E8DBC',
        550: '#2E7FAD',
        600: '#2274A5',
        700: '#19618A',
        800: '#125278',
        900: '#11425F',
    },
    cyan: {
        100: '#E0FCFF',
        200: '#BEF8FD',
        300: '#87EAF2',
        400: '#54D1DB',
        500: '#38BEC9',
        600: '#2CB1BC',
        700: '#14919B',
        800: '#0A6C74',
        900: '#044E54',
    },
    pink: {
        100: '#FFF5F7',
        200: '#FED7E2',
        300: '#FBB6CE',
        400: '#F687B3',
        500: '#ED64A6',
        600: '#D53F8C',
        700: '#B83280',
        800: '#97266D',
        900: '#702459',
    },
    purple: {
        100: '#FAF5FF',
        200: '#E9D8FD',
        300: '#D6BCFA',
        400: '#B794F4',
        500: '#9F7AEA',
        600: '#805AD5',
        700: '#6B46C1',
        800: '#553C9A',
        900: '#44337A',
    },
    teal: {
        100: '#E6FFFA',
        200: '#B2F5EA',
        300: '#81E6D9',
        400: '#4FD1C5',
        500: '#38B2AC',
        600: '#319795',
        700: '#2C7A7B',
        800: '#285E61',
        900: '#234E52',
    },
    indigo: {
        100: '#EBF4FF',
        200: '#C3DAFE',
        300: '#A3BFFA',
        400: '#7F9CF5',
        500: '#667EEA',
        600: '#5A67D8',
        700: '#4C51BF',
        800: '#434190',
        900: '#3C366B',
    },
    green: {
        100: '#EAFAF1',
        200: '#C6F6D5',
        300: '#9AE6B4',
        400: '#68D391',
        500: '#00A65A',
        550: '#079D58',
        600: '#0D9156',
        700: '#1F8457',
        800: '#19713E',
        900: '#22543D',
    },
    yellow: {
        100: '#FFFFF0',
        200: '#FEFCBF',
        300: '#FAF089',
        400: '#F6E05E',
        500: '#ECC94B',
        600: '#D69E2E',
        700: '#B7791F',
        800: '#975A16',
        900: '#744210',
    },
    orange: {
        100: '#FEF3E2',
        200: '#FEE7BE',
        300: '#FBCD89',
        400: '#F6B055',
        500: '#F39C12',
        550: '#E78D18',
        600: '#DD7F20',
        700: '#C06321',
        800: '#9C5421',
        900: '#6E3717',
    },
    red: {
        100: '#FCEFED',
        200: '#FEDAD7',
        300: '#FEB2B2',
        400: '#FC8B82',
        500: '#DD4B39',
        550: '#D63824',
        600: '#C52F1B',
        700: '#AC3020',
        800: '#8B2323',
        900: '#5E2222',
    },
};
