import i18n from '@/plugins/vue-i18n.js';

export const formatCurrency = {
    toCent(value) {
        return round(value * 100, 0);
    },

    toDecimal(value) {
        return value / 100;
    },
};

export function addSpaceBeetweenThreeNumber(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function extractDecimals(value, fractionDigits = 2, fallback = '') {
    const parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
        return fallback;
    }

    return parsedValue.toFixed(fractionDigits).split('.')[1] || fallback;
}

export function toCurrency(value, decimals = true, hidden = false, fallback = '', country = 'CA') {
    const parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
        return hidden ? 'XX.XX' : fallback;
    }

    const maximumFractionDigits = decimals ? 2 : 0;

    const formattedValue = new Intl.NumberFormat(`${i18n.locale}-${country}`, {
        style: 'currency',
        currency: country.toUpperCase() === 'US' ? 'US' : 'CAD',
        maximumFractionDigits,
    }).format(parsedValue);

    return hidden ? formattedValue.replace(/\d/g, 'X') : formattedValue;
}

export function toLocaleNumber(value, fractionDigits = 0, fallback = '', country = 'CA') {
    const parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
        return fallback;
    }

    return new Intl.NumberFormat(`${i18n.locale}-${country}`, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(parsedValue);
}

export function toPercent(numerator, denominator, rounded = true) {
    if (!denominator) {
        return 0;
    }

    const percent = (numerator / denominator) * 100;
    return rounded ? Math.round(percent) : percent;
}

export function toPercentString(numerator, denominator, rounded = true) {
    if (!denominator) {
        return 'N/A';
    }

    let percent = (numerator / denominator) * 100;
    percent = rounded ? Math.round(percent) : percent;

    return `${percent}%`;
}

/**
 * Round a number to a given decimal
 *
 * @param {number} number The source number
 * @param {number} places The decimal to which we want to round the number
 * @return {number}       The number rounded to any given decimal place
 */
export function round(number, places) {
    return +`${Math.round(`${number}e+${places}`)}e-${places}`;
}

export function isValidNumber(number) {
    return !!number && /^\d+$/.test(number);
}
