export default [
    {
        path: '/landing/unsubscribe/:leadSid',
        name: 'unsubscribe.index',
        component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe/Unsubscribe.vue'),
        meta: {
            landing: true,
        },
    },
];
