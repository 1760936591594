export default {
    salesActivity: {
        title: 'Activité de vente',
        newLead: 'Nouveau lead',
        newLeadTooltip: 'Nombre total de nouveaux leads reçus. \n' +
            '\n' +
            'Le comptage est établi selon la date de création du lead qui se situe dans la plage de dates sélectionnée.',
        pendingLead: 'Lead en attente',
        pendingLeadTooltip: 'Nombre total de leads avec le résultat \'En Attente\'.\n' +
            '\n' +
            'Le comptage est établi selon la date de création du lead qui se situe dans la plage de dates sélectionnée.',
        awaitingReplyFromDealer: 'En attente du concessionnaire',
        awaitingReplyFromDealerTooltip: 'Total des leads actifs dont la dernière communication était entrante.\n' +
            '\n' +
            'Le comptage est établi selon la date de la dernière communication qui se situe dans la plage de dates sélectionnée.',
        awaitingReplyFromCustomer: 'En attente du client',
        awaitingReplyFromCustomerTooltip: 'Total des leads actifs dont la dernière communication est sortante.\n' +
            '\n' +
            'Le comptage est établi selon la date de la dernière communication qui se situe dans la plage de dates sélectionnée.',
        appointment: 'Rendez-vous',
        appointmentTooltip: 'Total de leads ayant un rendez-vous ou un rendez-vous téléphonique planifié\n' +
            '\n' +
            'Le comptage est établi selon la date du dernier rendez-vous dans la plage sélectionnée.',
        testDrive: 'Essai routier',
        testDriveTooltip: 'Total de leads ayant eu des essais routier. \n' +
            '\n' +
            'Le comptage est établi selon la date des essais routier.',
        storeVisits: 'Visites',
        storeVisitsTooltip: 'Total de leads ayant eu des visites.\n' +
            '\n' +
            'Le comptage est établi selon la date des visites',
        sold: 'Vendu',
        soldTooltip: 'Total de leads marqués comme vendus\n' +
            '\n' +
            'Le comptage est établi selon la date de vente de chaque lead.',
        delivered: 'Livré',
        deliveredTooltip: 'Total de leads marqués comme livrés.\n' +
            '\n' +
            'Le comptage est établi selon la date à laquelle chaque lead a été livré.',
        lost: 'Perdu',
        lostTooltip: 'Total de leads définis comme perdus.\n' +
            '\n' +
            'Le comptage est établi selon la date où chaque lead a été marqué comme perdu.',
    },
    newLeadByType: {
        title: 'Nouveau lead par type',
    },
    responseTime: {
        title: 'Temps de réponse',
        webTitle: 'Web',
        phoneUpTitle: 'Phone-up',
        lastDaysText: 'comparé aux {0} précédents',
        noChangeText: 'Pas de changement comparé aux {0} précédents',
        webPerformanceTooltip: 'Indique le délai moyen avant qu\'une première communication soit effectué sur un lead web, excluant toute communication manuelle ou automatique.',
        phoneUpTooltip: 'Indique la durée moyenne nécessaire pour transférer les appels de la réceptionniste au conseiller.',
        period: {
            '7days': '7 jours',
            '30days': '30 jours',
            '90days': '90 jours',
        },
        filters: {
            period: 'Periode de temps',
        },
    },
    tasks: {
        title: 'Tâches',
        addTask: 'Ajouter une tâche',
        noTasks: 'Aucune tâche correspondant aux filtres appliqués.',
    },
    appointments: {
        title: 'Rendez-vous',
        addAppointment: 'Ajouter un rendez-vous',
        noAppointments: 'Aucun rendez-vous correspondant aux filtres appliqués.',
    },
    filters: {
        divisions: 'Divisions',
        noDivision: 'Sans division',
        users: 'Tous les utilisateurs',
        period: 'Période de temps',
        appointmentType: 'Type de rendez-vous',
        taskType: 'Type de tâche',
    },
};
