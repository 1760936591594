import { mapState } from 'pinia';
import { useGlobalStore } from '../store/store.js';

export default {
    computed: {
        ...mapState(useGlobalStore, ['authUser']),
    },

    methods: {
        async getFreshdeskUrl() {
            if (this.authUser.locale == 'en') {
                return process.env.VUE_APP_SUPPORT_URL;
            }

            const response = await this.$api.freshdesk.getUrl({
                name: this.authUser.fullName,
                email: this.authUser.email,
                locale: this.authUser.locale,
                currentTimestamp: now().timestamp(),
                baseUrl: process.env.VUE_APP_FRESHDESK_BASE_URL,
            });

            return response;
        },

        async openFreshdesk(inNewTab = true) {
            if (inNewTab) {
                window.open(await this.getFreshdeskUrl(), '_blank');
            } else {
                window.location.replace(await this.getFreshdeskUrl());
            }
        },
    },
};
