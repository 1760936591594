<template>
    <div>
        <activix-select
            class="nav-select"
            :autosize="true"
            :options="[]"
            :placeholder="$t('multiselect.selectOption')"
            v-model="selectedTeamId"
        >
            <el-option :label="$t('general.allTeams')" :value="null" />
            <el-option
                :label="team.label"
                :value="team.value"
                :key="team.value"
                v-for="team in teams"
            />
        </activix-select>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        data() {
            return {
                selectedTeamId: null,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeam: 'contextTeam',
            }),

            teams() {
                return this.contextAccount.activeTeams.map(team => {
                    return {
                        value: team.id,
                        label: team.name || `${this.$t('general.team')} ${team.number}`,
                    };
                });
            },
        },

        watch: {
            'contextTeam.id': {
                immediate: true,
                handler() {
                    this.selectedTeamId = this.contextTeam.id || null;
                },
            },

            selectedTeamId() {
                this.setContextTeamId(this.selectedTeamId);
                this.updateSessionAction('team_id', this.selectedTeamId);
            },
        },
        methods: {
            ...mapActions(useContextStore, ['setContextTeamId']),
            ...mapActions(useGlobalStore, ['updateSessionAction']),
        },
    };
</script>
