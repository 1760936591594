<template>
    <activix-modal
        size="lg"
        portal="modal-2"
        :opened="opened"
        :closable="false"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('addLead.originalEmail') }}
            </h4>
        </template>
        <template slot="body">
            <activix-summernote
                :height="200"
                :min-height="160"
                :max-height="200"
                @input="$emit('update:originalEmail', $event)"
            />
        </template>
        <template slot="footer">
            <div class="flex justify-end">
                <activix-button @click="$emit('close')">
                    {{ $t('general.cancel') }}
                </activix-button>
                <activix-button type="primary" :disabled="disabled" @click="$emit('close')">
                    {{ $t('general.save') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
