<template>
    <warning
        :icon="$icons.warning"
        :title="$t('notFound.title')"
        :content="$t('notFound.details')"
        :show-home="true"
    />
</template>

<script>
    import Warning from '../components/Warning.vue';

    export default {
        components: { Warning },
    };
</script>
