import Enum from './Enum.js';

export default class AutomationCriterionType extends Enum {
    static get entries() {
        return {
            autocomplete: 'autocomplete',
            boolean: 'boolean',
            currency: 'currency',
            date: 'date',
            datetime: 'datetime',
            defined: 'defined',
            integer: 'integer',
            select: 'select',
            definedSelect: 'defined_select',
            string: 'string',
            method: 'method',
        };
    }

    static get AUTOCOMPLETE() {
        return this.entries.autocomplete;
    }

    static get BOOLEAN() {
        return this.entries.boolean;
    }

    static get CURRENCY() {
        return this.entries.currency;
    }

    static get DATE() {
        return this.entries.date;
    }

    static get DATETIME() {
        return this.entries.datetime;
    }

    static get DEFINED() {
        return this.entries.defined;
    }

    static get INTEGER() {
        return this.entries.integer;
    }

    static get SELECT() {
        return this.entries.select;
    }

    static get DEFINED_SELECT() {
        return this.entries.definedSelect;
    }

    static get STRING() {
        return this.entries.string;
    }

    static get METHOD() {
        return this.entries.method;
    }
}
