import ExportType from '@/entities/ExportType.js';
import Model from './Model.js';

export default class DashboardViewSubscription extends Model {
    static get defaults() {
        return {
            recurrence: 'noSubscription',
            account: [],
            start_at: null,
            userParam: null,
            type: ExportType.PDF,
            subGroupsIncluded: false,
        };
    }
}
