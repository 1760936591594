import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
        '3xl': Infinity,
    },
});
