<template>
    <splashscreen>
        <p class="text-lg mt-0 mb-6">
            <i18n path="accessSuspend.subtitle1" tag="span">
                {{ accountName }}
            </i18n><br />
            {{ $t('accessSuspend.subtitle2') }}
        </p>
        <template v-if="onChildAccount">
            <activix-button type="primary" :loading="loading" @click="goBack">
                {{ $t('accessSuspend.goBack') }}
            </activix-button>
            <activix-button class="ml-4" :loading="loading" @click="goHome">
                {{ $t('accessSuspend.goHome') }}
            </activix-button>
        </template>
        <template v-else>
            <activix-button type="primary" :loading="loading" @click="reload">
                {{ $t('accessSuspend.reload') }}
            </activix-button>
            <activix-button class="ml-4" @click="logout">
                {{ $t('accessSuspend.logout') }}
            </activix-button>
        </template>
    </splashscreen>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import Splashscreen from '../layouts/Splashscreen.vue';

    // Pinia
    import { useContextStore } from '../store/modules/context/store.js';

    export default {
        components: {
            Splashscreen,
        },

        data() {
            return {
                loading: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            parentUser() {
                return this.$auth.user();
            },

            accountName() {
                return this.contextAccount.name ? this.contextAccount.name : this.parentUser.account.name;
            },

            onChildAccount() {
                return this.contextAccount.id && this.parentUser.account.id != this.contextAccount.id;
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction']),

            reload() {
                this.loading = true;
                window.location.reload();
            },

            logout() {
                this.$auth.logout();
            },

            goBack() {
                this.loading = true;
                this.setContextAccountAction(this.parentUser.account.id);
                this.$router.back();
            },

            goHome() {
                this.loading = true;
                this.setContextAccountAction(this.parentUser.account.id);
                this.$router.push('/');
            },
        },

        i18n: {
            messages: {
                en: {
                    accessSuspend: {
                        reload: 'Reload',
                        logout: 'Logout',
                        goBack: 'Back to the last page',
                        goHome: 'Go to home',
                        subtitle1: 'There was a problem with your subscription ({0}).',
                        subtitle2: 'Please contact your management or accounting team.',
                    },
                },
                fr: {
                    accessSuspend: {
                        reload: 'Rafraîchir',
                        logout: 'Déconnexion',
                        goBack: 'Retour à la dernière page',
                        goHome: "Aller à l'accueil",
                        subtitle1: 'Un problème est survenu avec votre abonnement ({0}).',
                        subtitle2: 'Veuillez contacter votre équipe de direction ou la comptabilité.',
                    },
                },
            },
        },
    };
</script>
