export default {
    alerts: {
        index: {
            error: 'Échec de la récupération des tâches et événements',
        },
        store: {
            error: 'Échec de la création de la tâche ou événement',
            success: 'Tâche ou événement créé avec succès',
        },
        show: {
            error: 'Échec de la récupération de la tâche ou événement',
        },
        update: {
            error: 'Échec de la mise à jour de la tâche ou événement',
            success: 'Tâche ou événement mis à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression de la tâche ou événement',
            success: 'Tâche ou événement supprimé avec succès',
        },
    },
};
