import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async getTaskEvents(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/task-events', {
            params: payload,
        });

        return response.data.data;
    },

    async getWidgetData(payload = {}) {
        if (!payload.accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        const response = await axios.get('v1/home', {
            params: payload,
        });

        return response.data;
    },
};
