export default {
    accountManagement: 'Accounts',
    userManagement: 'Users',

    lockNavigation: 'Lock navigation',
    unlockNavigation: 'Unlock navigation',

    sidebar: {
        webOrderBadgeTooltip: 'Active pending web order | Active pending web orders',
        activityReport: '@:dashboards.activityReport',
        allLead: '@:dashboards.allLead',
        automations: 'Automations',
        campaign: 'Campaign',
        commercial: 'F&I',
        duplicates: 'Duplicates',
        event: '@:dashboards.event',
        flow: 'Flow',
        headers: {
            client: 'Client',
            tools: 'Tools',
            management: 'Management',
        },
        homepage: 'Homepage',
        import: 'Import',
        inventory: 'Inventory',
        inTurn: 'Who\'s Next',
        analytics: 'Analytics',
        leadXpress: '@:dashboards.leadXpress',
        loyalty: '@:dashboards.loyalty',
        objectives: 'Objectives',
        phoneUp: '@:dashboards.phoneUp',
        renewal: '@:dashboards.renewal',
        templates: 'Templates',
        saleTable: '@:dashboards.saleTable',
        schedule: 'Schedule',
        service: '@:dashboards.service',
        trash: 'Trash',
        walkIn: '@:dashboards.walkIn',
        webBoost: '@:dashboards.webBoost',
        webOrder: '@:dashboards.webOrder',
    },
    userDropdown: {
        accounts: 'Accounts',
        changelog: 'Changelog',
        disconnect: 'Sign out',
        groups: 'Groups',
        training: 'Training',
        help: 'Help',
        myAccount: 'My account',
        myProfile: 'My profile',
        suggestions: 'Suggestions',
        users: 'Users',
        turnOffNotifications: 'Suspend notifications',
        turnOffNotificationsDetails: 'Do you want to suspend the reception of notification and redirect them to your screen?',
        turningOnNotifications: 'Notifications have been turned on',
    },
};
