import Enum from './Enum.js';

export default class Service extends Enum {
    static get entries() {
        return {
            webboost: 1,
            telboost: 2,
            revive: 3,
            lead_xpress: 4,
            niotext: 5,
            facebook_messenger: 6,
            video: 7,
        };
    }

    static get WEBBOOST() {
        return this.entries.webboost;
    }

    static get TELBOOST() {
        return this.entries.telboost;
    }

    static get REVIVE() {
        return this.entries.revive;
    }

    static get LEAD_XPRESS() {
        return this.entries.lead_xpress;
    }

    static get NIOTEXT() {
        return this.entries.niotext;
    }

    static get FACEBOOK_MESSENGER() {
        return this.entries.facebook_messenger;
    }

    static get VIDEO() {
        return this.entries.video;
    }
}
