/* eslint-disable import/first, import/no-import-module-exports */
// global.process = process; // DO NOT DELETE...REQUIRED FOR STUPID WORKERS.

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import fr from '../locales/fr/index.js';
import en from '../locales/en/index.js';

Vue.use(VueI18n);

let locale = 'en';

if (typeof localStorage !== 'undefined') {
    locale = localStorage.getItem('currentLocale');

    if (!locale || !['fr', 'en'].includes(locale)) {
        locale = navigator.language.includes('fr') ? 'fr' : 'en';
    }

    localStorage.setItem('currentLocale', locale);
}

const numberFormats = {
    USD: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
    },
    CAD: {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'narrowSymbol',
    },
    decimal: {
        style: 'decimal',
    },
    km: {
        style: 'unit',
        unit: 'kilometer',
    },
    mi: {
        style: 'unit',
        unit: 'mile',
    },
    hr: {
        style: 'unit',
        unit: 'hour',
    },
};

const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
        fr,
        en,
    },
    numberFormats: {
        fr: numberFormats,
        en: numberFormats,
    },
    missing: (lang, key) => {
        const message = `Missing translation: ${key} (${lang})`;

        console.warn(message); // eslint-disable-line no-console

        Sentry.captureMessage(message, 'info');
    },
});

// Hot Reloading
if (module.hot) {
    module.hot.accept(['../locales/fr', '../locales/en'], () => {
        i18n.setLocaleMessage('en', en);
        i18n.setLocaleMessage('fr', fr);
    });
}

export default i18n;
