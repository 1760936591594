import Vue from 'vue';
import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars
import axios from './axios.js';

const echoConfig = {
    auth: {
        headers: {
            Authorization: null,
        },
    },
    broadcaster: 'pusher',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    key: process.env.VUE_APP_PUSHER_KEY,
    channelAuthorization: {
        endpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
        headers: {
            Authorization: `Bearer ${Vue.auth.token()}`,
        },
    },
};

if (process.env.VUE_APP_POXA_KEY) {
    echoConfig.forceTLS = false;
    echoConfig.wsHost = process.env.VUE_APP_POXA_HOST;
    echoConfig.wsPort = process.env.VUE_APP_POXA_PORT;
    echoConfig.enabledTransports = ['ws'];
    echoConfig.key = process.env.VUE_APP_POXA_KEY;
}

const echo = new LaravelEcho(echoConfig);

const broadcasting = new Vue({
    data() {
        return {
            echo,
            channels: {
                account: null,
                global: null,
                user: null,
            },
        };
    },

    methods: {
        connect() {
            this.echo.connector.options.channelAuthorization.headers.Authorization = `Bearer ${Vue.auth.token()}`;
            this.echo.connector.pusher.connect();

            this.bindUserChannel();
            this.bindGlobalChannel();
        },

        disconnect() {
            this.echo.disconnect();
        },

        connected() {
            axios.defaults.headers.common['X-Socket-ID'] = this.echo.socketId();
        },

        disconnected() {
            delete axios.defaults.headers.common['X-Socket-ID'];
        },

        bindGlobalChannel() {
            this.channels.global = this.echo.channel('global');

            this.$emit('global-channel-ready');
        },

        bindAccountChannel(accountId) {
            this.channels.account = this.echo.private(`account_${accountId}`);

            this.$emit('account-channel-ready');
        },

        bindUserChannel() {
            this.channels.user = this.echo.private(`user_${Vue.auth.user().id}`);

            this.$emit('user-channel-ready');
        },

        unbindChannels() {
            this.unbindGlobalChannel();
            this.unbindAccountChannel();
            this.unbindUserChannel();
        },

        unbindGlobalChannel() {
            if (this.channels.global) {
                this.echo.leave(this.channels.global.name);
            }
        },

        unbindAccountChannel() {
            if (this.channels.account) {
                this.echo.leave(this.channels.account.name);
            }
        },

        unbindUserChannel() {
            if (this.channels.user) {
                this.echo.leave(this.channels.user.name);
            }
        },

        userChanged(newUserId, oldUserId) {
            if (newUserId == oldUserId) {
                return;
            }

            if (!newUserId) {
                this.unbindChannels();
                this.disconnect();
            } else if (!oldUserId) {
                this.connect();
            } else {
                this.unbindChannels();
                this.connect();
            }
        },

        accountChanged(newAccountId, oldAccountId) {
            if (newAccountId == oldAccountId) {
                return;
            }

            this.unbindAccountChannel();
            this.bindAccountChannel(newAccountId);
        },
    },

    created() {
        this.echo.connector.pusher.connection.bind('connected', event => this.connected(event));
        this.echo.connector.pusher.connection.bind('disconnected', () => this.disconnected());
    },
});

const Broadcasting = {
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        VueInstance.broadcasting = broadcasting;
        VueInstance.prototype.$broadcasting = broadcasting;
    },
};

Vue.use(Broadcasting);
