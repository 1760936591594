import i18n from '../plugins/vue-i18n.js';
import { formatCurrency } from '../utils/numbers.js';
import { toMoney, toUrl } from '../utils/index.js';
import ActivixDate from './ActivixDate.js';

export const parseCustomField = {
    toEdit(value, type = '') {
        switch (type) {
            case 'array':
                return value.map(item => {
                    return {
                        id: item,
                        label: item,
                    };
                });
            case 'currency':
                return formatCurrency.toDecimal(value[0]);
            case 'boolean':
            case 'datetime':
            case 'string':
            case 'url':
            default:
                return value[0];
        }
    },

    toRead(value, type = '') {
        switch (type) {
            case 'array':
                return value.join(', ');
            case 'boolean':
                return value[0] ? i18n.t('general.yes') : i18n.t('general.no');
            case 'currency':
                return toMoney(parseCustomField.toEdit(value, type), 2);
            case 'datetime':
                return new ActivixDate(parseCustomField.toEdit(value, type)).toHumanShort();
            case 'url':
                return toUrl(parseCustomField.toEdit(value, type));
            case 'string':
            default:
                return parseCustomField.toEdit(value, type);
        }
    },

    toSave(value, type = '') {
        switch (type) {
            case 'array':
                return value;
            case 'boolean':
                return [value];
            case 'currency':
                return [formatCurrency.toCent(value)];
            case 'datetime':
                return [value];
            case 'string':
                return [value];
            default:
                return [value];
        }
    },
};
