<template>
    <a
        v-bind="$attrs"
        :href="to"
        target="_blank"
        v-if="isExternalLink"
    >
        <slot />
    </a>
    <router-link
        v-bind="$props"
        custom
        v-else
        v-slot="{ href, navigate }"
    >
        <a
            v-bind="$attrs"
            :href="href"
            @click="navigate"
        >
            <slot />
        </a>
    </router-link>
</template>

<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'a',
            },
            to: {
                type: [String, Object],
                required: true,
            },
        },

        computed: {
            isExternalLink() {
                return typeof this.to === 'string' && this.to.startsWith('http');
            },
        },
    };
</script>
