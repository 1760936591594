import { initValues } from '@/entities/ModelHelper.ts';
import ConditionQuestion from '@/entities/ConditionQuestion.ts';

export default class VehicleCondition {
    private _condition_questions: ConditionQuestion[] = [];

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set condition_questions(conditionQuestions) {
        this._condition_questions = conditionQuestions.map((question: any) => new ConditionQuestion(question));
    }

    get condition_questions(): ConditionQuestion[] {
        return this._condition_questions;
    }
}
