import Lead from '../../../entities/Lead.js';
import Account from '../../../entities/Account.js';
import { useGlobalStore } from '../../store.js';
import { useContextStore } from './store.js';

export const getters = {
    contextLead() {
        const lead = useGlobalStore().leads.find(l => l.id == this.leadId);

        return lead || new Lead();
    },
    contextTeam() {
        return this.account.teams.find(team => team.id == this.teamId) || {};
    },

    contextDivision() {
        const division = useGlobalStore().divisions.find(division => division.id == this.divisionId);
        return division || {};
    },
    contextChildAccount() {
        if (!this.account || this.account.account_manager) {
            return null;
        }

        return this.account;
    },
};

export function findContextAccount(accountId) {
    if (useContextStore().account.id == accountId) {
        return useContextStore().account;
    }

    let account = useContextStore().account.children.find(a => a.id === accountId);
    if (account) {
        return account;
    }

    if (useContextStore().group.accounts) {
        account = useContextStore().group.accounts.find(a => a.id === accountId);
        if (account) {
            account = new Account(account);
        }
    }

    return account;
}
