<template>
    <button
        class="rounded-full shadow-md flex items-center justify-center"
        :class="[classes, linkColor]"
        @click="onClick"
    >
        <slot />
    </button>
</template>

<script>
    export default {
        name: 'ActivixRoundButton',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            active: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            linkColor() {
                if (this.disabled) {
                    return this.active ? 'text-blue-300' : 'text-grey-400';
                }

                return this.active ? 'link-blue' : 'link-grey';
            },

            classes() {
                if (this.disabled) {
                    return 'bg-grey-100 cursor-not-allowed';
                }

                return 'bg-white hover:bg-grey-100';
            },
        },

        methods: {
            onClick() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            },
        },
    };
</script>
