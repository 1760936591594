<template>
    <div class="flex items-center border-t border-gray-200" :class="[hasLeadAccessClass, isSelectedClass]">
        <router-link :to="{ name: 'leads.update', params: { id: lead.id } }" v-slot="{ href }" custom>
            <component
                :is="hasLeadAccess ? 'a' : 'div'"
                :href="hasLeadAccess ? href : '#'"
                class="flex-1 overflow-hidden text-grey-800 hover:text-grey-800 focus:text-grey-800"
                @click.prevent="onClick"
            >
                <div class="relative flex">
                    <div class="flex items-center flex-1">
                        <div class="pl-4 py-4 | md:py-3 ">
                            <inter-account-leads-result-item-icon :lead="lead" />
                        </div>
                        <div class="w-0 flex items-center flex-1 flex-row | md:flex-col md:items-start | xl:flex-row xl:items-center">
                            <div class="w-full flex-1 pr-8 px-4 py-0 overflow-hidden whitespace-nowrap | md:py-0 | xl:pr-0 xl:py-4">
                                <div>
                                    <div class="font-semibold truncate text-gray-650 | md:text-lg truncate" v-text="lead.account_name" />
                                    <div class="truncate mt-1" v-text="lead.fullName || $t('clientCard.unknown')" />
                                    <activix-tooltip :content="vehicleTooltip" :only-on-truncate="true">
                                        <div class="truncate" v-text="vehicle" />
                                    </activix-tooltip>
                                </div>
                            </div>
                            <div class="flex flex-col mt-0 px-4 items-end justify-center space-y-1 | md:flex-row md:mt-1 | xl:flex-col xl:mt-0">
                                <activix-tooltip :content="dateTime">
                                    <div class="text-gray-600 inline | md:hidden | xl:inline" v-text="date" />
                                </activix-tooltip>
                                <result-time
                                    :lead="lead"
                                    computed-result
                                    read-only
                                    side-by-side
                                />
                                <process-state
                                    class="text-gray-700 | md:ml-3 | xl:ml-0"
                                    :lead="lead"
                                />
                            </div>
                            <activix-tooltip
                                :content="$t('clientCard.readOnly')"
                                v-if="!hasLeadAccess"
                            >
                                <icon class="text-grey-650 absolute top-0 left-0  mt-1.5 | md:mt-2  ml-2 | md:ml-4  text-sm" name="regular/lock-1" />
                            </activix-tooltip>
                        </div>
                    </div>
                </div>
            </component>
        </router-link>
    </div>
</template>

<script>
    import Lead from '@/entities/Lead.js';
    import LeadVehicle from '@/entities/LeadVehicle.js';
    import ResultTime from '@/components/lead/ResultTime.vue';
    import ProcessState from '@/components/lead/ProcessState.vue';
    import InterAccountLeadsResultItemIcon from '@/components/inter_account_leads/InterAccountLeadsResultItemIcon.vue';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ResultTime,
            ProcessState,
            InterAccountLeadsResultItemIcon,
        },

        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),

            isSelected() {
                return this.$route.name == 'leads.update' && this.lead.id == this.$route.params.id;
            },

            vehicleString() {
                const vehicle = new LeadVehicle(this.lead.getSoldOrFirstWantedVehicle() || {});
                let value = vehicle.toString();

                if (vehicle.stock) {
                    value += ` (#${vehicle.stock})`;
                }

                return value.trim();
            },

            vehicle() {
                return this.vehicleString || this.$t('addLead.noVehicle');
            },

            vehicleTooltip() {
                return this.vehicleString ? this.vehicle : '';
            },

            hasLeadAccess() {
                return this.parentAuthUser.hasAccessToSeeAccount(this.lead.account_id);
            },

            hasLeadAccessClass() {
                return this.hasLeadAccess ? 'cursor-pointer' : 'opacity-70 cursor-not-allowed';
            },

            isSelectedClass() {
                if (!this.hasLeadAccess) {
                    return '';
                }

                return this.isSelected ? 'bg-gray-200' : 'hover:bg-gray-100';
            },
            dateTime() {
                return `${this.$t('addLead.createdAt')} ${new ActivixDate(this.lead.created_at).toHumanShort(true)}`;
            },
            date() {
                return new ActivixDate(this.lead.created_at).toHumanShort(false);
            },
        },

        methods: {
            onClick() {
                if (!this.hasLeadAccess) {
                    return;
                }

                if (!this.isSelected) {
                    this.$emit('click');
                }
            },
        },
    };
</script>
