export default {
    answered: 'Répondu',
    attempted: 'Tentative',
    calling: 'En cours',
    delivery_failed: 'Échec',
    error: 'Erreur',
    interrupted: 'Interrompu',
    invalid: 'Invalide',
    pending: 'En attente',
    unanswered: 'Non-répondu',
};
