import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';
import NotificationGroup from '../entities/NotificationGroup.js';

export default {
    async index(group, payload = {}) {
        if (!NotificationGroup.exists(group)) {
            throw new InvalidPayload('Invalid group.');
        }

        const response = await axios.get('v1/notifications', { params: { ...payload, group, channels: ['screen'] } });

        return response.data.data;
    },

    async notSeenCount(group) {
        if (!NotificationGroup.exists(group)) {
            throw new InvalidPayload('Invalid group.');
        }

        const response = await axios.get('v1/notifications/notSeenCount', {
            params: { group, channels: ['screen'] },
        });

        return response.data;
    },

    async markAsSeen(id) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        await axios.post('v1/notifications/seen', { id });
    },

    async markAllAsSeen(group) {
        if (!NotificationGroup.exists(group)) {
            throw new InvalidPayload('Invalid group.');
        }

        await axios.post('v1/notifications/seen', { group, channels: ['screen'] });
    },
};
