import Enum from './Enum.js';

export default class NotificationType extends Enum {
    static get entries() {
        return {
            communication: 'communication',
            general: 'general',
            event: 'event',
            task: 'task',
            other: 'other',
        };
    }

    static get COMMUNICATION() {
        return this.entries.communication;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get GENERAL() {
        return this.entries.general;
    }

    static get EVENT() {
        return this.entries.event;
    }

    static get TASK() {
        return this.entries.task;
    }

    static get OTHER() {
        return this.entries.other;
    }
}
