<template>
    <component
        :is="tag"
        class="btn | relative"
        :class="classes"
        :disabled="disabled || loading"
        :type="buttonType"
        :to="to"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <span class="absolute inset-0 flex items-center justify-center" v-if="loading">
            <icon class="spin-inverse" :name="$icons.loading" />
        </span>
        <span class="w-full flex items-center justify-center" :class="{ invisible: loading }">
            <slot />
        </span>
    </component>
</template>

<script>
    const types = {
        default: 'btn-default',
        primary: 'btn-primary',
        success: 'btn-success',
        info: 'btn-info',
        warning: 'btn-warning',
        danger: 'btn-danger',
        grey: 'btn-grey',
        white: 'btn-white',
        outline: 'btn-outline',
        'outline-white': 'btn-outline-white',
    };

    export const sizes = {
        large: 'btn-lg',
        medium: 'btn-md',
        small: 'btn-sm',
        mini: 'btn-xs',
    };

    export default {
        name: 'ActivixButton',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => Object.keys(sizes).includes(value),
            },
            type: {
                type: String,
                default: 'default',
                validator: value => Object.keys(types).includes(value),
            },
            submit: {
                type: Boolean,
                default: false,
            },
            active: {
                type: Boolean,
                default: false,
            },
            tag: {
                type: String,
                default: 'button',
            },
            to: {
                type: [String, Object],
                default: '',
            },
        },

        computed: {
            buttonType() {
                if (this.tag !== 'button') {
                    return null;
                }

                return this.submit ? 'submit' : 'button';
            },

            classes() {
                return [this.typeClass, this.sizeClass, { active: this.active }];
            },

            typeClass() {
                return types[this.type];
            },

            sizeClass() {
                return sizes[this.size];
            },
        },
    };
</script>
