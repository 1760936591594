import { defineStore } from 'pinia';
import state from './state.js';
import actions from './actions.js';

export const useUserCardStore = defineStore({
    id: 'userCard',
    state() {
        return state;
    },
    actions,
});
