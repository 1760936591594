<template>
    <div class="flex flex-col" v-if="inventoryUnit">
        <div class="flex-1 flex flex-col shadow mt-3 | group transition">
            <div class="flex justify-between items-center pl-4 pr-4 rounded-b-md bg-white | 3xl:pl-3">
                <div class="flex flex-col gap-1.5 py-4 w-2/5">
                    <div class="space-x-1">
                        <span
                            :class="{'italic text-gray-500' : !inventoryUnit.year}"
                            v-text="inventoryUnit.year || $t('clientCard.vehicles.year')"
                        />
                        <span
                            :class="{'italic text-gray-500': !inventoryUnit.make}"
                            v-text="inventoryUnit.make || $t('clientCard.vehicles.make')"
                        />
                    </div>

                    <div
                        class="text-xl"
                        :class="inventoryUnit.model ? 'text-gray-800' : 'italic text-gray-500'"
                        v-text="inventoryUnit.model || $t('clientCard.vehicles.model')"
                    />

                    <div class="flex items-center gap-x-1.5">
                        <activix-tooltip :content="inventoryUnit.trim">
                            <span
                                class="max-w-[8vw] truncate"
                                :class="inventoryUnit.trim ? 'text-sm text-gray-800': 'italic text-gray-500'"
                                v-text="inventoryUnit.trim || $t('clientCard.vehicles.noTrim')"
                            />
                        </activix-tooltip>

                        <div class="shrink-0 flex items-center gap-1.5 text-gray-650" v-if="inventoryUnit.mileage">
                            <span v-text="`–`" />
                            <span v-text="mileageText" />
                        </div>
                    </div>
                </div>

                <div class="shrink-0 flex flex-col gap-2 justify-between py-3 w-3/5">
                    <div class="flex flex-wrap items-center space-x-1.5 justify-end w-full gap-1.5">
                        <span class="w-2 h-2 rounded-full" :class="stockStatusColor" />
                        <activix-tooltip :content="inventoryUnit.status">
                            <span class="text-sm truncate" v-text="stockStatusText" />
                        </activix-tooltip>
                        <samp :class="{'px-1 border border-gray-300 rounded uppercase tracking-widest bg-white truncate' : inventoryUnit.stock }" v-text="inventoryUnit.stock ? `#${inventoryUnit.stock}` : $t('clientCard.vehicles.noStockNumber')" />
                    </div>

                    <div class="flex items-center justify-end text-right">
                        <samp class="text-sm tracking-wide tabular-nums text-gray-800" v-text="inventoryUnit.vin" v-if="inventoryUnit.vin" />
                        <span class="text-sm italic text-gray-500" v-text="$t('clientCard.boxes.vehicles.noVinInfo')" v-else />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center mt-2" @click="changeInventoryUnit">
            <label class="mb-0 text-gray-500 text-sm cursor-pointer select-none" v-text="changeInventoryUnitText" />
        </div>
    </div>
</template>

<script>
    import Account from '@/entities/Account.js';
    import InventoryStockStatus from '@/entities/InventoryStockStatus.js';
    import { toLocaleNumber } from '../../utils/numbers.js';

    export default {
        props: {
            account: {
                type: Account,
                required: true,
            },
            inventoryUnit: {
                type: Object,
                required: false,
            },
            category: {
                type: String,
                default: 'car',
            },
        },

        computed: {
            mileageText() {
                return `${toLocaleNumber(this.inventoryUnit.mileage)} km`;
            },

            stockStatusColor() {
                const statusColor = {
                    in_stock: 'text-white bg-green-500',
                    available: 'text-white bg-green-500',
                    unavailable: 'text-white bg-red-500',
                    order: 'text-white bg-blue-500',
                    reserved: 'text-white bg-blue-500',
                    locate: 'text-white bg-orange-500',
                }[this.inventoryUnit.status];

                if (statusColor && this.inventoryUnit.stock) {
                    return statusColor;
                }

                return 'text-gray-600 bg-gray-300';
            },

            stockStatusText() {
                const stock = InventoryStockStatus.getTranslation(this.inventoryUnit.status);

                if (stock && this.inventoryUnit.stock) {
                    return stock;
                }

                return this.$t('inventory.stockStatusTBD');
            },

            changeInventoryUnitText() {
                return this.$t('inventory.changeInventoryUnit');
            },
        },

        methods: {
            getPolestarTestDriveLabel() {
                if (this.inventoryUnit.isPolestarTestDriveAYL) {
                    return this.$t('clientCard.roadTestAYL');
                }

                if (this.inventoryUnit.isPolestarTestDriveTDM) {
                    return this.$t('clientCard.roadTestTDM');
                }

                return this.$tc('clientCard.roadTest');
            },

            changeInventoryUnit() {
                this.$router.push({ name: 'inventory' });
                this.$emit('reset-inventory-vehicle-object');
            },
        },
    };
</script>
