import { isEmpty, omitBy, isNil, get, find } from 'lodash-es';

import { formatPhone, orderByKey, toMoney, getFullName } from '@/utils/index.js';
import { maskEmail, maskPhone } from '@/utils/mask.js';
import { timeHms } from '@/utils/time.js';
import { toCurrency } from '@/utils/numbers.js';

import LeadType from '../entities/LeadType.js';
import Moment from '../value-objects/Moment.js';

export default {
    data() {
        return {
            mounted: false,
        };
    },

    methods: {
        // Resets component to its default state.
        $reset({ redirectTo } = {}) {
            Object.assign(this.$data, this.$options.data.apply(this));

            if (redirectTo) {
                this.$router.push(redirectTo);
            }
        },

        average(numerator, denominator, stringify) {
            if (stringify) {
                return denominator ? Math.round(numerator / denominator) : 'N/A';
            }

            return denominator ? Math.round(numerator / denominator) : 0;
        },

        averageComma(numerator, denominator, stringify, decimal) {
            if (stringify) {
                return denominator ? Math.round((numerator / denominator) * decimal) / decimal : 'N/A';
            }

            return denominator ? Math.round((numerator / denominator) * decimal) / decimal : 0;
        },

        count(value) {
            if (value !== undefined) {
                return typeof value === 'object' ? Object.keys(value).length : value.length;
            }

            return 0;
        },

        underscore(value) {
            if (!value) {
                return '';
            }

            return value.replace(/([A-Z])/g, match => {
                return `_${match.toLowerCase()}`;
            });
        },

        maskEmail(email) {
            return maskEmail(email);
        },

        maskPhone(number) {
            return maskPhone(number);
        },

        moment(date) {
            if (get(date, 'date')) {
                date = date.date;
            }

            if (Moment.now().diffInSeconds(date) < 60) {
                return this.$t('general.lessThanAMin');
            }

            return Moment.init(date).fromNow();
        },

        futurMoment(date) {
            if (get(date, 'date')) {
                date = date.date;
            }

            return Moment.init(date).fromNow();
        },

        filename(call, lead = null) {
            let fileName = '';

            const createdAt = Moment.asLocale(call.created_at, 'created_at');

            if (call.lead) {
                fileName += `${getFullName(call.lead)} ${createdAt.humanShort(true)}`;
            } else if (lead) {
                fileName += `${getFullName(lead)} ${createdAt.humanShort(true)}`;
            } else {
                fileName += createdAt.humanShort(true);
            }

            fileName = fileName.replace(/\s+/g, '-').toLowerCase();

            return fileName;
        },

        getExtension(communication) {
            if (!communication || !communication.url) {
                return '';
            }

            if (communication.url.endsWith('.mp3')) {
                return '.mp3';
            }

            return '.wav';
        },

        decodeString(html) {
            const txt = document.createElement('textarea');

            txt.innerHTML = html;

            return txt.value;
        },

        navigateToLead(id) {
            if (!id) {
                return;
            }

            this.$router.push({
                name: 'leads.update',
                params: { id },
            });
        },

        getFullName(obj, unknown = true) {
            return getFullName(obj, unknown);
        },

        getShortName(userOrAccount) {
            let name = getFullName(userOrAccount);

            if (name.indexOf(' ') > 0) {
                name = `${name.charAt(0)}. ${name.substring(name.indexOf(' '), name.length)}`;
            }

            return name;
        },

        timeHms(timeInSeconds, hideSeconds) {
            return timeHms(timeInSeconds, hideSeconds);
        },

        emailIsValid(email) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return regex.test(email);
        },

        validatePhone(phone) {
            // eslint-disable-next-line
            let re = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
            return re.test(phone);
        },

        urlIsValid(url) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

            return regex.test(url);
        },

        toCurrency(value, decimals = true, hidden = false, fallback = '', country = 'CA') {
            return toCurrency(value, decimals, hidden, fallback, country);
        },

        toMoney(decimal, fixed = 0, locale = undefined, hidden = false) {
            return toMoney(decimal, fixed, locale, hidden);
        },

        formatPhone(number, withCountryCode = false) {
            return formatPhone(number, withCountryCode);
        },

        getLeadType(id) {
            // @TODO Find a way to get the lead types from config
            const leadType = find(LeadType.list, obj => obj.id == id);

            return !isEmpty(leadType) ? leadType.name : '';
        },

        getLeadTypeIcon(lead) {
            const leadType = this.getLeadType(lead.lead_type_id);

            switch (leadType) {
                case 'email':
                    return this.$icons.leadXpress;

                case 'phone':
                    return this.$icons.phone;

                case 'sms':
                    return this.$icons.sms;

                case 'walk_in':
                    return this.$icons.visit;

                case 'loyalty':
                    return this.$icons.loyalty;

                case 'renewal':
                    return this.$icons.renewal;

                case 'event':
                    return this.$icons.event;

                case 'dms':
                case 'pre_booking':
                    return this.$icons.service;

                case 'web_order':
                    return this.$icons.webOrder;
            }

            return '';
        },

        formatExtension(text) {
            if (!text) return '';

            text = text.replace(/\D/g, '');

            if (!text) return '';

            return text;
        },

        as_locale(date, key, format = '', timezone = '', defaultNow = false) {
            return Moment.asLocale(date, key, { format, timezone, defaultNow });
        },

        locale_dt(date, format = '', timezone = '', defaultNow = false) {
            return Moment.init(date, 'dateTime', { format, timezone, defaultNow });
        },

        localeDate(date, format = '', timezone = '', defaultNow = false) {
            return Moment.init(date, 'date', { format, timezone, defaultNow });
        },

        locale_time(time, timezone) {
            return Moment.init(time, 'time', { timezone });
        },

        empty(value) {
            return empty(value);
        },

        updateQuery(newQuery) {
            const query = orderByKey({
                ...this.$route.query,
                ...newQuery,
            });

            // omitBy is used to remove empty values
            this.$router.replace({ query: omitBy(query, isNil) });
        },

        getFileName(fileString) {
            const firstIndex = fileString.lastIndexOf('/') + 1;
            const lastIndex = fileString.length;

            return fileString.slice(firstIndex, lastIndex);
        },

        includeInLayout(item) {
            return get(this.$route.meta, `layout.${item}`, true);
        },
    },

    computed: {
        isLandscape() {
            return window.innerWidth > window.innerHeight;
        },

        phoneLayout() {
            return this.mdLayout || (this.lgLayout && this.isLandscape);
        },

        xsLayout() {
            return ['xs'].includes(this.$mq);
        },

        smLayout() {
            return ['xs', 'sm'].includes(this.$mq);
        },

        mdLayout() {
            return ['xs', 'sm', 'md'].includes(this.$mq);
        },

        lgLayout() {
            return ['xs', 'sm', 'md', 'lg'].includes(this.$mq);
        },

        xlLayout() {
            return ['xs', 'sm', 'md', 'lg', 'xl'].includes(this.$mq);
        },

        xxlLayout() {
            return ['2xl', '3xl'].includes(this.$mq);
        },
    },
};
