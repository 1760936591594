import Vue from 'vue';
import api from '../api/index.js';

const VueApi = {
    install(Vue) {
        Vue.api = api;
        Vue.prototype.$api = api;
    },
};

Vue.use(VueApi);
