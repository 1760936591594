export default {
    showMore: 'Voir plus...',
    pageTitle: 'Calendrier',
    show: 'Afficher',
    hide: 'Cacher',
    agenda: 'Agenda',
    atTaskEventTime: 'Au moment même',
    toggleCallOn: 'Afficher les appels',
    toggleCallOff: 'Cacher les appels',
    toggleCsiOn: 'Afficher les CSI',
    toggleCsiOff: 'Cacher les CSI',
    toggleEmailOn: 'Afficher les courriels',
    toggleEmailOff: 'Cacher les courriels',
    toggleEmailSmsOn: 'Afficher les courriels / SMS',
    toggleEmailSmsOff: 'Cacher les courriels / SMS',
    toggleEventOn: 'Afficher les rendez-vous',
    toggleEventOff: 'Cacher les rendez-vous',
    toggleDeliveryOn: 'Afficher les @:lexicon.delivery.plural',
    toggleDeliveryOff: 'Cacher les @:lexicon.delivery.plural',
    toggleDeliveryGasFullOn: 'Afficher les @:lexicon.delivery.plural avec carburant',
    toggleDeliveryGasFullOff: 'Cacher les @:lexicon.delivery.plural avec carburant',
    toggleDeliveryGasEmptyOn: 'Afficher les @:lexicon.delivery.plural sans carburant',
    toggleDeliveryGasEmptyOff: 'Cacher les @:lexicon.delivery.plural sans carburant',
    toggleOtherOn: 'Afficher les autres tâches',
    toggleOtherOff: 'Cacher les autres tâches',
    toggleAgendaViewOn: 'Format Agenda',
    toggleAgendaViewOff: 'Format Liste',
    minutesBefore: 'minutes avant',
    hoursBefore: 'heures avant',
    before: 'avant',
    nineAm: 'À 9h00 la journée même',
    ninePm: 'À 21h00 la veille',
    reminders: 'Rappels par défaut',
    eventReminders: "Rappels d'événement par défaut",
    taskReminders: 'Rappels de tâche par défaut',
    taskRemindersTooltip:
        'Ces rappels s\'appliquent aux tâches et non aux événements. C\'est-à-dire "Appel", "Courriel", "SMS" et "CSI".',
    eventRemindersTooltip:
        'Ces rappels s\'appliquent aux événements et non aux tâches. C\'est-à-dire "Rendez-vous", "@.capitalize:lexicon.delivery.singular" et "Autres".',
    vehicle: 'Véhicule',
    commercial: 'F&I',
    task: 'Tâche',
    legend: {
        appt: 'Rendez-vous planifié',
        apptCompleted: 'Rendez-vous complété',
        apptCall: 'Rendez-vous téléphonique',
        apptNoShow: 'Rendez-vous passé, no show',
        apptDialogs: 'Rendez-vous événement V12 planifié',
        apptDialogsCompleted: 'Rendez-vous événement V12 complété',
        apptDialogsNoShow: 'Rendez-vous événement V12 passé, no show',
        delivery: '@.capitalize:lexicon.delivery.singular planifiée',
        deliveryCompleted: '@.capitalize:lexicon.delivery.singular complétée',
        contact: 'Événement de contact planifié',
        contactCompleted: 'Événement de contact complété',
        csiOther: 'Événement CSI / Autre planifié',
        csiOtherCompleted: 'Événement CSI / Autre complété',
        expiredEventNoAction: 'Événement passé, sans action',
        appointmentCanceledByCustomer: 'Rendez-vous annulé par le client',
    },
    filters: {
        event: "Type d'événement",
        leadType: 'Type de lead',
        automated: 'Automatisé',
        role: 'Rôle',
        status: 'Statut',
        division: 'Division',
        childAccount: 'Compte',
        user: 'Utilisateur',
        createdBy: 'Créé par',
        updatedBy: 'Modifié par',
        eventTypes: {
            appointment: 'Rendez-vous',
            phoneAppointment: 'RDV tél.',
            virtualAppointment: 'Rendez-vous virtuel',
            delivery: '@.capitalize:lexicon.delivery.singular',
            testDrive: 'Essai routier',
            call: 'Appel',
            email: 'Courriel',
            sms: 'SMS',
            csi: 'CSI',
            other: 'Autre',
        },
        statusTypes: {
            past: 'Passé',
            future: 'Futur',
            completed: 'Complété',
            uncompleted: 'Non complété',
            uncompletedPast: 'Passé et non complété',
            confirmed: 'Confirmé',
            noShow: 'No show',
            prepared: '@:clientCard.prepared',
            notPrepared: 'Non @:clientCard.prepared',
            withGas: 'Ravitaillé',
            withoutGas: 'Vide',
        },
        filter: 'Filtrer',
        selectFilters: 'Sélectionnez les filtres désirés',
        apply: 'Appliquer',
        clearAll: 'Effacer tout',
        clear: 'Effacer',
    },
    toggleColorScheme: 'Changer le code de couleurs',
    modals: {
        view: {
            title: 'Sauvegarder les filtres',
            name: 'Titre',
            addView: 'Ajouter une vue',
            titleTooltip: 'Sauvegarder vos filtres et votre configuration actuelle pour ce calendrier',
            shareView: 'Partager cette vue',
            editView: 'Éditer cette vue',
            deleteView: 'Supprimer cette vue',
            displayCalendarView: 'Information de cette vue',
        },
        colorScheme: {
            title: '@:error.warning',
            description: "Ce code de couleurs ne comprend pas l'identification des divisions.",
            proceed: '@:general.wishToProceed',
        },
    },
    saveCalendarView: 'Sauvegarder la vue',
    editCalendarView: 'Modifier la vue',
    deleteCalendarView: 'Supprimer la vue',
    deleteCalendarViewConfirmation: 'Voulez-vous supprimée de manière permanente votre vue personnalisée?',
    uniqueCalendarViewNameWarning: 'Le nom de la vue doit être unique',
    limitedAccessToTasks: 'Votre profil ne permet pas de voir les tâches des autres utilisateurs',
    visibleEvents: 'Tâches visibles',
};
