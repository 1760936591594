export default {
    alerts: {
        index: {
            error: 'Failed fetching the views',
        },
        store: {
            error: 'Failed creating the view',
            success: 'View created successfully',
        },
        show: {
            error: 'Failed fetching the view',
        },
        update: {
            error: 'Failed updating the view',
            success: 'View updated successfully',
        },
        destroy: {
            error: 'Failed deleting the view',
            success: 'View deleted successfully',
        },
    },
};
