import { useContextStore } from '@/store/modules/context/store.js';
import { useGlobalStore } from '@/store/store.js';

export default {
    communicatingLead() {
        const lead = useContextStore().lead;
        const user = useGlobalStore().authUser;

        if (!lead.id) {
            return null;
        }

        return this.communicatingLeads.find(communicatingLead => {
            return communicatingLead.id === lead.id && communicatingLead.communicating_user.id !== user.id;
        });
    },
};
