import Vue from 'vue';
import MixpanelClient from './mixpanel.js';

const VueMixpanel = {
    install(VueInstance, MixpanelClient) {
        VueInstance.behavior = MixpanelClient;
        VueInstance.prototype.$behavior = MixpanelClient;
    },
};

Vue.use(VueMixpanel, MixpanelClient);
