<template>
    <activix-modal
        :name="name"
        :opened="opened"
        :size="size"
        :portal="portal"
        :always-on-top="alwaysOnTop"
        :closable="false"
        @close="close"
        @closed="onClosed"
        @before-open="onBeforeOpen"
    >
        <template slot="body">
            <div :class="{ 'flex flex-col justify-center h-full': lgLayout }">
                <sweet-alert-icon class="mx-auto" :type="type" />
                <div class="text-2xl font-semibold text-center my-2 text-grey-650" v-text="title" v-if="title" />
                <div class="text-lg text-center" v-if="$slots.content">
                    <slot name="content" />
                </div>
                <div class="text-lg text-center whitespace-pre-line" v-html="content" v-else-if="content" />
            </div>
        </template>

        <template slot="footer">
            <activix-button :disabled="loading" @click="deny" v-if="showDeny">
                {{ denyText || $t('general.no') }}
            </activix-button>
            <activix-button
                :disabled="disabled"
                :loading="loading"
                :type="buttonType"
                @click="approve"
            >
                {{ approveText || $t('general.yes') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import SweetAlertIcon from './SweetAlert/Icon.vue';

    export default {
        name: 'ActivixConfirmModal',

        components: {
            SweetAlertIcon,
        },

        props: {
            name: {
                type: String,
                default: '',
            },
            opened: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'sm',
            },
            type: {
                type: String,
                default: 'warning',
                validator: value => ['error', 'danger', 'warning', 'info', 'success'].includes(value),
            },
            title: {
                type: String,
                default: '',
            },
            content: {
                type: String,
                default: '',
            },
            approveText: {
                type: String,
                default: '',
            },
            approveCallback: {
                type: Function,
                default: null,
            },
            denyText: {
                type: String,
                default: '',
            },
            showDeny: {
                type: Boolean,
                default: true,
            },
            portal: {
                type: String,
                default: 'modal-3',
            },
            alwaysOnTop: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                loading: false,
                approved: false,
                denied: false,
            };
        },

        computed: {
            buttonType() {
                switch (this.type) {
                    case 'error':
                    case 'danger':
                        return 'danger';

                    case 'info':
                        return 'primary';

                    case 'warning':
                        return 'warning';

                    default:
                        return 'success';
                }
            },
        },

        methods: {
            async approve() {
                if (this.approveCallback) {
                    this.loading = true;
                    await this.approveCallback();
                    this.loading = false;
                }

                this.approved = true;
                this.close();
            },

            deny() {
                this.denied = true;
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            onClosed() {
                if (this.approved) {
                    this.$emit('approve');
                }

                if (this.denied) {
                    this.$emit('deny');
                }

                this.approved = false;
                this.denied = false;
                this.loading = false;
                this.$emit('closed');
            },

            onBeforeOpen(params) {
                this.$emit('before-open', params);
            },
        },
    };
</script>
