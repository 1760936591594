<template>
    <portal to="screen-pop">
        <transition
            enter-active-class="ease-out duration-300"
            enter-class="translate-y-3 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="ease-in duration-150"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                class="max-w-104 w-full bg-white shadow-lg rounded-lg pointer-events-auto transition-all duration-300"
                v-if="isOpen"
            >
                <div class="rounded-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-4">
                        <div class="flex items-start space-x-4">
                            <div class="shrink-0 pt-0.5" v-if="$slots.avatar">
                                <slot name="avatar" />
                            </div>
                            <div class="flex-1">
                                <div class="text-lg font-semibold leading-6 text-gray-700" v-text="title" />
                                <div class="mt-1 leading-5 text-gray-600" v-text="description" />
                                <div class="mt-4 flex space-x-3" v-if="$slots.buttons">
                                    <slot name="buttons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
    export default {
        name: 'ActivixHelperNotification',

        props: {
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            isOpen: false,
        }),

        methods: {
            open() {
                this.isOpen = true;
            },

            close() {
                this.isOpen = false;
            },
        },
    };
</script>
