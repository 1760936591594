export default {
    add: 'Ajouter',
    clickAnywhereToView: 'Cliquez pour voir',
    late: 'En retard',
    nextStep: 'Prochaine étape',
    noOtherPlannedTask: 'Aucune autre tâche planifiée',
    nothingPlanned: 'Aucune tâche planifiée.',
    scheduled: 'Planifiée',
    view: 'Voir',
    viewAllActivities: 'Voir toutes les activités',
};
