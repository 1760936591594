import Enum from './Enum.js';

export default class ContactType extends Enum {
    static get entries() {
        return {
            main: 'main',
            second: 'second',
            business: 'business',
        };
    }

    static get MAIN() {
        return this.entries.main;
    }

    static get SECOND() {
        return this.entries.second;
    }

    static get BUSINESS() {
        return this.entries.business;
    }
}
