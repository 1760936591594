<template>
    <header class="shrink-0 flex items-stretch border-b border-gray-200 | h-14 md:h-18">
        <div class="flex">
            <slot name="left" />
        </div>

        <h3
            :class="[
                titleClassName,
                'grow flex items-center m-0 px-6 truncate font-semibold text-grey-650 text-lg | md:text-xl',
                'transition-all duration-300',
            ]"
        >
            <slot />
        </h3>

        <div class="flex">
            <slot name="right" />
        </div>
    </header>
</template>

<script>
    export default {
        props: {
            titleClassName: {
                type: [String, Array],
                default: '',
            },
        },
    };
</script>
