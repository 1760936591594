<template>
    <div>
        <email-accordion-item
            :class="{ 'border-t': index !== 0 }"
            :communication="communication"
            :lead-full-name="leadFullName"
            :is-loading="communication.loading_body"
            ref="items"
            :key="communication.id"
            @load="loadEmailBody(communication)"
            v-for="(communication, index) in orderedConnectedCommunications"
        />
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';

    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import EmailAccordionItem from '@/components/lead/EmailAccordionItem.vue';

    // Pinia
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: { EmailAccordionItem },

        props: {
            communications: {
                type: Array,
                required: true,
            },
            leadFullName: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                items: [],
                loadingBody: 0,
                connectedCommunications: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),
            ...mapState(useClientCardStore, ['currentCommunicationId']),

            orderedConnectedCommunications() {
                return orderBy(this.connectedCommunications, 'sort_created_at', 'desc');
            },

            communicationId() {
                if (!empty(this.currentCommunicationId)) {
                    return this.currentCommunicationId;
                }

                return null;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            loadEmailBody(communication) {
                const index = this.connectedCommunications.findIndex(c => c.id == communication.id);

                if (typeof communication.email_body !== 'undefined' || index === -1) return;

                this.connectedCommunications[index].loading_body = true;

                this.$axios
                    .get(`v1/communications/${communication.id}`, {
                        params: {
                            from: 'clientCard',
                        },
                    })
                    .then(response => {
                        if (response.status === 200 && this.connectedCommunications[index]) {
                            this.connectedCommunications[index].email_body = response.data.data.email_body;
                            this.connectedCommunications[index].attachments = response.data.data.attachments;
                        }

                        this.connectedCommunications[index].loading_body = false;
                    })
                    .catch(error => {
                        if (!error.request) {
                            throw error;
                        }

                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0025',
                            display: true,
                            error,
                        });
                    });
            },

            setConnectedCommunications() {
                this.connectedCommunications = this.communications
                    .filter(communication => {
                        return communication.communication_method_id === CommunicationMethod.EMAIL &&
                            (
                                (
                                    communication.connected &&
                                    communication.service_id === 4 &&
                                    !['interrupted', 'error', 'invalid', 'pending'].includes(communication.status)
                                ) || communication.kind === 'auto'
                            );
                    })
                    .map(communication => {
                        return {
                            ...communication,
                            loading_body: false,
                            sort_created_at: communication.original_created_at || communication.created_at,
                        };
                    });
            },
        },

        mounted() {
            this.setConnectedCommunications();

            this.$nextTick(() => {
                if (this.$route && this.$route.query.communicationId) {
                    const communicationId = this.$route.query.communicationId;
                    const communication = this.connectedCommunications.find(
                        communication => communication.id == communicationId,
                    );

                    useClientCardStore().currentCommunicationId = communicationId;

                    if (communication) {
                        this.loadEmailBody(communication);
                    }
                }
            });
        },
    };
</script>
