<template>
    <div class="flex">
        <activix-tooltip :content="!disabled ? $t('users.edit.confirmationWhen') : ''">
            <activix-multiselect
                identifier="value"
                label="name"
                class="flex-1"
                :class="{
                    'attached-l': attached,
                    'attached-r': showTime,
                }"
                :max-height="200"
                :options="whenOptions"
                :selected="selectedWhen"
                :searchable="false"
                :allow-empty="false"
                :disabled="disabled"
                @update="updateWhen"
            />
        </activix-tooltip>
        <el-time-select
            class="attached-l"
            :disabled="disabled"
            :value="selectedTime"
            :clearable="false"
            :editable="false"
            :picker-options="{
                start: '06:00',
                step: '00:15',
                end: '22:00',
            }"
            @input="updateTime"
            v-if="showTime"
        />
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash-es';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            timeframe: Object,
            currentTimeFrame: String,
            attached: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                whenOptions: [
                    // Hours
                    {
                        value: '1_hour_before',
                        name: `1 ${this.$tc('delays.hour', 1)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '2_hours_before',
                        name: `2 ${this.$tc('delays.hour', 2)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '3_hours_before',
                        name: `3 ${this.$tc('delays.hour', 3)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '4_hours_before',
                        name: `4 ${this.$tc('delays.hour', 4)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '5_hours_before',
                        name: `5 ${this.$tc('delays.hour', 5)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '6_hours_before',
                        name: `6 ${this.$tc('delays.hour', 6)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '12_hours_before',
                        name: `12 ${this.$tc('delays.hour', 12)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '24_hours_before',
                        name: `24 ${this.$tc('delays.hour', 24)} ${this.$t('delays.before')}`,
                    },

                    // Days
                    {
                        value: '0_days_before',
                        name: this.$t('delays.sameDay'),
                    },
                    {
                        value: '1_day_before',
                        name: `1 ${this.$tc('delays.day', 1)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '2_days_before',
                        name: `2 ${this.$tc('delays.day', 2)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '3_days_before',
                        name: `3 ${this.$tc('delays.day', 3)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '4_days_before',
                        name: `4 ${this.$tc('delays.day', 4)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '5_days_before',
                        name: `5 ${this.$tc('delays.day', 5)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '6_days_before',
                        name: `6 ${this.$tc('delays.day', 6)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '7_days_before',
                        name: `7 ${this.$tc('delays.day', 7)} ${this.$t('delays.before')}`,
                    },
                    {
                        value: '',
                        name: this.$t('modal.doNotSendConfirmation'),
                    },
                ],
            };
        },

        computed: {
            showTime() {
                if (!this.timeframe) {
                    return false;
                }

                return this.timeframe.when.includes('day');
            },

            selectedWhen() {
                if (!this.timeframe) {
                    return null;
                }

                return this.whenOptions.find(delay => delay.value == this.timeframe.when);
            },

            selectedTime() {
                if (this.timeframe && this.timeframe.time) {
                    return this.timeframe.time.slice(0, -3);
                }

                return null;
            },
        },

        methods: {
            updateWhen({ value }) {
                const timeframe = cloneDeep(this.timeframe);

                timeframe.when = value;

                // Make sure default time is good
                if (!value.includes('_day')) {
                    timeframe.time = null;
                } else if (!this.timeframe.time) {
                    timeframe.time = '09:00:00';
                }

                this.$emit('update', timeframe);
            },

            updateTime(time) {
                const timeframe = cloneDeep(this.timeframe);

                if (!time) {
                    timeframe.time = null;
                } else {
                    timeframe.time = `${time}:00`;
                }

                this.$emit('update', timeframe);
            },
        },
    };
</script>
