export default [
    {
        path: '/imports',
        name: 'imports.index',
        component: () => import(/* webpackChunkName: "import-list" */ '../views/Imports/List.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/imports/:id/match',
        name: 'imports.match',
        component: () => import(/* webpackChunkName: "import-match" */ '../views/Imports/Match.vue'),
        meta: {
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
        },
    },
];
