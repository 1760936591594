import Enum from './Enum.js';

export default class LeadPhoneType extends Enum {
    static get entries() {
        return {
            cell: 'cell',
            home: 'home',
            work: 'work',
            other: 'other',
        };
    }

    static get CELL() {
        return this.entries.cell;
    }

    static get HOME() {
        return this.entries.home;
    }

    static get WORK() {
        return this.entries.work;
    }

    static get OTHER() {
        return this.entries.other;
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
