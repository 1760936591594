import GraphDAL from '@/graphql/GraphDAL.js';

class BaseRepository {
    static getKey(id) {
        return `${this.getModelName()}:${id}`;
    }

    static getModelName() {
        throw Error('Must redefine getModelName');
    }

    static isValidId(id) {
        return !!parseInt(id, 10);
    }

    static find(id, specificFieldsToQuery = [], useCache = true, ignoredFields = []) {
        if (!this.isValidId(id)) {
            return null;
        }

        return GraphDAL.find(this.getKey(id), specificFieldsToQuery, useCache, ignoredFields);
    }

    static findWithFields(id, fields = [], useCache = true) {
        return this.find(id, fields, useCache);
    }

    static findWithoutField(id, fields = []) {
        return this.find(id, [], true, fields);
    }

    static findWithoutCache(id) {
        return this.find(id, [], false);
    }

    static findMultiple(ids, specificFieldsToQuery = [], useCache = true, ignoredFields = []) {
        return Promise.all(ids.map(id => this.find(id, specificFieldsToQuery, useCache, ignoredFields)));
    }
}
export default BaseRepository;
