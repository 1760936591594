export default [
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import('../views/Inventory.vue'),
        meta: {
            fixedContent: false,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
