import ActivixDate from '@/value-objects/ActivixDate.js';

import Lead from '@/entities/Lead.js';
import Model from '@/entities/Model.js';
import Reminder from '@/entities/Reminder.js';
import TaskEventType from '@/entities/TaskEventType.js';
import TaskEventTypeIcon from '@/entities/TaskEventTypeIcon.js';
import User from '@/entities/User.js';
import UserRepository from '@/graphql/repositories/UserRepository.js';

export default class TaskEvent extends Model {
    static get defaults() {
        return {
            address: null,
            automation_action: {},
            canceled: false,
            completed_at: null,
            confirmed: false,
            customer_virtual_event_link: null,
            description: '',
            end_at: null,
            guests: [],
            lead: {},
            location: null,
            main_event: false,
            no_show: false,
            priority: 'normal',
            reminders: [],
            start_at: null,
            status: false,
            task_event_type_id: null,
            title: '',
            user: {},
            user_id: null,
            user_virtual_event_link: null,
            confirmation_timeframe: null,
            send_ics: false,
            wanted_vehicle_ids: null,
        };
    }

    /**
     * Getters
     */

    get lead() {
        return this._lead;
    }

    get user() {
        return this.getUser();
    }

    get guests() {
        return (async () => {
            const guests = await Promise.all(
                this._guests.map(async guest => new User(await UserRepository.find(guest.id))),
            );

            return guests.sort((a, b) => a.fullName.localeCompare(b.fullName));
        })();
    }

    get guestIds() {
        return this._guests.map(guest => guest.id);
    }

    get reminders() {
        return this._reminders;
    }

    get startAt() {
        return new ActivixDate(this.start_at);
    }

    /**
     * Setters
     */

    set lead(lead) {
        this._lead = new Lead(lead);
    }

    set user(user) {
        this._user = user;
    }

    set guests(guests) {
        this._guests = guests;
    }

    set reminders(reminders) {
        this._reminders = reminders.map(reminder => new Reminder(reminder));
    }

    /**
     * Specifications
     */

    get hasStatus() {
        return this.canceled || this.no_show || this.status;
    }

    get icon() {
        const taskEventType = TaskEventType.getKey(this.task_event_type_id);

        return TaskEventTypeIcon.entries[taskEventType];
    }

    get iconName() {
        return TaskEventType.getIconName(this.task_event_type_id);
    }

    get isLate() {
        if (this.startAt.isFuture()) {
            return false;
        }

        return !this.hasStatus;
    }

    get isPlanned() {
        return !this.hasStatus;
    }

    get userId() {
        return this.user_id || this._user?.id;
    }

    set userId(userId) {
        this.user_id = userId;
    }

    get creatorId() {
        return this.created_by || this._creator?.id || null;
    }

    set creatorId(userId) {
        this.created_by = userId;
    }

    async getUser(fields = []) {
        if (!this.userId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.userId, fields));
    }

    async getCreator(fields = []) {
        if (!this.creatorId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.creatorId, fields));
    }

    hasGuest(userId) {
        return this.guestIds.includes(userId);
    }

    get isTodayOrFutureVirtualAppointment() {
        return this.task_event_type_id === TaskEventType.VIRTUAL_APPOINTMENT &&
            (new ActivixDate(this.start_at)).isSameOrAfter(new ActivixDate('now').startOfDay());
    }
}
