import Enum from './Enum.js';

export default class TaskEventLocation extends Enum {
    static get entries() {
        return {
            dealership: 'dealership',
            customer: 'customer',
            custom: 'custom',
        };
    }

    static get DEALERSHIP() {
        return this.entries.dealership;
    }

    static get CUSTOMER() {
        return this.entries.customer;
    }

    static get CUSTOM() {
        return this.entries.custom;
    }

    static get translationGroup() {
        return 'taskEventStatuses';
    }
}
