export default {
    appointment_request: 'Appointment Request',
    auto_show: 'The Auto Show',
    call_me: 'Call me',
    carproof: 'Carproof',
    coupon: 'Coupon',
    credit: 'Credit',
    exchange_evaluation: 'Exchange evaluation',
    express_request: 'Express request',
    information_request: 'Information request',
    mobile: 'Mobile',
    price_new: 'New price',
    price_use: 'Used price',
    test_drive: 'Test drive',
};
