import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(accountId) {
        if (!accountId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/leads/trash/${accountId}`);

        return response.data.data;
    },

    async restore(leadId) {
        if (!leadId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.post(`v1/leads/trash/${leadId}`);

        return response.data.data;
    },
};
