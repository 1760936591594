/* eslint-disable camelcase */
import Vue from 'vue';
import UserRepository from '@/graphql/repositories/UserRepository.js';
import Model from './Model.js';
import CommentType from './CommentType.js';
import User from './User.js';

export default class Comment extends Model {
    static get defaults() {
        return {
            parent_id: null,
            alert_users: [],
            content: '',
            file: null,
            lead_id: null,
            user_id: null,
            media_id: null,
            private: false,
            comment_type_id: CommentType.STRING,
        };
    }

    set user(user) {
        this._user = user;
    }

    get user() {
        return this.getUser();
    }

    get children() {
        return this._children || [];
    }

    set children(children) {
        this._children = (children || []).map(comment => new Comment(comment));
    }

    get parent() {
        return this._parent || new Comment();
    }

    set parent(parent) {
        this._parent = new Comment(parent);
    }

    get alert_users() {
        return this._alert_users || [];
    }

    set alert_users(alertUsers) {
        this._alert_users = (alertUsers || []).map(user => {
            if (typeof user === 'string' || Number.isInteger(user)) {
                return user;
            }

            if (Vue.router.currentRoute.name === 'leads.update' && Vue.feature.isEnabled('new-lead-page')) {
                return user.id;
            }

            return new User(user);
        });
    }

    get userId() {
        return this.user_id || this._user?.id;
    }

    async getUser(fields = []) {
        if (!this.userId) {
            return new User();
        }

        return new User(await UserRepository.findWithFields(this.userId, fields));
    }
}
