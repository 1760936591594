import Enum from './Enum.js';

export default class VideoRecordingStatus extends Enum {
    static get entries() {
        return {
            processing: 'processing',
            recording_available: 'recording_available',
            not_recorded: 'not_recorded',
            pending: 'pending',
        };
    }

    static get PROCESSING() {
        return this.entries.processing;
    }

    static get RECORDING_AVAILABLE() {
        return this.entries.recording_available;
    }

    static get NOT_RECORDED() {
        return this.entries.not_recorded;
    }

    static get PENDING() {
        return this.entries.pending;
    }
}
