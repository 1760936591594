import { render, staticRenderFns } from "./SuccessIcon.vue?vue&type=template&id=2df83024&scoped=true&"
import script from "./SuccessIcon.vue?vue&type=script&lang=js&"
export * from "./SuccessIcon.vue?vue&type=script&lang=js&"
import style0 from "./SuccessIcon.vue?vue&type=style&index=0&id=2df83024&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df83024",
  null
  
)

export default component.exports