import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(accountId) {
        if (!accountId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/accounts/${accountId}/guest-group`);

        return response.data.data;
    },

    async store(accountId, payload = {}) {
        if (!accountId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.post(`v1/accounts/${accountId}/guest-group`, payload);

        return response.data.data;
    },

    async update(groupId, payload = {}) {
        if (!groupId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.put(`v1/accounts/guest-group/${groupId}`, payload);

        return response.data.data;
    },

    async destroy(accountId, groupId) {
        if (!accountId || !groupId) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.delete(`v1/accounts/${accountId}/guest-group/${groupId}`);

        return response.data.data;
    },
};
