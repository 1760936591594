import EmailValidationResult from '@/entities/EmailValidationResult.js';
import EmailValidationRisk from '@/entities/EmailValidationRisk.js';
import Model from './Model.js';

export default class ValidatedEmail extends Model {
    static get defaults() {
        return {
            address: null,
            is_disposable_address: false,
            is_role_address: false,
            is_disposable: false,
            permanent_failed_at: null,
            permanent_failed_reason: [],
            reason: [],
            result: '',
            risk: EmailValidationRisk.UNKNOWN,
            typo_suggestions: [],
            validation_result: '',
            validation_attempts: 0,
        };
    }

    static get MAX_VALIDATION_ATTEMPTS() {
        return 3;
    }

    hasBounced() {
        return this.validation_result === EmailValidationResult.PERMANENT_FAIL && !!this.permanent_failed_at;
    }

    isValid() {
        return !this.isNotValid();
    }

    isNotValid() {
        return this.is_disposable ||
            this.risk === EmailValidationRisk.HIGH ||
            [EmailValidationResult.PERMANENT_FAIL, EmailValidationResult.UNDELIVERABLE].includes(this.validation_result);
    }
}
