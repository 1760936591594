import { getOnce } from '@/plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async index(id: string) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await getOnce(`v1/leads/${id}/get-eti`);
        if (response === null) {
            return null;
        }

        return response.data;
    },
};
