import hash from 'object-hash';

const Checksum = (object) => {
    if (!object) {
        return null;
    }

    return hash(object);
};

export default Checksum;
