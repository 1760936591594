import Vue from 'vue';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Message,
    Option,
    OptionGroup,
    Pagination,
    Popover,
    Radio,
    RadioGroup,
    Scrollbar,
    Select,
    Table,
    TableColumn,
    TimePicker,
    TimeSelect,
    Upload,
} from 'element-ui';
import ElementLocale from 'element-ui/lib/locale/index.js';
import i18n from './vue-i18n.js';
import '../assets/scss/element-ui.scss';

ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Scrollbar);
Vue.use(Select);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TimePicker);
Vue.use(TimeSelect);
Vue.use(Upload);

Vue.prototype.$message = Message;
