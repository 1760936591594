import Vue from 'vue';
import { redirectToFirstAvailableFlowRoute } from '@/mixins/Flows.js';
import Division from '../entities/Division.js';

export default [
    {
        path: '/flow',
        name: 'flow',
        beforeEnter: async (to, from, next) => {
            if (!Vue.auth.ready()) {
                await Vue.auth.waitForUser();
            }

            next(redirectToFirstAvailableFlowRoute());
        },
    },
    {
        path: '/flow/web-boost',
        name: 'flow.webBoost',
        component: () => import(/* webpackChunkName: "flow" */ '../views/Flow/WebBoost.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                division: [Division.NEW, Division.USED, Division.FINANCE, Division.SERVICE],
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/flow/phone-up',
        name: 'flow.phoneUp',
        component: () => import(/* webpackChunkName: "flow" */ '../views/Flow/PhoneUp.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                division: [Division.NEW, Division.USED, Division.SERVICE],
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
    {
        path: '/flow/lead-xpress',
        name: 'flow.leadXpress',
        component: () => import(/* webpackChunkName: "flow" */ '../views/Flow/LeadXpress.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                division: [Division.NEW, Division.USED, Division.FINANCE, Division.SERVICE],
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
