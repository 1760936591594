export default {
    addInventoryVehicle: 'Add inventory vehicle',
    inventoryUnit: 'Inventory vehicle',
    filter: 'Filter',
    changeInventoryUnit: 'Change inventory unit',
    concurrentOpportunityDate: 'Created {0} days ago',
    createdToday: 'Created Today',
    day: '{0} Day|{0} Days',
    emptyStatus: 'None',
    notAvailable: 'Not Avail.',
    make: 'Make',
    year: 'Year',
    new: 'New',
    used: 'Used',
    demo: 'Demo',
    fetchingError: 'Error while fetching the inventory',
    stockStatusTBD: 'Status to be determined',
    searchingVehicles: 'Searching for vehicles...',
    noStockFoundForFilter: 'No vehicle found for {0} {1} {2}.',
    noStockFoundForFilterWithStock: 'No vehicle found for {0} {1} {2} with stock {3}.',
    noStockFoundForFilterOnlyStock: 'No vehicle found for stock {0}.',
    noConcurrentOpportunity: 'No leads with this vehicles.',
    inventoryFromSyncAccount: 'This lead is from a different account.',
    emptyInventory: 'No vehicle available in inventory.',
    createLead: 'Create Lead',
    viewLead: 'View lead',
    select: 'Select',
    selected: 'Selected',
    expend: 'Expend',
    searchPlaceholder: 'Search make, model, year, trim, color, price...',
    shrink: 'Shrink',
    selectedInOtherLeads: 'Selected in 1 lead | Selected in {0} leads',
    leadsWithThisVehicle: 'leads with this vehicle',
    vehicles: 'Vehicles',
    vehicle: {
        bodyType: 'Body Type',
        certified: 'Certified',
        comments: 'Comments',
        demo: 'Demo',
        drivetrain: 'Drivetrain',
        fuel: 'Fuel',
        make: 'Make',
        mileage: 'Mileage',
        vin: 'Vin',
        price: 'Price',
        engine: 'Engine',
        options: 'Options',
        transmission: 'Transmission',
        type: 'Type',
        location: 'Location',
        warranty: 'Warranty',
        division: {
            new: 'New',
            used: 'Used',
        },
        visibility: {
            active: 'Active',
            archived: 'Archived',
        },
        daysInInventory: 'Days in Inventory',
        actualCost: 'Actual cost',
    },
    boxes: {
        clickToCollapse: 'Click to collapse',
        clickToExpand: 'Click to expand',
    },
    column: {
        status: 'Status',
        dmsStatus: 'DMS Status',
        stock: 'Stock (# • @:inventory.vehicle.type)',
        year: 'Year',
        daysInInventory: 'Days in Inv',
        model: 'Model',
        trim: 'Trim',
        color: 'Color (Ext • Int)',
        certified: 'Certified',
        exteriorColor: 'Ext. Color',
        interiorColor: 'Int. Color',
        price: 'Price',
        leads: 'Leads',
        action: 'Actions',
        make: 'Make',
        transmission: 'Transmission',
        odometer: 'Odometer',
        concurrence: 'Concurrence',
        demo: 'Demo',
        fuel: 'Fuel',
        engine: 'Engine',
        bodyType: 'Body type',
        drivetrain: 'Drivetrain',
        archived: 'Visibility',
        toArchived: 'Archive',
        toUnarchived: 'Unarchive',
        showOption: 'Show options',
    },
    statistics: {
        totalVehicles: 'Total Vehicles',
        averagePrice: 'Average Price',
        averageDaysInInventory: 'Avg. Days in Inventory',
        inStock: 'In stock',
        available: 'Available',
        unavailable: 'Unavailable',
        order: 'Order',
        reserved: 'Reserved',
        locate: 'Locate',
        invoice: 'Invoice',
        production: 'Production',
        transit: 'In transit',
        hold: 'Hold',
        sold: 'Sold',
        new: 'New',
        demo: 'Demo',
        lowestPrice: 'Lowest Price',
        highestPrice: 'Highest Price',
        totalValue: 'Total Value',
        moreThan7Days: '7 to 14 Days',
        moreThan14Days: '15 to 30 Days',
        moreThan30Days: '31 to 60 Days',
        moreThan60Days: 'More than 60 Days',
        days: 'Days',
        unknown: 'Unknown',
    },
    latestSaleState: {
        delivered: 'Delivered',
        deposit: 'Deposit',
        sold: 'Sold',
        test_drive: 'Test Drive',
        visited: 'Visited',
        appointment: 'Appointment',
        new: 'New Lead',
    },
    status: {
        active: 'Active',
        invalid: 'Invalid',
        duplicate: 'Duplicate',
        lost: 'Lost',
    },
    division: {
        all: 'All',
        new: 'New',
        used: 'Used',
    },
    modal: {
        areYouSure: 'Are you sure you want to select this inventory unit. \n {0} {1} #{2}?',
    },
    orderBy: {
        ascending: 'Ascending',
        descending: 'Descending',
    },
};
