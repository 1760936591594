export default {
    refresh: 'The vehicle was refreshed from vAuto successfully',
    error: {
        userNotActive: 'The user {0} is not active in vAuto.',
        userNotFound: 'The user {0} does not exist in vAuto.',
    },
    warning: {
        assignedFallbackVerifyUsername: 'Vehicle submitted to vAuto, can\'t assign submission to your vAuto username, submitted with default advisor of your account',
        assignedFallbackUsernameNotSet: 'Vehicle submitted to vAuto, no user defined in your preferences for vAuto, submitted with default vAuto advisor of your account',
        assignedDirectorVerifyUsername: 'Vehicle submitted to vAuto, can\'t assign submission to your vAuto username, submitted without an advisor',
        assignedDirectorUsernameNotSet: 'Vehicle submitted to vAuto, no user defined in your preferences for vAuto, submitted without an advisor',
        assignedDirectorLastFallback: 'Vehicle submitted to vAuto, default account advisor not valid, submitted without an advisor',
    },
    success: {
        assignedAdvisor: 'The vehicle was submitted to vAuto successfully with advisor {0}',
        assignedDirector: 'The vehicle was submitted to vAuto successfully with the account default advisor',
    },
    label: {
        unsubmitted: 'Not submitted',
        waiting: 'Evaluation submitted on {0}, waiting...',
        received: 'Evaluation received',
    },
    tooltip: {
        unsubmitted: 'Submit the vehicle information through vAuto',
        niv: 'You must provide a valid VIN in order to have a valid submission through vAuto',
        match:
            'The submission of the vehicle through vAuto is based on the brand, model and year, make sure entered values are valid in vAuto before submitting',
    },
};
