export default {
    dms: 'DMS',
    email: 'Web Lead',
    event: 'Event',
    loyalty: 'Loyalty',
    phone: 'Phone',
    preBooking: 'Pre-booking',
    renewal: '@.capitalize:lexicon.portfolio.singular',
    sms: 'SMS',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webOrder: 'Web Order',

    withLeadKeyword: {
        dms: 'DMS Lead',
        email: 'Web Lead',
        event: 'Event Lead',
        loyalty: 'Loyalty Lead',
        phone: 'Phone-up Lead',
        pre_booking: 'Pre-booking Lead',
        renewal: '@.capitalize:lexicon.portfolio.singular Lead',
        sms: 'SMS Lead',
        walk_in: '@.capitalize:lexicon.walkIn.singular Lead',
        web_order: 'Web Order Lead',
    },

    abbreviations: {
        dms: 'DMS',
        email: 'Web Lead',
        event: 'Event',
        loyalty: 'Loyalty',
        phone: 'Phone',
        preBooking: 'Pre-b.',
        renewal: '@.capitalize:lexicon.portfolio.abr ',
        sms: 'SMS',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        webOrder: 'Web Order',
    },
};
