export default {
    approved: 'Approved',
    cash: 'Cash down',
    cash_deal: 'Cash deal',
    endorser: 'Endorser',
    proof: 'Proof',
    vehicle_selection: 'Vehicle to select',
    refused: 'Refused',
    sent: 'Sent',
};
