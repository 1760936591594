<template>
    <div class="flex gap-4">
        <activix-button
            class="grow"
            size="small"
            :type="selectedForViewing ? 'primary' : 'default'"
            @click.stop="$emit('add-to-sales-journey')"
            v-if="isAttributionContext"
        >
            {{ $t('addLead.addToSalesJourney') }}
        </activix-button>

        <activix-button
            class="grow"
            size="small"
            :type="selectedForViewing ? 'primary' : 'default'"
            @click.stop="$emit('complete')"
            v-else-if="showCompleteButton"
        >
            {{ hasCompletableValues ? $t('addLead.complete') : $t('addLead.select') }}
        </activix-button>

        <activix-tooltip :content="associationTooltip">
            <div class="flex grow">
                <activix-button
                    class="grow"
                    size="small"
                    :loading="associationInProgress"
                    :disabled="associationDisabled"
                    :type="selectedForViewing ? 'primary' : 'default'"
                    @click.stop="$emit('associate')"
                >
                    {{ $t('addLead.associate') }}
                </activix-button>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    export default {
        inject: ['$association'],

        props: {
            isAttributionContext: {
                type: Boolean,
                default: false,
            },
            selectedForViewing: {
                required: true,
            },
            hasCompletableValues: {
                required: true,
            },
        },

        computed: {
            showCompleteButton() {
                return !this.$association().leadId;
            },

            associationDisabled() {
                if (!this.selectedForViewing) {
                    return false;
                }

                return this.$association().disabled;
            },

            associationTooltip() {
                if (!this.selectedForViewing) {
                    return '';
                }

                return this.$association().tooltip;
            },

            associationInProgress() {
                if (!this.selectedForViewing) {
                    return false;
                }

                return this.$association().creating;
            },
        },
    };
</script>
