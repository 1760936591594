export default [
    {
        path: '/auth/error',
        name: 'auth.error',
        component: () => import(/* webpackChunkName: "auth" */ '../views/AuthError.vue'),
        props: route => ({ error: route.query.error }),
        meta: {
            splashscreen: true,
        },
    },
    {
        path: '/auth/callback',
        name: 'auth.callback',
    },
    {
        path: '/auth/logout',
        name: 'auth.logout',
    },
    {
        path: '/new-password',
        name: 'auth.newPassword',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/NewPassword.vue'),
        props: route => ({ token: route.query.t, email: route.query.e }),
        meta: {
            auth: false,
        },
    },
];
