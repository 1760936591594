<template>
    <div class="relative rounded overflow-hidden w-full | transition duration-400" ref="container">
        <div class="flex items-center justify-center absolute w-full h-full z-10" v-if="loading">
            <activix-spinner :size="22" :line-size="2" />
        </div>
        <div class="rounded-sm relative" :class="{'opacity-0': loading}" ref="waveForm">
            <div class="line-player w-full absolute bg-gray-300" />
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import WaveSurfer from 'wavesurfer.js';

    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            url: {
                type: String,
                required: true,
            },
            duration: {
                type: Number,
            },
            placeholder: {
                type: Boolean,
                default: false,
            },
            autoplay: {
                type: Boolean,
                default: false,
            },
            autoload: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                observer: null,
                wavesurfer: null,
                ready: false,
                currentTime: '00:00',
            };
        },

        watch: {
            'url'(newValue, oldValue) {
                if (newValue != oldValue) {
                    if (this.wavesurfer) {
                        this.wavesurfer.destroy();
                        this.wavesurfer = null;
                    }

                    this.setup();
                }
            },
        },

        methods: {
            onResize() {
                if (this.wavesurfer) {
                    this.wavesurfer.drawBuffer();
                }
            },

            async setup() {
                await this.$nextTick();

                this.wavesurfer = WaveSurfer.create({
                    container: this.$refs.waveForm,
                    backend: 'MediaElement',
                    barWidth: 4,
                    normalize: false,
                    barHeight: 3,
                    barRadius: 2,
                    barMinHeight: 1,
                    height: this.getHeight(),
                    responsive: true,
                    waveColor: '#B0B0B0',
                    progressColor: '#3E8DBC',
                });

                if (this.placeholder) {
                    this.wavesurfer.backend.peaks = this.generatePlaceholder();
                }

                this.wavesurfer.drawBuffer();

                this.wavesurfer.on('seek', (seek) => {
                    this.tempSeek = !this.ready ? seek : null;
                    this.$emit('seek', seek);
                });

                this.wavesurfer.on('finish', () => {
                    this.currentTime = '00:00';
                    this.$emit('playing', false);
                    this.$emit('finish', true);
                });

                this.wavesurfer.on('play', () => {
                    this.$emit('playing', true);
                });

                this.wavesurfer.on('pause', () => {
                    this.$emit('playing', false);
                });

                this.wavesurfer.on('audioprocess', (time) => {
                    const delta = this.wavesurfer.getDuration() - time;
                    this.currentTime = delta <= 0 ? '00:00' : this.setHMS(delta);

                    this.$emit('time', this.currentTime);
                });

                this.wavesurfer.on('ready', () => {
                    this.ready = true;
                    this.$emit('update:loading', false);

                    if (this.tempSeek) {
                        this.wavesurfer.seekTo(this.tempSeek);
                    }

                    if (this.autoplay) {
                        this.playPause();
                    }

                    this.$emit('ready', true);
                });

                if (this.autoload) {
                    this.load();
                }
            },

            load() {
                this.$emit('update:loading', true);

                if (this.url) {
                    this.wavesurfer.load(this.url);
                }
            },

            setTime() {
                this.currentTime = this.setHMS(this.duration);
                this.$emit('time', this.currentTime);
            },

            setHMS(delta) {
                const seconds = Math.floor(delta);

                const h = Math.floor(seconds / 3600).toString();
                const m = Math.floor(seconds % 3600 / 60).toString().padStart(2, '0');
                const s = Math.floor(seconds % 60).toString().padStart(2, '0');

                return h > 0 ? `${h}:${m}:${s}` : `${m}:${s}`;
            },

            generatePlaceholder() {
                return [0.0218, ...Array.from({ length: 62 }, () => Math.random() * (0.27 - 0.03) + 0.03), 0.0108];
            },

            getHeight() {
                const height = this.$refs.container.parentElement.clientHeight;
                return (50 / 100) * height; // 50% of container height
            },

            playPause() {
                if (!this.ready) {
                    this.load();

                    return;
                }

                this.wavesurfer.playPause();
            },
        },

        mounted() {
            this.onResize = debounce(this.onResize, 10);
            this.observer = new ResizeObserver(this.onResize).observe(this.$refs.container);

            this.setTime();

            if (!this.ready && !this.loading) {
                this.setup();
            }
        },

        beforeDestroy() {
            if (this.observer) {
                this.observer.unobserve(this.$refs.container);
            }

            if (this.wavesurfer) {
                this.wavesurfer.destroy();
                this.wavesurfer = null;
            }
        },
    };
</script>

<style>
.line-player + wave {
    overflow: hidden !important;
}
</style>
