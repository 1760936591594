import Vue from 'vue';
import { useGlobalStore } from '../store/store.js';

export const redirectToFirstAvailableFlowRoute = (requestRoute) => {
    const user = useGlobalStore().authUser;

    let orderedFlowRoutes = ['flow.webBoost', 'flow.phoneUp', 'flow.leadXpress'];

    if (requestRoute) {
        orderedFlowRoutes = orderedFlowRoutes.filter(flowRoute => flowRoute !== requestRoute);
        orderedFlowRoutes.unshift(requestRoute);
    }

    const availableFlowRoute = orderedFlowRoutes.find(flowRoute => user.hasAccessTo(flowRoute));

    return { name: availableFlowRoute || 'unauthorized' };
};

export default {
    async beforeRouteEnter(to, from, next) {
        if (!Vue.auth.ready()) {
            await Vue.auth.waitForUser();
        }

        const route = redirectToFirstAvailableFlowRoute(to.name);

        if (route.name === to.name) {
            next();
        } else {
            next(route);
        }
    },

    async beforeRouteUpdate(to, from, next) {
        if (!Vue.auth.ready()) {
            await Vue.auth.waitForUser();
        }

        const route = redirectToFirstAvailableFlowRoute(to.name);

        if (route.name === to.name) {
            next();
        } else {
            next(route);
        }
    },
};
