<template>
    <span>
        <a
            :target="target"
            :class="className"
            :href="computedHref"
            :disabled="disabled"
        >
            <slot />
        </a>
    </span>
</template>

<script>
    const colors = {
        grey: 'link-grey',
        lightGrey: 'link-grey-light',
        darkGrey: 'link-grey-dark',
        blue: 'link-blue',
        green: 'link-green',
        orange: 'link-orange',
        red: 'link-red',
    };

    export default {
        name: 'ActivixLink',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            href: {
                type: String,
                default: '',
            },
            color: {
                type: String,
                default: 'grey',
                validator: value => Object.keys(colors).includes(value),
            },
            target: {
                type: String,
                default: '_self',
                validator: value => ['_blank', '_self', '_parent', '_top'].includes(value),
            },
        },

        computed: {
            computedHref() {
                return this.disabled ? null : this.href;
            },

            className() {
                return colors[this.color];
            },
        },
    };
</script>
