<template>
    <div>
        <email-composer-v2
            :name="name"
            :is-forwarding="isForwarding"
            :portal="portal"
            @canceled="$emit('canceled', $event)"
            v-if="$feature.isEnabled('multilingual_mass_mailling')"
        />
        <email-composer-v1
            :name="name"
            :is-forwarding="isForwarding"
            :portal="portal"
            @canceled="$emit('canceled', $event)"
            v-else
        />
    </div>
</template>

<script>
    import EmailComposerV1 from './EmailComposerV1.vue';
    import EmailComposerV2 from './EmailComposerV2.vue';

    export default {
        components: {
            EmailComposerV2,
            EmailComposerV1,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
            isForwarding: {
                type: Boolean,
                default: false,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
        },
    };
</script>
