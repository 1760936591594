export default {
    methods: {
        hasValidatedMobile(lead) {
            if (!lead.phones || !lead.phones.length) {
                return false;
            }

            return lead.phones.some(phone => {
                return phone.valid && phone.validated && phone.mobile;
            });
        },

        hasNotValidatedPhone(lead) {
            if (!lead.phones || !lead.phones.length) {
                return false;
            }

            return lead.phones.some(phone => {
                return phone.valid && !phone.validated;
            });
        },
    },
};
