import { initValues } from '@/entities/ModelHelper.ts';

export default class ValueImpactOption {
    description: string = '';

    selected: boolean = false;

    amount: number = 0;

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }
}
