<template>
    <div class="relative" :class="className">
        <label class="input-control input-control--radio | font-normal text-grey-800">
            <div class="mr-2" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'" v-if="$slots.before">
                <slot name="before" />
            </div>
            <input
                type="radio"
                :name="name"
                :checked="state"
                :value="value"
                :disabled="disabled"
                @change="toggle"
            />
            <div class="input-control__indicator" />
            <div class="ml-2" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'" v-if="$slots.default">
                <slot />
            </div>
        </label>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */

    export default {
        name: 'ActivixRadio',

        model: {
            prop: 'modelValue',
            event: 'input',
        },

        props: {
            name: {
                type: String,
                default: null,
            },
            value: {
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            checked: {
                type: Boolean,
                default: false,
            },
            modelValue: {
                default: undefined,
            },
            className: {
                type: String,
                default: 'inline-flex',
            },
        },

        computed: {
            state() {
                if (this.modelValue === undefined) {
                    return this.checked;
                }

                return this.modelValue === this.value;
            },
        },

        watch: {
            checked(newValue) {
                if (newValue !== this.state) {
                    this.toggle();
                }
            },
        },

        methods: {
            onChange() {
                this.toggle();
            },

            toggle() {
                this.$emit('input', this.state ? '' : this.value);
            },
        },

        mounted() {
            if (this.checked && !this.state) {
                this.toggle();
            }
        },
    };
</script>
