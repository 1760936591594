export default {
    alerts: {
        show: {
            error: 'Failed fetching the Who\'s Next information',
        },
        update: {
            error: 'Failed updating the Who\'s Next information',
            success: 'Who\'s Next updated successfully',
        },
        destroy: {
            error: 'Failed removing the associate in the Who\'s Next information',
        },
        gunmiss: {
            error: 'Failed to update the gun miss information on the associate',
        },
    },
    modals: {
        update: {
            title: '',
        },
        delete: {
            title: '',
        },
    },
    appointmentCounter: 'Number of appointments per day',
    break_date: 'Break',
    delivery_date: '@.capitalize:lexicon.delivery.singular',
    deskManagerAdvisor: 'Desk manager',
    deskManagerVehicle: 'Desk manager',
    financialAdvisor: 'Financial directors',
    financialVehicle: 'Financial directors',
    gunMiss: 'Gun miss',
    inBreak: 'Break in progress',
    inDelivery: '@.capitalize:lexicon.delivery.singular in progress',
    inMeeting: 'Meeting in progress',
    inPlannedMeeting: 'In planned meeting',
    meeting_date: 'Meeting',
    message_over_ten: 'A maximum of 10 users will be seen in the Who\'s Next queue',
    newAdvisor: 'Associate - New',
    newVehicle: 'New vehicle',
    noDeskManager: 'No desk manager available',
    noDirector: 'No manager available',
    noPlannedMeeting: 'No appointment scheduled in the calendar within the next 4 hours',
    noUser: 'No associate available',
    onlyDirectorCanEdit:
        'Depending on the options in your account, only the managers can change the statuses (meeting, @:lexicon.delivery.singular, break)',
    relevantLeads: 'Here are your clients of the day',
    resetGunMiss: 'Reset gun miss',
    usedAdvisor: 'Associate - Used',
    usedVehicle: 'Used vehicle',
};
