<template>
    <div class="relative" :class="className">
        <label
            class="input-control input-control--checkbox | font-normal text-grey-800"
            :class="[sizeClass, { 'pointer-events-none': readonly }]"
        >
            <div class="mr-2" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'" v-if="$slots.before">
                <slot name="before" />
            </div>
            <input
                :id="computedId"
                type="checkbox"
                :checked="value"
                :disabled="disabled"
                :tabindex="tabindex"
                @change="$emit('input', $event.target.checked)"
                @click="$emit('click', $event)"
            />
            <div class="input-control__indicator" :class="[colorClass, symbolClass]" />
            <div class="ml-2" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'" v-if="$slots.default">
                <slot />
            </div>
        </label>
    </div>
</template>

<script>
    import { uniqueId } from 'lodash-es';

    export default {
        name: 'ActivixCheckbox',

        props: {
            id: {
                type: [String, Number],
                default: '',
            },
            value: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: 'blue',
                validator: value => ['blue', 'green', 'purple', 'orange', 'red'].includes(value),
            },
            cross: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'md',
                validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            tabindex: {
                type: String,
                default: '',
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            className: {
                type: [String, Array, Object],
                default: 'inline-flex',
            },
        },

        computed: {
            colorClass() {
                return this.color ? `checkbox-${this.color}` : '';
            },

            computedId() {
                return this.id || uniqueId('checkbox-');
            },

            sizeClass() {
                return this.size ? `checkbox-${this.size}` : '';
            },

            symbolClass() {
                return this.cross ? 'cross' : '';
            },
        },
    };
</script>
