<template>
    <div class="flex flex-wrap -mx-3">
        <div class="pb-6 px-3 w-full | xl:w-1/2">
            <label v-text="$t('general.dealer')" />
            <activix-multiselect
                white
                label="name"
                :multiple="true"
                :searchable="false"
                :clearable="true"
                identifier="id"
                :selected="selectedAccounts"
                :options="accountOptions"
                @update="updateFilters($event, 'account')"
            />
        </div>

        <div class="pb-6 px-3 w-full | xl:w-1/2">
            <label v-text="$t('clientCard.division')" />
            <activix-multiselect
                white
                label="name"
                :multiple="true"
                :searchable="false"
                :clearable="true"
                identifier="id"
                :with-none="true"
                :selected="selectedDivisions"
                :options="divisions"
                @update="updateFilters($event, 'division')"
            />
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import Division from '@/entities/Division.js';
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            accountOptions: {
                type: Array,
                default: () => [],
            },
            previouslySelectedAccountId: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                filters: {},
                selectedDivisions: [],
                selectedAccounts: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            divisions() {
                return this.configs.divisions
                    .filter(division => {
                        return [Division.SERVICE, Division.NEW, Division.USED].includes(division.id);
                    })
                    .map(division => {
                        return {
                            ...division,
                            name: this.$t(`divisions.${division.name}`),
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            },
        },

        methods: {
            updateFilters(filters, type) {
                if (type == 'division') {
                    this.selectedDivisions = filters;
                } else if (type == 'account') {
                    this.selectedAccounts = filters;
                }

                this.filters[type] = filters;

                this.$emit('update-filters', this.filters);
                this.debounceSearch();
            },

            debounceSearch() {
                this.$emit('search');
            },
        },

        created() {
            this.debounceSearch = debounce(this.debounceSearch, 500);
        },

        beforeDestroy() {
            this.debounceSearch.cancel();
        },
    };
</script>
