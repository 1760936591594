import outdatedBrowser from 'outdated-browser-rework';
import 'outdated-browser-rework/dist/style.css';
import i18n from 'vue-i18n';

outdatedBrowser({
    browserSupport: {
        Chrome: 57,
        Edge: 79,
        Safari: 10,
        'Mobile Safari': 10,
        Firefox: 50,
        Opera: 50,
        Vivaldi: 1,
        Yandex: { major: 17, minor: 10 },
        IE: false,
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: true,
    language: i18n.locale,
});
