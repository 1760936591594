export default {
    minutes: 'minute | minutes',
    hours: 'hour | hours',
    days: 'day | days',
    weeks: 'week | weeks',
    months: 'month | months',
    years: 'year | years',
    same_day: 'same day | same day',
    exact_time: 'exact time | exact time',

    abbreviations: {
        seconds: 's',
        minutes: 'm',
        hours: 'h',
        days: 'd',
    },
};
