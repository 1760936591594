import { initValues } from '@/entities/ModelHelper.ts';
import PrequalDetail from '@/entities/PreQualDetail.ts';
import UserIdvDetail from '@/entities/UserIdvDetail.ts';

export default class PreQualification {
    private _pre_qual_detail?: PrequalDetail;

    private _user_idv_detail?: UserIdvDetail;

    constructor(initialValues: any = {}) {
        initValues(this, initialValues);
    }

    set pre_qual_detail(preQualDetail) {
        this._pre_qual_detail = preQualDetail ? new PrequalDetail(preQualDetail) : undefined;
    }

    set user_idv_detail(userIdvDetail) {
        this._user_idv_detail = userIdvDetail ? new UserIdvDetail(userIdvDetail) : undefined;
    }

    get pre_qual_detail(): PrequalDetail | undefined {
        return this._pre_qual_detail;
    }

    get user_idv_detail(): UserIdvDetail | undefined {
        return this._user_idv_detail;
    }
}
