import ActivixDate from '../value-objects/ActivixDate.js';

export default class Dashboard {
    static getCarryOverRange(startDate, endDate) {
        startDate = new ActivixDate(startDate);
        endDate = new ActivixDate(endDate);

        const startOfMonth = startDate.startOfMonth();
        const endOfMonth = endDate.endOfMonth();

        let carryOverStartDate;
        const carryOverEndDate = startDate.subDays();

        if (startDate.isSameDay(startOfMonth) && endDate.isSameDay(endOfMonth)) {
            // Complete Month
            carryOverStartDate = startOfMonth.subMonths();
        } else {
            // # of days between start and end, max 31. +1 necessary to be inclusive
            const carryOverPeriod = Math.min(31, Math.ceil(endDate.diffInDays(startDate))) + 1;

            // Get begining of carry over
            carryOverStartDate = startDate.subDays(carryOverPeriod);

            // Maximum # days based on month
            const maxDays = carryOverStartDate.daysInMonth;

            if (carryOverPeriod > maxDays) {
                carryOverStartDate = startDate.subDays(maxDays);
            }
        }

        return {
            start: carryOverStartDate.startOfDay().toDate(),
            end: carryOverEndDate.endOfDay().toDate(),
        };
    }
}
