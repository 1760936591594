export default {
    title: 'Procédure',
    priority: 'Priorité | Priorités',
    addPriority: 'Ajouter une priorité',
    deletePriority: 'Supprimer une priorité',
    selectDivision: "Vous devez sélectionner une division à l'aide des boutons ci-dessous.",
    callPriority: 'Priorité',
    emptyPriority: 'La priorité est vide',
    leadxpressRequiresContact: 'Les utilisateurs dans la procédure auront par défaut des notifications par courriel',
    allAdvisorsPriority: 'Tous les conseillers sont dans la liste de priorité',
    maxPriority: 'Vous avez atteint le nombre maximum de priorités',
    notifyPrecedentPriorities: 'Notifier les priorités précédentes',
    noUsers: 'Aucun conseiller pour la division ',
    schedule: 'Horaire',
    new: 'Neuf',
    used: 'Occasion',
    usedAbr: 'Occas.',
    credit: 'Crédit',
    service: 'Service',
    user: 'Utilisateur | Utilisateurs',
    noService: '{service} doit être activé',
    hasDuplicateAlert: 'Cet utilisateur a accès à recevoir les leads doublon',
    disabledTooltip: 'Choix indisponible avec le systéme téléphonique sélectionné',
    alerts: {
        show: {
            error: 'Échec de la récupération de la procédure',
        },
        update: {
            error: 'Échec de la mise à jour de la procédure',
            success: 'Procédure mise à jour avec succès',
        },
    },
    services: {
        webboost: 'WebBoost',
        phoneup: 'Phone-up',
        leadxpress: 'Lead Web',
    },
    roundRobin: 'Round Robin',
    roundRobinTooltip: 'Auto-Assignation & Distribution équitable',
};
