export default {
    account: {
        singular: 'space',
    },
    divisions: {
        general: 'Polestar',
    },
    civility: {
        singular: 'title',
    },
    delivery: {
        singular: 'handover',
        plural: 'handover',
    },
    deliverable: {
        singular: 'handed over',
    },
    delivered: {
        infinitive: 'to handover',
        singular: 'handover complete',
        plural: 'handover complete',
        feminineSingular: 'handover complete',
        femininePlural: 'handover complete',
    },
    deliveryDate: {
        singular: 'handover date',
    },
    dashboard_columns: {
        presented: 'visit',
    },
    presented: {
        singular: 'space visit',
    },
    presentedOther: {
        singular: 'other visit',
    },
    province: {
        CA: 'province / State',
    },
    portfolio: {
        singular: 'retention',
        plural: 'retentions',
        prefix: 'a retention',
        abr: 'reten.',
    },
    renewal: {
        singular: 'renewal',
        plural: 'renewals',
    },
    renewed: {
        infinitive: 'retain',
        singular: 'retained',
        plural: 'retained',
    },
    result: {
        singular: 'contacted',
    },
    sale: {
        singular: 'order',
        plural: 'orders',
        prefix: 'an order',
    },
    secondaryAdvisor: {
        singular: 'lead concierge',
    },
    sold: {
        singular: 'ordered',
        plural: 'ordered',
    },
    undelivered: {
        singular: 'not handed over',
        plural: 'not handed over',
    },
    version: {
        singular: 'variant',
    },
    walkIn: {
        singular: 'space visit',
        plural: 'space visits',
        prefix: 'a space visit',
        abr: 'space',
    },
    certified: 'Certified',
};
