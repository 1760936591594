export default {
    success: 'Le véhicule a été soumis à Autopropulsion avec succès',
    warning: {
        error403: 'Votre compte n\'a pas accès à Autopropulsion',
        error404: 'Impossible de joindre Autopropulsion, veuillez contacter le support',
        error500: 'Une erreur du côté d\'Autopropulsion est survenue, veuillez contacter le support',
        vehicleAlreadyReceived: 'Ce véhicule a déjà été soumis à Autopropulsion',
    },
    tooltip: {
        match:
            "La soumission du véhicule vers Autopropulsion est basée sur la marque, le modèle, l'année et l'odomètre. Assurez-vous que les valeurs entrées soient valides avant la soumission",
        niv: "Vous devez saisir un NIV valide afin d'avoir une soumission valide vers Autopropulsion",
        unsubmitted: 'Soumettre le véhicule à Autopropulsion',
    },
};
