<template>
    <div>
        <activix-input
            class="text-grey-700 flex-1 attached-r mb-4"
            :placeholder="$t('search.search')"
            v-model="searchString"
            v-if="mdLayout"
        >
            <template #icon>
                <icon class="text-grey-700" name="regular/search" />
            </template>
        </activix-input>
        <div class="w-full" :class="{ 'overflow-x-auto': mdLayout }">
            <table class="table table-bordered table-striped | border" cellspacing="0" width="100%">
                <thead>
                    <tr>
                        <th>{{ $t('searchLead.client_name') }}</th>
                        <th>{{ $t('searchLead.lead_type') }}</th>
                        <th>{{ $t('searchLead.date') }}</th>
                        <th>{{ $t('searchLead.division') }}</th>
                        <th class="w-px" />
                    </tr>
                </thead>
                <tbody>
                    <tr :key="lead.id" v-for="lead in filteredLeads">
                        <td>
                            <router-link
                                target="_blank"
                                :to="{ name: 'leads.update', params: { id: lead.id } }"
                                v-if="lead.id"
                            >
                                {{ getFullName(lead) }}
                            </router-link>
                        </td>
                        <td>{{ formatLeadType(lead) }}</td>
                        <td nowrap>
                            {{ formatDate(lead) }}
                        </td>
                        <td>{{ formatDivision(lead) }}</td>
                        <td class="text-center">
                            <activix-button
                                size="small"
                                :loading="leadLoading == lead.id"
                                :disable="!!leadLoading"
                                @click="$emit('select-lead', lead.id)"
                            >
                                {{ $t('searchLead.chooseLead') }}
                            </activix-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="flex items-center justify-between mt-4">
            <span>{{ $t('searchLead.tableFooter', { limit: searchCount, leadCount: filteredLeads.length }) }}</span>
            <input
                type="text"
                class="form-control | w-auto"
                :placeholder="$t('modal.search')"
                v-model="searchString"
                v-if="!mdLayout"
            />
        </div>
    </div>
</template>

<script>
    import { camelCase, isEmpty } from 'lodash-es';
    import { latinize } from '../../utils/string.js';
    import LeadType from '../../entities/LeadType.js';
    import Division from '../../entities/Division.js';

    export default {
        props: {
            leads: {
                type: Array,
                required: true,
            },
            leadLoading: {
                type: null,
                required: true,
            },
        },

        data() {
            return {
                limit: 10,
                searchString: '',
            };
        },

        computed: {
            filteredLeads() {
                let tmpSearch = '';

                if (!isEmpty(this.searchString)) {
                    tmpSearch = latinize(this.searchString.trim().toLowerCase());
                }

                const allFilteredLeads = this.leads.filter(lead => {
                    return latinize(lead.fullName.toLowerCase()).indexOf(tmpSearch) !== -1;
                });

                return allFilteredLeads.slice(0, this.limit);
            },

            searchCount() {
                return this.filteredLeads.length < this.limit ? this.filteredLeads.length : this.limit;
            },
        },

        methods: {
            formatLeadType(lead) {
                if (!lead.lead_type_id) {
                    return '';
                }

                const key = camelCase(LeadType.getKey(lead.lead_type_id));

                if (!key) {
                    return '';
                }

                return this.$t(`leadTypes.${key}`);
            },

            formatDate(lead) {
                return as_locale(lead.created_at, 'created_at').humanShort();
            },

            formatDivision(lead) {
                if (!lead.division_id) {
                    return '';
                }

                const key = camelCase(Division.getKey(lead.division_id));

                return this.$t(`divisions.${key}`);
            },
        },
    };
</script>
