import { gql } from 'apollo-boost';
import { cloneDeep, isArray, isPlainObject, merge } from 'lodash-es';
import Vue from 'vue';

import { apolloClient } from '@/plugins/vue-apollo.js';
import Caching from '@/plugins/caching.js';
import GraphCache from '@/graphql/GraphCache.js';
import GraphQuerier from '@/graphql/GraphQuerier.js';

class GraphDAL {
    static introspectedTypes = [];

    static cacheLoaded = false;

    static alreadyFetching = {};

    static MANDATORY_FIELDS = ['account_id', 'user_id'];

    static async find(id, specificFieldsToQuery = [], useCache = true, ignoredFields = []) {
        const identifiers = GraphDAL.idToIdentifiers(id);
        const fieldList = await this.getIntrospectedType(identifiers.__typename);
        const mandatoryFieldsToQuery = this.MANDATORY_FIELDS.filter(mandatoryField => fieldList.includes(mandatoryField));

        const cachedItem = useCache ? GraphCache.get(id) : null;

        if (specificFieldsToQuery.length) {
            return GraphDAL.findSpecificFields(identifiers, cachedItem, [...mandatoryFieldsToQuery, ...specificFieldsToQuery]);
        }

        if (!cachedItem && !ignoredFields.length) {
            return GraphDAL.findAllFields(id, identifiers, fieldList);
        }

        return GraphDAL.findMissingFields(identifiers, cachedItem, fieldList, mandatoryFieldsToQuery, ignoredFields);
    }

    static async findAllFields(id, identifiers, fieldList) {
        if (typeof GraphDAL.alreadyFetching[id] === 'undefined') {
            GraphDAL.alreadyFetching[id] = GraphQuerier.fetchItemWithFields(identifiers.__typename, fieldList, identifiers);
            const fetchResult = await GraphDAL.alreadyFetching[id];

            delete GraphDAL.alreadyFetching[id];

            return fetchResult;
        }

        return GraphDAL.alreadyFetching[id];
    }

    static async findMissingFields(identifiers, cachedItem, fieldList, mandatoryFieldsToQuery, ignoredFields) {
        const cachedItemKeys = Object.keys(cachedItem || {});
        const missingFields = fieldList.filter(field => {
            if (field.includes('.')) {
                return false;
            }

            if (mandatoryFieldsToQuery.includes(field)) {
                return false;
            }

            if (ignoredFields.includes(field)) {
                return false;
            }

            return !cachedItemKeys.includes(field);
        });

        if (!missingFields.length) {
            return cachedItem;
        }

        const fields = [...missingFields, ...mandatoryFieldsToQuery];
        const missingData = await GraphQuerier.fetchItemWithFields(identifiers.__typename, fields, identifiers);

        return merge(cachedItem, missingData);
    }

    static async findSpecificFields(identifiers, cachedItem, fields) {
        if (cachedItem) {
            const fieldsInObjectNotation = GraphQuerier.mapFieldsFromDotToObjectNotation(fields);
            const rebuiltItem = await GraphDAL.deepRebuildFromCache(cachedItem, fieldsInObjectNotation);

            const missingFields = GraphDAL.getMissingFields(rebuiltItem, fieldsInObjectNotation);

            if (missingFields.length) {
                const fetchedItem = await GraphQuerier.fetchItemWithFields(identifiers.__typename, missingFields, identifiers);

                return merge(rebuiltItem, fetchedItem);
            }

            return rebuiltItem;
        }

        return GraphQuerier.fetchItemWithFields(identifiers.__typename, fields, identifiers);
    }

    static async deepRebuildFromCache(cachedItem, fieldsInObjectNotation) {
        if (!cachedItem || !fieldsInObjectNotation.relations) {
            return cachedItem;
        }

        const buildedRelations = await Promise.all(
            Object.keys(fieldsInObjectNotation.relations).map(async relation => {
                const relationCacheEntry = cachedItem[relation];
                const subRelations = fieldsInObjectNotation.relations[relation];

                const relationData = await GraphDAL.getRelationCachedData(relationCacheEntry);

                if (!relationData) {
                    return {
                        [relation]: relationData,
                    };
                }

                if (isPlainObject(relationData)) {
                    return {
                        [relation]: await GraphDAL.deepRebuildFromCache(relationData, subRelations),
                    };
                }

                return {
                    [relation]: await Promise.all(
                        relationData.map(async relationItem => GraphDAL.deepRebuildFromCache(relationItem, subRelations)),
                    ),
                };
            }),
        );

        return Object.assign({ ...cachedItem }, ...buildedRelations);
    }

    static async getRelationCachedData(relationEntry) {
        if (isPlainObject(relationEntry)) {
            return {
                ...await GraphCache.get(GraphQuerier.getCacheKeyForObject(relationEntry)),
            };
        }

        if (isArray(relationEntry)) {
            return cloneDeep(
                await Promise.all(
                    relationEntry.map(async item => GraphCache.get(GraphQuerier.getCacheKeyForObject(item))),
                ),
            );
        }

        return null;
    }

    static getMissingFields(data, fieldsInObjectNotation, path = []) {
        return [
            ...(fieldsInObjectNotation?.fields || [])
                .filter(field => !data || !Object.hasOwn(data, field))
                .map(field => [...path, field].join('.')),
            ...Object.keys(fieldsInObjectNotation?.relations || {}).map(relation => {
                let relationData = data?.[relation];

                if (isArray(relationData)) {
                    relationData = relationData[0];
                }

                return GraphDAL.getMissingFields(
                    relationData,
                    fieldsInObjectNotation.relations[relation],
                    [...path, relation],
                );
            }),
        ].flat();
    }

    static async getIntrospectedType(type) {
        if (!GraphDAL.cacheLoaded) {
            const cachedType = Vue.caching.getCache(Caching.GRAPHQL_INTROSPECT);

            if (cachedType) {
                GraphDAL.introspectedTypes = cachedType;
            }

            GraphDAL.cacheLoaded = true;
        }

        if (GraphDAL.introspectedTypes[type]) {
            return GraphDAL.introspectedTypes[type];
        }

        const typeResponse = await apolloClient.query({
            query: gql`
                query introspectionQuery($type: String!) {
                    __type(name: $type) {
                        fields {
                            name
                            type {
                                name
                                kind
                                ofType {
                                    name
                                    kind
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                type,
            },
        });

        const queriable = typeResponse.data.__type.fields.filter((field) => {
            return field.type.kind === 'SCALAR' ||
                field.type.ofType?.kind === 'SCALAR' ||
                field.type.kind === 'OBJECT' ||
                field.type.ofType?.kind === 'OBJECT';
        });

        GraphDAL.introspectedTypes[type] = queriable.flatMap((field) => {
            if (field.type.kind === 'OBJECT' || field.type.ofType?.kind === 'OBJECT') {
                const identifiers = GraphDAL.idToIdentifiers(`${field.type.kind === 'OBJECT' ? field.type.name : field.type.ofType.name}:`);

                return Object.keys(identifiers)
                    .filter(key => !key.startsWith('_'))
                    .map(key => `${field.name}.${key}`);
            }

            return [field.name];
        });

        Vue.caching.setCache(Caching.GRAPHQL_INTROSPECT, GraphDAL.introspectedTypes, null);

        return GraphDAL.introspectedTypes[type];
    }

    static idToIdentifiers(id) {
        const parts = id.split(':');
        const ids = parts[1].split('_');
        const identifiers = {
            __typename: parts[0],
        };

        switch (identifiers.__typename) {
            case 'AccountProduct':
            case 'AccountSupplier':
                identifiers.id = ids[0];
                identifiers.account_id = ids[1];
                break;
            case 'FlowUser':
                identifiers.id = ids[0];
                identifiers.flow_id = ids[1];
                break;
            default:
                identifiers.id = parts[1];
                break;
        }

        return identifiers;
    }
}

export default GraphDAL;
