export default {
    accounts: {
        index: 'Comptes',
        store: 'Créer un compte',
        update: 'Modifier un compte',
    },
    auth: {
        login: 'Login',
        logout: 'Déconnexion',
        error: 'Erreur de connexion',
    },
    automations: {
        index: '@:navs.sidebar.automations',
        store: 'Créer une automatisation',
        update: 'Modifier une automatisation',
    },
    campaign: 'Campagne',
    calendar: 'Calendrier',
    customer: {
        update: 'Client',
    },
    analytics: 'Analyses',
    dashboards: {
        activityReport: '@:dashboards.activityReport',
        allLead: '@:dashboards.allLead',
        commercial: '@:dashboards.commercial',
        event: '@:dashboards.event',
        leadXpress: '@:dashboards.leadXpress',
        loyalty: '@:dashboards.loyalty',
        phoneUp: '@:dashboards.phoneUp',
        renewal: '@:dashboards.renewal',
        saleTable: '@:dashboards.saleTable',
        service: '@:dashboards.service',
        walkIn: '@:dashboards.walkIn',
        webBoost: '@:dashboards.webBoost',
        webOrder: '@:dashboards.webOrder',
    },
    duplicates: '@:navs.sidebar.duplicates',
    flow: {
        webBoost: '@:navs.sidebar.flow @:dashboards.webBoost',
        phoneUp: '@:navs.sidebar.flow @:dashboards.phoneUp',
        leadXpress: '@:navs.sidebar.flow @:dashboards.leadXpress',
    },
    groups: {
        index: 'Groupes',
    },
    homepage: 'Accueil',
    imports: {
        index: '@:navs.sidebar.import',
        match: 'Association des colonnes',
    },
    inTurn: {
        management: 'Gestion du tour de rôle',
        show: 'Tour de rôle',
    },
    inventory: 'Inventaire',
    leads: {
        store: 'Nouveau lead',
        storeAssociated: 'Nouveau lead associé',
        trash: 'Corbeille',
        update: 'Lead',
    },
    notFound: 'Page Introuvable',
    objectives: {
        activityReport: '@:dashboards.activityReport - @:navs.sidebar.objectives',
        saleTable: '@:dashboards.saleTable - @:navs.sidebar.objectives',
    },
    search: 'Recherche',
    schedule: '@:navs.sidebar.schedule',
    suppliers: {
        accountNotFound: 'Compte introuvable',
    },
    templates: {
        index: '@:navs.sidebar.templates',
        store: 'Créer un modèle',
        update: 'Modifier un modèle',
    },
    unauthorized: 'Non autorisé',
    users: {
        index: 'Utilisateurs',
        store: 'Créer un utilisateur',
        update: 'Modifier un utilisateur',
    },
    sandbox: 'Sandbox',
};
