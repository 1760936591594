import { getOnce } from '@/plugins/axios.js';
import PreQualification from '@/entities/PreQualification.ts';
import { AxiosError, AxiosResponse } from 'axios';
import InvalidPayload from '../exceptions/InvalidPayload.js';

interface ShowResponse extends AxiosResponse {
    status: number,
    data: PreQualification
}

export default {
    async show(id: string) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        try {
            const response = await getOnce(`v1/leads/${id}/get-prequalification`) as ShowResponse;
            if (response === null) {
                return null;
            }

            return response.data;
        } catch (error: unknown) {
            if (!(error instanceof AxiosError)) {
                throw error;
            }

            const response = error.response;
            if (response && response.status === 404) {
                return null;
            }

            throw error;
        }
    },
};
