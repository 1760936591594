import axios from '@/plugins/axios.js';
import InvalidPayload from '@/exceptions/InvalidPayload.js';
import caching from '@/utils/caching.js';

const cache = caching();

export default {
    async index(accountId, bustCache = false) {
        if (!accountId) {
            throw new InvalidPayload('Account ID not provided.');
        }

        if (bustCache) {
            cache.reset();
        }

        const fetch = async () => {
            const response = await axios.get('v1/niotext-templates', {
                params: {
                    account_id: accountId,
                    bust_cache: bustCache,
                },
            });

            return response.data.data;
        };

        cache.set(accountId, fetch);

        return cache.get(accountId);
    },
};
