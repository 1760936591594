<template>
    <div>
        <div class="alert alert-warning | text-center" v-if="leadBelongToSomeoneElse && assignedUserName">
            {{ $t('responseLeadXpress.leadBelongToSomeoneElse', [assignedUserName]) }}
        </div>

        <div class="alert alert-warning | text-center" v-if="communicatingUser && !ownCommunication">
            <template v-if="communicatingType === CommunicationMethod.PHONE">
                {{ $t('responseLeadXpress.calling', [getFullName(communicatingUser)]) }}
            </template>
            <template v-else-if="communicatingType === CommunicationMethod.EMAIL">
                {{ $t('responseLeadXpress.sendingEmail', [getFullName(communicatingUser)]) }}
            </template>
            <template v-else-if="communicatingType === CommunicationMethod.SMS">
                {{ $t('responseLeadXpress.sendingSms', [getFullName(communicatingUser)]) }}
            </template>
        </div>

        <div class="alert alert-warning | text-center" v-if="recentCommunication">
            <template v-if="recentCommunication.communication_method_id == CommunicationMethod.PHONE">
                {{
                    $t('responseLeadXpress.alreadyCalled', [
                        getFullName(recentCommunication.user),
                        as_locale(recentCommunication.created_at, 'created_at').fromNow(),
                    ])
                }}
            </template>
            <template v-else>
                {{
                    $t('responseLeadXpress.alreadySentEmail', [
                        getFullName(recentCommunication.user),
                        as_locale(recentCommunication.created_at, 'created_at').fromNow(),
                    ])
                }}
            </template>
        </div>

        <div class="alert alert-grey | text-center" v-if="mostRecentCommunication">
            <div class="mb-3" v-html="alreadyCommunicatedText" />

            <activix-tooltip :content="$t('responseLeadXpress.ignoreTooltip')">
                <activix-button type="white" @click="dismiss">
                    {{ $t('responseLeadXpress.ignore') }}
                </activix-button>
            </activix-tooltip>
            <activix-tooltip
                :content="$t('responseLeadXpress.notifyTooltip', [mostRecentCommunication.user.first_name])"
            >
                <activix-button class="ml-2" type="white" @click="notify">
                    {{ $t('responseLeadXpress.notify') }}
                </activix-button>
            </activix-tooltip>
        </div>

        <!-- Mass mailing alert -->
        <div
            class="alert alert-info | text-center"
            v-if="massMailingLeadsRemoved && massMailingLeadsRemoved.length"
        >
            <h4>
                {{ $tc('mailClient.removedFromMassMailing', massMailingLeadsRemoved.length, [massMailingLeadsRemoved.length]) }}
            </h4>

            <a @click="toggleMassMailingRemoved">
                {{ $tc('mailClient.showLeads', massMailingLeadsRemoved.length, [massMailingLeadsRemoved.length]) }}
            </a>

            <span :class="{'collapse': !massMailingLeadsRemovedOpened}">
                <div :key="index" v-for="(massMailingLead, index) in massMailingLeadsRemoved">
                    {{ massMailingLead.name }} - {{ $t('mailClient.contactedBy', [massMailingLead.user]) }}
                </div>
            </span>
        </div>

        <!-- Mass mailing do not market alert -->
        <div
            class="alert alert-info | text-center"
            v-if="massMailingLeadsDoNotMarket.length"
        >
            <h4>
                {{ $tc('mailClient.removedFromMassMailingDoNotMarket', massMailingLeadsDoNotMarket.length, [massMailingLeadsDoNotMarket.length]) }}
            </h4>

            <a @click="toggleMassMailingDoNotMarket">
                {{ $tc('mailClient.showLeads', massMailingLeadsDoNotMarket.length, [massMailingLeadsDoNotMarket.length]) }}
            </a>

            <span :class="{'collapse': !massMailingLeadsDoNotMarketOpened}">
                <div :key="index" v-for="(massMailingLead, index) in massMailingLeadsDoNotMarket">
                    {{ massMailingLead.name }}
                </div>
            </span>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    // Entities
    import CommunicationMethod from '@/entities/CommunicationMethod.js';
    import Lead from '@/entities/Lead.js';
    import Role from '@/entities/Role.js';

    export default {
        components: {},

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            communicatingType: {
                type: Number,
                default: null,
            },
            communicatingUser: {
                default: null,
            },
            recentCommunication: {
                default: null,
            },
            mostRecentCommunication: {
                default: null,
            },
            massMailingLeadsRemoved: {
                type: Array,
                default() {
                    return [];
                },
            },
            massMailingLeadsDoNotMarket: {
                type: Array,
                default() {
                    return [];
                },
            },
        },

        data: () => ({
            CommunicationMethod,
            assignedUserName: null,
            massMailingLeadsRemovedOpened: false,
            massMailingLeadsDoNotMarketOpened: false,
        }),

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),

            ownCommunication() {
                return this.communicatingUser?.id === this.parentAuthUser?.id || this.communicatingUser?.id === this.authUser.id;
            },

            alreadyCommunicatedText() {
                if (!this.mostRecentCommunication) return '';

                const route = this.$router.resolve({
                    name: 'leads.update',
                    params: { id: this.mostRecentCommunication.lead_id },
                });

                const key =
                    this.mostRecentCommunication.communication_method_id == CommunicationMethod.PHONE
                        ? 'alreadyCalledLink'
                        : 'alreadyEmailedLink';

                return this.$t(`responseLeadXpress.${key}`, {
                    userName: this.getFullName(this.mostRecentCommunication.user),
                    date: as_locale(this.mostRecentCommunication.created_at, 'created_at').fromNow(),
                    link: route.href,
                });
            },

            currentUser() {
                return this.parentAuthUser.childUserForAccount(this.lead.account_id) || this.authUser;
            },

            leadBelongToSomeoneElse() {
                if (
                    this.lead.userId &&
                    this.currentUser.isAdvisor() &&
                    !this.currentUser.isSameUser(this.lead.userId)
                ) {
                    return true;
                }

                if (
                    this.lead.bdcUserId &&
                    this.currentUser.isBdc() &&
                    !this.currentUser.isSameUser(this.lead.bdcUserId)
                ) {
                    return true;
                }

                return false;
            },
        },

        watch: {
            'lead.userId': {
                immediate: true,
                async handler() {
                    if (this.currentUser.isAdvisor()) {
                        this.assignedUserName = (await this.lead.getUser(['first_name', 'last_name'])).fullName;
                    }
                },
            },

            'lead.bdcUserId': {
                immediate: true,
                async handler() {
                    if (this.currentUser.isBdc()) {
                        this.assignedUserName = (await this.lead.getBdcUser(['first_name', 'last_name'])).fullName;
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            dismiss() {
                this.$emit('dismiss');
            },

            notify() {
                const data = {};

                if (this.mostRecentCommunication.user.role_id == Role.ADVISOR) {
                    data.user_id = this.mostRecentCommunication.user_id;
                } else if (this.mostRecentCommunication.user.role_id == Role.AGENT) {
                    data.bdc_user_id = this.mostRecentCommunication.user_id;
                } else if (this.mostRecentCommunication.user.role_id == Role.COMMERCIAL) {
                    data.commercial_id = this.mostRecentCommunication.user_id;
                } else {
                    data.user_id = this.mostRecentCommunication.user_id;
                }

                this.updateLeadAction(this.lead.id, data);

                this.$emit('close');
            },

            toggleMassMailingRemoved() {
                this.massMailingLeadsOpened = !this.massMailingLeadsOpened;
            },

            toggleMassMailingDoNotMarket() {
                this.massMailingLeadsDoNotMarketOpened = !this.massMailingLeadsDoNotMarketOpened;
            },
        },
    };
</script>
