/* eslint-disable no-async-promise-executor */
import ActivixDate from '@/value-objects/ActivixDate.js';
import Vue from 'vue';

class Caching {
    static CONTEXT_ACCOUNT_CACHE_KEY = 'contextAccountCache';

    static AUTH_USER_CACHE_KEY = 'authUserCache';

    static DASHBOARD_VIEWS_CACHE_KEY = 'dashboardViewsCache';

    static CALENDAR_VIEWS_CACHE_KEY = 'calendarViewsCache';

    static SHARED_CALENDAR_VIEWS_CACHE_KEY = 'sharedCalendarViewsCache';

    static USER_SESSION_CACHE_KEY = 'userSessionCache';

    static GRAPHQL_OBJECT_CACHE = 'graphqlObjectCache';

    static GRAPHQL_INTROSPECT = 'graphqlIntrospectCache';

    static SHARED_DASHBOARD_VIEWS_CACHE_KEY = 'sharedDashboardViewsCache';

    db = null;

    constructor() {
        const request = indexedDB.open('CRM', 1);

        request.onsuccess = (event) => {
            this.db = event.target.result;
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;

            if (!db.objectStoreNames.contains('cache')) {
                db.createObjectStore('cache', { keypath: 'key' });
            }
        };
    }

    getStore() {
        return new Promise(resolve => {
            try {
                if (this.db === null) {
                    setTimeout(() => {
                        resolve(this.getStore());
                    }, 100);
                } else {
                    const transaction = this.db.transaction(['cache'], 'readwrite');
                    resolve(transaction.objectStore('cache'));
                }
            } catch {
                resolve(null);
            }
        });
    }

    static install() {
        const cache = new Caching();
        Vue.caching = cache;
        Vue.prototype.$caching = cache;

        let cacheExpireInterval = null;

        const clearOldCache = async () => {
            try {
                const store = await cache.getStore();

                if (!store) {
                    return;
                }

                const allCacheRequest = store.openCursor();

                allCacheRequest.onsuccess = (event) => {
                    const cursor = event.target.result;

                    if (cursor) {
                        if (!Caching.isCurrentlyValid(cursor.value)) {
                            cursor.delete();
                        }

                        cursor.continue();
                    }
                };
            } catch (e) {
                clearInterval(cacheExpireInterval);
            }
        };

        cacheExpireInterval = setInterval(clearOldCache, 300000);
    }

    static isCurrentlyValid(cacheObject) {
        try {
            return typeof cacheObject !== 'undefined' &&
                (cacheObject.expire_at > (new ActivixDate('now')).timestamp || cacheObject.expire_at === null) &&
                cacheObject.version === process.env.VUE_APP_VERSION &&
                cacheObject.sub === Vue.auth.jwtSub();
        } catch (e) {
            return false;
        }
    }

    async getAll() {
        const cachedObjects = {};
        try {
            const store = await this.getStore();

            if (!store) {
                return {};
            }

            const allCacheRequest = store.openCursor();

            allCacheRequest.onsuccess = (event) => {
                const cursor = event.target.result;

                if (cursor) {
                    if (Caching.isCurrentlyValid(cursor.value)) {
                        cachedObjects[cursor.key] = cursor.value;
                    }

                    cursor.continue();
                }
            };
        } catch (e) {
            return {};
        }

        return cachedObjects;
    }

    async setCache(key, value, duration = 86400) {
        try {
            const expireAt = duration ? (new ActivixDate('now')).addSeconds(duration).timestamp : null;
            const cacheObject = {
                value,
                expire_at: expireAt,
                version: process.env.VUE_APP_VERSION,
                sub: Vue.auth.jwtSub(),
            };

            (await this.getStore())?.put(cacheObject, key);
        } catch {
            // Do nothing
        }
    }

    async deleteFromCache(key) {
        try {
            (await this.getStore())?.delete(key);
        } catch {
            // Do nothing
        }
    }

    getCache(key) {
        return new Promise(resolve => {
            this.getStore()
                .then(store => {
                    if (!store) {
                        resolve(null);
                        return;
                    }

                    const cacheObjectRequest = store.get(key);

                    try {
                        cacheObjectRequest.onsuccess = () => {
                            if (Caching.isCurrentlyValid(cacheObjectRequest.result)) {
                                resolve(cacheObjectRequest.result.value);
                            } else {
                                resolve(null);
                            }
                        };

                        cacheObjectRequest.onerror = () => {
                            resolve(null);
                        };
                    } catch {
                        resolve(null);
                    }
                });
        });
    }

    async clear() {
        (await this.getStore())?.clear();
    }
}

export default Caching;
