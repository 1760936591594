export default {
    alerts: {
        store: {
            error: 'Failed creating the schedule',
        },
        update: {
            error: 'Failed updating the schedule',
        },
        destroy: {
            error: 'Failed deleting the schedule',
        },
    },
    availability: {
        offlineForever: 'Offline (indefinite)',
        offlineToday: 'Offline (day)',
        schedule: 'Online',
    },
    visibility: {
        hide: 'Hide',
        hideOthers: 'Hide others',
        onlyVisibleUsersCanBeDragged: 'Only visible users can be added to the schedule',
        show: 'Show',
        showAll: 'Show all users',
    },
    deleteModal: 'Are you sure you want to delete this schedule for {user}?',
    users: 'Users',
    calendarTitle: 'Schedule',
    userInactive: 'User deactivated',
    userNoAccessDivision: 'User does not have access to this division',
};
