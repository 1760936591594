export default {
    andUp: 'and up',
    biWeekly: 'bi-weekly',
    creditScoreRange: 'Credit score range',
    lender: 'Lender',
    maxAmount: 'Max Amount',
    payment: 'Payment',
    preQualifiedForALoanOf: 'Pre-Qualified for a loan of',
    rate: 'Rate',
    term: 'Term',
    upTo: 'Up to',
    validUntil: 'Valid until',
};
