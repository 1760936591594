import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloLink, HttpLink } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import * as Sentry from '@sentry/vue';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { get } from 'lodash-es';
import GQLPromiseToObservable from '@/utils/GQLPromiseToObservable.js';
import GraphSynchronizer from '@/graphql/GraphSynchronizer.js';
import GraphQuerier from '@/graphql/GraphQuerier.js';

const trackerApolloLink = new ApolloLink((operation, forward) => {
    if (!Vue.openReplay.recordGraphQL) {
        return forward(operation);
    }

    return forward(operation).map(result => {
        const operationDefinition = operation.query.definitions[0];
        return Vue.openReplay.recordGraphQL(
            operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?',
            operation.operationName,
            operation.variables,
            result,
        );
    });
});

// eslint-disable-next-line no-unused-vars
const regeneratorRuntime = require('regenerator-runtime');

// Install the vue plugin
Vue.use(VueApollo);

// Config
const defaultOptions = {
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },

    cache: new InMemoryCache({
        dataIdFromObject: GraphQuerier.getCacheKeyForObject,
    }),

    link: ApolloLink.from([
        trackerApolloLink,
        setContext(async (_, { headers }) => {
            const authorization = await (async () => {
                const token = await Vue.auth.token();
                return token ? `Bearer ${token}` : '';
            })();

            const authorizationHeader = authorization ? { authorization } : {};

            return {
                headers: {
                    ...headers,
                    ...authorizationHeader,
                },
            };
        }),
        // eslint-disable-next-line consistent-return
        onError((error) => {
            if (get(error, 'networkError.statusCode') === 401) {
                return GQLPromiseToObservable((async () => {
                    await Vue.auth.handleRefreshToken();
                    return error.forward(error.operation);
                })());
            }

            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(({ message }) => {
                    Sentry.withScope(scope => {
                        scope.setExtras({
                            operationName: error.operation.operationName,
                            body: error.operation.query.loc.source.body,
                        });
                        Sentry.captureMessage(`[GraphQL error] ${message}`, 'error');
                    });
                });
            }
        }),
        ApolloLink.split(() => typeof window !== 'undefined', new GraphSynchronizer()),
        new HttpLink({ uri: `${process.env.VUE_APP_API_URL}/graphql` }),
    ]),
};

function getApolloClient() {
    if (typeof Vue.prototype.$apolloClient === 'undefined') {
        Vue.prototype.$apolloClient = new ApolloClient(defaultOptions);
    }

    return Vue.prototype.$apolloClient;
}

export const apolloClient = getApolloClient();

export function createProvider() {
    return new VueApollo({
        defaultClient: getApolloClient(),
        defaultOptions: {
            $query: {},
        },
        errorHandler(error) {
            Sentry.captureMessage(`[GraphQL provider error] ${error.message}`, 'error');
            console.error(`[GraphQL provider error] ${error.message}`);
        },
    });
}

export async function apolloLogin() {
    try {
        await apolloClient.resetStore();
    } catch (e) {
        Sentry.captureMessage(`[GraphQL cache error] ${e.message}`, 'error');
        console.error(`[GraphQL cache error] ${e.message}`);
    }
}

export async function apolloLogout() {
    try {
        await apolloClient.resetStore();
    } catch (e) {
        Sentry.captureMessage(`[GraphQL cache error] ${e.message}`, 'error');
        console.error(`[GraphQL cache error] ${e.message}`);
    }
}
