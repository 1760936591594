/**
 * Remove Accents
 */
export function latinize(string) {
    return `${string}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Replace regular linebreaks to HTML linebreaks
 *
 * @return {string}
 */
export function nl2br(string) {
    return `${string}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
}

/**
 * Capitalize first lettre from a string
 *
 * @return {string}
 */
export function capitalizeFirst(string) {
    return `${string}`.charAt(0).toUpperCase() + `${string}`.slice(1);
}
