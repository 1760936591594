import Vue from 'vue';
import VueScroll from 'vuescroll/dist/vuescroll-native.js';

Vue.use(VueScroll, {
    ops: {
        vuescroll: {
            zooming: false,
            detectResize: false,
        },
        bar: {
            background: '#000000',
            opacity: 0.4,
            onlyShowBarOnScroll: Modernizr.hiddenscroll,
        },
    },
});
