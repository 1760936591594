import Enum from './Enum.js';

export default class InventoryStockStatus extends Enum {
    static get entries() {
        return {
            in_stock: 'in_stock',
            available: 'available',
            unavailable: 'unavailable',
            order: 'order',
            reserved: 'reserved',
            locate: 'locate',
            invoice: 'invoice',
            production: 'production',
            transit: 'transit',
            hold: 'hold',
            sold: 'sold',
        };
    }

    static get IN_STOCK() {
        return this.entries.in_stock;
    }

    static get AVAILABLE() {
        return this.entries.available;
    }

    static get UNAVAILABLE() {
        return this.entries.unavailable;
    }

    static get ORDER() {
        return this.entries.order;
    }

    static get RESERVED() {
        return this.entries.reserved;
    }

    static get LOCATE() {
        return this.entries.locate;
    }

    static get INVOICE() {
        return this.entries.invoice;
    }

    static get PRODUCTION() {
        return this.entries.production;
    }

    static get TRANSIT() {
        return this.entries.transit;
    }

    static get HOLD() {
        return this.entries.hold;
    }

    static get SOLD() {
        return this.entries.sold;
    }

    static get translationGroup() {
        return 'inventoryStockStatus';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
