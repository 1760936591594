import { sort } from '../../../utils/index.js';

import TaskEventType from '../../../entities/TaskEventType.js';
import { useGlobalStore } from '../../store.js';
import { useContextStore } from '../context/store.js';

export default {
    accidentedParts() {
        const parts = useGlobalStore().configs.accidentedParts.map(part => {
            return {
                id: part.id,
                text: part.name,
            };
        });
        return sort(parts, 'id');
    },
    leadBanks() {
        const banks = useGlobalStore().configs.leadBanks.map(bank => {
            return {
                value: bank.id,
                text: bank.name,
            };
        });

        return sort(banks, 'text');
    },
    authorizedAction() {
        const lead = useContextStore().lead;
        const authUser = useGlobalStore().authUser;

        if (
            authUser.isAdmin() ||
            authUser.isDirector() ||
            authUser.isBdcDirector() ||
            authUser.isCommercialDirector() ||
            authUser.isSuperBdc()
        ) {
            return true;
        }

        // Is the user an event guest?
        if (useGlobalStore().guest && authUser.account.guest_action) {
            const isEventGuest = lead.task_events.some(taskEvent => {
                return (
                    ![TaskEventType.CALL, TaskEventType.EMAIL].includes(taskEvent.task_event_type_id) &&
                    taskEvent.guests.some(guest => guest.id == authUser.id)
                );
            });

            if (isEventGuest) {
                return true;
            }
        }

        // Is the user assigned to the lead?
        if (lead.userId && authUser.isAdvisor() && !authUser.isSameUser(lead.userId) && !authUser.isSameUser(lead.secondaryUserId)) {
            return false;
        }

        if (lead.bdcUserId && authUser.isBdc() && !authUser.isSameUser(lead.bdcUserId)) {
            return false;
        }

        // Does the user have access to lead's division?
        if (!authUser.account.leads_other_division && authUser.doesNotHaveDivision(lead.division_id) && !lead.isAssignedTo(authUser.id)) {
            return false;
        }

        return true;
    },
};
