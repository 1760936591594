export default {
    users: {},
    startDate: null,
    endDate: null,
    filteredCampaigns: [],
    filteredLeadTypes: [],
    viewId: null,
    notificationDate: null,
    selectedDashboardView: null,
    dashboardViews: {},
    configs: {
        urlFiltersAllowed: {
            campaigns: {
                format: (value) => { return value.split(','); },
            },
            status: {
                format: (value) => { return value.split(','); },
            },
            result: {
                format: (value) => { return value.split(','); },
            },
            contact_method: {
                format: (value) => { return value.split(','); },
            },
            'division:name': {
                format: (value) => { return value.split(','); },
            },
            assignees: {
                format: (value) => { return value.split(','); },
            },

        },
        hotSwapDate: null,
        currentView: {
            hotSwapDate: null,
        },
        dates: [],
        columnVisibilities: {},
        columnOrder: [],
        columns: [],
        filters: {},
        order: {
            orderBy: 'fullname',
            sortBy: 'asc',
        },
        expanded: {
            leadTable: true,
            saleProfit: false,
            saleTable: false,
            statsByAgent: true,
            tradeReport: false,
            userTable: false,
            statsTable: false,
        },
        options: {
            carryOver: true,
            doubleWalkIn: false,
            endContractStats: false,
            includeAttributions: false,
            phoneAppointment: false,
            recordedDate: false,
            resultStats: false,
            showAgent: true,
            showAllLeads: false,
            showStats: true,
            otherVisit: false,
            lostDate: false,
        },
    },
    pagination: {
        currentPage: undefined,
        lastPage: undefined,
        perPage: undefined,
        from: undefined,
        to: undefined,
        total: undefined,
        orderBy: 'date',
        sortBy: 'desc',
    },
    dashboardType: '',
    filters: {},
    filterApplied: false,
    bulkSelect: [],
    blackListedLeadIds: [],
    allLeadsInTableSelected: false,
    filteredDates: [],
    startContractDate: null,
    tableLeads: [],
    storedStartDate: null,
    storedEndDate: null,
    sharedDashboardViews: {},
};
