import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';

export default class DeliveryTimeframe extends Enum {
    static get entries() {
        return {
            fifteen_minute: 'PT15M',
            twenty_minute: 'PT20M',
            twenty_five_minute: 'PT25M',
            thirty_minute: 'PT30M',
            thirty_five_minute: 'PT35M',
            forty_minute: 'PT40M',
            forty_five_minute: 'PT45M',
            fifty_minute: 'PT50M',
            fifty_five_minute: 'PT55M',
            one_hour: 'PT1H',
            one_hour_fifteen_minute: 'PT1H15M',
            one_hour_thirty_minute: 'PT1H30M',
            one_hour_forty_five_minute: 'PT1H45M',
            two_hour: 'PT2H',
            two_hour_fifteen_minute: 'PT2H15M',
            two_hour_thirty_minute: 'PT2H30M',
            two_hour_forty_five_minute: 'PT2H45M',
            three_hour: 'PT3H',
            three_hour_fifteen_minute: 'PT3H15M',
            three_hour_thirty_minute: 'PT3H30M',
            three_hour_forty_five_minute: 'PT3H45M',
            four_hour: 'PT4H',
        };
    }

    static value(timeframe) {
        return this.entries[timeframe];
    }

    static selectOptions() {
        return Object.entries(this.entries).map(([key]) => {
            return {
                value: i18n.t(`deliveryTimeframe.${key}`),
                name: key,
            };
        });
    }

    static calculateEndDate(startDate, timeframe) {
        const timeframeValue = this.value(timeframe);
        const tIndex = timeframeValue.indexOf('T');
        const hIndex = timeframeValue.indexOf('H');
        const newEndDate = startDate.clone();

        if (hIndex !== -1) {
            const hourValue = timeframeValue.substring(tIndex + 1, hIndex);
            newEndDate.addHours(hourValue);
        }

        const mIndex = timeframeValue.indexOf('M');

        if (hIndex !== -1 && mIndex !== -1) {
            const minuteValue = timeframeValue.substring(hIndex + 1, mIndex);
            newEndDate.addMinutes(minuteValue);
        } else if (mIndex !== -1) {
            const minuteValue = timeframeValue.substring(tIndex + 1, minutesIndex);
            newEndDate.addMinutes(minuteValue);
        }

        return newEndDate.toString();
    }
}
