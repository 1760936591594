export default {
    answered: 'Answered',
    attempted: 'Attempted',
    calling: 'Calling',
    delivery_failed: 'Delivery failed',
    error: 'Error',
    interrupted: 'Interrupted',
    invalid: 'Invalid',
    pending: 'Pending',
    unanswered: 'Unanswered',
};
