<template>
    <div class="relative" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <div
            class="absolute w-full px-5 space-x-2 py-2 z-10 rounded-lg | hover:bg-gray-750 | hover:bg-opacity-50"
            v-if="isExternalLink && hover"
        >
            <activix-button
                class="relative left-1/2 transform -translate-x-1/2"
                :href="externalLinkUrl"
                tag="a"
                target="_blank"
            >
                {{ $t(externalLinkText) }}
            </activix-button>
        </div>

        <div class="flex items-center px-5 space-x-2 py-2 bg-gray-150 overflow-y-hidden h-full | 2xl:space-x-3 | transition-width duration-300 | hover:bg-gray-300" :class="[{'rounded-lg': !editing }, waveformClasses]">
            <button class="relative bg-blue-500 shadow-md rounded-full | transition duration-200 | hover:bg-blue-600" @click="playPause">
                <div class="h-10 w-10 flex items-center justify-center">
                    <activix-spinner :size="18" :line-size="2" v-if="loading && !placeholder && !editing" />
                    <icon
                        :name="iconIsPlaying"
                        class="text-sm text-white"
                        :class="{'-mr-0.5': !isPlaying}"
                        v-else
                    />
                </div>
            </button>

            <wave-surfer
                :url="file.url"
                :duration="file.duration"
                :placeholder="placeholder"
                :autoload="editing"
                :autoplay="!editing"
                :loading.sync="loading"
                ref="wavesurfer"
                @ready="setReady"
                @time="setTime"
                @playing="setPlaying"
            />

            <div class="tracking-wider" v-text="currentTime" />

            <activix-tooltip :content="$t('general.download')" v-if="downloadable">
                <activix-button
                    class="flex items-center text-gray-700 active:text-gray-700 focus:text-gray-700 visited:text-gray-700 hover:text-gray-800"
                    :href="file.url"
                    referrerpolicy="origin"
                    size="sm"
                    tag="a"
                    target="_blank"
                >
                    <icon name="regular/download-button" />
                </activix-button>
            </activix-tooltip>

        </div>
    </div>
</template>

<script>
    import { ActivixButton } from '@autosync/atx-activix-ui';
    import WaveSurfer from './WaveSurfer.vue';

    export default {
        components: {
            ActivixButton,
            WaveSurfer,
        },

        props: {
            action: {
                type: Object,
                default: null,
            },
            file: {
                type: Object,
                required: true,
                duration: null,
            },
            downloadable: {
                type: Boolean,
                default: false,
            },
            editing: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isPlaying: false,
                wavesurferReady: false,
                currentTime: '00:00',
                loading: false,
                hover: false,
            };
        },

        computed: {
            iconIsPlaying() {
                return this.isPlaying ? 'bold/controls-pause' : 'bold/arrow-button-right-2';
            },

            waveformClasses() {
                if (!this.placeholder && !this.wavesurferReady && !this.editing) {
                    return 'w-32';
                }

                if (!this.wavesurferReady && !this.editing) {
                    return 'w-auto';
                }

                return 'w-full';
            },

            isExternalLink() {
                return this.action && this.action.type == 'externalLink';
            },

            externalLinkText() {
                return this.action.text;
            },

            externalLinkUrl() {
                return this.action.url;
            },
        },

        methods: {
            setPlaying(playing) {
                this.isPlaying = playing;
            },

            setReady(ready) {
                this.wavesurferReady = ready;
            },

            setTime(time) {
                this.currentTime = time;
            },

            async playPause() {
                if (!this.$refs.wavesurfer) {
                    return;
                }

                this.$refs.wavesurfer.playPause();
            },

            onMouseEnter() {
                this.hover = true;
            },

            onMouseLeave() {
                this.hover = false;
            },
        },
    };
</script>
