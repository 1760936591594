import Enum from './Enum.js';

export default class LeadResult extends Enum {
    static get entries() {
        return {
            attempted: 'attempted',
            pending: 'pending',
            reached: 'reached',
        };
    }

    static get ATTEMPTED() {
        return this.entries.attempted;
    }

    static get REACHED() {
        return this.entries.reached;
    }

    static get PENDING() {
        return this.entries.pending;
    }

    static get translationGroup() {
        return 'leadResults';
    }
}
