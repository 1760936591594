import DashboardType from '@/entities/DashboardType.js';
import { mapActions, mapState } from 'pinia';
import { useLeadStore } from '@/store/modules/lead/store.js';
import { showConnectionTimeout } from '../../utils/toastr.js';

import { useDashboardStore } from '../../store/modules/dashboard/store.js';
import { useGlobalStore } from '../../store/store.js';
import { useContextStore } from '../../store/modules/context/store.js';

export default {
    render: () => null,

    computed: {
        ...mapState(useDashboardStore, {
            dashboardType: 'dashboardType',
        }),
        ...mapState(useContextStore, {
            account: 'account',
            contextLead: 'lead',
        }),
    },

    methods: {
        ...mapActions(useGlobalStore, [
            'commentCreated',
            'commentDeleted',
            'commentUpdated',
            'communicationCreated',
            'communicationDeleted',
            'communicationUpdated',
            'leadEmailCreated',
            'leadEmailDeleted',
            'leadEmailUpdated',
            'leadPhoneCreated',
            'leadPhoneDeleted',
            'leadPhoneUpdated',
            'leadUpdatedAction',
            'leadVehicleCreated',
            'leadVehicleDeleted',
            'leadVehicleUpdated',
            'reloadLead',
            'taskEventCreated',
            'taskEventDeleted',
            'taskEventUpdated',
            'updateWebOrderBadgeCount',
            'deleteLead',
            'leadIsInDashboard',
        ]),
        ...mapActions(useLeadStore, [
            'bulkLeadCommunicatingStarted',
            'bulkLeadCommunicatingEnded',
        ]),

        showEmailError(leadId) {
            let message = this.$t('toastr.errorMessages.sendingEmail');

            if (leadId) {
                const route = this.$router.resolve({
                    name: 'leads.update',
                    params: { id: leadId },
                });

                message += `
                    <a class="btn btn-warning" href="${route.href}" target="_blank">
                        ${this.$t('toastr.goToLead')}
                    </a>
                `;
            }

            this.$notify.warning({
                duration: -1,
                title: this.$t('toastr.error'),
                text: message,
            });
        },

        showAttachmentSizeTooBigError(maximumSize) {
            const message = this.$t('toastr.errorMessages.filesTooBig', { maximumSize });

            this.$notify.warning({
                duration: -1,
                title: this.$t('toastr.error'),
                text: message,
            });
        },

        showInvalidImageError() {
            const message = this.$t('toastr.errorMessages.invalidImage');

            this.$notify.warning({
                duration: -1,
                title: this.$t('toastr.error'),
                text: message,
            });
        },

        showInvalidDataError() {
            const message = this.$t('toastr.errorMessages.invalidData');

            this.$notify.warning({
                duration: -1,
                title: this.$t('toastr.error'),
                text: message,
            });
        },

        listenToGlobal() {
            if (!this.$broadcasting.channels.global) {
                return;
            }

            this.$broadcasting.channels.global
                .listen('.reload', showConnectionTimeout)
                .listen('NetlifyDeployed', this._onNetlifyDeployed);
        },

        removeListenersFromGlobal() {
            if (!this.$broadcasting.channels.global) {
                return;
            }

            this.$broadcasting.channels.global
                .stopListening('.reload', showConnectionTimeout)
                .stopListening('NetlifyDeployed', this._onNetlifyDeployed);
        },

        _onNetlifyDeployed({ buildId }) {
            if (buildId != process.env.VUE_APP_BUILD_ID) {
                window.reloadNextPage = true;
            }
        },

        listenToAccount() {
            if (!this.$broadcasting.channels.account) {
                return;
            }

            // Lead
            this.$broadcasting.channels.account
                .listen('Lead.LeadUpdatedBroadcast', this._onLeadUpdatedBroadcast)
                .listen('Lead.LeadDeletedBroadcast', this._onLeadDeletedBroadcast);

            // Communication
            this.$broadcasting.channels.account
                .listen('Communication.CommunicationCreatedBroadcast', this._onCommunicationCreatedBroadcast)
                .listen('Communication.CommunicationUpdatedBroadcast', this._onCommunicationUpdatedBroadcast)
                .listen('Communication.CommunicationDeletedBroadcast', this._onCommunicationDeletedBroadcast);

            // Communicating
            this.$broadcasting.channels.account
                .listen('LeadCommunicatingStarted', this._onLeadCommunicatingStarted)
                .listen('LeadCommunicatingEnded', this._onLeadCommunicatingEnded);

            // Lead Phone
            this.$broadcasting.channels.account
                .listen('LeadPhone.LeadPhoneCreatedBroadcast', this._onLeadPhoneCreatedBroadcast)
                .listen('LeadPhone.LeadPhoneUpdatedBroadcast', this._onLeadPhoneUpdatedBroadcast)
                .listen('LeadPhone.LeadPhoneDeletedBroadcast', this._onLeadPhoneDeletedBroadcast);

            // Lead Email
            this.$broadcasting.channels.account
                .listen('LeadEmail.LeadEmailCreatedBroadcast', this._onLeadEmailCreatedBroadcast)
                .listen('LeadEmail.LeadEmailUpdatedBroadcast', this._onLeadEmailUpdatedBroadcast)
                .listen('LeadEmail.LeadEmailDeletedBroadcast', this._onLeadEmailDeletedBroadcast);

            // Lead Vehicle
            this.$broadcasting.channels.account
                .listen('LeadVehicle.LeadVehicleCreatedBroadcast', this._onLeadVehicleCreatedBroadcast)
                .listen('LeadVehicle.LeadVehicleUpdatedBroadcast', this._onLeadVehicleUpdatedBroadcast)
                .listen('LeadVehicle.LeadVehicleDeletedBroadcast', this._onLeadVehicleDeletedBroadcast);

            // Comment
            this.$broadcasting.channels.account
                .listen('Comment.CommentCreated', this._onCommentCreated)
                .listen('Comment.CommentUpdated', this._onCommentUpdated)
                .listen('Comment.CommentDeleted', this._onCommentDeleted);

            // TaskEvents
            this.$broadcasting.channels.account
                .listen('TaskEvent.TaskEventCreatedBroadcast', this._onTaskEventCreatedBroadcast)
                .listen('TaskEvent.TaskEventUpdatedBroadcast', this._onTaskEventUpdatedBroadcast)
                .listen('TaskEvent.TaskEventDeletedBroadcast', this._onTaskEventDeletedBroadcast);

            // In turn
            this.$broadcasting.channels.account.listen('UpdateInTurn', this._onUpdateInTurn);

            // Web Order
            this.$broadcasting.channels.account.listen('WebOrderBadgeCountUpdated', this._onWebOrderBadgeCountUpdated);
        },

        async _onLeadUpdatedBroadcast({ leadId }) {
            this.reloadLead(leadId);
            this.updateDashboardLead(leadId);
        },

        _onLeadDeletedBroadcast(event) {
            const lead = JSON.parse(event.lead);
            this.updateDashboardLead(lead.id, true);
            this.deleteLead(lead.id);
        },

        _onCommunicationCreatedBroadcast(event) {
            const communication = JSON.parse(event.communication);

            this.updateDashboardLead(communication.lead_id);
            this.communicationCreated(communication);
        },
        _onCommunicationUpdatedBroadcast(event) {
            const communication = JSON.parse(event.communication);

            this.updateDashboardLead(communication.lead_id);
            this.communicationUpdated(communication);
        },
        _onCommunicationDeletedBroadcast(event) {
            const communication = JSON.parse(event.communication);

            this.updateDashboardLead(communication.lead_id);
            this.communicationDeleted(communication);
        },
        _onLeadCommunicatingStarted(event) {
            this.bulkLeadCommunicatingStarted(event.leads);
        },
        _onLeadCommunicatingEnded(event) {
            this.bulkLeadCommunicatingEnded(event.leads);
        },
        _onLeadPhoneCreatedBroadcast(event) {
            const leadPhone = JSON.parse(event.leadPhone);

            this.updateDashboardLead(leadPhone.lead_id);
            this.leadPhoneCreated(leadPhone);
        },
        _onLeadPhoneUpdatedBroadcast(event) {
            const leadPhone = JSON.parse(event.leadPhone);

            this.updateDashboardLead(leadPhone.lead_id);
            this.leadPhoneUpdated(leadPhone);
        },
        _onLeadPhoneDeletedBroadcast(event) {
            const leadPhone = JSON.parse(event.leadPhone);

            this.updateDashboardLead(leadPhone.lead_id);
            this.leadPhoneDeleted(leadPhone);
        },
        _onLeadEmailCreatedBroadcast(event) {
            const leadEmail = JSON.parse(event.leadEmail);

            this.updateDashboardLead(leadEmail.lead_id);
            this.leadEmailCreated(leadEmail);
        },
        _onLeadEmailUpdatedBroadcast(event) {
            const leadEmail = JSON.parse(event.leadEmail);

            this.updateDashboardLead(leadEmail.lead_id);
            this.leadEmailUpdated(leadEmail);
        },
        _onLeadEmailDeletedBroadcast(event) {
            const leadEmail = JSON.parse(event.leadEmail);

            this.updateDashboardLead(leadEmail.lead_id);
            this.leadEmailDeleted(leadEmail);
        },
        _onLeadVehicleCreatedBroadcast(event) {
            const leadVehicle = JSON.parse(event.leadVehicle);

            this.updateDashboardLead(leadVehicle.lead_id);
            this.leadVehicleCreated(leadVehicle);
        },
        async _onLeadVehicleUpdatedBroadcast({ leadId, leadVehicleId }) {
            this.updateDashboardLead(leadId);

            if (useContextStore().leadId != leadId && !this.leadIsInDashboard(leadId)) {
                return;
            }

            const leadVehicle = await this.$api.leadVehicles.show(leadVehicleId, {
                with: {
                    suppliers: '*',
                    customFields: [
                        'custom_fields.id',
                    ],
                },
            });

            this.leadVehicleUpdated(leadVehicle);
        },
        _onLeadVehicleDeletedBroadcast(leadVehicle) {
            this.updateDashboardLead(leadVehicle.lead_id);
            this.leadVehicleDeleted(leadVehicle);
        },
        _onCommentCreated({ comment }) {
            this.updateDashboardLead(comment.lead_id);
            this.commentCreated(comment);
        },
        _onCommentUpdated({ comment }) {
            this.updateDashboardLead(comment.lead_id);
            this.commentUpdated(comment);
        },
        _onCommentDeleted({ comment }) {
            this.updateDashboardLead(comment.lead_id);
            this.commentDeleted(comment);
        },
        _onTaskEventCreatedBroadcast(taskEvent) {
            this.updateDashboardLead(taskEvent.lead_id);
            this.taskEventCreated(taskEvent);
        },
        _onTaskEventUpdatedBroadcast(taskEvent) {
            this.updateDashboardLead(taskEvent.lead_id);
            this.taskEventUpdated(taskEvent);
        },
        _onTaskEventDeletedBroadcast({ taskEventId, leadId }) {
            this.updateDashboardLead(leadId);
            this.taskEventDeleted({ id: taskEventId, lead_id: leadId });
        },
        _onUpdateInTurn() {
            this.$eventBus.$emit('update-in-turn');
        },
        _onWebOrderBadgeCountUpdated({ badgeCount }) {
            this.updateWebOrderBadgeCount(badgeCount);
        },
        removeListenersFromAccount() {
            if (!this.$broadcasting.channels.account) {
                return;
            }

            // Lead
            this.$broadcasting.channels.account
                .stopListening('Lead.LeadUpdatedBroadcast', this._onLeadUpdatedBroadcast)
                .stopListening('Lead.LeadDeletedBroadcast', this._onLeadDeletedBroadcast);

            // Communication
            this.$broadcasting.channels.account
                .stopListening('Communication.CommunicationCreatedBroadcast', this._onCommunicationCreatedBroadcast)
                .stopListening('Communication.CommunicationUpdatedBroadcast', this._onCommunicationUpdatedBroadcast)
                .stopListening('Communication.CommunicationDeletedBroadcast', this._onCommunicationDeletedBroadcast);

            // Communicating
            this.$broadcasting.channels.account
                .stopListening('LeadCommunicatingStarted', this._onLeadCommunicatingStarted)
                .stopListening('LeadCommunicatingEnded', this._onLeadCommunicatingEnded);

            // Lead Phone
            this.$broadcasting.channels.account
                .stopListening('LeadPhone.LeadPhoneCreatedBroadcast', this._onLeadPhoneCreatedBroadcast)
                .stopListening('LeadPhone.LeadPhoneUpdatedBroadcast', this._onLeadPhoneUpdatedBroadcast)
                .stopListening('LeadPhone.LeadPhoneDeletedBroadcast', this._onLeadPhoneDeletedBroadcast);

            // Lead Email
            this.$broadcasting.channels.account
                .stopListening('LeadEmail.LeadEmailCreatedBroadcast', this._onLeadEmailCreatedBroadcast)
                .stopListening('LeadEmail.LeadEmailUpdatedBroadcast', this._onLeadEmailUpdatedBroadcast)
                .stopListening('LeadEmail.LeadEmailDeletedBroadcast', this._onLeadEmailDeletedBroadcast);

            // Lead Vehicle
            this.$broadcasting.channels.account
                .stopListening('LeadVehicle.LeadVehicleCreatedBroadcast', this._onLeadVehicleCreatedBroadcast)
                .stopListening('LeadVehicle.LeadVehicleUpdatedBroadcast', this._onLeadVehicleUpdatedBroadcast)
                .stopListening('LeadVehicle.LeadVehicleDeletedBroadcast', this._onLeadVehicleDeletedBroadcast);

            // Comment
            this.$broadcasting.channels.account
                .stopListening('Comment.CommentCreated', this._onCommentCreated)
                .stopListening('Comment.CommentUpdated', this._onCommentUpdated)
                .stopListening('Comment.CommentDeleted', this._onCommentDeleted);

            // TaskEvents
            this.$broadcasting.channels.account
                .stopListening('TaskEvent.TaskEventCreatedBroadcast', this._onTaskEventCreatedBroadcast)
                .stopListening('TaskEvent.TaskEventUpdatedBroadcast', this._onTaskEventUpdatedBroadcast)
                .stopListening('TaskEvent.TaskEventDeletedBroadcast', this._onTaskEventDeletedBroadcast);

            // In turn
            this.$broadcasting.channels.account.stopListening('UpdateInTurn', this._onUpdateInTurn);

            // Web Order
            this.$broadcasting.channels.account.stopListening('WebOrderBadgeCountUpdated', this._onWebOrderBadgeCountUpdated);
        },

        listenToUser() {
            if (!this.$broadcasting.channels.user) {
                return;
            }

            this.$broadcasting.channels.user
                .notification(this._onUserChannelNotification)
                .listen('LeadXpressEmailError', this._onLeadXpressEmailError)
                .listen('LeadXpressAttachmentSizeTooBigError', this._onLeadXpressAttachmentSizeTooBigError)
                .listen('LeadXpressInvalidImage', this._onLeadXpressInvalidImage)
                .listen('LeadXpressInvalidData', this._onLeadXpressInvalidData)
                .listen('Notification.NewNotification', this._onNewNotification)
                .listen('Notification.NotificationSeen', this._onNotificationSeen)
                .listen('Notification.NotificationGroupSeen', this._onNotificationGroupSeen)
                .listen('ExportLeadError', this._onExportLeadError)
                .listen('CallEnded', this._onCallEnded)
                .listen('ScreenPopOpen', this._onScreenPopOpen)
                .listen('ScreenPopClose', this._onScreenPopClose)
                .listen('ScreenPopVideoConferenceOpen', this._onScreenPopVideoConferenceOpen)
                .listen('ScreenPopVideoConferenceClose', this._onScreenPopVideoConferenceClose)
                .listen('UserSuspended', this._onUserSuspended)
                .listen('EmailConfig\\EmailConfigUpdated', this._onEmailConfigUpdated)
                .listen('BulkActionCompleted', this._onBulkActionCompleted)
                .listen('ExportLeadFromIntegrationsResult', this._onExportLeadFromIntegrationsResult);

            this.$broadcasting.channels.user
                .listenForWhisper('closeAllTabs', this._onCloseAllTabs)
                .listenForWhisper('closeCallSummary', this._onCloseCallSummary);
        },

        removeListenersFromUser() {
            if (!this.$broadcasting.channels.user) {
                return;
            }

            this.$broadcasting.channels.user
                // notification method is an alias for listen('.Illuminate\Notifications\Events\BroadcastNotificationCreated')
                // but we don't have one alias to remove it.
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', this._onUserChannelNotification)
                .stopListening('LeadXpressEmailError', this._onLeadXpressEmailError)
                .stopListening('LeadXpressAttachmentSizeTooBigError', this._onLeadXpressAttachmentSizeTooBigError)
                .stopListening('LeadXpressInvalidImage', this._onLeadXpressInvalidImage)
                .stopListening('LeadXpressInvalidData', this._onLeadXpressInvalidData)
                .stopListening('Notification.NewNotification', this._onNewNotification)
                .stopListening('Notification.NotificationSeen', this._onNotificationSeen)
                .stopListening('Notification.NotificationGroupSeen', this._onNotificationGroupSeen)
                .stopListening('ExportLeadError', this._onExportLeadError)
                .stopListening('CallEnded', this._onCallEnded)
                .stopListening('ScreenPopOpen', this._onScreenPopOpen)
                .stopListening('ScreenPopClose', this._onScreenPopClose)
                .stopListening('ScreenPopVideoConferenceOpen', this._onScreenPopVideoConferenceOpen)
                .stopListening('ScreenPopVideoConferenceClose', this._onScreenPopVideoConferenceClose)
                .stopListening('UserSuspended', this._onUserSuspended)
                .stopListening('EmailConfig\\EmailConfigUpdated', this._onEmailConfigUpdated)
                .stopListening('BulkActionCompleted', this._onBulkActionCompleted)
                .stopListening('ExportLeadFromIntegrationsResult', this._onExportLeadFromIntegrationsResult);

            this.$broadcasting.channels.user
                .stopListeningForWhisper('closeAllTabs', this._onCloseAllTabs)
                .stopListeningForWhisper('closeCallSummary', this._onCloseCallSummary);
        },
        _onUserChannelNotification(notification) {
            switch (notification.type) {
                case 'App\\Notifications\\NextInTurn':
                case 'App\\Notifications\\NoUserInTurn':
                case 'App\\Notifications\\InTurnRemove':
                    this.$eventBus.$emit('notification-in-turn', notification);
                    break;
            }
        },
        _onLeadXpressEmailError(data) {
            const leadId = data.leadId != this.contextLead.id ? data.leadId : null;

            this.showEmailError(leadId);
        },
        _onLeadXpressAttachmentSizeTooBigError(data) {
            this.showAttachmentSizeTooBigError(data.maximumSize);
        },
        _onLeadXpressInvalidImage() {
            this.showInvalidImageError();
        },
        _onLeadXpressInvalidData() {
            this.showInvalidDataError();
        },
        _onNewNotification({ notification }) {
            this.$eventBus.$emit('new-notification', notification);
        },
        _onNotificationSeen({ notification }) {
            this.$eventBus.$emit('notification-seen', notification);
        },
        _onNotificationGroupSeen({ group }) {
            this.$eventBus.$emit('notification-group-seen', group);
        },
        _onExportLeadError(data) {
            this.$eventBus.$emit('export-lead-error', data);
        },

        async _onCallEnded(event) {
            const lead = await this.$api.leads.show(event.leadId);

            if (this.account.call_summary) {
                const communication = await this.$api.communications.show(event.communicationId);
                this.$eventBus.$emit('show-call-summary', lead, communication);
                // show-next-step will be emitted when call-summary modal is closed
            } else {
                this.$eventBus.$emit('show-next-step', {
                    lead,
                    afterCallNextStep: true,
                });
            }
        },
        _onScreenPopOpen(event) {
            this.$eventBus.$emit('screen-pop-open', event.leadId, event.type);
        },
        _onScreenPopClose() {
            this.$eventBus.$emit('screen-pop-close');
        },
        _onScreenPopVideoConferenceOpen(event) {
            this.$eventBus.$emit('screen-pop-video-conference-open', event);
        },
        _onScreenPopVideoConferenceClose() {
            this.$eventBus.$emit('screen-pop-video-conference-close');
        },
        _onUserSuspended() {
            window.reloadNextPage = true;
        },
        _onEmailConfigUpdated(event) {
            this.$eventBus.$emit('email-config-updated', event);
        },
        _onBulkActionCompleted(event) {
            this.bulkActionCompleted(event);
        },
        _onExportLeadFromIntegrationsResult(event) {
            this.$eventBus.$emit('export-lead-from-integrations-result', event);
        },
        _onCloseAllTabs() {
            this.$eventBus.$emit('close-task-event');
        },
        _onCloseCallSummary() {
            this.$eventBus.$emit('close-call-summary');
        },

        updateDashboardLead(id, showIndicator = false) {
            if (this.dashboardType == DashboardType.ACTIVITY) {
                return;
            }

            if (showIndicator && this.leadIsInDashboard(id)) {
                this.$wait.start('indicator.leadTable');
            }

            this.$eventBus.$emit('fetch-stats', { passive: true, background: true });
        },

        bulkActionCompleted(event) {
            let message = '';
            const route = this.$router.resolve({
                name: 'leads.update',
                params: { id: event.leadId },
            });

            switch (event.type) {
                case 'associated':
                case 'merge':
                    message += `
                        <button class="btn btn-warning" data-vue-router="${route.href}">
                            ${this.$t('toastr.goToLead')}
                        </button>
                    `;
                    this.reloadLead(event.leadId);

                    break;
                case 'failed':
                    this.$notify.error({
                        title: this.$t('toastr.error'),
                        text: event.message,
                    });

                    return;
            }

            message = event.message + message;

            this.$notify.success({
                title: this.$t('toastr.success'),
                text: message,
            });

            this.$eventBus.$emit('fetch-table-leads');
        },
    },

    created() {
        this.listenToGlobal();
        this.listenToAccount();
        this.listenToUser();

        this.$broadcasting.$on('global-channel-ready', this.listenToGlobal);
        this.$broadcasting.$on('account-channel-ready', this.listenToAccount);
        this.$broadcasting.$on('user-channel-ready', this.listenToUser);
    },

    beforeDestroy() {
        this.$broadcasting.$off('global-channel-ready', this.listenToGlobal);
        this.$broadcasting.$off('account-channel-ready', this.listenToAccount);
        this.$broadcasting.$off('user-channel-ready', this.listenToUser);

        this.removeListenersFromGlobal();
        this.removeListenersFromAccount();
        this.removeListenersFromUser();
    },
};
