export default {
    conquest: 'Conquest',
    csi: 'CSI',
    endLcap: 'End LCAP',
    endLnette: 'End LNETTE',
    endWarranty: 'End warranty',
    loyalty: 'Loyalty',
    noShow: 'No Show',
    notSold: 'Not @:lexicon.sold.singular',
    other: 'Other',
    promo: 'Promo',
    reminder: 'Reminder',
    service: 'Service',
};
