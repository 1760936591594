export default {
    appointment_request: 'Demande de RV',
    auto_show: "Salon de l'auto",
    call_me: 'Appelle-moi',
    carproof: 'Carproof',
    coupon: 'Coupon',
    credit: 'Crédit',
    exchange_evaluation: "Évaluation d'échange",
    express_request: 'Demande express',
    information_request: "Demande d'information",
    mobile: 'Mobile',
    price_new: 'Prix neuf',
    price_use: 'Prix usager',
    test_drive: 'Essai routier',
};
