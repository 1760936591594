/* eslint-disable camelcase */
import Model from './Model.js';
import ValidatedEmail from './ValidatedEmail.js';
import LeadEmailType from './LeadEmailType.js';

export default class LeadEmail extends Model {
    static get defaults() {
        return {
            validated_email: {},
        };
    }

    static validType(type) {
        return LeadEmailType.exists(type);
    }

    get validated_email() {
        return this._validated_email;
    }

    set validated_email(validateEmail) {
        this._validated_email = new ValidatedEmail(validateEmail);
    }

    toString() {
        return this.email;
    }
}
