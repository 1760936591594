import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async validate(emailAddress) {
        if (!emailAddress) {
            throw new InvalidPayload('Email Address not provided.');
        }

        const response = await axios.post('v1/validate-email', { email: emailAddress });

        return response.data.data;
    },
};
