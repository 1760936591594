import mixpanel from 'mixpanel-browser';
import { isEmpty } from 'lodash-es';
import AuthUser from '@/entities/AuthUser.js';
import Division from '@/entities/Division.js';
import Role from '../entities/Role.js';
import { capitalizeFirst } from '../utils/string.js';

const MixpanelClient = {
    isInitialized: false,

    init() {
        if (this.isInitialized) {
            return;
        }

        if (['production'].includes(process.env.VUE_APP_ENV)) {
            mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);

            this.isInitialized = true;
        }
    },

    async setAuthUser(user) {
        if (!this.isInitialized) {
            return;
        }

        if (isEmpty(user)) {
            mixpanel.identify(null);
            return;
        }

        // Id
        mixpanel.identify(user.id);

        const userEntity = new AuthUser(user);

        const [post] = await Promise.all([
            userEntity.post,
        ]);

        // Attributes
        mixpanel.people.set(
            this.preparePayload({
                $email: user.email,
                $first_name: user.first_name,
                $last_name: user.last_name,
                $created: user.created_at,
                $timezone: user.timezone,
                locale: user.locale,
                dealer: user.account.name,
                sex: user.sex,
                division: user.divisions.map(divisionId => Division.getKey(divisionId)),
                role: Role.getKey(user.role_id),
                post: post?.name || 'programmer',
            }),
        );

        this.group('platform', 'web');
        this.group('environment', process.env.VUE_APP_ENV);
        this.group('province', user.account.province);
    },

    track(event, data) {
        if (!this.isInitialized) {
            return;
        }

        mixpanel.track(capitalizeFirst(event), this.preparePayload(data));
    },

    group(group, list) {
        if (!this.isInitialized) {
            return;
        }

        mixpanel.set_group(capitalizeFirst(group), list);
    },

    preparePayload(payload) {
        const formattedPayload = {};

        for (const [key, value] of Object.entries(payload)) {
            formattedPayload[capitalizeFirst(key)] = value || '';
        }
        return formattedPayload;
    },
};

MixpanelClient.init();

export default MixpanelClient;
