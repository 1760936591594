<template>
    <div
        class="w-full rounded bg-white"
        :class="{'shadow-lg pb-6': isAdvanced}"
    >
        <div :class="{'w-full bg-gray-200': !isAdvanced}">
            <div :class="{'flex pb-6 flex-col px-0 rounded bg-white shadow-lg': !isAdvanced, 'pt-4': !massMailing}">
                <div class="px-6 grid grid-cols-1 gap-6">
                    <div>
                        <label>{{ $t('responseLeadXpress.subject') }}</label>
                        <activix-input :value="subject" ref="subject" @input="updateSubject" />
                    </div>
                    <div class="relative flex-1 flex flex-col space-y-6">
                        <template-editor
                            :locale="locale"
                            :lead="lead"
                            :mass-mailing="massMailing"
                            :design="design"
                            :key="template?.id ?? 0"
                            @update-design="updateDesign"
                            @update-body="updateBody"
                            v-if="isAdvanced"
                        />
                        <activix-summernote
                            :height="200"
                            :min-height="160"
                            :max-height="200"
                            :sticky-toolbar="!mdLayout"
                            :disabled="disallowCommunication"
                            :is-last="massMailing"
                            :value="body"
                            ref="email"
                            @input="updateBody"
                            v-else
                        />
                    </div>
                    <div class="w-full text-right">
                        <label class="hidden | xs:block xs:invisible" v-if="showFrom">{{ $t('responseLeadXpress.addAttachment') }}</label>
                        <activix-drag-upload
                            class="truncate"
                            :value="attachments"
                            :maximum-size="attachmentParameters.maximumAttachmentSize"
                            :multiple="true"
                            :disabled="disallowCommunication"
                            @input="updateAttachment"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import TemplateEditor from '@/components/templates/TemplateEditor.vue';
    import ActivixDragUpload from '@/components/elements/ActivixDragUpload.vue';
    import Lead from '../../entities/Lead.js';

    export default {
        components: {
            ActivixDragUpload,
            TemplateEditor,
        },

        props: {
            subject: {
                type: String,
                required: false,
            },
            design: {
                type: Object,
                default: null,
            },
            template: {
                type: Object,
                default: null,
            },
            body: {
                type: String,
                required: false,
            },
            locale: {
                type: String,
                required: true,
            },
            attachments: {
                type: Array,
                required: true,
            },
            isAdvanced: {
                type: Boolean,
                default: false,
            },
            lead: {
                type: Lead,
                required: true,
            },
            massMailing: {
                type: Boolean,
                default: false,
            },
            communicating: {
                type: Boolean,
                default: false,
            },
            draft: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                templateAttributes: store => store.templateAttributes,
                attachmentParameters: 'attachmentParameters',
            }),

            disallowCommunication() {
                return this.communicating && !this.ownCommunication;
            },

            showFrom() {
                return this.massMailing;
            },
        },

        watch: {
            translation: {
                deep: true,
                handler(newTranslation) {
                    this.$emit('translation-updated', newTranslation);
                },
            },
        },

        methods: {
            updateSubject(subject) {
                this.$emit('update-subject', subject);
            },

            updateDesign(design) {
                this.$emit('update-design', design);
            },

            updateBody(body) {
                this.$emit('update-body', body);
            },

            updateAttachment(attachment) {
                this.$emit('update-attachment', attachment);
            },
        },
    };
</script>

<style lang="less">
.notification-wrapper {
    @apply relative whitespace-nowrap border border-grey-200 p-4 flex items-center;

    width: 363px;

    .subject {
        @apply truncate text-sm text-grey-600;
    }
}
</style>
