export default {
    class_a_diesel: 'Classe A Diesel',
    fifth_wheel: 'Roulotte à sellette',
    hybride: 'Hybride',
    motorized_a: 'Motorisé A',
    motorized_b: 'Motorisé B/B+',
    motorized_c: 'Motorisé C',
    tent_trailer: 'Tente roulotte',
    trailer_park: 'Roulotte de parc',
    travel_trailer: 'Roulotte de voyage',
};
