export default {
    name: 'Name',
    type: 'Type',
    accounts: 'Associated accounts',
    newGroup: 'New group',
    editGroup: 'Edit group',
    alerts: {
        index: {
            error: 'Failed fetching the groups',
        },
        store: {
            error: 'Failed creating the group',
            success: 'Group created successfully',
        },
        show: {
            error: 'Failed fetching the group',
        },
        update: {
            error: 'Failed updating the group',
            success: 'Group updated successfully',
        },
        destroy: {
            error: 'Failed deleting the group',
            success: 'Group deleted successfully',
        },
    },
    deleteGroup: 'Delete the group',
    relatedAccounts: 'Associated accounts',
    relatedUsers: 'Associated users',
    types: {
        franchise: 'Franchise',
        maker: 'Maker',
    },
};
