export default {
    name: 'Nom',
    type: 'Type',
    accounts: 'Comptes associés',
    newGroup: 'Nouveau groupe',
    editGroup: 'Modifier le groupe',
    alerts: {
        index: {
            error: 'Échec de la récupération des groupes',
        },
        store: {
            error: 'Échec de la création du groupe',
            success: 'Groupe créé avec succès',
        },
        show: {
            error: 'Échec de la récupération du groupe',
        },
        update: {
            error: 'Échec de la mise à jour du groupe',
            success: 'Groupe mis à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression du groupe',
            success: 'Groupe supprimé avec succès',
        },
    },
    deleteGroup: 'Supprimer le groupe',
    relatedAccounts: 'Comptes associés',
    relatedUsers: 'Utilisateurs associés',
    types: {
        franchise: 'Franchise',
        maker: 'Fabricant',
    },
};
