import Enum from './Enum.js';

export default class EmailValidationRisk extends Enum {
    static get entries() {
        return {
            high: 'high',
            medium: 'medium',
            low: 'low',
            unknown: 'unknown',
        };
    }

    static get HIGH() {
        return this.entries.high;
    }

    static get MEDIUM() {
        return this.entries.medium;
    }

    static get LOW() {
        return this.entries.low;
    }

    static get UNKNOWN() {
        return this.entries.unknown;
    }
}
