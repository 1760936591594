import '@/plugins/jquery.js';

// jQuery UI
import 'jquery-ui/ui/core.js'; // Required for fullcalendar
import 'jquery-ui/ui/widgets/draggable.js'; // Required for fullcalendar

// Boostrap (CSS is in bootstrap.less)
// @TODO Remove usage of Bootstrap JS
import 'bootstrap/js/transition.js';
import 'bootstrap/js/modal.js';
import 'bootstrap/js/tooltip.js';
import 'bootstrap/js/popover.js';
import 'bootstrap/js/collapse.js';
import 'bootstrap/js/tab.js'; // Required for summernote
import 'bootstrap/js/dropdown.js'; // Required for summernote

// Ajaxq
import 'ajaxq/ajaxq.js';

// Bootstrap DateRangePicker
import '@autosync/atx-bootstrap-daterangepicker/daterangepicker-bs3.css';
import '@autosync/atx-bootstrap-daterangepicker/daterangepicker.js';

// Bootstrap DateTimePicker
import '@autosync/atx-bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import '@autosync/atx-bootstrap-datetimepicker/js/bootstrap-datetimepicker.js';
import '@autosync/atx-bootstrap-datetimepicker/js/locales/bootstrap-datetimepicker.fr.js';

// Summernote
import 'summernote/dist/summernote.css';
import 'summernote/dist/summernote.js';
import 'summernote/dist/lang/summernote-fr-FR.js';
import '@autosync/atx-summernote-image-attributes/summernote-image-attributes.js';
import '@autosync/atx-summernote-image-attributes/lang/fr-FR.js';

// iFrameResizer
import 'iframe-resizer/js/iframeResizer.js';

// Summernote fix to prevent douple line breaks
$.summernote.dom.emptyPara = '<div><br></div>';
