<template>
    <router-view />
</template>

<script>
    export default {
        props: {
            showLoading: {
                type: Boolean,
                required: true,
            },
        },

        mounted() {
            this.$emit('update:showLoading', false);
        },
    };
</script>
