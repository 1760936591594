import { defineStore } from 'pinia';
import state from './state.js';
import actions from './actions.js';
import getters from './getters.js';

export const useClientCardStore = defineStore({
    id: 'clientCard',
    state() {
        return state;
    },
    getters,
    actions,
});
