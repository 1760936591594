import Model from './Model.js';

export default class AccountGuestGroup extends Model {
    static get defaults() {
        return {
            id: null,
            name: '',
            roles: [],
            users: [],
            posts: [],
        };
    }

    /**
     * Getter
     */

    get key() {
        return `G${this.id}`;
    }
}
