export default {
    andUp: 'et plus',
    biWeekly: 'bi-hebdomadaire',
    creditScoreRange: 'Échelle de cote de crédit',
    lender: 'Prêteur',
    maxAmount: 'Montant maximum',
    payment: 'Paiement',
    preQualifiedForALoanOf: 'Pré-qualifié pour un prêt de',
    rate: 'Taux',
    term: 'Terme',
    upTo: 'Jusqu\'à',
    validUntil: 'Valable jusqu\'au',
};
