export default {
    bounce: 'The email address has bounced and is therefore considered invalid.',
    deliverable: {
        high: 'This email address is invalid and can damage your reputation.',
        medium: 'Sending email is not recommended, it could damage your reputation.',
        low: 'The email address is valid.',
        unknown: 'Click to validate the email address.',
    },
    disposable: 'The email address has been identified as disposable and is therefore considered invalid',
    isValidating: 'Validation in progress...',
    reasons: {
        catchAll: 'The validity of the email address cannot be determined.',
        highRiskDomain: 'The domain is considered invalid.',
        immatureDomain: 'The domain is newly created.',
        longTermDisposable: 'The mailbox has been identified as a disposable email address.',
        mailboxDoesNotExist: 'The mailbox is undeliverable or does not exist.',
        mailboxIsDisposableAddress: 'The mailbox has been identified to be a disposable address.',
        mailboxIsRoleAddress: 'The mailbox is a role based email address (ex. support@..., marketing@...).',
        noMx: 'The recipient domain does not have a valid MX host.',
        subdomainMailer: 'Email addresses with unknown subdomains are considered risky.',
        tldRisk: 'The domain has been identified as invalid.',
        unknownProvider: 'The provider is unknown.',
        erroneousFormat: 'The email address is not properly formatted.',
        maxValidationAttemptsExceeded: 'After multiple attempts, the email address could not be validated.',
    },
    sendRisky: 'You are about to send an email to a potentially risky recipient, it could damage the reputation of your email.',
    typo: 'A typo might have been catched. Did you mean "{address}"?',
    validate: 'Validate',
    validateError: "Can't validate this email address right now.",
};
