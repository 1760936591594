export default {
    class_a_diesel: 'Class A Diesel',
    fifth_wheel: 'Fifth wheel',
    hybride: 'Hybrid',
    motorized_a: 'Motorized A',
    motorized_b: 'Motorized B/B+',
    motorized_c: 'Motorized C',
    tent_trailer: 'Tent trailer',
    trailer_park: 'Trailer park',
    travel_trailer: 'Travel trailer',
};
