import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async show(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.get(`v1/flows/${id}`, { params: payload });

        return response.data.data;
    },

    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.put(`v1/flows/${id}`, payload);

        return response.data.data;
    },
};
