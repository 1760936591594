<template>
    <spinner
        :font-size="14"
        :line-bg-color="bgColor"
        :line-fg-color="fgColor"
        :line-size="lineSize"
        :message="message"
        :size="size"
    />
</template>

<script>
    import Spinner from 'vue-simple-spinner';
    import colors from '../../../tailwind.colors.js';

    export default {
        name: 'ActivixSpinner',

        components: {
            Spinner,
        },

        props: {
            size: {
                type: Number,
                default: 60,
            },
            message: {
                type: String,
                default: '',
            },
            lineSize: {
                type: Number,
                default: 4,
            },
        },

        computed: {
            bgColor() {
                return colors.grey[300];
            },

            fgColor() {
                return colors.blue[500];
            },
        },
    };
</script>
