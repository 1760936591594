<template>
    <activix-modal
        size="lg"
        portal="modal-3"
        :loading="$wait.is('fetching.automationJobAsCommunication')"
        :opened="opened"
        @close="close"
        @closed="$emit('closed')"
    >
        <template slot="header">
            <h4 class="modal-title" v-text="title" />
        </template>
        <template slot="body">
            <div class="flex">
                <icon class="cursor-pointer text-grey-650" scale="2.1" :name="icon" />
                <div class="flex-1 flex flex-col mt-2 ml-4">
                    <div class="flex justify-between items-center">
                        <span class="font-semibold text-lg" v-html="subject" />
                        <span class="font-normal text-sm" v-html="date" />
                    </div>
                    <div class="mt-4" v-html="body" />
                </div>
            </div>
        </template>
        <template slot="footer">
            <div class="flex justify-between">
                <div class="flex items-center justify-center" v-if="internalJob && $feature.isEnabled('new-lead-page', false)">
                    <activix-tooltip :content="activateAutomationTooltip">
                        <activix-switcher
                            :disabled="readonly || guest || isPastAutomation"
                            :value="isActiveAutomation"
                            @input="toggleAutomationActivation"
                        />
                    </activix-tooltip>

                    <activix-tooltip :content="$t('communications.automationTooltip')">
                        <router-link
                            target="_blank"
                            :to="{ name: 'automations.update', params: { id: internalJob.action.automation_id } }"
                            v-if="parentAuthUser.hasAccessTo('automations.index')"
                        >
                            <activix-button class="ml-2" size="small">
                                <icon name="regular/video-game-magic-wand" />
                            </activix-button>
                        </router-link>
                    </activix-tooltip>
                </div>

                <div class="flex flex-1 justify-end">
                    <activix-button @click="close">
                        {{ $t('general.close') }}
                    </activix-button>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import { useClientCardStore } from '@/store/modules/clientCard/store.js';
    import { useGlobalStore } from '@/store/store.js';

    import EmailCompose from '@/mixins/EmailCompose.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';
    import AutomationActionType from '@/entities/AutomationActionType.js';

    export default {
        mixins: [EmailCompose],

        props: {
            job: {
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            internalJob: null,
        }),

        computed: {
            ...mapState(useClientCardStore, ['communicationAutomationJobs']),
            ...mapState(useGlobalStore, {
                guest: 'guest',
                parentAuthUser: 'parentAuthUser',
            }),

            activateAutomationTooltip() {
                if (this.isPastAutomation) {
                    return this.$t('automations.pastExecutionDate');
                }

                if (this.isActiveAutomation) {
                    return this.$t('automations.disableJob');
                }

                return this.$t('automations.enableJob');
            },

            isActiveAutomation() {
                return !!this.internalJob?.active;
            },

            isPastAutomation() {
                const executionDate = new ActivixDate(this.internalJob?.execution_date);

                return executionDate.isPast();
            },

            title() {
                if (this.internalJob?.action?.action === AutomationActionType.SEND_SMS) {
                    return this.$t('clientCard.automatedSms');
                }

                return this.$t('clientCard.automatedEmail');
            },

            icon() {
                if (this.internalJob?.action?.action === AutomationActionType.SEND_SMS) {
                    return this.$icons.sms;
                }

                return this.$icons.email;
            },

            subject() {
                return this.internalJob?.parsed_template?.subject;
            },

            body() {
                return this.internalJob?.parsed_template?.body;
            },

            date() {
                if (!this.internalJob) {
                    return '';
                }

                return new ActivixDate(this.internalJob.execution_date).toHumanShort();
            },
        },

        watch: {
            job() {
                this.syncJobData();
            },

            opened() {
                this.syncJobData();
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
            },

            async fetchAutomationJobAsCommunication() {
                this.$wait.start('fetching.automationJobAsCommunication');

                this.internalJob = this.job;

                try {
                    this.internalJob = await this.$api.automationJobs.getAsCommunication(this.job.id);
                } catch (_) {
                    this.$notify.warning(this.$t('automations.errors.fetchCommunicationAutomation'));
                }

                this.$wait.end('fetching.automationJobAsCommunication');
            },

            syncJobData() {
                if (!this.opened) {
                    return;
                }

                if (this.job.parsed_template) {
                    this.internalJob = this.job;

                    return;
                }

                if (this.job.id === this.internalJob?.id) {
                    this.internalJob = {
                        ...this.internalJob,
                        ...this.job,
                    };

                    return;
                }

                const existingCommunicationAutomationJob = this.communicationAutomationJobs.find(job => job.id === this.job.id);

                if (existingCommunicationAutomationJob) {
                    this.internalJob = existingCommunicationAutomationJob;

                    return;
                }

                this.fetchAutomationJobAsCommunication();
            },

            toggleAutomationActivation(active) {
                this.$emit('update-automation-job', {
                    id: this.internalJob.id,
                    payload: { active },
                });
            },
        },
    };
</script>
