<template>
    <activix-banner :color="color" @close="close" v-if="hasIncidents">
        <span class="truncate" v-t="textPath" />
        <activix-tooltip :content="informationTooltip">
            <icon class="text-sm ml-1 shrink-0" name="regular/information-circle" />
        </activix-tooltip>

        <template #link>
            <a :href="statusPageUrl" target="_blank" class="ml-2 text-white font-bold underline">
                {{ $t('statusPage.learnMore') }} &rarr;
            </a>
        </template>
    </activix-banner>
</template>

<script>
    import ActivixBanner from './elements/ActivixBanner.vue';

    export default {
        components: {
            ActivixBanner,
        },

        data() {
            return {
                incidents: [],
                statusPageUrl: process.env.VUE_APP_STATUS_PAGE_URL,
            };
        },

        computed: {
            currentIncident() {
                if (!this.hasIncidents) {
                    return {};
                }

                return this.incidents[0];
            },

            hasIncidents() {
                return !!this.incidents.length;
            },

            color() {
                switch (this.currentIncident.impact) {
                    case 'critical':
                        return 'red';

                    case 'major':
                        return 'orange';

                    case 'minor':
                    case 'maintenance':
                    case 'none':
                    default:
                        return 'gray';
                }
            },

            textPath() {
                switch (this.currentIncident.type) {
                    case 'maintenance':
                        return 'statusPage.text.maintenance';
                    case 'incident':
                    default:
                        return 'statusPage.text.incident';
                }
            },

            affectedServices() {
                return this.currentIncident.components || [];
            },

            informationTooltip() {
                return `
                    <div class="text-left">
                        <div class="font-bold">
                            ${this.currentIncident.title}
                        </div>
                        ${this.currentIncident.text}
                        <div class="font-bold mt-3">
                            ${this.$tc('statusPage.affected', this.affectedServices.length)}
                        </div>
                        ${this.affectedServices.join(', ')}
                    </div>
                `;
            },
        },

        methods: {
            async fetchIncidents() {
                if (!this.$ls.get('statusBanner:dismissed')) {
                    this.incidents = await this.$statusPage.fetch();
                }

                const thirtySeconds = 30 * 1000;
                const fiveMinutes = 5 * 60 * 1000;

                setTimeout(
                    () => {
                        this.fetchIncidents();
                    },
                    this.incidents.length ? thirtySeconds : fiveMinutes,
                );
            },

            close() {
                this.incidents = [];
                const oneHour = 60 * 60 * 1000;

                this.$ls.set('statusBanner:dismissed', true, oneHour);
            },
        },

        mounted() {
            this.fetchIncidents();
        },
    };
</script>
