export default {
    alerts: {
        index: {
            error: 'Échec de la récupération des vues',
        },
        store: {
            error: 'Échec de la création de la vue',
            success: 'Vue créée avec succès',
        },
        show: {
            error: 'Échec de la récupération de la vue',
        },
        update: {
            error: 'Échec de la mise à jour de la vue',
            success: 'Vue mise à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression de la vue',
            success: 'Vue supprimée avec succès',
        },
    },
};
