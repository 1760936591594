<template>
    <transition
        enter-class="translate-y-full"
        enter-to-class="translate-y-0"
        leave-class="translate-y-0"
        leave-to-class="translate-y-full"
    >
        <div
            :class="[
                collapsed ? 'h-12' : 'h-main-view',
                'max-w-[calc(100vw-1.5rem)] w-[25rem] lg:w-[30rem]',
                'bg-gray-100 rounded-lg shadow-origin-xl',
                'pointer-events-auto overflow-hidden',
                'transition-all duration-300',
            ]"
            v-show="showing"
        >
            <add-lead
                :visible="!collapsed"
                :collapsed.sync="collapsed"
                @close="hide"
                v-if="type === 'addLead'"
            />
            <inter-account-leads
                :collapsed.sync="collapsed"
                @close="hide"
                v-if="type === 'interAccountLeads' && showing"
            />
        </div>
    </transition>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useInterAccountLeadsStore } from '@/store/modules/interAccountLeads/store.js';

    import AddLead from '@/components/add_lead/AddLead.vue';
    import InterAccountLeads from '@/components/inter_account_leads/InterAccountLeads.vue';

    export default {
        components: {
            AddLead,
            InterAccountLeads,
        },

        props: {
            type: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            collapsed: false,
            showing: false,
        }),

        methods: {
            ...mapActions(useInterAccountLeadsStore, ['setInterAccountLeadsOpened']),

            show(activeSidebar) {
                if (activeSidebar !== this.type) {
                    return;
                }

                if (this.type === 'interAccountLeads') {
                    this.setInterAccountLeadsOpened(true);
                }

                this.collapsed = false;
                this.showing = true;
            },

            hide() {
                if (this.type === 'interAccountLeads') {
                    this.setInterAccountLeadsOpened(false);
                }

                this.showing = false;
            },
        },

        created() {
            this.$eventBus.$on('show-right-sidebar', this.show);
        },

        beforeDestroy() {
            this.$eventBus.$off('show-right-sidebar', this.show);
        },
    };
</script>
