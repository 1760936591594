<template>
    <div class="flex items-center justify-center h-screen w-screen">
        <div class="text-center text-grey-650">
            <div class="mb-6 flex justify-center">
                <img class="h-8" src="../assets/img/logo_activix_crm_gray.png" alt="Activix CRM" />
            </div>
            <slot>
                <router-view />
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            showLoading: {
                type: Boolean,
                required: true,
            },
        },

        mounted() {
            this.$emit('update:showLoading', false);
        },
    };
</script>
